import request from '@/utils/request';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/api/loreessay`;
const BasUserUrl = `${AccountDomain}/api/user/loreessay`;

// 查询文章投稿
export async function queryArticleAudit(data: any) {
  return request({
    url: `${BasUserUrl}/getloreessayaudit`,
    method: 'post',
    data
  });
}
// 获取文章投稿详情
export async function queryArticleDraft(data: any) {
  return request({
    url: `${BasUserUrl}/getloreessayauditall`,
    method: 'post',
    data
  });
}
// 保存文章投稿
export async function saveArticle(data: any) {
  return request({
    url: `${BasUserUrl}/setloreessayaudit`,
    method: 'post',
    data
  });
}
// 删除文章投稿
export async function deleteArticle(data: any) {
  return request({
    url: `${BasUserUrl}/setloreessayaudit`,
    method: 'post',
    data
  });
}
// 查询文章
export async function getArticle(data: any) {
  return request({
    url: `${BaseUrl}/setloreessayaudit`,
    method: 'post',
    data
  });
}
// 查询所有知识文章
export async function getAllArticle(data: any) {
  return request({
    url: `${BaseUrl}/getloreessayall`,
    method: 'post',
    data
  });
}
//获取知识文章收藏数据
export async function getArticleCollect(data: any) {
  return request({
    url: `${BasUserUrl}/getloreessaycollect`,
    method: 'post',
    data
  });
}
// 更新知识文章收藏数据
export async function updateArticleCollect(data: any) {
  return request({
    url: `${BasUserUrl}/replaceloreessaycollect`,
    method: 'post',
    data
  });
}
// 删除知识文章收藏数据
export async function deleteArticleCollect(data: any) {
  return request({
    url: `${BasUserUrl}/delloreessaycollect`,
    method: 'post',
    data
  });
}
// 知识文章点赞
export async function likeArticle(data: any) {
  return request({
    url: `${BasUserUrl}/loreessaylike`,
    method: 'post',
    data
  });
}







