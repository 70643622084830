<template>
  <div class="guide-contact-card">
    <div class="icon" :style="{ background: 'url(' + iconPath + ') no-repeat', backgroundSize: 'contain' }"></div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'guide-contact-card',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    clickMore() {
      this.$emit('onMore');
    }
  }
}

</script>

<style lang="scss" scoped>
.guide-contact-card{
  width: 224px;
  height: 123px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.30);
  .icon{
    min-width: 40px;
    min-height: 40px;
  }
  .title{
    color:  #FFF;
    text-align: center;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
</style>
