var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "game-intro",
      style: { height: _vm.boxSize.height + "rem" },
      on: {
        mouseenter: _vm.handleEnter,
        mouseleave: _vm.handleLeave,
        click: _vm.handleToGame,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isHover,
              expression: "!isHover",
            },
          ],
        },
        [
          _c("img", {
            staticClass: "game-intro-img",
            style: { width: _vm.boxSize.imgWidth + "rem" },
            attrs: {
              src:
                (_vm.info.store &&
                  _vm.info.store.medias &&
                  _vm.info.store.medias.cover &&
                  _vm.getCosUrls(_vm.info.store.medias.cover, "base")) ||
                _vm.DefaultGameCover,
              alt: "",
            },
          }),
        ]
      ),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.curVideo && _vm.isHover,
            expression: "curVideo && isHover",
          },
        ],
        ref: "coverMedia",
        staticClass: "imgs",
        style: { width: _vm.boxSize.imgWidth + "rem" },
        attrs: {
          src: _vm._f("fCosBase")(_vm.curVideo),
          poster: _vm._f("fCosPoster")(_vm.curVideo),
          autoplay: "",
          muted: "",
        },
        domProps: { muted: true },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mediaList.length && _vm.isHover,
              expression: "mediaList.length && isHover",
            },
          ],
          staticClass: "cover",
          style: { width: _vm.boxSize.imgWidth + "rem" },
        },
        _vm._l(_vm.mediaList, function (cover, i) {
          return _c("img", {
            key: i,
            staticClass: "imgs",
            class: i == _vm.curCoverIndex ? "show" : "",
            style: { opacity: i == _vm.curCoverIndex ? "1" : "0" },
            attrs: { src: _vm._f("fCosBase")(cover.url) },
          })
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.mediaList.length && !_vm.curVideo && _vm.isHover,
              expression: "!mediaList.length && !curVideo && isHover",
            },
          ],
          staticClass: "cover",
          style: { width: _vm.boxSize.imgWidth + "rem" },
        },
        [
          _c("img", {
            staticClass: "imgs",
            attrs: {
              src:
                (_vm.info.store &&
                  _vm.info.store.medias &&
                  _vm.info.store.medias.cover &&
                  _vm.getCosUrls(_vm.info.store.medias.cover, "base")) ||
                _vm.DefaultGameCover,
              alt: "",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "game-intro-info" }, [
        _c("div", { staticClass: "game-intro-info-text" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                (_vm.info.store &&
                  _vm.info.store.info &&
                  _vm.info.store.info.productName) ||
                  ""
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "game-intro-info-bottom" }, [
          !_vm.isHover
            ? _c(
                "div",
                { staticClass: "game-intro-info-bottom-rate" },
                [
                  _c("el-rate", {
                    attrs: {
                      colors: _vm.scoreColors,
                      "void-color": _vm.scoreVoidColor,
                      "disabled-void-color": _vm.scoreVoidColor,
                      disabled: "",
                      "allow-half": true,
                    },
                    model: {
                      value: _vm.info.score,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "score", $$v)
                      },
                      expression: "info.score",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.commentList) + "人评测")]),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "game-intro-info-bottom-text",
                  attrs: {
                    title:
                      (_vm.info.store &&
                        _vm.info.store.info &&
                        _vm.info.store.info.summary) ||
                      "",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      (_vm.info.store &&
                        _vm.info.store.info &&
                        _vm.info.store.info.summary) ||
                        ""
                    )
                  ),
                ]
              ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "platform" },
        _vm._l(_vm.platformList, function (val, i) {
          return _c("span", { key: i }, [
            _c("img", {
              attrs: {
                src: require(`../../../assets/shop/${val}.svg`),
                alt: "",
              },
            }),
          ])
        }),
        0
      ),
      _c("span", { staticClass: "tips" }, [_vm._v("免费")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }