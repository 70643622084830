
import { Component, Vue } from 'vue-property-decorator'
import { Loading } from 'element-ui'
import { ALogin } from '@/api/user'
import { saveContinueQuery, getParam } from '@/utils'
import { clientId, AuthService, redirectUri, responseType, scope } from '@/config/auth'
import { saveCookie } from '@/utils/cache'
import { randomString } from '@/utils/string'
import { isClient } from '@/utils/env'
import { clearUserSession, Local, verifyUserSession } from '@/utils/storage'
import { OasisSessionKey } from '@/config'
import { oasisToken } from '@/api/auth'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'AccountLogin',
  components: { },
})
class AccountLogin extends Vue {
  public loading = {
    third: false,
  }
  // 是否check state字段,用来判断是否本端发送的登录请求
  public checkState = true;
  public processType = '';

  mounted() {
    console.log('mounted');
    this.checkCurrentStatus();
  }

  public goToLogin(type: string) {
    if (type == '' || type == 'email') {
      this.processType = type
    }
    // 第三方渠道——登录系统
    else {
      // 特殊参数入storage
      saveContinueQuery()
      this.loading.third = true
      ALogin({ provider: type }).then((data: any) => {
        console.error(data)
        this.loading.third = false
        if (!data) return
        if (data.cookie) {
          saveCookie(data.cookie)
        }
        if (data && data.redirect_browser_to) {
          // alert(data.redirect_browser_to)
          const oasisBackRoute = {
            path: <string>this.$route.query?.redirect || (getParam('sso_source') ? '/account/login' : '/'),
            query: Object.keys(this.$route.query && <string>this.$route.query?.params || {}).length > 0 ? JSON.parse(<string>this.$route.query?.params) : '',
          };
          console.log('localStorage.setItem oasisBackRoute', oasisBackRoute)
          localStorage.setItem('oasisBackRoute', JSON.stringify({ time: Date.now(), route: oasisBackRoute }))
          // client且非ioa渠道，打开浏览器
          if (isClient && window.oasis && type != 'ioa') {
            // 第三方渠道，如果在Client中，拉起浏览器
            window.oasis.openUrl(`${window.location.protocol}//${window.location.host}/account/redirect/${type}?to=${encodeURIComponent(data.redirect_browser_to)}`)
          } else {
            window.location.href = data.redirect_browser_to
          }
        }
      }).catch(err => {
        console.error(err)
        this.$message.error(err)
        this.loading.third = false
      })
    }
  }
  public goToOasis() {
    const authState = randomString(16);
    Local.set('authState', authState);
    const authUrl = `${AuthService.OASIS_DEV}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${authState}`;
    window.location.href = authUrl;
  }

  public async checkCurrentStatus() {
    const { state: queryState, code: authCode } = this.$route.query;
    const localAuthState = Local.get('authState');
    console.log('state:', localAuthState, queryState);
    console.log('authCode:', authCode);
    // 如果有code标记并符合state，则先进行授权。
    if (this.checkState && queryState === localAuthState && authCode) {
      const loginResult = await this.checkLoginAuth();
      if (loginResult) {
        return;
      }
    }
    // 授权失败｜不需要授权，验证本地session是否存在,是否可用
    const verifyResult = await verifyUserSession();
    if (verifyResult) {
      await this.loginSuccess();
      return;
    }
    // 本地session无效,清理
    clearUserSession();
  }

  public async checkLoginAuth() {
    const { code: authCode } = this.$route.query;
    const loadComponent = Loading.service({
      lock: true,
      text: '授权中···',
      background: 'rgba(0, 0, 0, 0.6)'
    });
    const oasisResult = await oasisToken({
      code: authCode,
      redirect_uri: redirectUri,
      login_channel: 0,
      grant_type: 'authorization_code'
    });
    const { data: oasisData } = oasisResult;
    loadComponent.close();
    if (oasisData) {
      Local.set(OasisSessionKey, oasisData);
      await this.loginSuccess();
      return true;
    }
    // code无效,刷新路由，清理code参数避免重复验证
    this.$router.push({ path: '/account/login' })
  }

  public async loginSuccess() {
    Local.remove('authState');
    await UserModule.GTGetUserInfo();
    this.$router.push('/');
  }
}
export default AccountLogin
