
export enum EInstallStatus {
  NotInstall = -1,
  Installing = 0,
  Installed = 1,
}
export enum EProductStatus {
  Developing = 0,
  ComingSoon,
  Experience,
  Released,
}
export enum EClientStatus {
  ComingSoon = 0,
  NotInstall,
  Installing,
  Installed,
}
