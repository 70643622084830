import { render, staticRenderFns } from "./productSet.vue?vue&type=template&id=6c3b8eb2&scoped=true&"
import script from "./productSet.vue?vue&type=script&lang=ts&"
export * from "./productSet.vue?vue&type=script&lang=ts&"
import style0 from "./productSet.vue?vue&type=style&index=0&id=6c3b8eb2&lang=scss&scoped=true&"
import style1 from "./productSet.vue?vue&type=style&index=1&id=6c3b8eb2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c3b8eb2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/landun/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c3b8eb2')) {
      api.createRecord('6c3b8eb2', component.options)
    } else {
      api.reload('6c3b8eb2', component.options)
    }
    module.hot.accept("./productSet.vue?vue&type=template&id=6c3b8eb2&scoped=true&", function () {
      api.rerender('6c3b8eb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/developer/app/component/productSet.vue"
export default component.exports