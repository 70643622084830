<template>
  <div class="gate-guide-about">
    <div class="gate-guide-contain">
      <guideTitle :title="$t('views.guide.aboutUs.title')" :hasMore="false"/>
      <div class="gate-guide-about-box">
        <div class="first-info">
          <div class="title">{{ $t('views.guide.aboutUs.introTitle1') }}</div>
          <div class="greenStroke"></div>
          <div class="content">{{ $t('views.guide.aboutUs.introContent1') }}</div>
          <div class="introduction" :style="{ backgroundImage: 'url(' + introAIImg + ') ' }">
            <div class="content">
              <div class="intro" v-for="(item, index) in $t('views.guide.aboutUs.stations')" :key="index">
                <div class="title">{{ item.title }}</div>
                <div class="label">{{ item.label }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <guideIntroduction :title="$t('views.guide.aboutUs.introTitle1')" :content="$t('views.guide.aboutUs.introContent1')" :iconPath="introductionBg" /> -->
        <guideIntroduction :title="$t('views.guide.aboutUs.introTitle2')" :content="$t('views.guide.aboutUs.introContent2')" :iconPath="introKnowledgeImg" :isReserve="true"/>
        <guideIntroduction :title="$t('views.guide.aboutUs.introTitle3')" :content="$t('views.guide.aboutUs.introContent3')" :iconPath="introGameImg" />
      </div>
    </div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import guideIntroduction from './components/guideIntroduction.vue';
import introductionBg from '../../assets/guide/introduction.png';
import introAIImg from '../../assets/guide/about/intro_ai.png';
import introKnowledgeImg from '../../assets/guide/about/intro_knowledge.png';
import introGameImg from '../../assets/guide/about/intro_gamejam.png';
export default {
  name: 'guide-about',
  components: { guideTitle, guideIntroduction },
  data() {
    return {
      introductionBg,
      introAIImg,
      introKnowledgeImg,
      introGameImg,
      introductionList: []
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.gate-guide-about {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000;
  .gate-guide-contain{
    width: 1200px;
    height: 1750px;
    margin-top: 150px;
    .gate-guide-about-box{
      margin-top: 20px;
      width: 100%;
      .first-info{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        .title{
          color: #F3F4F6;
          font-size: 44px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
        .greenStroke{
          width: 100px;
          height: 0px;
          border: 2px solid #31C48D;
          margin: 20px 0;
        }
        .content{
          color: #F3FAF7;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 150%;
          opacity: 0.65;
        }
        .introduction{
          width: 100%;
          height: 400px;
          background-size: auto 100%;
          background-position: left center;
          background-repeat: no-repeat;
          justify-content: end;
          flex-direction: row;
          display: flex;
          align-content: center;
          .content{
            width: 668px;
            height: 368px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            background: url('../../assets/guide/about/about_intro_bg.png') no-repeat;
            background-size: 100% 100%;
            .intro{
              width: 320px;
              height: 180px;
              padding: 32px 20px;
              display: flex;
              flex-direction: column;
              .title{
                font-size: 28px;
                color: #F3FAF7;
                
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
              }
              .label{
                color: #F3FAF7;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                opacity: 0.65;
              }
            }
          }
        }
      }
    }
  }
}
</style>
