
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AStoreTriUpt } from '@/api/developerApi/developer'
import { DeveloperModule } from '@/store/modules/developer'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'CompStoreExprience',
})
class CompStoreExprience extends Vue {
  public form = {
    enableExperience: false,
    developerComment: '',
  }
  public rules = {}
  public loading = {
    save: false,
    exp: false,
  }
  private appId = this.$route.query.appId as string

  get cHasChanged() {
    return true
  }
  get devModule() {
    return this.$store.state.developer
  }
  get storeExp() {
    return this.devModule.storeData.exp
  }

  mounted() {
    this.initData()
  }

  public onCancel() {
    this.reset()
  }
  public async submitForm() {
    this.$report('dev/store/exp/save', 'click', { appid: this.appId })
    this.loading.save = true
    const param = {
      enableExperience: this.form.enableExperience,
      developerComment: this.form.developerComment,
    }
    AStoreTriUpt(this.appId, param).then(() => {
      this.loading.save = false
      this.$message.success(this.$t('tips.succ.edit') as string)
      DeveloperModule.MAGetDevStore(this.appId)
    }).catch(err => {
      console.error(err)
      this.loading.save = false
      this.$message.error(this.$t('tips.fail.edit') as string)
    })
  }
  
  private async initData() {
    this.loading.exp = true
    if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    this.loading.exp = false
    this.initMedias()
  }
  private initMedias() {
    this.form.enableExperience = this.storeExp.enableExperience
    this.form.developerComment = this.storeExp.developerComment
  }
  private reset() {
    (this.$refs.formRef as ElForm)?.clearValidate()
    this.initMedias()
  }
}
export default CompStoreExprience
