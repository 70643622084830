var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "page-developer-app-store" } },
    [
      _c("div", { staticClass: "tabs-head" }, [
        _c(
          "div",
          {
            staticClass: "tabs-head-item",
            class: { active: _vm.tab.store == "general" },
            on: {
              click: function ($event) {
                return _vm.changeTab("general")
              },
            },
          },
          [_vm._v("基本信息")]
        ),
        _c(
          "div",
          {
            staticClass: "tabs-head-item",
            class: { active: _vm.tab.store == "media" },
            on: {
              click: function ($event) {
                return _vm.changeTab("media")
              },
            },
          },
          [_vm._v("宣传素材")]
        ),
        _c(
          "div",
          {
            staticClass: "tabs-head-item",
            class: { active: _vm.tab.store == "build" },
            on: {
              click: function ($event) {
                return _vm.changeTab("build")
              },
            },
          },
          [_vm._v("构件")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "tabs-content" },
        [
          _c(
            "keep-alive",
            [
              _vm.tab.store == "general"
                ? _c("StoreGeneral", {
                    on: { changeTab: (tabName) => (_vm.tab.store = tabName) },
                  })
                : _vm._e(),
              _vm.tab.store == "media"
                ? _c("StoreMedia", {
                    on: { changeTab: (tabName) => (_vm.tab.store = tabName) },
                  })
                : _vm._e(),
              _vm.tab.store == "build" ? _c("StoreBuildAI") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }