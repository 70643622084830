var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "oauth" }, [
    _c("div", { staticClass: "oauth-content" }, [
      _vm.isValid
        ? _c("h3", [_vm._v("Oauth重定向中···")])
        : _vm.error
        ? _c("h3", [
            _c("p", [_vm._v("Oauth重定向失败 " + _vm._s(_vm.error))]),
            _c("p", [_vm._v(_vm._s(_vm.errorDesc))]),
          ])
        : _c("h3", [_vm._v("Oauth重定向失败，请重新尝试")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }