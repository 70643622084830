var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _vm.relustLoading
      ? _c("div", { staticClass: "loading-warp" }, [
          _c("div", { staticClass: "loading-warp__box" }, [
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.relustLoading,
                  expression: "relustLoading",
                },
              ],
              attrs: {
                "element-loading-text": "数据加载中",
                "element-loading-spinner": "el-icon-loading",
              },
            }),
          ]),
        ])
      : _vm._e(),
    !_vm.relustLoading && !_vm.relustData
      ? _c("div", { staticClass: "result-warp" }, [
          _c("div", { staticClass: "result-warp__title title" }, [
            _vm._v("获奖结果"),
          ]),
          _c("div", { staticClass: "result-warp__list" }, [
            !_vm.isMobile
              ? _c("div", { staticClass: "result-warp__list-top" }, [
                  _c("div", { staticClass: "result-warp__second-box" }, [
                    _c("div", { staticClass: "result-warp__ranking" }),
                    _c("img", {
                      attrs: {
                        src:
                          (_vm.topThreeList[1].medias &&
                            _vm.topThreeList[1].medias.cover &&
                            _vm.getCosUrl(_vm.topThreeList[1].medias.cover)) ||
                          _vm.DefaultGameCover,
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "result-warp__comment" }, [
                      _c("p", [_vm._v(_vm._s(_vm.topThreeList[1].worksname))]),
                      _c("div", [
                        _vm._v(
                          "团队名称：" + _vm._s(_vm.topThreeList[1].teamname)
                        ),
                      ]),
                      _c("span", [_vm._v("专家评语")]),
                      _c("div", { staticClass: "result-warp__expertreview" }, [
                        _vm._v(_vm._s(_vm.topThreeList[1].expertreview)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "result-warp__first-box" }, [
                    _c("div", { staticClass: "result-warp__ranking" }),
                    _c("img", {
                      attrs: {
                        src:
                          (_vm.topThreeList[0].medias &&
                            _vm.topThreeList[0].medias.cover &&
                            _vm.getCosUrl(_vm.topThreeList[0].medias.cover)) ||
                          _vm.DefaultGameCover,
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "result-warp__comment" }, [
                      _c("p", [_vm._v(_vm._s(_vm.topThreeList[0].worksname))]),
                      _c("div", [
                        _vm._v(
                          "团队名称： " + _vm._s(_vm.topThreeList[0].teamname)
                        ),
                      ]),
                      _c("span", [_vm._v("专家评语")]),
                      _c("div", { staticClass: "result-warp__expertreview" }, [
                        _vm._v(_vm._s(_vm.topThreeList[0].expertreview)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "result-warp__third-box" }, [
                    _c("div", { staticClass: "result-warp__ranking" }),
                    _c("img", {
                      attrs: {
                        src:
                          (_vm.topThreeList[2].medias &&
                            _vm.topThreeList[2].medias.cover &&
                            _vm.getCosUrl(_vm.topThreeList[2].medias.cover)) ||
                          _vm.DefaultGameCover,
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "result-warp__comment" }, [
                      _c("p", [_vm._v(_vm._s(_vm.topThreeList[2].worksname))]),
                      _c("div", [
                        _vm._v(
                          "团队名称： " + _vm._s(_vm.topThreeList[2].teamname)
                        ),
                      ]),
                      _c("span", [_vm._v("专家评语")]),
                      _c("div", { staticClass: "result-warp__expertreview" }, [
                        _vm._v(_vm._s(_vm.topThreeList[2].expertreview)),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            !_vm.isMobile
              ? _c(
                  "div",
                  { staticClass: "result-warp__works-list" },
                  _vm._l(_vm.worksList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "result-warp__works-box" },
                      [
                        _c("div", {
                          class: `result-warp__ranking ranking${item.rankno}`,
                        }),
                        _c("div", { staticClass: "result-warp__img-box" }, [
                          _c("img", {
                            attrs: {
                              src:
                                (item.medias &&
                                  item.medias.cover &&
                                  _vm.getCosUrl(item.medias.cover)) ||
                                _vm.DefaultGameCover,
                              alt: "",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "result-warp__comment" }, [
                          _c("p", [_vm._v(_vm._s(item.worksname))]),
                          _c("div", [
                            _vm._v("团队名称： " + _vm._s(item.teamname)),
                          ]),
                          _c("span", [_vm._v("专家评语")]),
                          _c(
                            "div",
                            { staticClass: "result-warp__expertreview" },
                            [_vm._v(_vm._s(item.expertreview))]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.isMobile
              ? _c(
                  "div",
                  { staticClass: "result-warp__works-list" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "result-warp__works-box result-warp__works-mobile-box",
                        style: _vm.topThreeList[0].isExpertreview
                          ? "height: auto;"
                          : "",
                      },
                      [
                        _c("div", {
                          class: `result-warp__ranking ranking${_vm.topThreeList[0].rankno}`,
                        }),
                        _c("div", { staticClass: "result-warp__works-top" }, [
                          _c("img", {
                            attrs: {
                              src:
                                (_vm.topThreeList[0].medias &&
                                  _vm.topThreeList[0].medias.cover &&
                                  _vm.getCosUrl(
                                    _vm.topThreeList[0].medias.cover
                                  )) ||
                                _vm.DefaultGameCover,
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "result-warp__names" }, [
                            _c("p", [
                              _vm._v(_vm._s(_vm.topThreeList[0].worksname)),
                            ]),
                            _c("div", [
                              _vm._v(
                                "团队名称： " +
                                  _vm._s(_vm.topThreeList[0].teamname)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "divider" }),
                        _c(
                          "div",
                          { staticClass: "result-warp__expertreview-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "result-warp__expertreview-name-box",
                                on: {
                                  click: function ($event) {
                                    return _vm.isShowComment(
                                      _vm.topThreeList[0].rankno,
                                      _vm.topThreeList[0].isExpertreview,
                                      0
                                    )
                                  },
                                },
                              },
                              [
                                _c("p", [_vm._v("专家评语")]),
                                _vm.topThreeList[0].isExpertreview
                                  ? _c("img", {
                                      attrs: {
                                        src: require("../../assets/aiCompetion/mobile/icon-up.png"),
                                        alt: "",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("../../assets/aiCompetion/mobile/icon-down.png"),
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm.topThreeList[0].isExpertreview
                              ? _c(
                                  "div",
                                  { staticClass: "result-warp__expertreview" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.topThreeList[0].expertreview)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "result-warp__works-box result-warp__works-mobile-box",
                        style: _vm.topThreeList[1].isExpertreview
                          ? "height: auto;"
                          : "",
                      },
                      [
                        _c("div", {
                          class: `result-warp__ranking ranking${_vm.topThreeList[1].rankno}`,
                        }),
                        _c("div", { staticClass: "result-warp__works-top" }, [
                          _c("img", {
                            attrs: {
                              src:
                                (_vm.topThreeList[1].medias &&
                                  _vm.topThreeList[1].medias.cover &&
                                  _vm.getCosUrl(
                                    _vm.topThreeList[1].medias.cover
                                  )) ||
                                _vm.DefaultGameCover,
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "result-warp__names" }, [
                            _c("p", [
                              _vm._v(_vm._s(_vm.topThreeList[1].worksname)),
                            ]),
                            _c("div", [
                              _vm._v(
                                "团队名称： " +
                                  _vm._s(_vm.topThreeList[1].teamname)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "divider" }),
                        _c(
                          "div",
                          { staticClass: "result-warp__expertreview-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "result-warp__expertreview-name-box",
                                on: {
                                  click: function ($event) {
                                    return _vm.isShowComment(
                                      _vm.topThreeList[1].rankno,
                                      _vm.topThreeList[1].isExpertreview,
                                      1
                                    )
                                  },
                                },
                              },
                              [
                                _c("p", [_vm._v("专家评语")]),
                                _vm.topThreeList[1].isExpertreview
                                  ? _c("img", {
                                      attrs: {
                                        src: require("../../assets/aiCompetion/mobile/icon-up.png"),
                                        alt: "",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("../../assets/aiCompetion/mobile/icon-down.png"),
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm.topThreeList[1].isExpertreview
                              ? _c(
                                  "div",
                                  { staticClass: "result-warp__expertreview" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.topThreeList[1].expertreview)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "result-warp__works-box result-warp__works-mobile-box",
                        style: _vm.topThreeList[2].isExpertreview
                          ? "height: auto;"
                          : "",
                      },
                      [
                        _c("div", {
                          class: `result-warp__ranking ranking${_vm.topThreeList[2].rankno}`,
                        }),
                        _c("div", { staticClass: "result-warp__works-top" }, [
                          _c("img", {
                            attrs: {
                              src:
                                (_vm.topThreeList[2].medias &&
                                  _vm.topThreeList[2].medias.cover &&
                                  _vm.getCosUrl(
                                    _vm.topThreeList[2].medias.cover
                                  )) ||
                                _vm.DefaultGameCover,
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "result-warp__names" }, [
                            _c("p", [
                              _vm._v(_vm._s(_vm.topThreeList[2].worksname)),
                            ]),
                            _c("div", [
                              _vm._v(
                                "团队名称： " +
                                  _vm._s(_vm.topThreeList[2].teamname)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "divider" }),
                        _c(
                          "div",
                          { staticClass: "result-warp__expertreview-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "result-warp__expertreview-name-box",
                                on: {
                                  click: function ($event) {
                                    return _vm.isShowComment(
                                      _vm.topThreeList[2].rankno,
                                      _vm.topThreeList[2].isExpertreview,
                                      2
                                    )
                                  },
                                },
                              },
                              [
                                _c("p", [_vm._v("专家评语")]),
                                _vm.topThreeList[2].isExpertreview
                                  ? _c("img", {
                                      attrs: {
                                        src: require("../../assets/aiCompetion/mobile/icon-up.png"),
                                        alt: "",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("../../assets/aiCompetion/mobile/icon-down.png"),
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm.topThreeList[2].isExpertreview
                              ? _c(
                                  "div",
                                  { staticClass: "result-warp__expertreview" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.topThreeList[2].expertreview)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._l(_vm.worksList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "result-warp__works-box result-warp__works-mobile-box",
                          style: item.isExpertreview ? "height: auto;" : "",
                        },
                        [
                          _c("div", {
                            class: `result-warp__ranking ranking${item.rankno}`,
                          }),
                          _c("div", { staticClass: "result-warp__works-top" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  (item.medias &&
                                    item.medias.cover &&
                                    _vm.getCosUrl(item.medias.cover)) ||
                                  _vm.DefaultGameCover,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "result-warp__names" }, [
                              _c("p", [_vm._v(_vm._s(item.worksname))]),
                              _c("div", [
                                _vm._v("团队名称： " + _vm._s(item.teamname)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "divider" }),
                          _c(
                            "div",
                            { staticClass: "result-warp__expertreview-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "result-warp__expertreview-name-box",
                                  on: {
                                    click: function ($event) {
                                      return _vm.isShowComment(
                                        item.rankno,
                                        item.isExpertreview,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("p", [_vm._v("专家评语")]),
                                  item.isExpertreview
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../../assets/aiCompetion/mobile/icon-up.png"),
                                          alt: "",
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("../../assets/aiCompetion/mobile/icon-down.png"),
                                          alt: "",
                                        },
                                      }),
                                ]
                              ),
                              item.isExpertreview
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "result-warp__expertreview",
                                    },
                                    [_vm._v(_vm._s(item.expertreview))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm.relustData
      ? _c("div", { staticClass: "no-data-warp" }, [
          _c("div", { staticClass: "no-data-box" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/aiCompetion/result-no-data.png"),
                alt: "",
              },
            }),
            _c("p", [_vm._v("7月10日公布评选结果，敬请期待")]),
            _c(
              "button",
              {
                staticClass: "check-works",
                on: {
                  click: function ($event) {
                    return _vm.toPath("/aicompetions/allworks")
                  },
                },
              },
              [_vm._v("查看全部作品")]
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "bottom" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }