
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinAISeason from '@/mixins/aiSeason'
import { ElForm } from 'element-ui/types/form'
import Dialog from '@/components/dialog/index.vue'
import { DeveloperModule } from '@/store/modules/developer'
import { AClientAdd, AClientUpt, AClientDel } from '@/api/developerApi/client'
import { getDocumentUrl } from '@/utils'
import clipboard from '@/utils/clipboard'
import { RegUrlLax } from '@/utils/regs';
import { RegIp } from '@/utils/regs'
import { AccountDomain } from '@/utils/env';
import { validateBackspace } from '@/utils/validator'

const MaxLenName = 12
const OfficialRedirectUrl = `${AccountDomain}/oauth/redirect`
@Component({
  name: 'CompInfoClient',
  components: {
    Dialog,
  },
})
class CompInfoClient extends mixins(MixinAISeason) {
  public officialRedirectUrl = OfficialRedirectUrl
  public validateUrl = (rule: any, value: any, callback: any) => {
    if (value == '') callback();
    else if (!RegUrlLax.test(value)) callback(new Error(`URL ${this.$t('error.format')}`));
    else callback();
  }
  public form = {
    id: '',
    name: '',
    secret: '',
    urls: [''],
    ipWhitelist: '',
  }
  public rules = {
    name: [
      { required: true, message: '请输入客户端名称', trigger: 'change', },
      { max: MaxLenName, message: '超过最大长度：' + MaxLenName, trigger: 'change' },
      { validator: validateBackspace.bind(this), trigger: 'change' },
    ],
    ipWhitelist: [
      {
        validator: (rule: object, value: string, callback: Function) => {
          if (value == '') {
            callback();
            return;
          }
          let ips = value.split(';')
          for (let ip of ips) {
            if (!RegIp.test(ip)) {
              callback(new Error('IP ' + this.$t('error.format')))
              return
            }
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  }
  public visible = {
    editDrawer: false,
  }
  public loading = {
    save: false,
    list: false,
    delete: false,
  }
  private appId = this.$route.query.appId as string
  private curClient: any = null

  // get devModule() {
  //   return this.$store.state.developer
  // }
  // get appData() {
  //   return this.devModule.appData
  // }
  get list() {
    return this.appData && this.appData.clients || []
  }
  get isEdit() {
    return !!this.curClient
  }

  mounted() {
    this.initData()
  }

  async initData() {
    this.loading.list = true
    if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    this.loading.list = false
  }

  public showEditDrawer(client: object) {
    this.curClient = client
    this.visible.editDrawer = true
  }
  public onClose() {

  }

  public onCopySecret(secret, e) {
    clipboard(secret, e, '拷贝成功')
  }
  public onAddRow() {
    this.form.urls.push('')
  }

  public onDrawerOpen() {
    // 编辑，初始化数据
    if (this.curClient) {
      this.setcur()
    }
  }
  public onDrawerClose() {
    this.reset()
  }

  public onCancel() {
    this.visible.editDrawer = false
  }

  public async submitForm() {
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate((valid: boolean) => {
      if (!valid) return false
      this.loading.save = true
      // 过滤url空格和空行
      let urls: string[] = []
      this.form.urls.forEach(url => {
        if (!url) return
        url = url.trim()
        if (url) urls.push(url)
      })
      const param = {
        name: this.form.name,
        urls: urls,
        ipWhitelist: this.form.ipWhitelist,
      }
      if (!this.isEdit) {
        AClientAdd(this.appId, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.add') as string)
          DeveloperModule.MAGetDevAppData(this.appId)
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.add') as string)
        })
      }
      else {
        AClientUpt(this.appId, this.curClient?.id, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAppData(this.appId)
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.edit') as string)
        })
      }
    })
  }

  public onDelClient(client) {
    this.loading.delete = true
    AClientDel(this.appId, client.id).then(() => {
      this.loading.delete = false
      this.$message.success(this.$t('tips.succ.del') as string)
      DeveloperModule.MAGetDevAppData(this.appId)
      this.visible.editDrawer = false
    }).catch(err => {
      console.error(err)
      this.loading.delete = false
      this.$message.error(this.$t('tips.fail.del') as string)
    })
  }
  public onDelUrl(index) {
    this.form.urls.splice(index, 1)
  }

  private reset() {
    this.form.id = ''
    this.form.name = ''
    this.form.secret = ''
    this.form.urls = ['']
    this.form.ipWhitelist = ''
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  private setcur() {
    this.form.id = this.curClient.id
    this.form.name = this.curClient.name
    this.form.secret = new Array(this.curClient.secret.length).fill('*').join('')
    this.form.urls = [].concat(this.curClient.urls)
    this.form.ipWhitelist = this.curClient.ipWhitelist
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  public goToDocument() {
    let docUrl = getDocumentUrl()
    window.open(`/idocs?uri=${encodeURIComponent(docUrl)}`)
  }
}
export default CompInfoClient
