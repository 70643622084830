// export const TOKEN_NAME = 'kratos_token'
// export const KratosUrl = 'http://127.0.0.1:4433'
export const SessionKey = 'oasis_session';
export const SSOSessionKey = 'sso_session';
export const OasisSessionKey = 'oasis_verify_session';
export const ContinueQueryKey = 'oasis_continue_query';
// session postMessage
export const SessionMsgName = 'oasis_ssn';
export const SessionCbMsgName = 'oasis_ssn_cb';

export const LangList = [
  {
    key: 'zh-cn',
    name: '中文',
    name_en: '中文',
  },
  {
    key: 'en',
    name: 'ENGLISH',
    name_en: 'ENGLISH',
  },
];

export const CountryList = [
  {
    id: 'China',
    name: '中国',
    name_en: 'China',
  },
  {
    id: 'USA',
    name: '美国',
    name_en: 'United States',
  },
  {
    id: 'UK',
    name: '英国',
    name_en: 'United Kingdom',
  },
  {
    id: 'Japan',
    name: '日本',
    name_en: 'Japan',
  },
  {
    id: 'Korea',
    name: '韩国',
    name_en: 'Korea',
  },
];

export const IssueExpireTime = 60 * 60 * 1000;   // issue过期时间，执行setting时判断

// 平台相关协议配置
export const Policy = {
  MinPasswordLength: 6,
  MaxPasswordLength: 20,
  MinNicknameLength: 2,
  MaxNicknameLength: 20,
  VerifyCodeLength: 6,
  MinIdentifierPasswordDist: 5,
  MaxIdentifierPasswordSubstrThreshold: 0.5,
  // 测试账号
  MinTestAccUserNameLength: 6,
  MaxTestAccUserNameLength: 30,
};

// 需要保持的特殊参数
export const ContinueQueryList = ['login_challenge', 'consent_challenge', 'sso_source'];

// 默认封面图
export const DefaultAppCover = '/files/icon-app-default.png'
// 默认游戏图
export const DefaultGameCover = '/files/icon-game-default.png'
// 默认头像
export const DefaultAvatar = 'https://image.guangzi.qq.com/uploadfiles/oasis/avatar_default.jpg'

// 平台类型
export const platformType = [
  {
    id: 0,
    label: 'windows',
  },
  {
    id: 1,
    label: 'macos',
  },
  {
    id: 2,
    label: 'pcweb',
  },
  {
    id: 3,
    label: 'android',
  },
  {
    id: 4,
    label: 'ios'
  },
  {
    id: 5,
    label: 'h5',
  },

  {
    id: 6,
    label: 'xbox'
  },
  {
    id: 7,
    label: 'ps'
  },
  {
    id: 8,
    label: 'will'
  }
]

export const verifySessionEffective = 1;

export enum ResponseCode {
  SUCCESS = 0,
  SESSION_INVALID = 100, // session失效
}
