
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'navMenuSubItem',
  components: {},
})
class navMenuSubItem extends Vue {
  @Prop({ default: () => [] }) private chil!: any[]

  // 获取父级菜单数据
  get chils() {
    return <any>this.chil;
  }
}
export default navMenuSubItem
