
import { Component, Vue, Watch } from 'vue-property-decorator'
import { clearUserSession } from '@/utils/storage'

@Component({
  name: 'Error401',
  components: {},
})
class Error401 extends Vue {
  public onSetAuth() {
    clearUserSession()
    this.$router.push('/')
  }
}
export default Error401
