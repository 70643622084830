var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "page-developer-doc" } },
    [
      _c("div", { staticClass: "app-section" }, [
        _c("h1", { staticClass: "app-title" }, [_vm._v("Oasis文档")]),
        _c(
          "div",
          { staticClass: "app-doc" },
          [
            _vm._m(0),
            _c(
              "el-button",
              { staticClass: "btn-doc", on: { click: _vm.goToDocument } },
              [_vm._v("接入文档")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "app-doc" },
          [
            _vm._m(1),
            _c(
              "el-button",
              { staticClass: "btn-doc", on: { click: _vm.goToAIDoc } },
              [_vm._v("接入文档")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left" }, [
      _c("img", { attrs: { src: require("../../assets/logo_200.png") } }),
      _c("div", { staticClass: "text" }, [
        _c("p", [_vm._v("Oasis平台使用说明")]),
        _c("p", [_vm._v("使用Oasis平台，创建与发布你的作品")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left" }, [
      _c("img", { attrs: { src: require("../../assets/logo_200.png") } }),
      _c("div", { staticClass: "text" }, [
        _c("p", [_vm._v("AI工具使用教程")]),
        _c("p", [_vm._v("使用AI工具，丰富你的游戏内容")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }