var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gate-guide-footer" }, [
      _c("div", { staticClass: "gate-guide-contain" }, [
        _c("div", { staticClass: "gate-guide-logo" }),
        _c("div", { staticClass: "gate-copyright" }, [
          _vm._v(" Copyright © 2023 GATE GAME.All rights reserved "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }