import { RouteConfig } from 'vue-router';
import { isClient } from '@/utils/env';

/**
 * 定义默认路由
 * @description 当单独打包时，会设置默认路由
 */
export const defaultRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: '/',
    component: () => import('@/layout/index.vue'),
    redirect: '/aicompetions/index',
    meta: {
      isKeepAlive: true,
    },
    children: [],
  },
];

/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，
 * 会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/layout/game.vue'),
    redirect: isClient ? '/home/client' : '/home/browser',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'browser',
        name: 'homeBrowser',
        component: () => import(/* webpackChunkName: "acc-home-browser" */ '@/views/home/browserAI.vue'),
        meta: {
          title: 'route.home.browser',
          noSession: false,
          fitH5: true,
        },
      },
      {
        path: 'client',
        name: 'homeClient',
        component: () => import(/* webpackChunkName: "acc-home-client" */ '@/views/home/clientAI.vue'),
        meta: {
          title: 'route.home.client',
          noSession: false,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/oauth',
    name: 'oauth',
    component: () => import('@/layout/oauth.vue'),
    redirect: '/oauth/login',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'login',
        name: 'oauthLogin',
        component: () => import(/* webpackChunkName: "acc-oauth" */ '@/views/oauth/login.vue'),
        meta: {
          title: 'route.oauth.login',
          noSession: true,
        },
      },
      {
        path: 'consent',
        name: 'oauthConsent',
        component: () => import(/* webpackChunkName: "acc-oauth" */ '@/views/oauth/consent.vue'),
        meta: {
          title: 'route.oauth.consent',
          noSession: true,
        },
      },
      {
        path: 'redirect',
        name: 'oauthRedirect',
        component: () => import(/* webpackChunkName: "acc-oauth" */ '@/views/oauth/redirect.vue'),
        meta: {
          title: 'route.oauth.redirect',
          noSession: true,
        },
      },
    ],
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('@/layout/game.vue'),
    redirect: '/game/info',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'info',
        name: 'gameInfo',
        component: () => import(/* webpackChunkName: "acc-game" */ '@/views/game/infoAI.vue'),
        meta: {
          title: 'route.game.info',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'shop',
        name: 'gameShop',
        component: () => import('@/views/shop/info.vue'),
        meta: {
          title: 'route.game.shop',
          noSession: false,
          fitH5: true,
        }
      },
    ],
  },
  // 商城首页
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/views/aicompetions/home.vue'),
    redirect: '/shop/index',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'index',
        name: 'shopIndex',
        component: () => import('@/views/shop/home.vue'),
        meta: {
          title: 'route.shop.index',
          noSession: false,
          fitH5: true,
        }
      },
    ]
  },
  {
    path: '/aicompetions',
    name: 'aiCompetion',
    component: () => import('@/views/aicompetions/home.vue'),
    redirect: '/aicompetions/index',
    children: [
      {
        path: 'index',
        name: 'aiCompetionsIndex',
        component: () => import(/* webpackChunkName: "acc-oauth" */ '@/views/aicompetions/index.vue'),
        meta: {
          title: 'route.aicompetion.index',
          noSession: false,
          fitH5: true,
        },
      },
      {
        path: 'allworks',
        name: 'allWorks',
        component: () => import(/* webpackChunkName: "acc-oauth" */ '@/views/aicompetions/allworks.vue'),
        meta: {
          title: 'route.aicompetion.allWorks',
          noSession: false,
          fitH5: true,
        },
      },
      {
        path: 'result',
        name: 'result',
        component: () => import(/* webpackChunkName: "acc-oauth" */ '@/views/aicompetions/result.vue'),
        meta: {
          title: 'route.aicompetion.result',
          noSession: false,
          fitH5: true,
        },
      },
    ]
  },
];
