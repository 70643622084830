
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as XLSX from 'xlsx'
import { ATestAccGET, ATestAccAdd, ATestAccUpt, ATestAccDel } from '@/api/developerApi/testAccount'
import { normalizeDate, formatDate } from '@/utils/format'
import { ElForm } from 'element-ui/types/form'
import { validateTestAccUserName, validatePassword } from '@/utils/validator'
import { RegTestAccUserName, RegPassword } from '@/utils/regs'

const validatePasswordSnd = function (rule: object, value: string, callback: Function) {
  if (!this.isEdit) callback()
  else {
    console.log("validatePasswordSnd", this.form)
    let password = this.form.password || ''
    if (value === '') {
      callback(new Error(this.$t('error.passwordSnd') as string))
    } else if (value !== password) {
      callback(new Error(this.$t('error.pswNotSame') as string))
    } else {
      callback()
    }
  }
}
@Component({
  name: 'CompInfoTestAcc',
})
class CompInfoTestAcc extends Vue {
  public form = {
    name: '',
    password: '',
    passwordSnd: '',
  }
  public list: any = []
  public accList: any = []
  public fileList: any = []
  public rules = {
    name: [
      {
        required: true,
        validator: validateTestAccUserName.bind(this),
        trigger: 'change',
      },
    ],
    password: [
      {
        required: true,
        validator: validatePassword.bind(this),
        trigger: 'change',
      },
    ],
    passwordSnd: [
      {
        required: true,
        validator: validatePasswordSnd.bind(this),
        trigger: 'change',
      },
    ],
  }
  public visible = {
    editDrawer: false,
  }
  public loading = {
    save: false,
    list: false,
    delete: false,
    upload: false,
  }
  private appId = this.$route.query.appId as string
  private current: any = {
    account: null
  }

  get devModule() {
    return this.$store.state.developer
  }
  get appData() {
    return this.devModule.appData
  }
  get isEdit() {
    return !!this.current.account
  }

  mounted() {
    this.initData()
  }

  public onAddAccount() {
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate((valid: boolean) => {
      if (!valid) return false
      let hasExist = false
      hasExist = this.accList.find(item => item.name == this.form.name)
      if (hasExist) {
        this.$message.info("账号已添加")
        return
      }
      hasExist = this.list.find(item => this.form.name == item.name)
      if (hasExist) {
        this.$message.info("账号已存在")
        return
      }
      this.accList.push({
        name: this.form.name,
        password: this.form.password,
      })
    })
  }

  public showEditDrawer(account) {
    this.current.account = account
    this.visible.editDrawer = true
  }

  public onDelTestAcc(accout) {
    this.loading.delete = true
    const param = {
      uuids: [accout.uuid]
    }
    ATestAccDel(this.appId, param).then(() => {
      this.loading.delete = false
      this.$message.success(this.$t('tips.succ.del') as string)
      this.getTestAccs()
      this.visible.editDrawer = false
    }).catch(err => {
      console.error(err)
      this.loading.delete = false
      this.$message.error(this.$t('tips.fail.del') as string)
    })
  }
  public onDelAddAcc(index) {
    this.accList.splice(index, 1)
  }

  public onDrawerOpen() {
    // 编辑，初始化数据
    if (this.current.account) {
      this.setcur()
    }
  }
  public onDrawerClose() {
    this.reset()
  }

  public onCancel() {
    this.visible.editDrawer = false
  }

  public async submitForm() {
    if (!this.isEdit) {
      // 去重
      let list: any = []
      this.accList.forEach(item => {
        let existAcc = this.list.find(subItem => subItem.name == item.name)
        if (!existAcc) list.push(item)
      })
      if (!list.length) {
        this.$message.info("待添加列表为空")
        return
      }
      const param = list.map(item => {
        return {
          account: item.name,
          password: item.password
        }
      })
      this.loading.save = true
      ATestAccAdd(this.appId, param).then(() => {
        this.loading.save = false
        this.$message.success(this.$t('tips.succ.edit') as string)
        this.getTestAccs()
        this.visible.editDrawer = false
      }).catch(err => {
        console.error(err)
        this.loading.save = false
        this.$message.error(this.$t('tips.fail.edit') as string)
      })
    }
    else {
      if (!this.form.name) {
        this.$message.info("请输入需要添加的账号")
        return
      }
      let formEl = this.$refs.formRef as ElForm
      if (!formEl) return
      formEl.validate((valid: boolean) => {
        if (!valid) return false
        const param = {
          password: this.form.password,
          uuid: this.current.account.uuid,
          email: this.current.account.email,
        }
        this.loading.save = true
        ATestAccUpt(this.appId, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.edit') as string)
          this.getTestAccs()
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.edit') as string)
        })
      })
    }
  }

  public onFileChange(file) {
    const sizeLimit = 100
    const fileSize = file.size / 1024 / 1024
    if (fileSize > sizeLimit) {
      this.$message.error('文件大小不能超过' + sizeLimit + 'M')
      return false
    }
    if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
      this.$message.error("上传格式不正确，请上传xls或者xlsx格式")
      return false
    }

    const fileReader = new FileReader()
    fileReader.onload = (ev: any) => {
      try {
        const data = ev.target.result
        const workbook = XLSX.read(data, {
          type: 'binary'
        })
        const exlname = workbook.SheetNames[0]
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[exlname])
        // 处理列表
        let succList: any = [], failList: any = []
        sheet.forEach((row: any, i: number) => {
          let errMsg: string[] = []
          if (!/test/i.test(row['账号类型'])) errMsg.push('账号类型非测试账号')
          if (!row['用户名']) errMsg.push('用户名为空')
          if (!RegTestAccUserName.test(row['用户名'])) errMsg.push('用户名格式错误')
          if (!row['密码']) errMsg.push('密码为空')
          if (!RegPassword.test(row['密码'])) errMsg.push('密码格式错误')
          if (errMsg.length) {
            let failRow = {
              row: i + 2,
              name: row['用户名'],
              password: row['密码'],
              errMsg: errMsg
            }
            failList.push(failRow)
            console.error(failRow)
            return
          }
          succList.push({
            name: row['用户名'],
            password: row['密码'],
          })
        })
        let newList: any = []
        succList.forEach(item => {
          if (this.list.find(subItem => subItem.name == item.name)) return
          newList.push(item)
        })
        let list = this.accList.concat(newList)
        let uniqNameList: any = [], uniqList: any = []
        list.forEach(item => {
          if (uniqNameList.includes(item.name)) return
          uniqList.push(item)
          uniqNameList.push(item.name)
        })
        this.accList = uniqList
        this.$message.success(`导入成功，其中解析成功：${succList.length}行，失败：${failList.length}行，重复：${succList.length - uniqNameList.length}行`)
      } catch (err) {
        console.error(err)
        this.$message.error("解析失败，请重试")
        return false
      }
    }
    fileReader.readAsBinaryString(file.raw)
  }
  public onUploadError(error, file) {
    console.error(error)
    this.$message.error("上传失败 " + error)
    this.loading.upload = false
  }
  public formatTime(time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime)
  }

  private async initData() {
    this.getTestAccs()
  }
  private getTestAccs() {
    this.loading.list = true
    ATestAccGET(this.appId).then((res: any) => {
      this.loading.list = false
      let list = res.data || []
      this.list = list
    }).catch(err => {
      console.error(err)
      this.loading.list = false
      this.$message.error(this.$t('tips.fail.get') as string)
    })
  }
  private reset() {
    this.form.name = ''
    this.form.password = ''
    this.accList = []
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  private setcur() {
    this.form.name = this.current.account.name
    this.form.password = ''
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
}
export default CompInfoTestAcc
