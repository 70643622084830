var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "guide-partner-card" }, [
    _c("div", {
      staticClass: "icon",
      style: {
        background: "url(" + _vm.iconPath + ") no-repeat",
        backgroundSize: "100% 100%",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }