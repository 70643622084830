import request from '@/utils/request';
import { OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

// 游戏包接口

export async function AStoreAIBuildGET(appid: string) {
  return request({
    url: `${BaseUrl}/app/gamepackage/query?appid=${appid}`,
    method: 'GET',
  });
}
export async function AStoreAIBuildAdd(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/gamepackage/create?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function AStoreAIBuildPkgUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/gamepackage/update?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function AStoreAIBuildPptUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/gamepackage/ppt/update?appid=${appid}`,
    method: 'POST',
    data,
  });
}