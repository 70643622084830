<template>
  <div class="gate-guide-partner">
    <div class="gate-guide-contain">
      <guideTitle :title="$t('views.guide.partner.title')" :hasMore="false"/>
      <div class="guide-partner-container">
        <partnerCard v-for="(item, index) in partnerList" :key="index" :iconPath="item.icon" :title="item.name" />
      </div>
    </div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import partnerCard from './components/partnerCard.vue';
import googleIcon from '../../assets/guide/partnerIcon/google.png';
import microsoftIcon from '../../assets/guide/partnerIcon/microsoft.png';
import riotIcon from '../../assets/guide/partnerIcon/riot.png';
import ubisoftIcon from '../../assets/guide/partnerIcon/ubisoft.png';
import unrealIcon from '../../assets/guide/partnerIcon/unreal.png';
import unityIcon from '../../assets/guide/partnerIcon/unity.png';
import eaIcon from '../../assets/guide/partnerIcon/ea.png';
import activisionIcon from '../../assets/guide/partnerIcon/activision.png';
export default {
  name: 'guide-partner',
  components: { guideTitle, partnerCard },
  data() {
    return {
      partnerList: [
        { name: 'google', icon: googleIcon },
        { name: 'microsoft', icon: microsoftIcon },
        { name: 'riot games', icon: riotIcon },
        { name: 'ubisoft', icon: ubisoftIcon },
        { name: 'unreal', icon: unrealIcon },
        { name: 'unity', icon: unityIcon },
        { name: 'ea', icon: eaIcon },
        { name: 'activision', icon: activisionIcon },
      ]
    }
  },
  mounted() {
    console.log('mounted');
  },
}

</script>

<style lang="scss" scoped>
.gate-guide-partner {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000;
  align-items: end;
  .gate-guide-contain{
    width: 1200px;
    height: 600px;
    .guide-partner-container{
      margin-top: 60px;
      width: 100%;
      height: 284px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-content: space-between;
      flex-wrap: wrap;
    }
    .guide-partner-container>div:nth-child(4n+1){
      margin-left: 0px;
    }
    .guide-partner-container>div{
      margin-left: 4px;
    }
  }
}
</style>
