/**
 * 判断两数组是否相同
 * @param news 新数据
 * @param old 源数据
 * @returns 两数组相同返回 `true`，反之则反
 */
export function judementSameArr(news: unknown[] | string[], old: string[]): boolean {
  let count = 0;
  const leng = old.length;
  for (const i in old) {
    for (const j in news) {
      if (old[i] === news[j]) count += 1;
    }
  }
  return count === leng;
}

/**
 * 判断两个对象是否相同
 * @param a 要比较的对象一
 * @param b 要比较的对象二
 * @returns 相同返回 true，反之则反
 */
export function isObjectValueEqual(a: { [key: string]: any }, b: { [key: string]: any }) {
  if (!a || !b) return false;
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) return false;
  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    const propA = a[propName];
    const propB = b[propName];
    if (!Object.prototype.hasOwnProperty.call(b, propName)) return false;
    if (propA instanceof Object) {
      if (!isObjectValueEqual(propA, propB)) return false;
    } else if (propA !== propB) {
      return false;
    }
  }
  return true;
}

export function transArrayToMapByKey(arr, key = 'id') {
  let map = {}
  arr.forEach((item) => {
    if(item[key] !== undefined) map[item[key]] = item
  })
  return map
}