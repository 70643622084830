import request from '@/utils/request';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/openpf/api`;
export async function APubBannerGet() {
  return request({
    url: `${BaseUrl}/banner`,
    method: 'GET',
  });
}

export async function AGameListGet() {
  return request({
    url: `${BaseUrl}/store/gamelist/query`,
    method: 'GET',
  });
}

export async function APubStoreGet(appid: string) {
  return request({
    url: `${BaseUrl}/public/store/query?appid=${appid}`,
    method: 'GET',
  });
}

export async function ADevStoreGet(appid: string) {
  return request({
    url: `${BaseUrl}/store/query?appid=${appid}`,
    method: 'GET',
  });
}

export async function ADevAIBuildGET(appid: string) {
  return request({
    url: `${BaseUrl}/app/gamepackage/query?appid=${appid}`,
    method: 'GET',
  });
}

// 评论
export async function ADevAICommentAllGET(appid: string) {
  return request({
    url: `${BaseUrl}/store/comment/queryall?appid=${appid}`,
    method: 'GET',
  });
}
export async function ADevAICommentSelfGET(appid: string) {
  return request({
    url: `${BaseUrl}/store/comment/query?appid=${appid}`,
    method: 'GET',
  });
}
export async function ADevAICommentScoreGET(appid: string) {
  return request({
    url: `${BaseUrl}/store/comment/queryscore?appid=${appid}`,
    method: 'GET',
  });
}
export async function ADevAICommentSelfAdd(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/store/comment/create?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function ADevAICommentSelfUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/store/comment/update?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function ADevAICommentSelfDel(appid: string) {
  return request({
    url: `${BaseUrl}/store/comment/delete?appid=${appid}`,
    method: 'POST',
  });
}

// 
export async function ADevAIResult(data: object) {
  return request({
    url: `${AccountDomain}/api/ai/getawardworks`,
    method: 'POST',
    data
  })
}

export async function getLeaveday() {
  console.log('AccountDomain', AccountDomain)
  return request({
    url: `${AccountDomain}/api/ai/getleaveday`,
    method: 'POST'
  })
}
