// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/guide/gate_logo_footer.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n/* 栅格布局（媒体查询变量）\n* https://developer.mozilla.org/zh-CN/docs/Learn/CSS/CSS_layout/Media_queries\n* $us ≥376px  响应式栅格\n* $xs ≥576px  响应式栅格\n* $sm ≥768px  响应式栅格\n* $md ≥992px  响应式栅格\n* $lg ≥1200px 响应式栅格\n* $xl ≥1920px 响应式栅格\n------------------------------- */\n.gate-guide-footer[data-v-5bcb170c] {\n  margin: 0;\n  height: 100px;\n  margin-top: 150px;\n  padding: 12px 40px;\n  width: 100%;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n          align-items: center;\n  background: #000;\n}\n.gate-guide-footer .gate-guide-contain[data-v-5bcb170c] {\n  width: 1200px;\n  padding: 20px 0;\n  -webkit-box-pack: justify;\n  -webkit-justify-content: space-between;\n          justify-content: space-between;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n          align-items: center;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: flex;\n}\n.gate-guide-footer .gate-guide-contain .gate-guide-logo[data-v-5bcb170c] {\n  width: 240px;\n  height: 60px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n          align-items: center;\n}\n.gate-guide-footer .gate-guide-contain .gate-copyright[data-v-5bcb170c] {\n  color: #FFF;\n  text-align: right;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%; /* 24px */\n  text-transform: uppercase;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
