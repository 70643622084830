// 定义内容
export default {
  route: {
    setting: {
      general: 'General',
      password: 'Password',
      connect: 'Connections',
      developer: 'Developer',
    },
    home: {
      browser: 'Home',
      client: 'Home',
    },
    account: {
      login: 'Sign in',
      register: 'Register',
      reset: 'Reset password',
      bind: 'Bind account',
      redirect: 'Redirect',
    },
    oauth: {
      login: 'Oauth authorize',
      consent: 'Oauth authorize',
      redirect: 'Oauth authorize',
    },
    developer: {
      home: 'Home',
      register: 'Sign in',
      application: 'My applications',
      sdk: 'SDK and AI tools',
      document: 'Document',
    },
    article: {
      add: 'article'
    },
    error: {
      index: 'Error',
      401: 'No permission',
      404: 'Not found',
    },
  },
  views: {
    account: {
      login: 'Sign In',
      logout: 'Sign Out',
      register: 'Sign Up',
      email: 'Email',
      forgotPassword: 'Forgot password',
      rememberMe: 'Remember me',
      hasNoAccount: 'Don’t have an account?',
      hasAnAccount: 'Already have an account?',
      termsOfService: 'terms of service',
      privacyPolicy: 'Privacy Policy',
      backToOtherLogin: 'Back up to all sign in options',
      backToOtherRegister: 'Back up to all sign up options',
      linkToExistAcc: 'Link to exising account',
      linkToNewAcc: 'Link to new account',
      donotWantLink: 'Don’t want to link this account?',
      resetSuccess: 'Reset password succeed',
      hasResetPls: 'You have reset your password, please',
      loginAgain: 'sign in again',
      autoJumpIn10: '(automatically jump after 10s)',
      sendSuccess: 'We have sent a verification email to your email，please click the link in the email to complete the registration',
      sendNotRec: 'Didn’t receive email？',
      sendAgain: 'Send email again',
      sendVerifyCode: 'Send Code',
      haveReadAndAgree: 'I have read and agree the',
      bindHasBind1: 'Your email ',
      bindHasBind2: ' is associated with an existing Oasis account. If this is your Oasis account, sign in to connect it to your ',
      bindHasBind3: ' account',
      or: 'OR',
    },
    home: {
      ourGame: 'Our Game',
      myAccount: 'My account',
      signin: 'Sign in',
      signout: 'Sign out',
      download: 'Download Oasis',
      downloadGame: 'Download game',
      launchGame: 'Launch game',

    },
    setting: {
      connect: 'Connect',
      disconnect: 'Disconnect',
      gameTips: 'You can choose to link your Oasis account with the game. You can revoke the game’s access to your data at any time',
      channelTips: 'You can choose to link your Oasis account with the third party account. You can revoke the binding relationship at any time',
      game: 'Apps',
      none: 'Empty',
      account: 'Accounts',
      add: 'Added',
      diaCon1: 'Connect to your ',
      diaCon2: ' account ',
      diaCon3: 'Connect',
      diaCon4: 'You have logged in to AI Competition using ',
      diaCon5: ' to link your account, you will be redirected to the ',
      diaCon6: ' website to log in',
      diaDiscon1: 'Unlink ',
      diaDiscon2: ' from this account',
      diaDiscon3: 'Disconnect',
      diaDiscon4: 'You will no longer be able to log into your AI Competition account with ',
      diaDiscon5: '',

      accountInfo: 'Account Info',
      delTips: 'After selecting “Delete Account Permanently”，your Oasis account will be permanently deleted and this  operation is irreversible',
      delForever: 'Delete Account Permanently',
      sessionTips: 'Setting sensitive information requires a newer session, the current session has expired, please sign in again',
    },
    game: {
      info: '游戏信息',
    },
    guide: {
      more: 'More Details',
      success: 'subscribe success!',
      emailCheckFail: 'Please enter a valid address',
      banner: {
        tips1: 'KNOWLEDGE, GUIDES, RESOURCES AND MORE FOR AI-EMPOWERED GAMES',
        slogan1: 'DISCOVER THE INNOVATIVE',
        scrollSlogan: ['GAME CREATION', 'AI APPLICATION', 'SPOTLIGHTS&INSIGHTS', 'CAREER GROWTH'],
        slogan3: 'WITH LITGATE COMMUNITY',
        emailTips: 'Coming Soon: Get Notified When We Launch!',
        tips2: 'We are committed to protecting your privacy and ensuring the security of your personal information. When you provide us with your email address, we guarantee that it will only be used for the purpose of sending product update notifications. We will never disclose or share your email address with any third parties without your explicit consent.',
        subscribe: 'SUBSCRIBE',
        placeholder: 'Please enter your email',
        discord: 'Discord',
        wechat: 'Wechat',
      },
      events: {
        title: 'EVENTS',
        eventTimeRange1: 'Oct. 21st 2023 - Oct. 30th 2023',
        eventTitle1: 'CiGA AI Game Tour',
        eventAddress1: 'SHANGHAI, WUHAN, GUANGZHOU, SHENZHEN',
        eventIntro1: "○AIGT (China's AI Game Tournament) is an AI-themed game creation competition hosted by CiGA and sponsored by LitGate. In this event participants will attempt to apply AI technology to game demos and compete for awards. Outstanding entries will receive extensive exposure opportunities, trophies, and also have the chance to connect with a wide range of industry professionals, enthusiasts, and other creators.",
        eventMore: 'More',
        eventTimeRange2: 'Jan. 1st 2024 -Jan. 10th 2024',
        eventTitle2: 'Global GameJam',
        eventAddress2: 'Global Online & Onsite',
        eventIntro2: "○Global Gamejam（GGJ）is the world's largest game creation event taking place around the globe boasting tens of thousands of participants (jammers) at hundreds of physical and virtual sites in over one hundred countries around the world since 2008. Every year, GGJ organizes 48-hour Gamejam events to empower all individuals worldwide to learn, experiment, and create together. LitGate will attend 2024 GGJ as a sponsor, providing AI-related game development themes and offering resource support to creators."
      },
      aboutUs: {
        title: 'About LitGate',
        introTitle1: 'A ROBUST AI&GAME ECOSYSTEM',
        introContent1: "Regardless of whether you're a seasoned creator or simply a gamer embracing the AI revolutions, you're encouraged to take part in creation campaigns or events, and share your thoughts, resources, creations, or any insights as community contributor.",
        stations: [
          { title: 'GAME STATION', label: 'Game center of AI-empowered creations publishing and sharing.'},
          { title: 'GEEK COMMUNITY', label: 'Information exchange, seasoned knowledge. and talent matching.'},
          { title: 'PIONEERING TECH', label: 'Professional tool station of AI-empowered game development.'},
          { title: 'CAREER GROWTH', label: 'Opportunities of techincal support, incubation, and investment.'},
        ],
        introTitle2: 'MORE THAN A COMMUNITY',
        introContent2: "We offer creators with a wide variety of open resources and tools, as well as access to paid and proprietary resources developed or sponsored by LitGate. With the dynamic and informative feeds and academy columns, LitGate keeps users up-to-date with what's new and exclusive or first-published articles.  ",
        introTitle3: 'EVENTS AND GAMEJAM',
        introContent3: `We are hosting abundant campaigns and Gamejams worldwide to help creators stand out and get exposed to potential financing investors and incubation opportunities. 
        Here, you could also get connected to the industrial pioneers and well-known leading talents by engaging in their streaming or offline seminars.`,
      },
      case: {
        title: 'GAME SHOWCASES',
        carousel1: {
          title: '2023 Top Ten Works Collection',
          intro: 'The second Photon Al Exploration and Innovation Competition is nearing its end, and in the past two months, participants have actively explored the boundaries of developing games, tools, and designs using Al, maximizing their creativity. A total of 28 entries were received in this competition, and after a joint decision by experts and the public, 10 were ultimately selected for the "Best AI Works Award". ',
        },
        banner2: {
          title: 'Wraith',
          intro: "The game utilizes AI's real-time capabilities powered by ChatGPT4 to generate new narrative content. With each game start, the system inputs a detailed prompt of around 10,000 words, ensuring the AI's clear understanding of the context.",
          tags: ['Roguelike', 'Horror', 'Real-time production'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner3: {
          title: 'AI Princess Maker',
          intro: 'The game is an anime-style life simulator of "raising a daughter". Powered by Stable Diffusion with real-time calculation, players would experience unique and different images based on their choice and the character points.',
          tags: ['Life simulator', 'RPG', 'Real-time production'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner1: {
          title: 'Fusion',
          intro: 'This card game features diverse card equipment and varied level generation with high replayability. All the visual assets are produced by  Midjourney and Stable Diffusion, while the music is generated by AIVA. The text, images, and card effects are real-time calculated by ChatGPT and SD together',
          tags: ['Cards', 'Strategy'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner4: {
          title: 'God Can Talk',
          intro: 'The tool allows one-click friendly deployment of SDwebUI for all computer environment, meanwhile also offers all-in-one dashboard launcher of SD-related model resources, script tools, preset, etc',
          tags: ['Sandbox', 'Adventure', 'Action'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
      },
      partner: {
        title: 'RESOURCES PARTNERS'
      },
      contact: {
        title: 'CONTACT US',
        tips: 'If you are interested in business opportunities or have any questions, please contact us at',
        email: 'business@gate.com',
        listTitle: 'OUR COSIALS:',
      },
    }
  },
  common: {
    ok: 'Ok',
    cancel: 'Cancel',
    and: 'and',
    continue: 'Continue',
    nickname: 'Display Name',
    email: 'Email',
    country: 'Country',
    password: 'Password',
    curPassword: 'Current password',
    newPassword: 'New password',
    sndPassword: 'Confirm password',
    save: 'Save',
    giveup: 'Discard',
    tips: 'Tips',
    logoutTips: 'This operation will log you out, do you want to continue?',
    logouting: 'logging out',

  },
  plchld: {
    email: 'Email',
    password: 'Password',
    passwordCur: 'Current password',
    passwordNew: 'New password',
    passwordSnd: 'Confirm password',
    country: 'Country',
    nickname: 'Display Name',
    verifyCode: 'Verify Code',
    firstname: 'First name',
    lastname: 'Last namme',
  },
  tips: {
    charLen: 'English occupies 1 character, Chinese 2 characters',
    succ: {
      get: 'Pull data successfully',
      connect: 'Connect successfully',
      disconnect: 'Disconnect successfully',
      add: 'Add successfully',
      edit: 'Modify successfully',
      del: 'Delete successfully',
      login: 'Sign in successfully',
      submit: 'Submitted successfully',

    },
    fail: {
      get: 'Pull data failed',
      connect: 'Connect failed',
      disconnect: 'Disconnect failed',
      add: 'Add failed',
      edit: 'Modify failed',
      del: 'Delete failed',
      closed: 'Unavailable temporarily',
      submit: 'Submit failed',

    },
  },
  error: {
    content: 'Please enter content',
    email: 'Please enter email',
    emailFormat: 'Please enter email in the correct format',
    password: 'Please enter password',
    passwordCur: 'Please enter current password',
    passwordNew: 'Please enter new password',
    passwordSnd: 'Please enter confirm password',
    pswNotSame: 'The two passwords do not match',
    pswOldSame: 'The old and new password are the same, please re-enter',
    country: 'Please enter country',
    nickname: 'Please enter display Name',
    verifyCode: 'Please enter verify code',
    verifyCodeFormat: 'Please enter verify code in the correct format',
    lengthAt: 'The length is between ',
    notBlank: ' and cannot contain spaces',
    withSpecialChar: ', can contain special characters such as @#$',
    sameEmailPsw: 'Password and email are too similar',
    protocol: 'Please agree to the agreement first',
    overMax: 'Length exceeds limit',
    format: 'format wrong',
    backspace: 'cannot contain spaces',

  },
};
