var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-content", attrs: { id: "view-account-login" } },
    [
      _c("div", { staticClass: "login-content-main" }, [
        _c("h4", { staticClass: "login-content-title ml15" }, [
          _vm._v(_vm._s(_vm.$t("views.account.login"))),
        ]),
        _c("div", { staticClass: "login-box" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.third,
                  expression: "loading.third",
                },
              ],
              staticClass: "btn-other-wrap special",
            },
            [
              _c("div", {
                staticClass: "btn-other btn-ioa",
                on: {
                  click: function ($event) {
                    return _vm.goToLogin("ioa")
                  },
                },
              }),
              _c("div", {
                staticClass: "btn-other btn-ioa",
                on: {
                  click: function ($event) {
                    return _vm.goToOasis()
                  },
                },
              }),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }