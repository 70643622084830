
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { DeveloperModule } from '@/store/modules/developer'

@Component({
  name: 'navMenuAppMenu',
  components: {},
})
class NavMenuAppMenu extends Vue {
  @Prop({ default: () => { return {} } }) private appInfo!: any
  @Prop({}) public tab!: string

  public isCollapse = true
  // public curTab = ''
  public menuList = [
    {
      id: 'info',
      type: 'main',
      name: 'App',
      icon: 'el-icon-arrow-right',
      routerName: 'developerAppInfo',
    },
    {
      id: 'store',
      type: 'sub',
      name: '作品信息',
      icon: 'el-icon-goods',
      routerName: 'developerAppStore',
      leaveEnsure: true,
    },
    // {
    //   id: 'service',
    //   type: 'sub',
    //   name: '账户服务',
    //   icon: 'el-icon-user',
    //   routerName: 'developerAppService',
    // },
    // {
    //   id: 'data',
    //   type: 'sub',
    //   name: '游戏数据',
    //   icon: 'el-icon-pie-chart',
    //   routerName: 'developerAppData',
    // },
  ]

  get mainItem() {
    return this.menuList.find(item => item.type == 'main')
  }
  get subMenuList() {
    return this.menuList.filter(item => item.type != 'main')
  }
  get mainTitle() {
    return this.appInfo && this.appInfo.appName || this.mainItem?.name || ''
  }
  get queryAppid() {
    return this.$route.query.appId
  }

  @Watch('$route', { immediate: true })
  onRouteChanged(val: any) {
    // console.log("onRouteChanged", val)
    if (val.meta && val.meta.module == 'app') {
      if (this.queryAppid && this.queryAppid == this.appInfo.appId) {
        // 根据route path查找id
        let curRoute = this.$router.currentRoute
        let menu = this.menuList.find(item => item.routerName == curRoute.name)
        if (menu) {
          this.isCollapse = false
          // console.log("curTab", this.curTab)
          this.$emit('changeTab', menu.id)
          return
        }
      }
      else {
        // 切换app，清空app缓存数据
        // DeveloperModule.MAReset(['info', 'store', 'aiBuild'])
      }
    }
    else {
      this.$emit('changeTab', '')
    }
  }

  mounted() {
    // 初始化激活态
    let curRoute = this.$router.currentRoute
    if (curRoute.meta && curRoute.meta.module == 'app' && !this.$route.query.appId) {
      this.$router.push({ name: 'developerApps' })
    }
  }

  private checkConfirm() {
    return new Promise((resolve, reject) => {
      this.$confirm('系统可能不会保存您所做的更改，是否离开？', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        resolve(true)
      }).catch(() => {
        resolve(false)
      })
    })
  }
  private changeEnsure(menuItem) {
    let curMenuItem = this.menuList.find(item => item.id == this.tab)
    if (!curMenuItem) return Promise.resolve(true)
    // 判断appid是否变化 leaveEnsure配置
    if (this.queryAppid == this.appInfo.appId) {
      if (curMenuItem.id != menuItem.id && curMenuItem.leaveEnsure) return this.checkConfirm()
    }
    else {
      if (curMenuItem.leaveEnsure) return this.checkConfirm()
    }
    return Promise.resolve(true)
  }

  public toogleMenu(menuItem) {
    this.changeEnsure(menuItem).then((result) => {
      if (!result) return
      this.isCollapse = !this.isCollapse
      this.execChange(menuItem)
    })
  }
  public changeApp(appInfo) {
    // console.log("appInfo", appInfo)
    this.$router.push({ name: 'developerAppInfo', query: { appId: this.appInfo.appId } })
  }
  private execChange(menuItem) {
    this.$emit('changeTab', menuItem.id)
    // this.curTab = menuItem.id
    if (menuItem.routerName) this.$router.push({ name: menuItem.routerName, query: { appId: this.appInfo.appId } })
    // if (menuItem.routerName) this.$router.push({ name: menuItem.routerName, query: { appId: this.appInfo.appId, tab: menuItem.id } })
    // if (menuItem.type == 'main' && this.isCollapse) this.isCollapse = false
    // this.curAppId = this.appInfo.appId
  }
}
export default NavMenuAppMenu
