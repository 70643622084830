import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { Local, clearUserSession } from '@/utils/storage';
import { AWhoami, ALogout } from '@/api/user';
import { OasisSessionKey, ResponseCode, SessionKey } from '@/config';
import { UserState } from '@/store/interface/index';
import Watermark from 'watermark-plus';

import store from '@/store';
import { getUserInfo } from '@/api/auth';

export type IUserState = UserState;

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
  public userInfo = {
    identityId: '',
    account: '',
    email: '',
    country: '',
    name: [],
    nickname: '',
    profile: ''
  };
  public session = {
    token: '',
    expiresAt: 0,   // 过期时间
    issuedAt: 0,   // 登录时间
    code: '',   // oauth code
    state: '',   // oauth state
  };
  public roles = [];

  public stateInit = false;

  @Action
  public async MASetUserInfos(data: any) {
    if (data) {
      this.MMSetUserInfos(data);
    }
  }
  @Action
  public async MALogin(session) {
    if (session) {
      this.MMSetSession(session);
    }
  }
  @Action
  public async MSaveOauth({ code, state }) {
    this.MMSetSession({ code, state });
  }
  @Action
  public async MALogout() {
    clearUserSession();
  }
  @Action
  public async GTGetUserInfo() {
    // 获取用户数据
    return new Promise((resolve, reject) => {
      const oasisSession = Local.get(OasisSessionKey);
      if (oasisSession) {
        getUserInfo({ user_id: oasisSession.user_id }).then((result: any) => {
          const { code, data } = result;
          if (code === ResponseCode.SUCCESS) {
            console.log('用户数据：', data);
            this.MMSetUserInfos(data);
            resolve(true);
          }
          // try {
          //   const watermark = new Watermark({
          //     content: data.email || data.nickname || '',
          //     alpha: 0.1
          //   });
          //   watermark.create();
          // }catch(e) {
          //   console.error("设置水印失败", e)
          // }
        })
      }
    });
  }
  @Action
  public async MAGetUserInfo() {
    // 获取用户数据
    return new Promise((resolve, reject) => {
      AWhoami(this.session.token).then((data: any) => {
        this.MMSetUserInfos(data);
        resolve(null);
        // 设置水印
        try {
          const watermark = new Watermark({
            content: data.identity.traits.email || data.identity.traits.nickname || '',
            alpha: 0.1
          });
          watermark.create();
        }
        catch (e) {
          console.error("设置水印失败", e)
        }
      })
        .catch(reject);
    });
  }

  @Mutation
  private MMSetUserInfos(data: any) {
    const userInfo: any = {};
    if (data.id) userInfo.identityId = data.id;
    if (data.email) userInfo.email = data.email;
    if (data.country) userInfo.country = data.country;
    if (data.name) userInfo.name = data.name;
    if (data.nickname) userInfo.nickname = data.nickname;
    if (data.profile) userInfo.profile = data.profile;
    this.stateInit = true;
    this.userInfo = Object.assign(this.userInfo, userInfo);
  }
  @Mutation
  private MMSetSession(session: any) {
    if (session.token) {
      this.session.token = session.token;
      this.session.expiresAt = session.expiresAt;
      this.session.issuedAt = session.issuedAt;
    }
    if (session.code) {
      this.session.code = session.code;
      this.session.state = session.state;
    }
    // console.log('MMSetSession', this.session);
    Local.set(SessionKey, this.session);
  }
}

export const UserModule = getModule(User);
