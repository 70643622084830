import { Component, Vue } from 'vue-property-decorator';
import Upload from '@/components/upload/index.vue';
import { getCosUrl } from '@/utils/file';

@Component({
  name: 'MixinUpload',
  components: {
    Upload,
  },
  filters: {
    fCosBase(value) {
      return getCosUrl(value, 'base');
    },
    fCosPoster(value) {
      return getCosUrl(value, 'poster');
    },
    fCosGif(value) {
      return getCosUrl(value, 'gif');
    },
  },
})
export default class extends Vue {
  public getCosUrl = getCosUrl;
}
