
import { Component, Vue, Watch } from 'vue-property-decorator'
import StoreGeneral from '../component/StoreGeneral.vue'
import StoreMedia from '../component/StoreMedia.vue'
import StoreExprience from '../component/StoreExprience.vue'
// import StoreBuild from './component/StoreBuild.vue'
import StoreBuildAI from '../component/StoreBuildAI.vue'

// Component.registerHooks(['beforeRouteLeave'])
@Component({
  name: 'DeveloperAppStore',
  components: {
    StoreGeneral,
    StoreMedia,
    StoreExprience,
    // StoreBuild,
    StoreBuildAI,
  }
})
class DeveloperAppStore extends Vue {
  public tab = {
    store: 'general'
  }

  @Watch('$route.query.appId', { immediate: true })
  onRouteQueryAppIdChanged(val: string) {
    if (val) this.tab.store = 'general'
  }
  @Watch('$route.query.tab', { immediate: true })
  onRouteQueryTabChanged(val: string) {
    if(val !== 'info') this.tab.store = 'general'
  }
  @Watch('tab.store')
  onTabInfoChanged(val: string) {
    let path = this.$router.history.current.path, query = this.$router.history.current.query
    let newQuery = JSON.parse(JSON.stringify(query))
    newQuery.subtab = val
    this.$router.push({ path, query: newQuery })
  }

  // beforeRouteLeave(to, from, next) {
  //   if (/\/error\//.test(to.path)) return false
  //   next(false)
  //   setTimeout(() => {
  //     this.$confirm('系统可能不会保存您所做的更改，是否离开？', {
  //       confirmButtonText: '是',
  //       cancelButtonText: '否',
  //       type: 'warning'
  //     }).then(() => {
  //       next()
  //     })
  //   })
  // }

  mounted() {
    if (this.$route.query.subtab) this.tab.store = this.$route.query.subtab as string
  }

  changeTab(type) {
    this.tab.store = type
  }

}
export default DeveloperAppStore
