
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "Component-Modal-Center",
  components: {},
})
class ComponentSearchMobile extends Vue {

  @Prop({ required: false, default: false }) public visible;

  public condition = {
    name: '',
  }

  @Watch('visible')
  onVisibleChanged(val: string) {
    if (val) {
      this.$nextTick(() => {
        this.$refs.searchInput?.focus()
      })
    }
  }

  created() {

  }
  mounted() {

  }

  public onSearch() {
    this.$emit("search", this.condition.name)
    this.hide()
  }
  public show() {
    this.$emit("update:visible", true)
  }
  public hide() {
    this.$emit("update:visible", false)
  }

}
export default ComponentSearchMobile
