
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getParam } from '@/utils'

@Component({
  name: 'Error',
  components: {},
})
class Error extends Vue {
  public error = decodeURIComponent(getParam('error') || '')
  public errorDesc = decodeURIComponent(getParam('error_description') || '')

  public onGoHome() {
    this.$router.push('/')
  }
}
export default Error
