var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "guide-contact-card" }, [
    _c("div", {
      staticClass: "icon",
      style: {
        background: "url(" + _vm.iconPath + ") no-repeat",
        backgroundSize: "contain",
      },
    }),
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }