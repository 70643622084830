var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-guide-event" }, [
    _c(
      "div",
      { staticClass: "gate-guide-contain" },
      [
        _c("guideTitle", {
          attrs: { title: _vm.$t("views.guide.events.title"), hasMore: true },
        }),
        _c(
          "div",
          { staticClass: "gate-event" },
          [
            _c("activityCard", {
              attrs: {
                timeRange: _vm.$t("views.guide.events.eventTimeRange1"),
                address: _vm.$t("views.guide.events.eventAddress1"),
                title: _vm.$t("views.guide.events.eventTitle1"),
                introduction: _vm.$t("views.guide.events.eventIntro1"),
                bg: _vm.actCigaBg,
              },
            }),
            _c("activityCard", {
              attrs: {
                timeRange: _vm.$t("views.guide.events.eventTimeRange2"),
                address: _vm.$t("views.guide.events.eventAddress2"),
                title: _vm.$t("views.guide.events.eventTitle2"),
                introduction: _vm.$t("views.guide.events.eventIntro2"),
                bg: _vm.actGGJBg,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }