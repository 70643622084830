var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("Header"),
      _vm.isIndex
        ? _c(
            "div",
            { staticClass: "banner-wap", attrs: { id: "banner-warps" } },
            [
              _vm.isMobile && _vm.isIos
                ? _c("div", { staticClass: "banner-mask" })
                : _vm._e(),
              _c("video", {
                attrs: {
                  src: require("../../assets/aiCompetion/banner-bg.mp4"),
                  id: "video1",
                  muted: "muted",
                  loop: "loop",
                  autoplay: "autoplay",
                  preload: "auto",
                  playsinline: "",
                  "webkit-playsinline": "",
                  "x5-playsinline": "",
                },
                domProps: { muted: true },
              }),
              _c("div", [
                _vm.isMobile
                  ? _c("img", {
                      staticClass: "banner-wap__title",
                      attrs: {
                        src: require("../../assets/aiCompetion/mobile/banner-title.png"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      staticClass: "banner-wap__title",
                      attrs: {
                        src: require("../../assets/aiCompetion/banner-title.png"),
                        alt: "",
                      },
                    }),
                _c(
                  "button",
                  {
                    staticClass: "banner-wap__btn",
                    on: {
                      click: function ($event) {
                        return _vm.openPath("/application")
                      },
                    },
                  },
                  [_vm._v("上传作品")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isIndex
        ? _c(
            "div",
            {
              class: _vm.menuStatus ? "nav-warp nav-warp-scroll" : "nav-warp",
              attrs: { id: "nav-warps" },
            },
            [
              _c(
                "div",
                { staticClass: "nav-warp__box" },
                _vm._l(_vm.menuList, function (item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      class: item.select ? "nav-warp__select" : "",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.menuPush(index, item.path, item.href)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content-warp" },
        [_c("router-view", { ref: "child" })],
        1
      ),
      !_vm.isMobile
        ? _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "bottom-nav" }, [
              _c(
                "div",
                {
                  staticClass: "logo",
                  on: {
                    click: function ($event) {
                      return _vm.toPath("/aicompetions/index")
                    },
                  },
                },
                [_vm._v("AI Competition")]
              ),
              _c("p", [
                _vm._v("Copyright © 2023 AI Competition.All rights reserved"),
              ]),
              _vm._m(0),
            ]),
          ])
        : _vm._e(),
      _vm.isMobile
        ? _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "bottom-nav" }, [
              _c(
                "div",
                {
                  staticClass: "logo",
                  on: {
                    click: function ($event) {
                      return _vm.toPath("/aicompetions/index")
                    },
                  },
                },
                [_vm._v("AI Competition")]
              ),
              _vm._m(1),
            ]),
          ])
        : _vm._e(),
      !_vm.isMobile ? _c("div", { staticClass: "bottom-bar" }) : _vm._e(),
      _c("div", { attrs: { id: "fix-bottom" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom-icon-warp" }, [
      _c("a", { staticClass: "bottom-icon1", attrs: { href: "javascript:;" } }),
      _c("a", { staticClass: "bottom-icon2", attrs: { href: "javascript:;" } }),
      _c("a", { staticClass: "bottom-icon3", attrs: { href: "javascript:;" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom-icon-warp" }, [
      _c("a", { staticClass: "bottom-icon1", attrs: { href: "javascript:;" } }),
      _c("a", { staticClass: "bottom-icon2", attrs: { href: "javascript:;" } }),
      _c("a", { staticClass: "bottom-icon3", attrs: { href: "javascript:;" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }