import Vue from 'vue';
import VueRouter from 'vue-router';
import { ConfigModule } from '@/store/modules/config';
import { staticRoutes, defaultRoutes } from './route';
import { commonRoutes } from '../commonRoute/route';
import 'nprogress/nprogress.css';

import { isClient } from '@/utils/env';
import routeDecorator from '../routeDecorators';

Vue.use(VueRouter);

const defaultPath = isClient ? '/account/login' : '/aicompetions/index';

ConfigModule.MASetTitle('Oasis');

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: createRoutesTree(),
});


function createRoutesTree() {
  return [...defaultRoutes, ...commonRoutes, ...staticRoutes];
}

// 导出路由
export default routeDecorator(router, defaultPath);
