var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _vm.worksData
      ? _c("div", { staticClass: "list-warp" }, [
          _c("div", { staticClass: "all-works-title title" }, [
            _vm._v("全部作品"),
          ]),
          _c("div", { staticClass: "list-warp__menu" }, [
            _c(
              "ul",
              [
                _c(
                  "span",
                  {
                    staticClass: "tabs-item",
                    class: { active: _vm.curTab === "all" },
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("all", $event)
                      },
                    },
                  },
                  [_vm._v("全部")]
                ),
                _vm._l(_vm.AITrackList, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.key,
                      staticClass: "tabs-item",
                      class: { active: _vm.curTab === item.key },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(item.key, $event)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ]),
          !_vm.isMobile
            ? _c(
                "div",
                { staticClass: "list-warp__list" },
                _vm._l(_vm.fitGameList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        (index + 1) % 3 == 0
                          ? "list-warp__list-box-warp list-box-warp-last"
                          : "list-warp__list-box-warp",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "list-warp__list-box",
                          on: {
                            click: function ($event) {
                              return _vm.goToGame(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "list-warp__list-item" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  (item.store &&
                                    item.store.medias &&
                                    _vm.getCosUrl(
                                      item.store.medias.cover,
                                      "base"
                                    )) ||
                                  _vm.DefaultGameCover,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "list-warp__info-box" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    (item.store &&
                                      item.store.info &&
                                      item.store.info.productName) ||
                                      ""
                                  )
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (item.store &&
                                      item.store.info &&
                                      item.store.info.productDetail) ||
                                      ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.isMobile
            ? _c(
                "div",
                { staticClass: "list-warp__list" },
                _vm._l(_vm.fitGameList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        (index + 1) % 2 == 0
                          ? "list-warp__list-box-warp list-box-warp-last"
                          : "list-warp__list-box-warp",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "list-warp__list-box",
                          on: {
                            click: function ($event) {
                              return _vm.goToGame(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "list-warp__list-item" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  (item.store &&
                                    item.store.medias &&
                                    _vm.getCosUrl(
                                      item.store.medias.cover,
                                      "base"
                                    )) ||
                                  _vm.DefaultGameCover,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "list-warp__info-box" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    (item.store &&
                                      item.store.info &&
                                      item.store.info.productName) ||
                                      ""
                                  )
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (item.store &&
                                      item.store.info &&
                                      item.store.info.productDetail) ||
                                      ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ])
      : _vm._e(),
    !_vm.worksData
      ? _c("div", { staticClass: "list-warp" }, [
          _c("div", { staticClass: "no-data-warp" }, [
            _vm.isMobile
              ? _c("div", { staticClass: "all-works-title title" }, [
                  _vm._v("全部作品"),
                ])
              : _vm._e(),
            _c("img", {
              attrs: {
                src: require("../../assets/aiCompetion/works-no-data.png"),
                alt: "",
              },
            }),
            _c("p", [_vm._v("作品还在路上")]),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.openPath("/application")
                  },
                },
              },
              [_vm._v("上传作品")]
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "bottom" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }