var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-store-general" } },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "comp-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "large",
            "label-width": "1rem",
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "form-row" },
            [_c("InfoGeneral", { ref: "infoGenerlRef" })],
            1
          ),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [_c("keep-alive", [_c("InfoClient")], 1)],
            1
          ),
          _c("el-row", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-item-btns" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn save",
                    attrs: {
                      type: "primary",
                      loading: _vm.loading.save || _vm.loading.upload,
                    },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("保存草稿")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn cancel",
                    attrs: {
                      type: "default",
                      loading: _vm.loading.save || _vm.loading.upload,
                    },
                    on: { click: _vm.onCancel },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.giveup")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }