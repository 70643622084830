
  import { Component, Vue } from 'vue-property-decorator'
  @Component({
    name: 'community-home',
    components: {},
  })
  class communityHome extends Vue {
    private active = {
      lang: 'zh-cn'
    }
  
    get key() {
      return this.$route.path;
    }
  
    public onLogoClick() {
      // this.$router.push(isClient ? '/home/client' : '/home/browser');
    }
  }
  export default communityHome
  