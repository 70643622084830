import { render, staticRenderFns } from "./StoreGeneral.vue?vue&type=template&id=0f709234&scoped=true&"
import script from "./StoreGeneral.vue?vue&type=script&lang=ts&"
export * from "./StoreGeneral.vue?vue&type=script&lang=ts&"
import style0 from "./StoreGeneral.vue?vue&type=style&index=0&id=0f709234&lang=scss&scoped=true&"
import style1 from "./StoreGeneral.vue?vue&type=style&index=1&id=0f709234&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f709234",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/landun/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f709234')) {
      api.createRecord('0f709234', component.options)
    } else {
      api.reload('0f709234', component.options)
    }
    module.hot.accept("./StoreGeneral.vue?vue&type=template&id=0f709234&scoped=true&", function () {
      api.rerender('0f709234', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/developer/app/component/StoreGeneral.vue"
export default component.exports