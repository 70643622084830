var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-header" }, [
    _c("div", { staticClass: "logo" }, [_vm._v("logo")]),
    _c(
      "div",
      { staticClass: "menu-container" },
      [
        _c("div", { staticClass: "icon-arrow-left" }),
        _vm._l(_vm.menuList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: { menu: true, active: _vm.currentMenu == item.key },
            },
            [_vm._v(_vm._s(item.label))]
          )
        }),
      ],
      2
    ),
    _c("div", { staticClass: "user" }, [_vm._v("user")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }