var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gate-article-add" },
    [
      _c("PublishHeader"),
      _c("div", { staticClass: "article-container" }, [
        _c(
          "div",
          { staticClass: "editor-card" },
          [
            _c(
              "div",
              { staticClass: "editor-title" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Title (Maximum 100 Words)" },
                  model: {
                    value: _vm.articleTitle,
                    callback: function ($$v) {
                      _vm.articleTitle = $$v
                    },
                    expression: "articleTitle",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "editor-title" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 2,
                    placeholder: "Short Introduction (Optional)",
                  },
                  model: {
                    value: _vm.introduction,
                    callback: function ($$v) {
                      _vm.introduction = $$v
                    },
                    expression: "introduction",
                  },
                }),
              ],
              1
            ),
            _c("TinyEditor", { ref: "articleEditor" }),
          ],
          1
        ),
        _c("div", { staticClass: "divisionLine" }),
        _c(
          "div",
          { staticClass: "option-card" },
          [
            _c("div", { staticClass: "option-title" }, [_vm._v("Setting")]),
            _c("ArticleOptionFormVue", { ref: "articleOption" }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "style-spacer" }),
      _c("ArticleFooter", { on: { onSave: _vm.onSave } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }