var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "page-developer-app-store" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "store-tabs",
          model: {
            value: _vm.tabs.store,
            callback: function ($$v) {
              _vm.$set(_vm.tabs, "store", $$v)
            },
            expression: "tabs.store",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "set" } },
            [
              _c(
                "keep-alive",
                [_vm.tabs.store == "set" ? _c("productSet") : _vm._e()],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "宣传素材", name: "info" } },
            [
              _c(
                "keep-alive",
                [_vm.tabs.store == "info" ? _c("productInfo") : _vm._e()],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "构件", name: "info" } },
            [
              _c(
                "keep-alive",
                [_vm.tabs.store == "info" ? _c("productInfo") : _vm._e()],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }