var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-guide-case" }, [
    _c("div", { staticClass: "gate-guide-contain" }, [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("guideTitle", {
            attrs: { title: _vm.$t("views.guide.case.title"), hasMore: false },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "carousel" },
        [
          _c("caseCarouselCard", {
            attrs: {
              title: _vm.$t("views.guide.case.carousel1.title"),
              introduction: _vm.$t("views.guide.case.carousel1.intro"),
              bgPath: _vm.carouselBg1,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "banner" }, [
        _c("div", { staticClass: "cardBox" }, [
          _c(
            "div",
            { staticClass: "container", style: _vm.animationStyle },
            [
              _c("caseSwiperCard", {
                attrs: {
                  title: _vm.$t("views.guide.case.banner1.title"),
                  introduction: _vm.$t("views.guide.case.banner1.intro"),
                  tags: _vm.$t("views.guide.case.banner1.tags"),
                  videoUrl: _vm.wraithVideo,
                  bgPath: _vm.bannerBg1,
                },
              }),
              _c("caseSwiperCard", {
                attrs: {
                  title: _vm.$t("views.guide.case.banner2.title"),
                  introduction: _vm.$t("views.guide.case.banner2.intro"),
                  tags: _vm.$t("views.guide.case.banner2.tags"),
                  videoUrl: _vm.aiMakerVideo,
                  bgPath: _vm.bannerBg2,
                },
              }),
              _c("caseSwiperCard", {
                attrs: {
                  title: _vm.$t("views.guide.case.banner3.title"),
                  introduction: _vm.$t("views.guide.case.banner3.intro"),
                  tags: _vm.$t("views.guide.case.banner3.tags"),
                  videoUrl: _vm.fusionVideo,
                  bgPath: _vm.bannerBg3,
                },
              }),
              _c("caseSwiperCard", {
                attrs: {
                  title: _vm.$t("views.guide.case.banner4.title"),
                  introduction: _vm.$t("views.guide.case.banner4.intro"),
                  tags: _vm.$t("views.guide.case.banner4.tags"),
                  videoUrl: _vm.godCanTalkVideo,
                  bgPath: _vm.bannerBg4,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "maskContainer" }, [
          _c("div", { staticClass: "leftMask mask" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.translateIndex > 0,
                    expression: "this.translateIndex > 0",
                  },
                ],
                staticClass: "leftBtnBg btnBg",
                on: { click: _vm.toLast },
              },
              [_c("div", { staticClass: "leftBtn Btn" })]
            ),
          ]),
          _c("div", { staticClass: "rightMask mask" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.translateIndex < this.translateXList.length - 1,
                    expression:
                      "this.translateIndex < this.translateXList.length - 1",
                  },
                ],
                staticClass: "rightBtnBg btnBg",
                on: { click: _vm.toNext },
              },
              [_c("div", { staticClass: "rightBtn Btn" })]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "style-spacer" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }