
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  name: "Component-Modal-Center",
  components: {},
})
class ComponentModalCenter extends Vue {

  @Prop({ required: false, default: false }) public visible

  created() {

  }
  mounted() {

  }

  public show() {
    this.$emit("update:visible", true)
  }
  public hide() {
    this.$emit("update:visible", false)
  }
  public onOk() {
    this.$emit('ok')
    this.hide()
  }
  public onCancel() {
    this.$emit('cancel')
    this.hide()
  }

}
export default ComponentModalCenter
