import Vue from 'vue';
import Clipboard from 'clipboard';

function clipboardSuccess(msg) {
  Vue.prototype.$message({
    message: msg,
    type: 'success',
    duration: 1500,
  });
}

function clipboardError(msg) {
  Vue.prototype.$message({
    message: msg,
    type: 'error',
  });
}

export default function handleClipboard(text, event, succMsg = 'Copy successfully', errMsg = 'Copy failed') {
  const clipboard: any = new Clipboard(event.target, {
    text: () => text,
  });
  clipboard.on('success', () => {
    clipboardSuccess(succMsg);
    clipboard.off('error');
    clipboard.off('success');
    clipboard.destroy();
  });
  clipboard.on('error', () => {
    clipboardError(errMsg);
    clipboard.off('error');
    clipboard.off('success');
    clipboard.destroy();
  });
  clipboard.onClick(event);
}
