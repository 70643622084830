<template>
  <div class="article-option">
    <el-form ref="optionForm" :model="form" :rules="rules" label-width="160px" >
      <el-form-item label="Article Category" prop="lore_essay_type">
        <el-select v-model="form.lore_essay_type" placeholder="please select article category">
          <el-option v-for="(item, index) in ArticleCategoryOptions" :key="index" :label="item.label" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Article Tags" prop="label">
        <el-select
          v-model="form.label"
          multiple
          filterable
          allow-create
          :multiple-limit="5"
          default-first-option
          placeholder="Separate tags with spaces, up to 5 tags">
          <el-option v-for="(item, index) in ArticleTagOptions" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Add Cover" prop="cover">
        <el-upload
          list-type="picture-card"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-remove="handleRemove"
          :multiple="false"
          :on-change="uploadCover"
          >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="Recommended AI Tool" prop="tools_id">
        <el-button icon="el-icon-plus" plain>Add AI Tool</el-button>
      </el-form-item>
      <el-form-item label="Relavent Content" prop="connects">
        <el-button icon="el-icon-plus" plain>Add Content</el-button>
      </el-form-item>
    </el-form>
  </div>
  </template>
  
  <script>
  import { ArticleCategoryOptions, ArticleTagOptions } from '@/config/article';
  export default {
    name: 'article-option-form',
    data() {
      return {
        ArticleCategoryOptions,
        ArticleTagOptions,
        form: {
          lore_essay_type: '',
          label: [],
          cover: '',
          connects: [],
          tools_id: []
        },
        rules: {
          lore_essay_type: [
            { required: true, message: 'please select article category', trigger: 'change' }
          ],
        }
      }
    },
    mounted() {
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      uploadCover(file) {
        this.form.diy_config = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
           console.log('file result:', reader.result);
          }
        };
        reader.readAsText(file.raw);
      },
      submitForm() {
        return new Promise(async (resolve) => {
          this.$refs.optionForm.validate((valid) => {
            console.log(this.form);
            if (valid) {
              return resolve(this.form);
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        });
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .article-option{
    padding: 24px 0;
    :deep(.el-select){
      width: 320px;
    }

    :deep(.el-button){
      width: 160px;
    }
  }
  
  </style>
  