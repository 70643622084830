var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gate-guide-banner" },
    [
      _c("guideNav", { on: { setLang: _vm.setLang } }),
      _c("div", { staticClass: "gate-guide-contain" }, [
        _c("div", { staticClass: "gate-email-contain" }, [
          _c("div", { staticClass: "gate-tips" }, [
            _vm._v(" " + _vm._s(_vm.$t("views.guide.banner.tips1")) + " "),
          ]),
          _c("div", { staticClass: "gate-slogan font-while" }, [
            _vm._v(" " + _vm._s(_vm.$t("views.guide.banner.slogan1")) + " "),
          ]),
          _c("div", { staticClass: "gate-slogan font-green" }, [
            _vm.isChinese ? _c("div", [_vm._v("开启")]) : _vm._e(),
            _c("div", { staticClass: "scrollAnimation" }, [
              _c(
                "div",
                { class: { animation: true, zhText: _vm.isChinese } },
                [
                  _vm._l(
                    _vm.$t("views.guide.banner.scrollSlogan"),
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "sloganText" },
                        [_vm._v(_vm._s(item))]
                      )
                    }
                  ),
                  _c("div", { staticClass: "sloganText" }, [
                    _vm._v(
                      _vm._s(_vm.$t("views.guide.banner.scrollSlogan")[0])
                    ),
                  ]),
                ],
                2
              ),
            ]),
            _vm.isChinese ? _c("div", [_vm._v("之门")]) : _vm._e(),
          ]),
          !_vm.isChinese
            ? _c("div", { staticClass: "gate-slogan font-while" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("views.guide.banner.slogan3")) + " "
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "gate-email-box" },
            [
              _c("div", { staticClass: "email-tips" }, [
                _vm._v(_vm._s(_vm.$t("views.guide.banner.emailTips"))),
              ]),
              _c(
                "el-input",
                {
                  staticStyle: { width: "460px" },
                  attrs: {
                    placeholder: _vm.$t("views.guide.banner.placeholder"),
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.submitEmail },
                        },
                        [_vm._v(_vm._s(_vm.$t("views.guide.banner.subscribe")))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "follow-row" }, [
            _c("div", { staticClass: "follow-btn" }, [
              _c("div", { staticClass: "icon icon-discord" }),
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("views.guide.banner.discord"))),
              ]),
            ]),
            _c("div", { staticClass: "follow-btn" }, [
              _c("div", { staticClass: "icon icon-wechat" }),
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("views.guide.banner.wechat"))),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "style-spacer" }),
        ]),
      ]),
      _c("div", { staticClass: "gate-guide-readme" }, [
        _c("div", { staticClass: "gate-readme" }, [
          _vm._v(" " + _vm._s(_vm.$t("views.guide.banner.tips2")) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }