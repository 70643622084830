
import { Component, Vue } from 'vue-property-decorator'
import {  getToolsbytype, getToolsCollect, addToolsCollect, deleteToolsCollect, getToolsrecentuse, replaceToolsRecentuse, delToolsRecentuse} from '@/api/developerApi/tools'
import { ASDKInfoGet } from '@/api/developerApi/developer'
import { RoutesModule } from '@/store/modules/routes'
import User from '@/layout/navBars/breadcrumb/user.vue';
import ToolMarket from './component/toolMarket.vue';
import ToolDetailsVue from './component/toolDetails.vue';
import { AIToolMenuList, AIToolPlatform } from '@/config/developer'

@Component({
  name: 'DeveloperSDK',
  components: { User, ToolMarket, ToolDetailsVue },
  filters: {
    lableFormat(value: number) {
      if (!value && value !== 0) return '';
      let labelName = '';
      AIToolPlatform.forEach(item => {
        if (item.key === value) {
          labelName = item.name;
        }
      })
      return labelName;
    }
  }
})
class DeveloperSDK extends Vue {
  public loading = {
    info: false,
    download: false,
  }
  public sdkList: any = [];
  public curtab = 'mytool';
  public menuList:any = [];
  public collectList:any = [];
  public classifyList:any = [];

  created() {
    RoutesModule.MASetNavList([{ path: "/sdk", meta: { title: "SDK" } }]);
    this.getSDKInfo();
    if (this.$route.query.curtab) {
      this.tabChange(this.$route.query.curtab)
    }
    this.initData()
  }

  public getSDKInfo() {
    this.loading.info = true
    ASDKInfoGet().then((res) => {
      console.log("res", res)
      let data = res.data || {}
      this.sdkList = data.Tabs || []
      if (this.sdkList.length !== 0) {
        this.sdkList = this.sdkList.slice(0, 1);
        this.sdkList[0].Title = '作品必须接入的SDK';
      }
      this.loading.info = false
    }).catch(err => {
      console.error(err)
      this.loading.info = false
      this.$message.error(this.$t('tips.fail.get') as string)
    })
  }

  public goToDocment(item) {
    this.$report('dev/sdk/document/' + item.Name, 'click')
    window.open(item.Doc)
  }
  public onDownload(item) {
    this.$report('dev/sdk/download/' + item.Name, 'click')
    window.open(item.Download)
    // this.loading.download = true
    // fetch(sdk.url).then(res =>
    //   res.blob().then(blob => {
    //     download(blob, sdk.fileName)
    //     this.loading.download = false
    //   })
    // )
  }
  public showDetails(param: any) {
    const child = this.$refs.details as ToolMarket;
    child.ShowToolDetail(param);
  }
  public getCollect() {
    getToolsCollect({text: ''}).then(res => {
      console.log('getToolsCollect', res)
      if (!res.data) return;
      let list = [];
      if (!res.data) {
        this.collectList = [];
        this.geSrecentuse();
        return;
      }
      list = res.data?.get_data;
      AIToolMenuList.forEach((type) => {
        if (type.index) {
          let childList = {
            typeName: '',
            typeList: []
          };
          list?.forEach((item: any) => {
            item.more = false;
            if (item.tools_basic_data.tools_type === type.index) {
              childList.typeName = type.name;
              childList.typeList.push(item);
            }
          })
          if (childList.typeList.length > 0) {
            this.collectList.push(childList);
          }
        }
      })
      this.geSrecentuse();
      console.log('collectList', this.collectList);
    })
  }
  public tabChange(cur: string, type: string = '') {
    this.curtab = cur;
    let query = {curtab: cur}
    this.$router.push({ path: this.$route.path, query });
    if (type === 'update') {
      this.initData();
    }
  }
  public getClassify() {
    this.classifyList = []
    getToolsbytype({tools_type: 1, tools_limit: 10, tools_offset: 0}).then((res) => {
      console.log('getToolsbytype res', res)
      this.classifyList = res.data;
      console.log('classifyList', this.classifyList)
    })
  }
  public openLink(link: string, id: string = '') {
    if (id) this.addToolsRecentuse(id);
    window.open(`${link}`)
  }
  public deleteCollect(id: string, index: number, childIndex: number) {
    const data = {
      del_data: {
        tools_id: id
      }
    }
    deleteToolsCollect(data).then(res => {
      console.log('deleteToolsCollect res', res)
      if (res.code === 0) {
        this.updateState(index, childIndex, 'delete')
      }
    })
  }
  public toolsCollect(id: string, index: number, childIndex: number) {
    const data = {
      replace_data: {
        tools_id: id
      }
    }
    addToolsCollect(data).then(res => {
      console.log('addToolsCollect res', res)
      if (res.code === 0) {
        //收藏成功
        this.updateState(index, childIndex, 'add')
      }
    })
  }
  public geSrecentuse() {
    getToolsrecentuse({text: ''}).then(res => {
      console.log('getToolsRecentuse res', res)
      if (!res.data) {
        return;
      } 
      let childList = {
        typeName: '最近使用',
        typeList: [],
        type: 'recentuse'
      };
      if (res.data.get_data.length > 0) {
        childList.typeList = res.data.get_data;
        childList.typeList.sort((a:any, b: any) =>{
          return parseInt(b.up_time) - parseInt(a.up_time);
        })
        if (!this.collectList[0].type && this.collectList[0].type !== 'recentuse') {
          this.collectList.unshift(childList);
        }
      }
    })
  }
  //更新收藏状态
  public updateState(index: number, childIndex: number, type: string = '') {
    if (childIndex !== -1) { //我的收藏
      this.collectList[index].typeList[childIndex].tools_basic_data.is_collect = type === 'add' ? 1 : 0;
    } else {
      this.classifyList[index].tools_basic_data.is_collect = type === 'add' ? 1 : 0;
    }
  }
  public initData() {
    this.menuList = [];
    this.collectList = [];
    this.classifyList = [];
    this.getCollect();
    this.getClassify();
  }
  public delRecentuse(id: string, index: number) {
    const data = {
      data: {
        tools_id: id
      }
    }
    delToolsRecentuse(data).then(res => {
      if (res.code === 0) {
        this.collectList[0].typeList.splice(index, 1)
      }
    })
  }
  public labelChange(label: number) {
    let labelNmae = '';
    AIToolPlatform.forEach(item => {
      if (item.key === label) {
        labelNmae = item.name;
      }
    })
    return labelNmae;
  }
  public isShowMore(index: number, childIndex: number) {
    this.collectList[index].typeList[childIndex].more = !this.collectList[index].typeList[childIndex].more;
  }
  public addToolsRecentuse(id: string) {
    const data = {
      data: {
        tools_id: id
      }
    }
    replaceToolsRecentuse(data).then(res => {
      console.log('replaceToolsRecentuse res', res)
    })
  }
}
export default DeveloperSDK
