var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-container",
    { staticClass: "layout-container", attrs: { id: "layout-index" } },
    [
      _c("el-header", { staticClass: "layout-header" }, [_c("Header")], 1),
      _c(
        "el-main",
        { staticClass: "layout-main" },
        [
          _c("Aside", { staticClass: "layout-aside" }),
          _c(
            "el-scrollbar",
            {
              ref: "layoutDefaultsScrollbarRef",
              staticClass: "layout-content",
            },
            [_c("Main")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }