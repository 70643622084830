
import { Component, Vue, Watch } from 'vue-property-decorator'
import InfoTestAcc from './component/InfoTestAcc.vue'

@Component({
  name: 'DeveloperAppService',
  components: {
    InfoTestAcc,
  }
})
class DeveloperAppService extends Vue {
  public tab = {
    service: 'testAcc'
  }
  
}
export default DeveloperAppService
