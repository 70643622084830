var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "dialog",
      attrs: { id: "comp-search-mobile" },
      on: {
        touchmove: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hide.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "search",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "el-input",
            {
              ref: "searchInput",
              staticClass: "search-input",
              attrs: { placeholder: "搜索作品", clearable: "" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onSearch.apply(null, arguments)
                },
              },
              model: {
                value: _vm.condition.name,
                callback: function ($$v) {
                  _vm.$set(_vm.condition, "name", $$v)
                },
                expression: "condition.name",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ]
          ),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.hide.apply(null, arguments)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }