
import { Component, Vue, Watch } from 'vue-property-decorator'
import User from '@/layout/navBars/breadcrumb/user.vue';
import { RoutesModule } from '@/store/modules/routes'

@Component({
  name: 'layoutBreadcrumbIndex',
  components: { User },
})
class layoutBreadcrumbIndex extends Vue {

  get navList() {
    return RoutesModule.navList
  }

  created() {
  }
}
export default layoutBreadcrumbIndex
