var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-aside",
    { staticClass: "layout-aside" },
    [
      _c("Logo"),
      _c(
        "el-scrollbar",
        { ref: "layoutAsideScrollbarRef", staticClass: "flex-auto" },
        [
          _c("Vertical", { attrs: { menuList: _vm.menuList } }),
          _vm.isDevModule
            ? [
                _vm.devModule.apps.length
                  ? _c("div", { staticClass: "line" })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading.apps,
                        expression: "loading.apps",
                      },
                    ],
                  },
                  _vm._l(_vm.devModule.apps, function (app) {
                    return _c("AppMenu", {
                      key: app.id,
                      attrs: { appInfo: app, tab: _vm.curTab },
                      on: {
                        changeTab: (tab) => {
                          _vm.curTab = tab
                        },
                      },
                    })
                  }),
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }