// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/article/icon/arrow_left.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n/* 栅格布局（媒体查询变量）\n* https://developer.mozilla.org/zh-CN/docs/Learn/CSS/CSS_layout/Media_queries\n* $us ≥376px  响应式栅格\n* $xs ≥576px  响应式栅格\n* $sm ≥768px  响应式栅格\n* $md ≥992px  响应式栅格\n* $lg ≥1200px 响应式栅格\n* $xl ≥1920px 响应式栅格\n------------------------------- */\n.publish-header {\n  padding: 32px 0;\n  margin: 0;\n  width: 100%;\n  height: 113px;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: justify;\n  -webkit-justify-content: space-between;\n          justify-content: space-between;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n          align-items: center;\n  color: #fff;\n}\n.publish-header .menu-container {\n  width: 1200px;\n  height: 100%;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: flex;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n          align-items: center;\n}\n.publish-header .menu-container .icon-arrow-left {\n  width: 24px;\n  height: 24px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n}\n.publish-header .menu-container .menu {\n  color: rgba(255, 255, 255, 0.6);\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  margin-left: 42px;\n}\n.publish-header .menu-container .active {\n  color: #fff;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
