// https://test-openoasis.woa.com/
// export const clientId = 'CH4TBHJTWEFDFTI2X7SZRS65XOU';
// https://dev-openoasis.woa.com/
// export const clientId = 'CSFE7GME6UFFLNMC5YIQVRVNU4U';
// https://oasisdev.qq.com/
export const clientId = 'CBC3DY2SLVFGZLDF5RM5WPS2EUI';

export const redirectUri = `${location.origin}/account/login`;

export const responseType = 'code';
export const scope = 'openid+offline';

export enum AuthService {
    WOA = 'https://o-openoasis.woa.com/oauth2/auth',
    WOA_DEV = 'https://dev-openoasis.woa.com/oauth2/auth',
    WOA_TEST = 'https://test-openoasis.woa.com/oauth2/auth',
    OASIS_GAME = 'https://oasisgames.qq.com/oauth2/auth',
    OASIS_DEV = 'https://oasisdev.qq.com/oauth2/auth',
}

