const regFile = /([^\\/]+)\.([^\\/]+)$/;

// 获取文件名
export function getFileName(url: string) {
  const result = (url || '').match(regFile);
  return result?.[0];
}

// 拆分文件名
export function splitFileName(fileName: string) {
  const result = (fileName || '').match(regFile);
  return {
    name: result?.[1] || '',
    ext: result?.[2] || '',
  };
}

// 解析生成cos的完整路径
const CosDomain = process.env.VUE_APP_COS_DOMAIN;
export function parseCosUrl(fileName: string) {
  if (!fileName) return { base: '' };
  // url拼接规则：BucketURL + (目录) + SHA1 + 文件字节数 + (后缀) + 扩展名
  // 目录：视频=video-pub/, 图片=image/
  // 后缀：视频流=-stream, 封面=-poster, 动图=-slice
  // 例子：原视频=/video/de8aec4cf111d3307d2cefcbfb9f5cfa7edd8628-4228091.mp4
  // 视频流=/video/de8aec4cf111d3307d2cefcbfb9f5cfa7edd8628-4228091-stream.mp4
  // 普通文件=/de8aec4cf111d3307d2cefcbfb9f5cfa7edd8628-4228091.zip
  const { name, ext } = splitFileName(fileName);
  if (VidFileTypeList.includes(ext)) {
    return {
      base: `${CosDomain}/video-pub/${name}-stream.${ext}`,
      poster: `${CosDomain}/video-pub/${name}-poster.jpg`,
      gif: `${CosDomain}/video-pub/${name}-slice.gif`,
    };
  }
  else if (ImgFileTypeList.includes(ext)) {
    return { base: `${CosDomain}/image/${fileName}` };
  }
  else if (AudFileTypeList.includes(ext)) {
    return { base: `${CosDomain}/audio/${fileName}` };
  }
  else if (DocFileTypeList.includes(ext)) {
    return { base: `${CosDomain}/doc/${fileName}` };
  }
  else {
    return { base: `${CosDomain}/other/${fileName}` };
  }
  // switch (ext) {
  //   case 'bmp':
  //   case 'jpg':
  //   case 'jpeg':
  //   case 'png':
  //   case 'gif':
  //     return { base: `${CosDomain}/image/${fileName}` };
  //   case 'mp4':
  //   case 'avi':
  //   case 'wmv':
  //   case 'mpg':
  //   case 'mpeg':
  //   case 'mov':
  //     return {
  //       base: `${CosDomain}/video-pub/${name}-stream.${ext}`,
  //       poster: `${CosDomain}/video-pub/${name}-poster.jpg`,
  //       gif: `${CosDomain}/video-pub/${name}-slice.gif`,
  //     };
  //   default:
  //     return { base: `${CosDomain}/${fileName}` };
  // }
}

const CosUrlCache: any = {};
export const getCosUrl = (fileName, key = ''): any => {
  // console.log("fileName", fileName)
  if (!fileName) return key ? '' : {};
  let cosUrl: any;
  const cacheUrl = CosUrlCache[fileName];
  if (cacheUrl) cosUrl = cacheUrl;
  else {
    cosUrl = parseCosUrl(fileName);
    CosUrlCache[fileName] = cosUrl;
  }
  return key ? cosUrl[key] : cosUrl;
};

export const ImgFileTypeList = ["jpg", "jpeg", "png", "tif", "gif", "bmp", "webp"];
export const VidFileTypeList = ["mp4", "avi", "mov", "wmv", "rm", "rmvb", "mpg", "mpeg"];
export const AudFileTypeList = ["mp3", "amr", "aac", "m4a"];
export const DocFileTypeList = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "csv"];
export const ZipFileTypeList = ["zip", "rar", "7z"];
export const ExtFileTypeList = ["json"];