
export default {
  name: 'guide-introduction',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: false
    },
    isReserve: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    clickMore() {
      this.$emit('onMore');
    }
  }
}

