var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "guide-title" }, [
    _c("div", { staticClass: "gate-guide-title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "guide-title-around around-right" }, [
      _vm.hasMore
        ? _c("div", { staticClass: "more", on: { click: _vm.clickMore } }, [
            _vm._v(" " + _vm._s(_vm.$t("views.guide.more")) + " "),
            _c("div", { staticClass: "icon" }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }