import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

// 玩家组接口

export async function AGroupGET(appid: string) {
  return request({
    url: `${BaseUrl}/app/group/getusersgroups?appid=${appid}`,
    method: 'GET',
  });
}
export async function AGroupAdd(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/group/create?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function AGroupUpt(appid: string, groupid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/group/update?appid=${appid}&groupid=${groupid}`,
    method: 'POST',
    data,
  });
}
export async function AGroupDel(appid: string, groupid: string) {
  return request({
    url: `${BaseUrl}/app/group/delete?appid=${appid}&groupid=${groupid}`,
    method: 'POST',
  });
}