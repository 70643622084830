
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ADeployAdd, ADeployUpt, ADeployDel } from '@/api/developerApi/deploy'
import { DeveloperModule } from '@/store/modules/developer'
import { normalizeDate, formatDate } from '@/utils/format'
import { ElForm } from 'element-ui/types/form'

const MaxLenName = 12
@Component({
  name: 'CompInfoEnv',
})
class CompInfoEnv extends Vue {
  public form = {
    name: '',
    type: '',
    build: '',
  }
  public rules = {
    name: [
      { required: true, message: '请输入部署名称', trigger: 'change', },
      { max: MaxLenName, message: '超过最大长度：' + MaxLenName, trigger: 'change' }
    ],
    type: [
      { required: true, message: '请选择类型', trigger: 'change', },
    ],
    // build: [
    //   { required: true, message: '请选择关联构件', trigger: 'change', },
    // ],
  }
  public visible = {
    editDrawer: false,
  }
  public loading = {
    save: false,
    list: false,
    delete: false,
  }
  private appId = this.$route.query.appId as string
  private curDeploy: any = null

  get devModule() {
    return this.$store.state.developer
  }
  get storeBuilds() {
    return this.devModule.storeData.builds
  }
  get appData() {
    return this.devModule.appData
  }
  get list() {
    return this.appData && this.appData.env || []
  }
  get isEdit() {
    return !!this.curDeploy
  }

  mounted() {
    this.initData()
  }

  async initData() {
    this.loading.list = true
    if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    this.loading.list = false
  }

  public showEditDrawer(deploy) {
    this.curDeploy = deploy
    this.visible.editDrawer = true
  }

  // 删除部署
  public onDelDeploy(deploy) {
    this.loading.delete = true
    ADeployDel(this.appId, deploy.id).then(() => {
      this.loading.delete = false
      this.$message.success(this.$t('tips.succ.del') as string)
      DeveloperModule.MAGetDevAppData(this.appId)
      this.visible.editDrawer = false
    }).catch(err => {
      console.error(err)
      this.loading.delete = false
      this.$message.error(this.$t('tips.fail.del') as string)
    })
  }

  public onDrawerOpen() {
    // 编辑，初始化数据
    if (this.curDeploy) {
      this.setcur()
    }
  }
  public onDrawerClose() {
    this.reset()
  }

  public onCancel() {
    this.visible.editDrawer = false
  }

  public async submitForm() {
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate((valid: boolean) => {
      if (!valid) return false
      this.loading.save = true
      const param = {
        name: this.form.name,
        type: this.form.type,
        build: this.form.build,
      }
      if (!this.isEdit) {
        ADeployAdd(this.appId, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAppData(this.appId)
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.edit') as string)
        })
      }
      else {
        ADeployUpt(this.appId, this.curDeploy.id, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAppData(this.appId)
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.edit') as string)
        })
      }
    })
  }

  public formatTime(time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime)
  }

  private reset() {
    this.form.name = ''
    this.form.type = ''
    this.form.build = ''
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  private setcur() {
    this.form.name = this.curDeploy.name
    this.form.type = this.curDeploy.type
    this.form.build = this.curDeploy.build
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
}
export default CompInfoEnv
