var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "view-account-home" } },
    [
      _c(
        "div",
        { staticClass: "banner-wrap" },
        [
          _c(
            "el-carousel",
            {
              staticClass: "banner",
              attrs: {
                trigger: "click",
                arrow: "never",
                "indicator-position": "none",
              },
            },
            _vm._l(_vm.bannerList, function (item) {
              return _c("el-carousel-item", { key: item.id }, [
                _c(
                  "div",
                  {
                    staticClass: "banner-pic",
                    on: {
                      click: function ($event) {
                        return _vm.goToBanner(item)
                      },
                    },
                  },
                  [
                    _vm.isPc
                      ? _c("img", { attrs: { src: item.img } })
                      : _c("img", { attrs: { src: item.imgMobile } }),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "selection" }, [
          _c("div", { staticClass: "selection-box" }, [
            _c("span", { staticClass: "title" }, [_vm._v("AI作品")]),
            _c(
              "div",
              {
                staticClass: "tabs-box",
                class: { mask: _vm.visible.scrollMask },
              },
              [
                _c(
                  "div",
                  { ref: "tabs", staticClass: "tabs" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "tabs-item",
                        class: { active: _vm.curTab === "all" },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab("all", $event)
                          },
                        },
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._l(_vm.AITrackList, function (item) {
                      return _c(
                        "span",
                        {
                          key: item.key,
                          staticClass: "tabs-item",
                          class: { active: _vm.curTab === item.key },
                          on: {
                            click: function ($event) {
                              return _vm.changeTab(item.key, $event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-input",
                {
                  staticClass: "search-input",
                  attrs: { placeholder: "搜索作品", clearable: "" },
                  on: { clear: _vm.onSearch },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.onSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.condition.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.condition, "name", $$v)
                    },
                    expression: "condition.name",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "gamelist" },
          [
            _vm._l(_vm.fitGameList, function (item) {
              return _c(
                "div",
                {
                  key: item.appId,
                  staticClass: "gamelist-item",
                  on: {
                    click: function ($event) {
                      return _vm.goToGame(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "gamelist-item-box" }, [
                    _c("img", {
                      staticClass: "poster",
                      attrs: {
                        src:
                          (item.store &&
                            item.store.medias &&
                            _vm.getCosUrl(item.store.medias.cover, "base")) ||
                          _vm.DefaultGameCover,
                      },
                    }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          (item.store &&
                            item.store.info &&
                            item.store.info.productName) ||
                            ""
                        )
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            _vm._l(_vm.fitGameList.slice(0, 1), function (item) {
              return _c(
                "div",
                {
                  key: "aaa",
                  staticClass: "gamelist-item",
                  on: {
                    click: function ($event) {
                      return _vm.goToGame(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "gamelist-item-box" }, [
                    _c("img", {
                      staticClass: "poster",
                      attrs: {
                        src:
                          (item.store &&
                            item.store.medias &&
                            _vm.getCosUrl(item.store.medias.cover, "base")) ||
                          _vm.DefaultGameCover,
                      },
                    }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          (item.store &&
                            item.store.info &&
                            item.store.info.productName) ||
                            ""
                        )
                      ),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible.toTop,
                expression: "visible.toTop",
              },
            ],
            staticClass: "btn-top",
            on: { click: _vm.scrollToTop },
          },
          [_c("i", { staticClass: "el-icon-arrow-up" })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }