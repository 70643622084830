var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "process" }, [
    _c("div", { staticClass: "process-flex" }, [
      _vm._m(0),
      _c("div", { staticClass: "item-animation item-num" }, [_vm._v("审核中")]),
      _c("div", { staticClass: "item-animation item-msg" }, [
        _vm._v("恭喜，您的项目已经创建成功，正在审核中"),
      ]),
      _c(
        "div",
        { staticClass: "item-animation item-btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onGoApps } },
            [_vm._v("返回作品列表")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "process-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/developer/icon-check-circle.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }