
import { Component, Vue, Watch } from 'vue-property-decorator'
import Logo from '@/layout/logo/index.vue';
import Vertical from '@/layout/navMenu/vertical.vue';
import AppMenu from '@/layout/navMenu/appMenu.vue';
import { DeveloperModule } from '@/store/modules/developer'

@Component({
  name: 'layoutAside',
  components: { Logo, Vertical, AppMenu },
})
class layoutAside extends Vue {
  public menuList = [];
  public appList = [];
  public loading = {
    apps: false,
  }
  public curTab = 'info'
  private curAppId = this.$route.query.appId

  get isDevModule() {
    // return COMPILE_ENV.MODULE == 'developer'
    return true
  }
  get devModule() {
    // if (!this.isDevModule) return {}
    return this.$store.state.developer
  }

  @Watch('$route')
  onRouteChanged(val: any) {
    if (val.meta && val.meta.module == 'app') {
      // console.log("onRouteChanged", val, this.$route.query.appId, this.curAppId)
      if (this.$route.query.appId !== this.curAppId && this.curAppId !== '') {
        // 切换app，清空app缓存数据
        DeveloperModule.MAReset(['info', 'store', 'aiBuild'])
        this.curAppId = this.$route.query.appId
      }
    }
  }

  // 页面加载前
  beforeMount() {
    this.initData()
    this.setFilterRoutes();
  }

  private async initData() {
    // if (!this.isDevModule) return
    this.loading.apps = true
    if (!this.devModule.init.apps) await DeveloperModule.MAGetDevApps();
    this.loading.apps = false
  }

  // 设置/过滤路由（非静态路由/是否显示在菜单中）
  private setFilterRoutes() {
    (this.menuList as any) = this.filterRoutesFun(this.$store.state.routes.routesList);
  }
  // 路由过滤递归函数
  private filterRoutesFun(arr: Array<object>) {
    let list = arr
      .filter((item: any) => !item.meta || !item.meta.isHide)
      .map((item: any) => {
        item = Object.assign({}, item);
        if (item.children) item.children = this.filterRoutesFun(item.children);
        return item;
      });
    return list
  }
}
export default layoutAside
