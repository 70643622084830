var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "view-developer-home" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", {
          staticClass: "header-title",
          on: { click: _vm.onLogoClick },
        }),
        _c("div", { staticClass: "header-right" }, [
          _c(
            "div",
            { staticClass: "header-lang" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "header-pop dark",
                    placement: "bottom",
                    trigger: "hover",
                    width: 100,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function () {
                        return [
                          _c("div", {
                            staticClass: "header-lang-icon",
                            class: [_vm.active.lang],
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "pop-list" },
                    _vm._l(_vm.langList, function (item, i) {
                      return _c(
                        "li",
                        {
                          key: i,
                          class: { active: _vm.active.lang == item.key },
                          on: {
                            click: function ($event) {
                              return _vm.onChangeLang(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item[_vm.$tk("name")]))]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.devInfo,
                  expression: "loading.devInfo",
                },
              ],
              staticClass: "developer",
              on: { click: _vm.onJump },
            },
            [_vm._v("提交作品")]
          ),
          !_vm.session.token
            ? _c(
                "button",
                {
                  staticClass: "header-btn login",
                  on: { click: _vm.goToLogin },
                },
                [_vm._v(_vm._s(_vm.$t("views.home.signin")))]
              )
            : _c(
                "div",
                { staticClass: "header-user" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "header-pop dark",
                        placement: "bottom",
                        trigger: "hover",
                        width: 100,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "reference",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "header-user-name" }, [
                                _c("img", { attrs: { src: _vm.avatarUrl } }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.userInfo.nickname)),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("ul", { staticClass: "pop-list" }, [
                        _c("li", { on: { click: _vm.logout } }, [
                          _vm._v(_vm._s(_vm.$t("views.home.signout"))),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "banner-wrap" },
        [
          _c(
            "el-carousel",
            {
              staticClass: "banner",
              attrs: {
                trigger: "click",
                arrow: "never",
                height: "5.86rem",
                "indicator-position": "none",
              },
            },
            _vm._l(_vm.bannerList, function (item) {
              return _c("el-carousel-item", { key: item.id }, [
                _c("div", { staticClass: "banner-pic" }, [
                  _c("img", {
                    attrs: { src: item.img },
                    on: {
                      click: function ($event) {
                        return _vm.goToBanner(item)
                      },
                    },
                  }),
                ]),
              ])
            }),
            1
          ),
          _c("div", { staticClass: "banner-line top" }),
          _c("div", { staticClass: "banner-line bottom" }),
        ],
        1
      ),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "part tools" }, [
          _c("h3", [_vm._v("AI工具")]),
          _c("span", { staticClass: "seemore", on: { click: _vm.onJump } }, [
            _vm._v("了解更多 >"),
          ]),
          _vm._m(0),
        ]),
        _c("div", { staticClass: "part platforms" }, [
          _c("h3", [_vm._v("AI游戏平台")]),
          _c("div", { staticClass: "list" }, [
            _c(
              "div",
              { staticClass: "list-item", on: { click: _vm.goToOasisAccount } },
              [
                _c("div", { staticClass: "dot top" }),
                _c("div", { staticClass: "dot bottom" }),
                _c("img", {
                  attrs: {
                    src: require("../../assets/developer/ai/img_logo_big.png"),
                  },
                }),
                _c("h4", [_vm._v("AI Competition")]),
                _c("p", [
                  _vm._v(
                    "AI Competition是一款AI游戏发行平台，汇聚了AI游戏、AI工具与AI艺术作品，玩家可以通过平台查看与下载这些创作内容，还可以与其他玩家进行互动交流"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "list-item", on: { click: _vm.goToDeveloper } },
              [
                _c("div", { staticClass: "dot top" }),
                _c("div", { staticClass: "dot bottom" }),
                _c("img", {
                  attrs: {
                    src: require("../../assets/developer/ai/img_logo_big.png"),
                  },
                }),
                _c("h4", [_vm._v("Oasis AI Developer")]),
                _c("p", [
                  _vm._v(
                    "Oasis AI Developer是一款提供创作、发行的工具网站，致力于为开发者提供高效、便捷的创作工具。平台提供账户服务、数据服务、AI创作工具等，同时也提供SDK与多样化的API，方便开发者进行创作与发布"
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "part community" }, [
          _c("h3", [_vm._v("开发者社区")]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-item" }, [
              _c("div", { staticClass: "dot top" }),
              _c("div", { staticClass: "dot bottom" }),
              _vm._m(1),
              _vm._m(2),
              _c(
                "div",
                { staticClass: "godiscord", on: { click: _vm.goToDiscord } },
                [_vm._v("Discord Server →")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._m(3),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "list-item" }, [
        _c("div", { staticClass: "dot top" }),
        _c("div", { staticClass: "dot bottom" }),
        _c("img", {
          attrs: {
            src: require("../../assets/developer/ai/icon_DigitalHumanTool.png"),
          },
        }),
        _c("h4", [_vm._v("AI Draw插件")]),
        _c("p", [_vm._v("包含基本绘画和controlnet动画迁移功能的PS插件")]),
      ]),
      _c("div", { staticClass: "list-item" }, [
        _c("div", { staticClass: "dot top" }),
        _c("div", { staticClass: "dot bottom" }),
        _c("img", {
          attrs: {
            src: require("../../assets/developer/ai/icon_SmartConversation.png"),
          },
        }),
        _c("h4", [_vm._v("语音TTS")]),
        _c("p", [_vm._v("让游戏开发者把文本直接转成语音")]),
      ]),
      _c("div", { staticClass: "list-item" }, [
        _c("div", { staticClass: "dot top" }),
        _c("div", { staticClass: "dot bottom" }),
        _c("img", {
          attrs: {
            src: require("../../assets/developer/ai/icon_AI3DModeling.png"),
          },
        }),
        _c("h4", [_vm._v("Lightbox")]),
        _c("p", [
          _vm._v(
            "中心化协同/发布平台，可用于发布部署AIGC相关各类插件到此平台，同时进行环境统一配置等"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "pic" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/developer/ai/img_logo_Discord.png"),
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("h4", [_vm._v("AI开发者社区")]),
      _c("p", [_vm._v("在社区与开发者们交流沟通心得")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "footer" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/developer/ai/image_logo-lignt.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }