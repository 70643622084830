var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "layout-logo" }, [
    _c("img", {
      staticClass: "layout-logo-medium-img",
      attrs: { src: _vm.logo },
      on: { click: _vm.goToHome },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }