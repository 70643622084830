
import imgBox from './components/imgBox.vue';
import singleModule from './components/singleModule.vue';
import GameIntro from '@/views/shop/components/GameIntro.vue';
import User from '@/layout/navBars/breadcrumb/user.vue';
import { OpenDomain } from '@/utils/env'
import { ADevAICommentScoreGET } from '@/api/game'
import { getShopAdvertise, getShopGather } from '@/api/shop'
import { getNewArray } from '@/utils/index'
import { GameModule } from '@/store/modules/game'

export default {
  name: 'shop-home',
  components: { imgBox, singleModule, GameIntro, User },

  data() {
    return {
      numList: Array.from(Array(9).keys()),
      menuList: [
        {
          title: '平台',
          id: 1,
          filed: 'platform',
          menuList: [
            {
              id: 11,
              label: 'Windows',
              value: 0,
              check: false
            },
            {
              id: 12,
              label: 'macOS',
              value: 1,
              check: false
            },
            {
              id: 13,
              label: 'Web',
              value: 2,
              check: false
            },
          ]
        },
        {
          title: '类型',
          filed: 'type',
          id: 2,
          menuList: [
            {
              id: 21,
              label: '游戏',
              key: 'game',
              check: false,
              value: 'game'
            },
            {
              id: 22,
              key: 'aitoolkit',
              label: '工具',
              check: false,
              value: 'aitoolkit'
            },
            {
              id: 23,
              key: 'visualart',
              label: '视觉效果',
              check: false,
              value: 'visualart'
            },
          ]
        }
      ],
      gameClassify: [
        {
          label: '所有项目',
          id: 1
        },
        // {
        //     label:'最受欢迎',
        //     id:2
        // },
        {
          label: '评分最高',
          id: 3
        },
        {
          label: '最新发布',
          id: 4
        },
      ],
      curTid: 1,
      gameLists: [], // 筛选之后
      lists: [],
      more: null,
      value: '',
      curIndex: 0,
      openeds: ['0', '1'],
      idFilters: [],
      toTop: false,
      advertiseInfo: [],
      gatherInfo1: [],
      gatherInfo2: [],
      gatherInfo: [],
      // gameList: [],
      carouselInt: null,
      isMouseHover: false,
      curCoverIndex: 0,
      intTime: 5000,
      curIid: 0,
      hover: {
        left: false,
        right: false
      },
      scoreLists: [],
      tags: [],
      filterObj: {},
    }
  },
  async mounted() {
    await GameModule.MAGetGameList()
    this.handleShopAdvertise()
    this.handleShopGather()
    window.addEventListener('scroll', this.onScroll)
  },
  watch: {
    curCover: {
      handler(val) {
        window.clearTimeout(this.carouselInt)
        this.checkAutoNext()
      },
      immediate: true
    },
    curCoverIndex: {
      handler(val) {
        if (!this.$refs['curImages']) return
        if (val >= 3) {
          this.$refs['curImages'].scrollTo(0, 100 * (val - 3))
        } else {
          this.$refs['curImages'].scrollTo(0, 0)
        }
      },
    },
    idFilters: {
      handler(val) {
        const { menuList } = this;
        let list = [], res = [], obj = { platform: [], type: [] };
        menuList.forEach(item => {
          list.push(item.menuList)
        })
        list.flat().forEach(x => {
          if (val.includes(x.id)) {
            res.push(x)
          }
        })
        val.forEach(item => {
          let ids = (item + '').slice(0, 1)
          let filed = menuList.filter(x => x.id == ids)[0].filed
          let value = list.flat().filter(x => x.id == item)[0].value
          obj[filed].push(value)
        })
        this.tags = res
        this.filterObj = obj
        this.cutContent()
      },
      immediate: true
    }
  },
  computed: {
    curCover() {
      return this.curCoverIndex >= 0 ? this.advertiseInfo[this.curCoverIndex] : null
    },
    gameList() {
      const { curTid, filterObj, more } = this // 排序 、 搜索 、 查看更多
      // this.more = this.gameLists.length.length >= 8 ? this.more : false
      if (curTid == 1) {// 全部 
        let res = more ? this.gameLists.slice(0, 8) : this.gameLists
        return res
      } else if (curTid == 3) { // 评分最高
        let list = this.gameLists.sort((a, b) => b.score - a.score)
        let res = more ? list.slice(0, 8) : list
        return res

      } else if (curTid == 4) { // 最新发布 -> 筛选（对所有数据）、查看更多（筛选之后的结果）
        let list = this.gameLists.sort((a, b) => b.createdOn - a.createdOn)
        let res = more ? list.slice(0, 8) : list
        return res
      }
    }
  },
  methods: {
    async handleShopAdvertise() {
      let res = await getShopAdvertise({ advertise_type: 1 })
      if (res.code) return
      this.advertiseInfo = res.data.info.sort((a, b) => a.sorts - b.sorts)
    },
    async handleAllScore() {
      let list = this.$store.state.game.list
      let arr = list.map((item) => {
        return new Promise((resolve, reject) => {
          let id = item.appId
          ADevAICommentScoreGET(id).then(res => {
            resolve(res)
          })
        })
      })
      let promises = Promise.all(arr).then((res) => {
        for (var i = 0; i < res.length; i++) {
          list[i].score = res[i].data ? res[i].data.avg : 0
        }
        this.$set(list)
        this.lists = list.filter(x => x)
        this.more = this.lists.length >= 8 ? true : false
        this.cutContent()
      })
    },
    async handleShopGather() {
      let res1 = await getShopGather({ gather_type: 1 })
      let res2 = await getShopGather({ gather_type: 2 })
      if (res1.code && res2.code) return
      let list1 = res1.data && res1.data.gather_data.length ? res1.data.gather_data[0].gather_info.sort((a, b) => a.sorts - b.sorts) : []
      let list2 = res2.data && res2.data.gather_data.length ? res2.data.gather_data[0].gather_info.sort((a, b) => a.sorts - b.sorts) : []
      this.gatherInfo1 = getNewArray(list1, 3)
      this.gatherInfo2 = getNewArray(list2, 7)
      this.handleAllScore()
    },
    cutContent() {
      const { lists, filterObj } = this
      let newList = lists.filter(item => {
        if (filterObj.platform.length) {
          return this.getInclude(filterObj.platform, item.store && item.store.info && item.store.info.platformer || [])
        }
        return true
      }).filter(item => {
        if (filterObj.type.length) {
          let tracks = item.pkg && item.pkg.tracks || []
          return this.getInclude(filterObj.type, tracks)
        }
        return true
      })
      this.gameLists = newList
      if (!filterObj.platform.length && !filterObj.type.length) this.gameLists = this.lists
      // if(this.gamelists.length) this.loading.data = false
    },
    handleClose(val) {
      this.idFilters.splice(this.idFilters.indexOf(val.id), 1)
      this.menuList.forEach(item => {
        item.menuList.forEach(x => {
          if (val.id === x.id) x.check = false
        })
      })
    },
    handleCheck(val) {
      const { idFilters } = this
      let id = val.id
      if (idFilters.includes(id)) { // 已包含 -> 删除
        this.idFilters.splice(this.idFilters.indexOf(id), 1)
      } else {
        this.idFilters.push(id)
      }

    },
    handleReset() {
      this.idFilters = []
      this.tags = []
      this.menuList.forEach(item => {
        item.menuList.forEach(x => {
          x.check = false
        })
      })
      this.gameList = this.lists
    },
    handleToInfo(info) {
      window.open(info.web_url, '_blank')
    },
    openPath(url: string) {
      window.open(`${OpenDomain}${url}`)
    },
    handleSwitch(type) {
      let lastIid = this.curIid + 1 // 下一页
      let preIid = this.curIid - 1
      if (type === 'pre') {
        this.$refs['nop'].prev()
        this.curIid = preIid
      } else {
        this.$refs['nop'].next()
        this.curIid = lastIid
      }
    },
    onScroll(e) {
      this.toTop = document.documentElement.scrollTop >= window.screen.height / 2
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    handleEnter(e) {
      let startTime = Date.now()
      this.isMouseHover = true
      let curTime = Date.now(), deltaTime = curTime - startTime
      this.intTime -= deltaTime
    },
    hanldeLeave() {
      this.isMouseHover = false
      this.checkAutoNext()
    },
    checkAutoNext() {
      const { isMouseHover } = this
      this.carouselInt = setTimeout(() => {
        if (!isMouseHover) this.onNext()
      }, this.intTime)
    },
    onNext() {
      let lastIndex = this.curCoverIndex
      let nextIndex = this.curCoverIndex + 1
      if (nextIndex >= this.advertiseInfo.length) nextIndex = 0
      this.curCoverIndex = nextIndex
    },
    getInclude(arr1, arr2) {
      let res = false
      for (let ele of arr1) {
        if (arr2.includes(ele)) {
          res = true;
        }
      }
      return res
    },
    handleChange(val) {
      this.curIid = val
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
