var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-info-env" } },
    [
      _c("h2", [_vm._v("部署")]),
      _c("p", { staticClass: "title-desc" }, [
        _vm._v(
          "部署代表产品的特定发布，可以用来对游戏的开发、测试、发行版本进行区分"
        ),
      ]),
      _c("div", { staticClass: "table-head" }, [
        _c("span", { staticClass: "sum" }, [
          _vm._v(_vm._s(_vm.list.length) + "个部署"),
        ]),
        _c(
          "div",
          {
            staticClass: "btn-add",
            on: {
              click: function ($event) {
                return _vm.showEditDrawer(null)
              },
            },
          },
          [_vm._v("添加部署")]
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.list,
              expression: "loading.list",
            },
          ],
          staticClass: "table-list",
          attrs: { data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "部署名称与ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [_vm._v(_vm._s(scope.row.name))]),
                    _c("p", [_vm._v(_vm._s(scope.row.id))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 0
                      ? _c("span", { staticClass: "deptype public" }, [
                          _vm._v("公开"),
                        ])
                      : scope.row.type == 1
                      ? _c("span", { staticClass: "deptype private" }, [
                          _vm._v("私密"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "build", label: "关联构件", width: "180" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createdOn", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatTime(scope.row.createdOn))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operation",
              "class-name": "col-operation",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", {
                      staticClass: "el-icon-edit btn-edit",
                      on: {
                        click: function ($event) {
                          return _vm.showEditDrawer(scope.row)
                        },
                      },
                    }),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.delete,
                            expression: "loading.delete",
                          },
                        ],
                        attrs: { title: "是否确认删除此条记录？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.onDelDeploy(scope.row)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-delete btn-delete",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.isEdit ? "编辑部署" : "创建部署",
            visible: _vm.visible.editDrawer,
            direction: "rtl",
            "show-close": false,
            "custom-class": "drawer-edit",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "editDrawer", $event)
            },
            open: _vm.onDrawerOpen,
            close: _vm.onDrawerClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  staticClass: "comp-form",
                  attrs: { model: _vm.form, rules: _vm.rules, size: "large" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("部署名称"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入部署名称",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("类型"),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("私密"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("公开"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "build" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("关联构件"),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.form.build,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "build", $$v)
                            },
                            expression: "form.build",
                          },
                        },
                        _vm._l(_vm.storeBuilds, function (item, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: item.name, value: item.file },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item-btns" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn save",
                          attrs: { type: "primary", loading: _vm.loading.save },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn cancel",
                          attrs: { type: "default", loading: _vm.loading.save },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.giveup")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }