var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("router-view", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ Component }) {
          return [
            _c(
              "transition",
              { attrs: { mode: "out-in" } },
              [
                _c(Component, {
                  key: _vm.refreshRouterViewKey,
                  tag: "component",
                  staticClass: "w100",
                  style: { minHeight: _vm.minHeight },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }