var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.noFrame
    ? _c(
        "el-container",
        { staticClass: "layout-container", attrs: { id: "layout-developer" } },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("Aside", { staticClass: "left layout-aside" }),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _vm.isShowHeader
                    ? _c(
                        "el-header",
                        { staticClass: "layout-header" },
                        [_c("Header")],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-main",
                    { staticClass: "layout-main" },
                    [
                      _c(
                        "el-scrollbar",
                        {
                          ref: "layoutDefaultsScrollbarRef",
                          staticClass: "layout-content",
                        },
                        [_c("Main")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _c("Main")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }