import NProgress from 'nprogress';
import { Message } from 'element-ui'
import { clearUserSession, verifyUserSession } from '@/utils/storage';
import { getParam } from '@/utils';
import 'nprogress/nprogress.css';

const loginPath = '/account/login';

function createBeforeGuard(defaultPath) {
  return async function beforeGuard(to, from, next) {
    console.log('defaultPath', defaultPath)
   // 调整目标路由
   if (to.path === '/' && defaultPath !== '/') {
     next(defaultPath);
     return;
   }
   // error页面，跳过校验
   if (/^\/error/.test(to.path)) {
     next();
     return;
   }
   NProgress.configure({ showSpinner: false });
   if (to?.meta?.title) NProgress.start();
   // 不需要session的路由，直接放行
   if (to.meta?.noSession) {
     NProgress.done();
     next();
     return;
   }
   // 校验oasis session是否失效
   const verifyResult = await verifyUserSession();
   if (verifyResult) {
     NProgress.done();
     next();
     return;
   }
   // 到此处的都是不通过的，清理状态，定向到登录
   NProgress.done();
   Message.error('登录已过期，请重新登录');
   clearUserSession();
   next(loginPath);
 }
}

function createAfterGuard(router) {
  return function afterGuard(to){
    const error = getParam('error');
    const errorDesc = getParam('error_description');
    if (error && !/^\/error/.test(to.path)) {
      router.push(`/error?error=${error}&error_description=${errorDesc}`);
    }
    NProgress.done();
    // 触发自定义事件，全局捕获做适配
    let event = new CustomEvent('router-change', { 'detail': { path: to.path, mode: to?.meta?.fitH5 ? 'h5' : 'pc' } });
    window.dispatchEvent(event);
  }
}


function routeDecorator(router, defaultPath = '/') {
  router.beforeEach(createBeforeGuard(defaultPath));
  router.afterEach(createAfterGuard(router));
  return router;
}

// 导出路由
export default routeDecorator;
