
export default {
  name: 'activity-card',
  props: {
    bg: {
      type: String,
      default: ''
    },
    timeRange: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isHover: false
    }
  },
  mounted() {
    console.log('mounted1', this.info);
  },
  methods: {
    mouseOverFunc(){
      if (!this.isHover) {
        this.isHover = true
      }
    },
    mouseoutFunc() {
      if (this.isHover) {
        this.isHover = false;
      }
    }
  },
  computed: {
    computedStyle() {
      if (!this.isHover) {
        return { background: 'url(' + this.bg + ') no-repeat', backgroundSize: '100% 100%' };
      }
      return {};
    }
  }
}

