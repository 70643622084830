
import { Component, Vue, Watch } from 'vue-property-decorator'
import { isClient } from '@/utils/env'
import { DefaultGameCover } from '@/config'
import { getCosUrl } from '@/utils/file';
import { AITrackListMap } from '@/config/developer';
import { GameModule } from '@/store/modules/game'
import eventbus from '@/utils/eventbus'
// import { Pagination, FreeMode } from 'swiper'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
// import 'swiper/css/pagination.css'
// import 'swiper/css/free-mode.css'

@Component({
  name: 'HomeBrowser',
  components: {
    // Swiper,
    // SwiperSlide,
  },
})
class HomeBrowser extends Vue {
  public getCosUrl = getCosUrl
  public AITrackList = AITrackListMap[2]
  public loading = {
    gameList: false,
    download: false
  }
  public visible = {
    toTop: false,
    scrollMask: true,
  }
  public bannerList = [
    {
      id: 1,
      img: require('../../assets/home/ai/banner.jpg'),
      imgMobile: require('../../assets/home/ai/banner-m.png'),
      url: 'https://km.woa.com/group/LightspeedExplorationLab/articles/show/536372'
    },
  ]
  public curTab = 'all'
  public condition = {
    name: ''
  }
  // public rawGameList: any = []
  public fitGameList: any = []
  public DefaultGameCover = DefaultGameCover

  // public swiperOption = {
  //   slidesPerView: 3,
  //   spaceBetween: 30,
  //   freeMode: true,
  // }
  private splitNum = 3

  get isPc() {
    return document.documentElement.clientWidth > 750
  }
  get gameModule() {
    console.log('this.$store', this.$store)
    return this.$store.state.game
  }
  get rawGameList() {
    return this.gameModule.list
  }
  get spliFitGameList() {
    let sNum = this.splitNum, total = this.fitGameList.length, sList: any = []
    for (let i = 0; i < Math.ceil(total / sNum); i++) {
      sList.push(this.fitGameList.slice(i * sNum, (i + 1) * sNum))
    }
    return sList
  }

  beforeCreate() {
    if (isClient) this.$router.replace('/home/client')
  }
  created() {
    this.initParams()
    this.initData()
  }
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs) elTabs.addEventListener('scroll', this.onTabsScroll)
    eventbus.$on('game-search', this.onGameSearch)
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs) elTabs.removeEventListener('scroll', this.onTabsScroll)
  }

  private async initData() {
    this.loading.gameList = true
    if (!this.gameModule.init.list) await GameModule.MAGetGameList()
    this.loading.gameList = false
    this.filterGameList()
  }
  public initParams() {
    if (this.$route.params.tab) this.curTab = this.$route.params.tab
    if (this.$route.params.search) this.condition.name = this.$route.params.search
  }
  public onScroll(e) {
    this.visible.toTop = document.documentElement.scrollTop >= window.screen.height / 2
  }
  public onTabsScroll(e) {
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs) this.visible.scrollMask = (elTabs.scrollLeft + elTabs.clientWidth) < (elTabs.scrollWidth - 40)
  }
  public scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  public changeTab(key: string, e) {
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs && e.target) {
      let offset = 50
      let rect = e.target.getBoundingClientRect()
      if (rect.left + rect.width >= elTabs.clientWidth) {
        elTabs.scrollTo({
          left: (rect.left + rect.width) - elTabs.clientWidth + elTabs.scrollLeft + offset,
          behavior: "smooth"
        })
      }
      else if (rect.left < offset) {
        elTabs.scrollTo({
          left: elTabs.scrollLeft + rect.left - offset,
          behavior: "smooth"
        })
      }
    }
    this.curTab = key
    this.filterGameList()
  }
  public onGameSearch(name) {
    console.log("search", name)
    this.condition.name = name
    this.onSearch()
  }
  public onSearch() {
    this.filterGameList()
  }
  public filterGameList() {
    this.fitGameList = this.rawGameList.filter(item => {
      if (this.condition.name == '') return true
      return new RegExp(this.condition.name, 'i').test(item.store && item.store.info && item.store.info.productName || '')
    }).filter(item => {
      if (this.curTab == 'all') return true
      let tracks = item.pkg && item.pkg.tracks || []
      return tracks.includes(this.curTab)
    })
  }

  public goToBanner(banner) {
    if (!banner.url) return
    window.open(banner.url)
  }
  public goToGame(game) {
    // this.$router.push({ path: '/game/info', query: { appId: game.appId } })
    location.href = `${location.origin}/game/info?appId=${game.appId}`

  }

  // private getBanner() {
  //   this.loading.gameList = true
  //   // APubBannerGet().then((res: any) => {
  //   //   let list = res.data || []
  //   //   this.rawGameList = list
  //   //   this.condition.name = ''
  //   //   this.filterGameList()
  //   //   this.loading.gameList = false
  //   // }).catch((err) => {
  //   //   console.error(err)
  //   //   this.$message.error("获取banner列表失败")
  //   //   this.loading.gameList = false
  //   // })
  //   AGameListGet().then((res: any) => {
  //     let list = res.data || []
  //     this.rawGameList = list
  //     this.filterGameList()
  //     this.loading.gameList = false
  //   }).catch((err) => {
  //     console.error(err)
  //     this.$message.error("获取banner列表失败")
  //     this.loading.gameList = false
  //   })
  // }

}
export default HomeBrowser
