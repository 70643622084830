var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("iframe", {
    staticClass: "mframe",
    attrs: { src: _vm.uri },
    on: { load: _vm.setHeight },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }