
import { Component, Vue } from 'vue-property-decorator'
import { ADevAIResult } from '@/api/game';
import { DefaultGameCover } from '@/config';

@Component({
  name: 'AIresult',
})

class AIresult extends Vue{
  public DefaultGameCover = DefaultGameCover;
  public worksList:any = [];
  public topThreeList:any = [];
  public relustLoading = true;
  public relustData = true;
  public isMobile = false;

  created() {
    this.initData()
    // @ts-ignore
    this.isMobile =  this.$parent.getMode() === 'h5' ? true : false;
  }

  mounted() {
    const bannerDOM :any = document.getElementById('banner-warps');
    const navDOM :any = document.getElementById('nav-warps');
    const scrollTop = bannerDOM.offsetHeight + navDOM.offsetHeight;
    window.scrollTo(0, scrollTop)
    navDOM.scrollLeft = 100
  }

  public async initData() {
    const res = await ADevAIResult({"period":2});
    this.relustLoading = false;
    res.list.forEach(item => {
      item.isExpertreview = false;
    })
    if (res.list.length === 0) {
      this.relustData = true;
    } else {
      this.topThreeList = res.list.slice(0, 3);
      this.worksList = res.list.slice(3, 10);
      this.relustData = false;
    }
  }
  public getCosUrl(cover: string) {
    const prefix = 'https://oasis-1300799399.cos.ap-nanjing.myqcloud.com/image/'
    return `${prefix}${cover}`
  }
  public toPath(path: string) {
    // @ts-ignore
    this.$parent.menuPush(3);
    this.$router.push(path)
  }
  public isShowComment(rankno: number, isExpertreview: boolean, index: number) {
    if (rankno <= 3) {
      this.topThreeList[rankno-1].isExpertreview = !isExpertreview;
    } else {
      this.worksList[index].isExpertreview = !isExpertreview
    }
  }
}
export default AIresult;
