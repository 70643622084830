
import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinUpload from '@/mixins/upload'
import { AStoreGet, AStoreGenUpt } from '@/api/developerApi/developer'
import { AStoreAIBuildAdd, AStoreAIBuildPptUpt } from '@/api/developerApi/gamePackage'
import { ProductStatusList, MediumTypeList, AITypeListMap, AITrackListMap } from '@/config/developer'
import { DeveloperModule } from '@/store/modules/developer'
import { ElForm } from 'element-ui/types/form'
import { validateUrl, validateBackspace } from '@/utils/validator'
import { getFileName, splitFileName, DocFileTypeList, VidFileTypeList, ImgFileTypeList } from '@/utils/file'
import { transArrayToMapByKey } from '@/utils/array'
import InfoClient from '../component/InfoClient.vue'
import InfoGeneral from '../component/InfoGeneral.vue'

const MaxLenName = 24
let keyCnt = 1
@Component({
  name: 'CompStoreGeneral',
  components: {
    InfoGeneral,
    InfoClient,
  }
})
class CompStoreGeneral extends mixins(MixinUpload) {
  public tab = {
    info: 'general'
  }
  public validateUrl = validateUrl
  public ProductStatusList = ProductStatusList
  public MediumTypeList = MediumTypeList
  public ImgFileTypeList = ImgFileTypeList
  public DocAndVidTypeList = ([] as string[]).concat(DocFileTypeList, VidFileTypeList)
  // public AITypeList = AITypeList
  // private AITrackKeyMap = transArrayToMapByKey(AITrackList, 'key')
  // private AITrackNameMap = transArrayToMapByKey(AITrackList, 'name')
  public uploadUrl = ''
  public form = {
    productName: '',
    cover: '',
    productDetail: '',
    developer: '',
    publisher: '',
    productStatus: '',
    pubdate: '',
    mediums: [
      {
        id: keyCnt++,
        mediumType: '',
        mediumUrl: ''
      }
    ],
    aiType: '',
    aiPpt: '',
    aiPptName: '',
    aiTrack: [] as string[],
  }
  public aiTrackList: any = []
  public fileList: any = []
  public rules = {
    productName: [
      { required: true, message: '请输入作品名称', trigger: 'change', },
      { max: MaxLenName, message: '超过最大长度：' + MaxLenName, trigger: 'change' },
      { validator: validateBackspace.bind(this), trigger: 'change' },
    ],
   
  }
  public loading = {
    info: false,
    save: false,
    upload: false,
  }
  private appId = this.$route.query.appId as string

  get devModule() {
    return this.$store.state.developer
  }
  get appData() {
    return this.devModule.appData
  }
  get storeInfo() {
    return this.devModule.storeData.info
  }
  get aiBuildInfo() {
    return this.devModule.aiBuild
  }
  get AITypeList() {
    return AITypeListMap[this.appData.stage]
  }
  get AITrackKeyMap() {
    return transArrayToMapByKey(this.AITypeList, 'key')
  }
  get AITrackNameMap() {
    return transArrayToMapByKey(AITrackListMap[this.appData.stage], 'name')
  }

  @Watch('$route.query.appId', { immediate: true })
  onAppIdChanged(val: any) {
    if (val) {
      this.appId = val
      this.getStoreData()
    }
  }
  onRouteQueryAppIdChanged(val: string) {
    if (val) this.tab.info = 'general'
  }
  @Watch('tab.info')
  @Watch('form.aiType', { immediate: true })
  onTabInfoChanged(val: string) {
    let path = this.$router.history.current.path, query = this.$router.history.current.query
    let newQuery = JSON.parse(JSON.stringify(query))
    newQuery.tab = val
    this.$router.push({ path, query: newQuery })
  }
  onFormAiTypeChanged(val: any) {
    let list
    let curType = this.AITypeList.find(item => item.key === this.form.aiType)
    if (!curType) list = []
    else {
      list = curType.tracks.map(item => {
        let track = this.AITrackKeyMap[item]
        if (!track) return undefined
        return track
      })
      // 如果之前有选择，用之前的，否则设置为默认
      if (val == this.aiBuildInfo.type && this.aiBuildInfo.tracks.length) {
        this.form.aiTrack = this.aiBuildInfo.tracks.map(key => this.AITrackKeyMap[key] && this.AITrackKeyMap[key].name)
      }
      else {
        let defaultTrack = list.find(item => item.key === curType.key)
        this.form.aiTrack = defaultTrack ? [defaultTrack.name] : []
      }
    }
    this.aiTrackList = list
    this.aiBuildInfo.type
  }

  mounted() {
    if (this.$route.query.tab) this.tab.info = this.$route.query.tab
  }
  public onCancel() {
    // @ts-ignore
    this.$refs.infoGenerlRef.onCancel();
    // this.reset()
  }
  public async submitForm() {
    // @ts-ignore
    this.$refs.infoGenerlRef.submitForm();
    // this.$report('dev/store/general/save', 'click', { appid: this.appId })
    // let formEl = this.$refs.formRef as ElForm
    // if (!formEl) return
    // // 数据校验
    // if (!this.form.cover) {
    //   this.$message.error("请上传封面图")
    //   return
    // }
    // formEl.validate(async (valid: boolean) => {
    //   if (!valid) return false
    //   this.loading.save = true
    //   try {
    //     await this.updateStore()
    //     await this.updateAi()
    //     this.loading.save = false
    //   }
    //   catch (e) {
    //     console.error(e)
    //     this.loading.save = false
    //   }
    // })
  }
  private updateStore() {
    return new Promise((resolve, reject) => {
      const param = {
        productName: this.form.productName,
        productDetail: this.form.productDetail,
        developer: this.form.developer,
        publisher: this.form.publisher,
        productStatus: +this.form.productStatus,
        pubdate: this.form.pubdate,
        mediums: this.form.mediums.filter(item => item.mediumType !== '' && item.mediumUrl !== '').map(item => {
          return {
            mediumType: item.mediumType,
            mediumUrl: item.mediumUrl,
          }
        })
      }
      AStoreGenUpt(this.appId, param).then(() => {
        this.$message.success(this.$t('tips.succ.edit') as string)
        DeveloperModule.MAGetDevStore(this.appId)
        resolve(null)
      }).catch(err => {
        console.error(err)
        this.$message.error(this.$t('tips.fail.edit') as string)
        reject()
      })
    })
  }
  // AI数据上传
  private updateAi() {
    return new Promise((resolve, reject) => {
      // 判断是否需要create
      if (!this.aiBuildInfo.created) {
        const aiParam = {
          type: this.form.aiType,
          ppt: `${this.form.aiPpt}|${this.form.aiPptName}`,
          package: '',
          packgename: '',
          tracks: this.form.aiTrack.map(name => this.AITrackNameMap[name] && this.AITrackNameMap[name].key)
        }
        AStoreAIBuildAdd(this.appId, aiParam).then(() => {
          // this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAIBuild(this.appId)
          resolve(null)
        }).catch(err => {
          console.error(err)
          this.$message.error(this.$t('tips.fail.edit') as string)
          reject()
        })
      }
      else {
        const aiParam = {
          type: this.form.aiType,
          ppt: `${this.form.aiPpt}|${this.form.aiPptName}`,
          tracks: this.form.aiTrack.map(name => this.AITrackNameMap[name] && this.AITrackNameMap[name].key)
        }
        AStoreAIBuildPptUpt(this.appId, aiParam).then(() => {
          // this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAIBuild(this.appId)
          resolve(null)
        }).catch(err => {
          console.error(err)
          this.$message.error(this.$t('tips.fail.edit') as string)
          reject()
        })
      }
    })
  }
  public onDelMedium(index) {
    this.form.mediums.splice(index, 1)
    if (this.form.mediums.length === 0) {
      keyCnt = 1
      this.form.mediums.push({
        id: keyCnt++,
        mediumType: '',
        mediumUrl: ''
      })
    }
  }

  private async getStoreData() {
    this.loading.info = true
    if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    if (!this.devModule.init.aiBuild) await DeveloperModule.MAGetDevAIBuild(this.appId)
    this.loading.info = false
    this.initInfo()
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  private initInfo() {
    this.form.productName = this.storeInfo.productName
    this.form.productDetail = this.storeInfo.productDetail
    this.form.developer = this.storeInfo.developer
    this.form.publisher = this.storeInfo.publisher
    this.form.productStatus = this.storeInfo.productStatus
    this.form.pubdate = this.storeInfo.pubdate
    let mediums: any = [].concat(this.storeInfo.mediums || [])
    if (mediums.length < MediumTypeList.length - 1) {
      mediums.push({
        mediumType: '',
        mediumUrl: ''
      })
    }
    this.form.mediums = mediums.map(item => {
      item.id = keyCnt++
      return item
    })

    this.form.aiType = this.aiBuildInfo.type
    let aiPptFile = (this.aiBuildInfo.ppt || '').split('|')
    this.form.aiPpt = aiPptFile[0] || ''
    this.form.aiPptName = aiPptFile[1] || ''
    this.form.aiTrack = this.aiBuildInfo.tracks.map(key => this.AITrackKeyMap[key] && this.AITrackKeyMap[key].name)
    // fileList特殊处理，判断是否有修改，不触发动画
    if (this.form.aiPpt) {
      if (this.fileList.length && this.fileList[0].name === (this.form.aiPptName || this.form.aiPpt) && this.fileList[0].url === this.form.aiPpt) return
      this.fileList = [
        {
          name: this.form.aiPptName || this.form.aiPpt,
          url: this.form.aiPpt
        }
      ]
    }
    else {
      if (this.fileList.length === 0) return
      this.fileList = []
    }
  }
  private reset() {
    (this.$refs.formRef as ElForm)?.clearValidate()
    keyCnt = 1
    this.initInfo()
  }


  public onUploadSuccess(res, file) {
    this.loading.upload = false
    let fileName = getFileName(res.url) as string
    this.form.aiPpt = fileName
    this.form.aiPptName = file.name
    this.fileList = [
      {
        name: this.form.aiPptName,
        url: this.form.aiPpt
      }
    ]
  }
  public onUploadError(error, file) {
    this.$message.error("上传失败 " + error)
    this.loading.upload = false
    if (!this.fileList.length) this.fileList = []
  }
  public beforeUpload(file) {
    const { name, ext = '' } = splitFileName(file.name)
    const extLimit = this.DocAndVidTypeList
    if (!extLimit.includes(ext)) {
      this.$message.error('格式错误，只支持' + extLimit.join() + '格式')
      this.fileList = []
      return false
    }
    this.loading.upload = true
    return true
  }
  public onRemove() {
    this.fileList = []
    this.form.aiPpt = ''
    this.form.aiPptName = ''
  }
}
export default CompStoreGeneral
