
export default {
  name: 'guide-footer',
  data() {
    return {
      lang: 'en',
      langTypes: [
        { key: 'zh', label: '中' },
        { key: 'en', label: 'EN' },
      ]
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    setLang(item) {
      this.lang = item.key;
    }
  }
}
