<template>
  <div class="article-footer">
    <div class="menu-container">
      <div class="box">
        <div class="info">{{ saveInfo }}</div>
        <div class="info">A total of{{ total }}</div>
        <div class="info" @click="backToTop">Back to Top</div>
      </div>
      <div class="box">
        <el-button  plain @click="onPreview">Preview</el-button>
        <el-button  plain @click="onSave">Save</el-button>
        <el-button  plain @click="onPost">Post</el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'publish-header',
    props: {
      saveInfo: {
        type: String,
        default: 'Draft was saved 12 mins ago'
      },
      total: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {}
    },
    mounted() {
    },
    methods: {
      onSubmit() {
        
      },
      backToTop() {

      },
      onPreview() {

      },
      onSave() {
        this.$emit('onSave');
      },
      onPost() {

      }
    }
  }
  </script>
  <style lang="scss">
  .article-footer {
    padding: 32px 0;
    margin: 0;
    width: 100%;
    height: 96px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    .menu-container{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .info{
          color: #666;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-left: 32px;
          .info:first-child{
            margin-left: 0px;
          }
        }
      }
    }
  }
  </style>