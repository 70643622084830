<template>
    <div class="gate-card">
        <div class="img-box"
            :style="{ opacity: isHover ? '0' : '1', width: boxSize.boxWidth + 'rem', height: boxSize.boxHeight + 'rem' }"
            @mouseenter="handleEnterBox" @mouseleave="handleLeave">
            <img :src='propObj.basic_info && propObj.basic_info.medias && propObj.basic_info.medias.cover && getCosUrls(propObj.basic_info.medias.cover) || DefaultGameCover'
                class="imgs" :style="{ height: boxSize.imgHeight + 'rem' }" />
            <div class="box">
                <span :title="propObj.basic_info.app_name">{{ propObj.basic_info.app_name }}</span>
                <span class="tips">免费</span>
            </div>
        </div>
        <div class="img-hover-box" v-show="isHover"
            :style="{ width: hoverSize.boxWidth + 'rem', left: '-' + boxSize.boxWidth / 20 + 'rem', opacity: isHover ? '1' : '0' }"
            @mouseleave="handleLeave" @mouseenter="handleEnterHover" @click="handleToGame" :class="isHover ? 'show' : ''">
            <!-- minHeight: hoverSize.boxHeight + 'rem' -->
            <!-- <img :src='propObj.basic_info && propObj.basic_info.medias && propObj.basic_info.medias.cover && getCosUrls(propObj.basic_info.medias.cover) || DefaultGameCover'
                class="imgs" /> -->
            <video v-if="curVideo" :src="curVideo | fCosBase" :poster="curVideo | fCosPoster" autoplay loop class="imgs"
                muted :style="{ height: hoverSize.imgHeight + 'rem' }"></video>
            <div v-else class="cover" :style="{ height: hoverSize.imgHeight + 'rem' }">
                <!-- <img class="imgs" :src="curCover.url | fCosBase" :style="{ height: hoverSize.imgHeight + 'rem', opacity: curCover.id == curCoverIndex ? '1' : '0' }" /> -->
                <img v-for="(cover, i) in mediaList" :key="i" class="imgs" :src="cover.url | fCosBase"
                    :style="{ opacity: i == curCoverIndex ? '1' : '0' }" :class="i == curCoverIndex ? 'show' : ''" />
            </div>
            <div v-if="!curVideo && !mediaList.length && isHover" class="cover">
                <img :src='propObj.basic_info && propObj.basic_info.medias && propObj.basic_info.medias.cover && getCosUrls(propObj.basic_info.medias.cover) || DefaultGameCover'
                    class="imgs" :style="{ height: boxSize.imgHeight + 'rem' }" />
            </div>


            <div class="hover-box">
                <div class="hover-box-cover" :style="{ height: hoverSize.coverHeight / 2 + 'rem' }">
                    <img :src="propObj.basic_info && propObj.basic_info.medias && propObj.basic_info.medias.cover && getCosUrls(propObj.basic_info.medias.cover) || DefaultGameCover"
                        alt="" :style="{ width: hoverSize.coverWidth + 'rem', height: hoverSize.coverHeight + 'rem' }">
                    <div class="platform">
                        <span v-for="(val, i) in platformList" :key="i">
                            <img :src="require(`../../../assets/shop/${val}.svg`)" alt="">
                        </span>
                    </div>
                </div>
                <div class="hover-box-info">
                    <p>{{ propObj.basic_info.app_name }}</p>
                    <!-- <div class="hover-box-info-tags">
                        <span v-for="(tag, i) in tags" :key="i">{{ tag }}</span>
                    </div> -->
                    <div class="hover-box-info-bottom">
                        <div class="hover-box-info-bottom-rate">
                            <el-rate class="rate" v-model="score" :colors="scoreColors" :void-color="scoreVoidColor"
                                :disabled-void-color="scoreVoidColor" disabled :allow-half="true">
                            </el-rate> <span>{{ commentList }}人评测</span>
                        </div>
                        <span class="tips">免费</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { platformType } from '@/config/index'
import { ADevAICommentScoreGET, ADevAICommentAllGET } from '@/api/game'
import { DefaultGameCover } from '@/config'
import MixinUpload from '@/mixins/upload'
export default {
    name: 'img-box',
    props: {
        size: {
            type: Number,//  2,3,4(一行两个、三个、四个)
        },
        propObj: {
            tyoe: Object
        }
    },
    mixins: [MixinUpload],
    data() {
        return {
            hoverSize: {
                boxWidth: 0,
                boxHeight: 0,
                imgHeight: 0,
                coverWidth: 0,
                coverHeight: 0
            },
            isHover: false,
            score: null,
            commentList: null,
            tags: ['冒险', '视觉小说', '2D', '免费开玩', '冒险'],
            scoreVoidColor: 'rgba(255,255,255,0.1)',
            scoreColors: ['#FF7A01', '#FF7A01', '#FF7A01'],
            platformList: [],
            DefaultGameCover: DefaultGameCover,
            carouselInt: null,
            intTime: 2000,
            curCoverIndex: 0,
            curVideo: '',
            mediaList: []
        }
    },
    watch: {
        propObj: {
            handler(val) {
                // if (!val.basic_info) return
                let mediaInfo = this.propObj.basic_info.medias || {}
                let list = val.basic_info.platformer.map(item => {
                    return platformType.filter(x => x.id === item).length ? platformType.filter(x => x.id === item)[0].label : ''
                })
                this.platformList = list
                this.curVideo = mediaInfo.movie
                if (!this.curVideo) { // 有视频播放视频 ，没有视频再展示图片
                    let lists = []
                    mediaInfo.pictures && mediaInfo.pictures.forEach((item, index) => {
                        lists.push({
                            type: 'image',
                            url: item,
                            id: index
                        })
                    })
                    this.mediaList = lists
                }
                // console.log('curVideo', this.curVideo);
                // console.log('lists', this.mediaList);
            },
            immediate: true
        },
    },
    mounted() {
        this.handleHover()
    },
    computed: {
        boxSize() {
            const { size } = this
            let obj = {
                boxWidth: 0,
                boxHeight: 0,
                imgHeight: 0
            }
            switch (size) {
                case 2:
                    obj.boxWidth = 5.88
                    obj.boxHeight = 3.92
                    obj.imgHeight = 3.30
                    break;
                case 3:
                    obj.boxWidth = 3.84
                    obj.boxHeight = 2.78
                    obj.imgHeight = 2.16
                    break;
                case 4:
                    obj.boxWidth = 2.82
                    obj.boxHeight = 2.22
                    obj.imgHeight = 1.6
                    break;
            }
            return obj
        },
        curCover() {
            return this.curCoverIndex >= 0 ? this.mediaList[this.curCoverIndex] : null
        },
    },
    methods: {
        async getScore() {
            let id = this.propObj.app_id
            let res = await ADevAICommentScoreGET(id)
            let comment = await ADevAICommentAllGET(id)
            this.score = res.data && res.data.avg || 0
            this.commentList = comment.data && comment.data.length || 0
        },
        handleEnterBox() {
            if (!this.score) this.getScore()
            this.isHover = true
            this.handleHover()
        },
        handleEnterHover() {
            this.isHover = true
            this.curCoverIndex = 0
            if (!this.curVideo) this.checkAutoNext()
        },
        handleLeave() {
            this.isHover = false
            clearTimeout(this.carouselInt);
        },
        handleHover() {
            const { size } = this
            let obj = {
                boxWidth: 0,
                boxHeight: 0,
                imgHeight: 0,
                coverWidth: 0,
                coverHeight: 0
            }
            switch (size) {
                case 2:
                    obj.boxWidth = 5.88
                    obj.boxHeight = 5.58
                    obj.imgHeight = 3.30
                    obj.coverWidth = 2.52
                    obj.coverHeight = 1.4
                    break;
                case 3:
                    obj.boxWidth = 4.2
                    obj.boxHeight = 4.44
                    obj.imgHeight = 2.36
                    obj.coverWidth = 1.8
                    obj.coverHeight = 1
                    break;
                case 4:
                    obj.boxWidth = 3.1
                    obj.boxHeight = 3.68
                    obj.imgHeight = 1.76
                    obj.coverWidth = 1.2
                    obj.coverHeight = 0.68
                    break;
            }
            this.hoverSize = obj
        },
        handleToGame() {
            location.href = `${location.origin}/game/shop?appId=${this.propObj.app_id}`
        },
        getCosUrls(cover) {
            const prefix = 'https://oasis-1300799399.cos.ap-nanjing.myqcloud.com/image/';
            return `${prefix}${cover}`;
        },
        checkAutoNext() {
            this.carouselInt = setInterval(() => {
                this.onNext()
            }, this.intTime)
        },
        onNext() {
            let lastIndex = this.curCoverIndex
            let nextIndex = this.curCoverIndex + 1
            if (nextIndex >= this.mediaList.length) nextIndex = 0
            this.curCoverIndex = nextIndex
        },
    },
    beforeDestroy() {

    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/mixins/index.scss';

.gate-card {
    position: relative;
    cursor: pointer;


    .img-box {
        border-radius: .12rem;
        overflow: hidden;
        margin-bottom: .24rem;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 4;

        .imgs {
            width: 100%;
            height: 2.16rem;
            object-fit: cover;
        }

        .box {

            flex: 1;
            background: rgba(255, 255, 255, 0.08);
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'cssClassCNB';
            font-style: normal;
            font-weight: 800;
            font-size: .2rem;
            padding: 0 .16rem;
            padding-right: .6rem;

            &>span:first-child {
                @include text-ellipsis(1);
                line-height: .3rem;
            }

            .tips {
                display: inline-block;
                padding: 0.01rem 0.08rem;
                border-radius: 0.04rem;
                background: rgba(255, 255, 255, 0.08);
                margin-right: 0.1rem;
                font-family: 'cssClassCNR';
                font-style: normal;
                font-weight: 400;
                font-size: .12rem;
                color: rgba(255, 255, 255, 0.55);
                position: absolute;
                right: 0;
                bottom: .16rem;
            }
        }


    }

    .img-hover-box {
        border-radius: .12rem;
        overflow: hidden;
        background: #263256;
        position: absolute;
        border-radius: .12rem;
        top: -20%;
        left: 0;
        box-shadow: 0px .12rem .32rem 0px rgba(255, 94, 44, 0.65);
        border: .04rem solid transparent;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #263256, #263256), linear-gradient(90deg, #FF7A01, #FF33E7);
        z-index: 5;
        animation: show .3s linear !important;

        .cover {
            width: 100%;
            transition: all 2s;
            position: relative;

            .imgs {
                width: 100%;
                // height: 2.16rem;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }

            .show {
                animation: coverShow 1s linear !important;
            }

            .hide {
                animation: coverHide 1s linear !important;
            }

            @keyframes coverShow {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            @keyframes coverHide {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }

        }






        .hover-box {
            // 两个一行
            flex: 1;
            display: flex;
            flex-direction: column;
            background: #263256;

            &-cover {
                // height: .7rem;
                position: relative;

                &>img {
                    // width: 2.52rem;
                    // height: 1.4rem;
                    border-radius: .112rem;
                    position: absolute;
                    top: -100%;
                    margin-left: .16rem;
                    object-fit: cover;
                }

                .platform {
                    position: absolute;
                    top: 0.16rem;
                    right: 0.16rem;

                    &>span {
                        margin-left: 0.04rem;

                        &>img {
                            width: .2rem;
                            height: .2rem;
                        }
                    }
                }
            }

            &-info {
                flex: 1;
                width: 100%;
                padding: 0 0 .24rem .16rem;
                margin-top: .16rem;
                // background:#fff;

                p {
                    font-size: .2rem;
                    font-family: 'cssClassCNB';
                }

                &-tags {
                    width: 70%;

                    &>span {
                        display: inline-block;
                        padding: 0.01rem 0.08rem;
                        border-radius: 0.04rem;
                        background: rgba(255, 255, 255, 0.08);
                        margin-right: 0.1rem;
                        font-family: 'cssClassCNR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: .12rem;
                        color: rgba(255, 255, 255, 0.55);
                        margin-top: 0.08rem;
                    }
                }

                &-bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: .14rem;

                    &-rate {
                        display: flex;
                        align-items: center;


                        &>span {
                            font-family: 'cssClassENR';
                            font-style: normal;
                            font-weight: 400;
                            font-size: .12rem;
                            line-height: .18rem;
                            color: rgba(255, 255, 255, 0.55);
                            margin-left: 0.08rem;
                        }
                    }

                    &>span {
                        font-family: 'cssClassCNR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: .12rem;
                        background: rgba(255, 255, 255, 0.08);
                        border-radius: 0.04rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: rgba(255, 255, 255, 0.55);
                        padding: 0.01rem 0.08rem;
                    }
                }

                .tips {
                    display: inline-block;
                    padding: 0.01rem 0.08rem;
                    border-radius: 0.04rem;
                    background: rgba(255, 255, 255, 0.08);
                    margin-right: 0.1rem;
                    font-family: 'cssClassCNR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .12rem;
                    color: rgba(255, 255, 255, 0.55);
                    // position: absolute;
                    // right: 0;
                    // bottom: .16rem;
                }
            }
        }

        @keyframes show {
            0% {
                transform: scale(0.9);
            }


            100% {
                transform: scale(1);
            }
        }

    }



}
</style>
<style lang="scss">
.gate-card {
    .el-rate {
        height: auto;

        .el-rate__item {
            width: 0.09rem;
            margin-right: 0.02rem;

            .el-rate__icon {
                font-size: .12rem;
                // color: #FF7A01 !important
            }
        }
    }
}
</style>