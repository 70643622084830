
import { Component, Vue, Watch } from 'vue-property-decorator'
import utils from '@/utils'
@Component({
  name: 'app',
  components: {},
})
class App extends Vue {

  @Watch('route.path')
  onRoutePathChanged(val: string) {
    utils.useTitle()
  }

  mounted() {}
  
}
export default App
