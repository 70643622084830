<template>
  <div class="guide-partner-card">
    <div class="icon" :style="{ background: 'url(' + iconPath + ') no-repeat', backgroundSize: '100% 100%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'guide-partner-card',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    clickMore() {
      this.$emit('onMore');
    }
  }
}

</script>

<style lang="scss" scoped>
.guide-partner-card{
  width: 297px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.10);
  .icon{
    min-width: 297px;
    min-height: 81px;
  }
}
</style>
