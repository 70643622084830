var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article-footer" }, [
    _c("div", { staticClass: "menu-container" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "info" }, [_vm._v(_vm._s(_vm.saveInfo))]),
        _c("div", { staticClass: "info" }, [
          _vm._v("A total of" + _vm._s(_vm.total)),
        ]),
        _c("div", { staticClass: "info", on: { click: _vm.backToTop } }, [
          _vm._v("Back to Top"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-button",
            { attrs: { plain: "" }, on: { click: _vm.onPreview } },
            [_vm._v("Preview")]
          ),
          _c("el-button", { attrs: { plain: "" }, on: { click: _vm.onSave } }, [
            _vm._v("Save"),
          ]),
          _c("el-button", { attrs: { plain: "" }, on: { click: _vm.onPost } }, [
            _vm._v("Post"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }