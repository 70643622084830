import request from '@/utils/request';
import { Local } from '@/utils/storage';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/proxy`;
export interface IFlowData {
  flow: string
  expiresAt: number
}
export interface IFlowMap {
  [key: string]: IFlowData
}
interface IFlowUrl {
  path: string
  noSessionToken?: boolean
}

class Flow {
  static hasExpired(flowData: IFlowData) {
    return flowData.expiresAt < Date.now();
  }
  static delExpiredData(flowMap: { [key: string]: IFlowData }) {
    for (const [k, v] of Object.entries(flowMap)) {
      if (Flow.hasExpired(v)) delete (flowMap[k]);
    }
  }

  private flowKey: string;
  private flowCacheMap: IFlowMap;
  private flowUrlMap: { [key: string]: IFlowUrl };

  constructor() {
    this.flowKey = 'kratos_flow';
    this.flowCacheMap = {};
    this.flowUrlMap = {
      FLogin: {
        path: '/self-service/login/api',
        noSessionToken: true,
      },
      // 'FLogout': {
      //     path: '/self-service/logout/api',
      // },
      FRegister: {
        path: '/self-service/registration/api',
        noSessionToken: true,
      },
      FSendVerifyCode: {
        path: '/self-service/registration/api',
        noSessionToken: true,
      },
      FRecovery: {
        path: '/self-service/recovery/api',
        noSessionToken: true,
      },
      FSettingsSave: {
        path: '/self-service/settings/api',
      },
      FSettingsUnLink: {
        path: '/self-service/settings/api',
      },
    };
  }

  public getFlowCache(key: string) {
    const flowData: IFlowData = this.flowCacheMap[key];
    if (flowData && !Flow.hasExpired(flowData)) return flowData;
    // 剔除过期数据后，合并缓存，存入Local
    Flow.delExpiredData(this.flowCacheMap);
    const cache = Local.get(this.flowKey);
    if (!cache) return null;
    Flow.delExpiredData(cache);
    this.flowCacheMap = Object.assign(this.flowCacheMap, cache);
    Local.set(this.flowKey, this.flowCacheMap);
    return null;
  }

  public fetchFlow(key: string, params: any = null) {
    return new Promise<string>((resolve, reject) => {
      const url = this.getFlowUrl(key);
      if (!url) {
        reject(new Error('未找到url配置'));
        return;
      }
      let { path } = url;
      if (params) {
        path = `${path}?${Object.keys(params).map(k => `${k}=${params[k] ? encodeURIComponent(params[k]) : ''}`)}`;
      }
      const cache: IFlowData | null = this.getFlowCache(key);
      if (cache) {
        resolve(cache.flow);
        return;
      }
      request({
        url: `${BaseUrl}/${path}`,
        noSessionToken: url.noSessionToken,
      }).then((data: any) => {
        const { expires_at, id } = data;
        this.saveFlow(key, id, new Date(expires_at).valueOf());
        resolve(id);
      })
        .catch(reject);
    });
  }

  public getFlowUrl(key: string) {
    return this.flowUrlMap[key] || null;
  }

  public saveFlow(key: string, flow: string, expiresAt: number) {
    this.flowCacheMap[key] = { flow, expiresAt };
    Local.set(this.flowKey, this.flowCacheMap);
  }
}

const flow = new Flow();

export const fetchFlow = flow.fetchFlow.bind(flow);
