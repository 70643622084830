var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.gameList,
          expression: "loading.gameList",
        },
      ],
      staticClass: "container",
      attrs: {
        "element-loading-background": "rgba(0, 0, 0, 0.5)",
        id: "view-game-info",
      },
    },
    [
      _c("div", { staticClass: "header-bg" }),
      !_vm.isPc
        ? _c("div", { staticClass: "developer" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "developer-info" }, [
                _c("h1", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.appData.store.info.productName)),
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "block user" }, [
                    _c("span", [_vm._v("开发者: ")]),
                    _c("span", [_vm._v(_vm._s(_vm.appData.email || "-"))]),
                  ]),
                  _c("div", { staticClass: "block time" }, [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.appData.updateAt &&
                            _vm.formatTime(
                              _vm.appData.updateAt,
                              "YYYY-mm-dd"
                            )) ||
                            "-"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.avgScore
                ? _c(
                    "div",
                    { staticClass: "developer-score" },
                    [
                      _vm.avgScore
                        ? _c("p", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.avgScore.toFixed(1))),
                            ]),
                            _c("i", [_vm._v("评分")]),
                          ])
                        : _c("p", [_vm._v("暂无评分")]),
                      _c("el-rate", {
                        staticClass: "rate",
                        attrs: {
                          colors: _vm.scoreColors,
                          "void-color": _vm.scoreTransColor,
                          "disabled-void-color": _vm.scoreTransColor,
                          disabled: "",
                        },
                        model: {
                          value: _vm.avgScore,
                          callback: function ($$v) {
                            _vm.avgScore = $$v
                          },
                          expression: "avgScore",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.appData
        ? _c("div", { staticClass: "contentbox" }, [
            _c("div", {
              class: _vm.headerScroll ? "header header-scroll" : "header",
            }),
            _vm.isPc
              ? _c("h1", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.appData.store.info.productName)),
                ])
              : _vm._e(),
            _c("div", { staticClass: "section-box" }, [
              _c("section", { staticClass: "left" }, [
                _c(
                  "div",
                  { staticClass: "mediabox" },
                  [
                    _vm.curCover
                      ? [
                          _vm.curCover.type == "video"
                            ? _c("div", { staticClass: "cover" }, [
                                _c("video", {
                                  ref: "coverMedia",
                                  staticClass: "mediaVideo",
                                  attrs: {
                                    src: _vm._f("fCosBase")(_vm.curCover.url),
                                    poster: _vm._f("fCosPoster")(
                                      _vm.curCover.url
                                    ),
                                    autoplay: "",
                                    controls: "",
                                    controlslist: "nodownload",
                                    "webkit-playsinline": "",
                                    "x5-playsinline": "",
                                    playsinline: "",
                                  },
                                }),
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "cover preview",
                                  attrs: { title: "双击查看大图" },
                                  on: { dblclick: _vm.showPreview },
                                },
                                [
                                  _c("img", {
                                    ref: "coverMedia",
                                    attrs: {
                                      src: _vm._f("fCosBase")(_vm.curCover.url),
                                    },
                                  }),
                                ]
                              ),
                        ]
                      : _c("div", { staticClass: "cover" }),
                    _c(
                      "div",
                      { ref: "covercontrolbox", staticClass: "covercontrol" },
                      [
                        _c(
                          "div",
                          { staticClass: "btn-left", on: { click: _vm.onPre } },
                          [_c("i", { staticClass: "el-icon-arrow-left" })]
                        ),
                        _c(
                          "el-carousel",
                          {
                            ref: "covercontrol",
                            staticClass: "coverlist",
                            attrs: {
                              arrow: "always",
                              autoplay: false,
                              loop: true,
                              "indicator-position": "none",
                            },
                          },
                          _vm._l(_vm.splitMediaList, function (cover, i) {
                            return _c("el-carousel-item", { key: i }, [
                              _c(
                                "div",
                                { staticClass: "cover-box" },
                                _vm._l(cover, function (item, j) {
                                  return _c(
                                    "div",
                                    {
                                      key: j,
                                      staticClass: "cover-thumb",
                                      class: {
                                        active:
                                          _vm.curCover &&
                                          _vm.curCover.id == item.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeCover(item)
                                        },
                                      },
                                    },
                                    [
                                      item.type == "video"
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm._f("fCosGif")(item.url),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: _vm._f("fCosBase")(item.url),
                                            },
                                          }),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ])
                          }),
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn-right",
                            on: { click: _vm.onNext },
                          },
                          [_c("i", { staticClass: "el-icon-arrow-right" })]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                !_vm.isPc
                  ? _c(
                      "div",
                      {
                        class: _vm.tabsScroll
                          ? "tabs-detail-scroll"
                          : "tabs-detail",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "tabs-detail-item",
                            class: { active: _vm.curTab === "detail" },
                            on: {
                              click: function ($event) {
                                _vm.curTab = "detail"
                              },
                            },
                          },
                          [_vm._v("作品详情")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "tabs-detail-item",
                            class: { active: _vm.curTab === "comment" },
                            on: {
                              click: function ($event) {
                                _vm.curTab = "comment"
                              },
                            },
                          },
                          [_vm._v("Oasis玩家评测")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.appData.store.exp && _vm.appData.store.exp.enableExperience
                  ? _c("div", { staticClass: "info" }, [
                      _c("h2", [_vm._v("抢先体验说明")]),
                      _vm.appData.store.exp.developerComment
                        ? _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.appData.store.exp.developerComment
                              ),
                            },
                          })
                        : _c("p", [_vm._v("暂无说明")]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "info" },
                  [
                    _vm.isPc
                      ? _c("div", { staticClass: "tabs-detail" }, [
                          _c(
                            "span",
                            {
                              staticClass: "tabs-detail-item",
                              class: { active: _vm.curTab === "detail" },
                              on: {
                                click: function ($event) {
                                  _vm.curTab = "detail"
                                },
                              },
                            },
                            [_vm._v("游戏详情")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "tabs-detail-item",
                              class: { active: _vm.curTab === "comment" },
                              on: {
                                click: function ($event) {
                                  _vm.curTab = "comment"
                                },
                              },
                            },
                            [_vm._v("Oasis玩家测评")]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.curTab === "detail",
                            expression: "curTab === 'detail'",
                          },
                        ],
                      },
                      [
                        _c("h2", { staticClass: "info-title" }, [
                          _vm._v("作品简介"),
                        ]),
                        _c(
                          "div",
                          {
                            ref: "gamedetail",
                            staticClass: "gamedetail",
                            class: {
                              showMore: _vm.moreText.show,
                              activeMore:
                                _vm.moreText.show && _vm.moreText.active,
                            },
                          },
                          [
                            _vm.appData.store.info.productDetail
                              ? _c("p", {
                                  staticClass: "gamedetail-text",
                                  class: !_vm.moreText.active
                                    ? "gamedetail-text-hide"
                                    : "",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.appData.store.info.productDetail
                                    ),
                                  },
                                })
                              : _c("p", [_vm._v("暂无介绍")]),
                            _vm.moreText.show
                              ? [
                                  !_vm.moreText.active
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "gamedetail-more",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onShowMore(true)
                                            },
                                          },
                                        },
                                        [_vm._m(0)]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "gamedetail-more",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onShowMore(false)
                                            },
                                          },
                                        },
                                        [_vm._m(1)]
                                      ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.appData.store.info.mediums &&
                        _vm.appData.store.info.mediums.length
                          ? _c("div", { staticClass: "contact-box" }, [
                              _c("h2", { staticClass: "info-title" }, [
                                _vm._v("关注我们"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "concat" },
                                _vm._l(
                                  _vm.appData.store.info.mediums,
                                  function (item, i) {
                                    return _c("i", {
                                      key: i,
                                      staticClass: "logo",
                                      class: "logo-" + item.mediumType,
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToMedius(item)
                                        },
                                      },
                                    })
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c("Comment", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.curTab === "comment",
                          expression: "curTab === 'comment'",
                        },
                      ],
                      attrs: { appId: _vm.appId },
                      on: {
                        avgScore: (score) => {
                          _vm.avgScore = score
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.isPc
                ? _c("section", { staticClass: "right" }, [
                    _c(
                      "div",
                      { staticClass: "box" },
                      [
                        _c("div", { staticClass: "logo" }, [
                          _vm.appData.store.medias &&
                          _vm.appData.store.medias.cover
                            ? _c("img", {
                                attrs: {
                                  src: _vm._f("fCosBase")(
                                    _vm.appData.store.medias &&
                                      _vm.appData.store.medias.cover
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm.clientStatus == 0
                          ? _c(
                              "el-button",
                              { staticClass: "btn", attrs: { type: "info" } },
                              [
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("即将推出"),
                                ]),
                              ]
                            )
                          : _vm.clientStatus == 1
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading.download,
                                    expression: "loading.download",
                                  },
                                ],
                                staticClass: "btn",
                                attrs: { type: "primary" },
                                on: { click: _vm.downloadGame },
                              },
                              [
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("下载作品"),
                                ]),
                              ]
                            )
                          : _vm.clientStatus == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "btn downloading",
                                attrs: { type: "primary" },
                              },
                              [
                                _c("span", {
                                  staticClass: "bar",
                                  style: { width: _vm.downloadPercent + "%" },
                                }),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(
                                    "下载中 " +
                                      _vm._s(_vm.downloadPercent) +
                                      "%"
                                  ),
                                ]),
                              ]
                            )
                          : _vm.clientStatus == 3
                          ? _c(
                              "el-button",
                              {
                                staticClass: "btn",
                                attrs: { type: "success" },
                                on: { click: _vm.onLaunch },
                              },
                              [
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("启动作品"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("span", [_vm._v("发布日期")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.appData.updateAt &&
                                    _vm.formatTime(
                                      _vm.appData.updateAt,
                                      "YYYY-mm-dd"
                                    )) ||
                                    "-"
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("span", [_vm._v("开发者名称")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.appData.email || "-")),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm.recommendList.length
                      ? _c("div", { staticClass: "box recommend" }, [
                          _c("h3", [_vm._v("相似作品")]),
                          _c(
                            "div",
                            { staticClass: "gamelist" },
                            _vm._l(_vm.recommendList, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.appId,
                                  staticClass: "gamelist-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToGame(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "poster",
                                    attrs: {
                                      src:
                                        (item.store &&
                                          item.store.medias &&
                                          _vm.getCosUrl(
                                            item.store.medias.cover,
                                            "base"
                                          )) ||
                                        _vm.DefaultGameCover,
                                    },
                                  }),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(
                                        (item.store &&
                                          item.store.info &&
                                          item.store.info.productName) ||
                                          ""
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "bottom-color" }),
          ])
        : !_vm.loading.gameList
        ? _c("div", { staticClass: "contentbox blank" }, [
            _c(
              "div",
              { staticClass: "nav" },
              [
                _c("router-link", { staticClass: "link", attrs: { to: "/" } }, [
                  _vm._v("首页"),
                ]),
                _c("span", { staticClass: "separator" }, [_vm._v(">")]),
                _c("span", [_vm._v("未知")]),
              ],
              1
            ),
            _c("p", [_vm._v("未找到作品数据")]),
          ])
        : _vm._e(),
      _c(
        "Dialog",
        {
          attrs: {
            visible: _vm.visible.openClient,
            titleText: "已有Oasis客户端？",
            okText: "打开AI Competition",
            okClass: "dialog-btn-open",
            cancelText: "下载AI Competition",
            cancelClass: "dialog-btn-download",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "openClient", $event)
            },
            ok: _vm.onOpen,
            cancel: _vm.downloadClient,
          },
        },
        [
          _c("p", [
            _vm._v(
              "如果你已经拥有Oasis客户端，可使用“打开”按钮；否则需要下载Oasis客户端进行畅玩。"
            ),
          ]),
        ]
      ),
      _c("Preview", {
        attrs: {
          visible: _vm.visible.preview,
          list: _vm.previewList,
          curIndex: _vm.curCoverIndex,
        },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.visible, "preview", $event)
          },
          pre: _vm.onPre,
          next: _vm.onNext,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("展开全部"),
      _c("i", { staticClass: "el-icon-arrow-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("收起全部"),
      _c("i", { staticClass: "el-icon-arrow-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }