var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "guide-introduction": true,
        "introduction-normal": !_vm.isReserve,
        "introduction-reverse": _vm.isReserve,
      },
      style: { backgroundImage: "url(" + _vm.iconPath + ") " },
    },
    [
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "greenStroke" }),
        _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }