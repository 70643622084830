import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

export async function ATestAccGET(appid: string) {
  return request({
    url: `${BaseUrl}/app/testaccount/query?appid=${appid}`,
    method: 'GET',
  });
}
export async function ATestAccAdd(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/testaccount/create?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function ATestAccUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/testaccount/changepassword?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function ATestAccDel(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/testaccount/delete?appid=${appid}`,
    method: 'POST',
    data,
  });
}
