
import Editor from '@tinymce/tinymce-vue'
import { gateEditorConfig, tinyApiKey } from '@/config/editor'

// tinymce富文本编辑器
export default {
  name: 'tiny-editor',
  components: { Editor },
  props: {
    height: {
      type: Number,
      default: 500
    },
  },
  data() {
    return {
      tinyApiKey: tinyApiKey,
      editorData: '',
      editorConfig: {
        ...gateEditorConfig,
        selector: '#textarea',
        height: this.height
      }
    }
  },
  mounted() {
    console.log('height:', this.height)
  },
  methods: {
    getContent() {
      return this.editorData;
    },

    setContent(content) {
      console.log('setContent:', content);
      this.editorData = content;
    }
  }
}
