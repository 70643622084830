
import i18n, { getLanguage, setLanguage } from '@/lang';
import guideBanner from './guideBanner.vue';
import guideAbout from './guideAbout.vue';
import guideEvents from './guideEvents.vue';
import guideCase from './guideCase.vue';
import guidePartners from './guidePartners.vue';
import guideContact from './guideContact.vue';
import guideFooter from './guideFooter.vue';

export default {
  name: 'guide',
  components: { guideBanner, guideAbout, guideEvents, guideCase, guidePartners, guideContact, guideFooter },
  data() {
    return {
      cacheBgColor: '',
      lang: 'en'
    }
  },
  mounted() {
    this.cacheBgColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#000';
    console.log('mounted');
    this.lang = getLanguage();
  },
  methods: {

  },
  beforeDestroy() {
    document.body.style.backgroundColor = this.cacheBgColor;
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      // 当语言切换时执行的逻辑
      this.lang = newLocale;
    }
  }
}
