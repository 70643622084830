
import { Component, Vue } from 'vue-property-decorator'
import { isClient } from '@/utils/env'
import { DefaultGameCover } from '@/config'
// import { getCosUrl } from '@/utils/file';
import { GameModule } from '@/store/modules/game'
import eventbus from '@/utils/eventbus'
import { AITypeListMap } from '@/config/developer'
import { DeveloperModule } from '@/store/modules/developer';
import { OpenDomain } from '@/utils/env'

@Component({
  name: 'allWorks',
})
class allWorks extends Vue {
  // public getCosUrl = getCosUrl
  public AITrackList = []
  public loading = {
    gameList: false,
    download: false
  }
  public visible = {
    toTop: false,
    scrollMask: true,
  }
  public bannerList = [
    {
      id: 1,
      img: require('../../assets/home/ai/banner.jpg'),
      imgMobile: require('../../assets/home/ai/banner-m.png'),
      url: 'https://km.woa.com/group/LightspeedExplorationLab/articles/show/536372'
    },
  ]
  public curTab = 'all'
  public condition = {
    name: ''
  }
  // public rawGameList: any = []
  public fitGameList: any = []
  public DefaultGameCover = DefaultGameCover

  // public swiperOption = {
  //   slidesPerView: 3,
  //   spaceBetween: 30,
  //   freeMode: true,
  // }
  private splitNum = 3
  public period = 0
  public worksData = false
  public isMobile = false

  get isPc() {
    return document.documentElement.clientWidth > 750
  }
  get gameModule() {
    return this.$store.state.game
  }
  get rawGameList() {
    return this.gameModule.list
  }
  get developerModule() {
    return this.$store.state.developer
  }
  get spliFitGameList() {
    let sNum = this.splitNum, total = this.fitGameList.length, sList: any = []
    for (let i = 0; i < Math.ceil(total / sNum); i++) {
      sList.push(this.fitGameList.slice(i * sNum, (i + 1) * sNum))
    }
    return sList
  }

  beforeCreate() {
    if (isClient) this.$router.replace('/home/client')
  }
  created() {
    this.initParams();
    this.initDevModule();
    // @ts-ignore
    this.isMobile =  this.$parent.getMode() === 'h5' ? true : false;
  }
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs) elTabs.addEventListener('scroll', this.onTabsScroll)
    eventbus.$on('game-search', this.onGameSearch)
    const bannerDOM :any = document.getElementById('banner-warps');
    const navDOM :any = document.getElementById('nav-warps');
    const scrollTop = bannerDOM.offsetHeight + navDOM.offsetHeight;
    window.scrollTo(0, scrollTop)
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs) elTabs.removeEventListener('scroll', this.onTabsScroll)
  }

  private async initData() {
    this.loading.gameList = true
    if (!this.gameModule.init.list) await GameModule.MAGetGameList();
    this.loading.gameList = false
    this.filterGameList()
  }
  public initParams() {
    if (this.$route.params.tab) this.curTab = this.$route.params.tab
    if (this.$route.params.search) this.condition.name = this.$route.params.search
  }
  public onScroll(e) {
    this.visible.toTop = document.documentElement.scrollTop >= window.screen.height / 2
  }
  public onTabsScroll(e) {
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs) this.visible.scrollMask = (elTabs.scrollLeft + elTabs.clientWidth) < (elTabs.scrollWidth - 40)
  }
  public scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  public changeTab(key: string, e) {
    let elTabs = this.$refs.tabs as HTMLDivElement
    if (elTabs && e.target) {
      let offset = 50
      let rect = e.target.getBoundingClientRect()
      if (rect.left + rect.width >= elTabs.clientWidth) {
        elTabs.scrollTo({
          left: (rect.left + rect.width) - elTabs.clientWidth + elTabs.scrollLeft + offset,
          behavior: "smooth"
        })
      }
      else if (rect.left < offset) {
        elTabs.scrollTo({
          left: elTabs.scrollLeft + rect.left - offset,
          behavior: "smooth"
        })
      }
    }
    this.curTab = key
    this.filterGameList()
  }
  public onGameSearch(name) {
    console.log("search", name)
    this.condition.name = name
    this.onSearch()
  }
  public onSearch() {
    this.filterGameList()
  }
  public filterGameList() {
    this.rawGameList.forEach(item => {
      if (item.stage == this.period) {
        this.worksData = true
      }
    })
    this.fitGameList = this.rawGameList.filter(item => {
      if (this.condition.name == '') return true
      return new RegExp(this.condition.name, 'i').test(item.store && item.store.info && item.store.info.productName || '')
    }).filter(item => {
      if (this.curTab == 'all') return true
      let tracks = item.pkg && item.pkg.tracks || []
      return tracks.includes(this.curTab)
    })
    this.fitGameList = this.fitGameList.filter((item: any) =>{
      return item.stage == this.period;
    })
  }

  public goToBanner(banner) {
    if (!banner.url) return
    window.open(banner.url)
  }
  public goToGame(game) {
    location.href = `${location.origin}/game/info?appId=${game.appId}`
  }
  public getCosUrl(cover: string) {
    const prefix = 'https://oasis-1300799399.cos.ap-nanjing.myqcloud.com/image/';
    return `${prefix}${cover}`;
  }
  public async getMenuList() {
    this.AITrackList = AITypeListMap[this.developerModule.aiSeason.period]
  }
  public async initDevModule() {
    if (!DeveloperModule.init.aiSeason) await DeveloperModule.MAGetAISeason()
    this.getMenuList()
    this.period = this.developerModule.aiSeason.period;
    this.initData()
  }
  public openPath(url: string) {
    window.open(`${OpenDomain}${url}`)
  }
}
export default allWorks
