<template>
  <div class="gate-layer-footer">

  </div>
</template>

<script>
export default {
  name: 'gate-layer-footer',
  data() {
    return {
    }
  },
  mounted() {
    
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.gate-layer-footer{
  height: 80px;
  background: #000;
}

</style>
