import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { AGameListGet, ADevAICommentScoreGET, ADevAICommentAllGET } from '@/api/game'
import { GameState, GameInfo, ScoreInfo } from '@/store/interface/index';
import { deepClone } from '@/utils';
import store from '@/store';

export type IGameState = GameState;
const defaultData = {
  list: [] as GameInfo[],
  scoreInfo: [],
  idList: []
};

@Module({ dynamic: true, store, name: 'game' })
class Game extends VuexModule {
  public init = {
    list: false,
    scoreInfo: false,
    idList: false
  };
  public list = deepClone(defaultData.list);
  public scoreInfo = deepClone(defaultData.scoreInfo)
  public idList = deepClone(defaultData.idList)

  @Mutation
  private MMReset(keys: string[] = Object.keys(this.init)) {
    for (const k of keys) {
      this.init[k] = false;
      if (defaultData[k]) this[k] = deepClone(defaultData[k]);
    }
  }
  @Mutation
  private MMSetGameList(list: GameInfo[]) {
    this.list = list || [];
  }
  @Mutation
  private MMGetScoreInfo(data: any) {
    this.scoreInfo.push(data)
  }
  @Action
  public async MAReset(keys: string[]) {
    this.MMReset(keys);
  }
  @Action
  public async MAGetGameList() {
    return new Promise((resolve) => {
      AGameListGet().then((res: any) => {
        let list = res.data.map(item => {
          return {
            ...item,
            score: 0
          }
        })
        this.MMSetGameList(list);
        this.init.list = true;
        resolve(null);
      }).catch(() => {
        this.MMSetGameList([]);
        resolve(null);
      });
    });
  }
  @Action
  public async MAScoreInfo(data: any) {

  }
}

export const GameModule = getModule(Game);
