
import { Component, Vue, Prop } from "vue-property-decorator"
import { AUpdInit, AUpdUpload } from '@/api/upload'
import { splitFileName } from '@/utils/file'

const chunkSize = 2097152

// 使用worker方式
import Sha1Worker from 'worker-loader!./sha1.worker';
const sha1Worker = new Sha1Worker();
let fileIncId = 0;

@Component({
  name: "Component-Upload",
  components: {},
})
class ComponentUpload extends Vue {

  @Prop({ type: Number, required: false, default: 0 }) private maxSize   // 最大文件大小，Mb

  private fileRef: any = null
  private fileReader: any = null
  private currFileId: number = fileIncId

  private sha1 = ''
  private loading = {
    upload: false,
  }
  private progress = {
    type: '',
    n: 0
  }
  private fid = 0
  private startTime = 0

  get progressTips() {
    if (this.progress.type == 'sha1') return `校验中 ${this.progress.n.toFixed(1)}%`
    if (this.progress.type == 'upload') return `上传中 ${this.progress.n.toFixed(1)}%`
    return '处理中'
  }

  mounted() {
    this.fileRef = this.$refs.fileRef
  }
  beforeDestroy() {
    this.fileReader && this.fileReader.abort()
  }

  // 处理拖拽非accept文件不报错问题
  public onDrop(e) {
    let file = e.dataTransfer.files[0]
    if (!file) return
    let extLimit = (this.$attrs.accept || '').split(',')
    const { name, ext = '' } = splitFileName(file.name)
    if (!extLimit.includes('.' + ext)) {
      this.$emit('error', '格式错误，只支持' + extLimit.join() + '格式', file)
    }
  }
  public onFileChange(file, fileList) {
    const fileSize = file.size / 1024 / 1024
    if (this.maxSize && fileSize > this.maxSize) {
      const errMsg = '文件大小不能超过' + (this.maxSize >= 1024 ? (+(this.maxSize / 1024).toFixed(1) + 'G') : (this.maxSize + 'Mb'))
      // this.$message.error(errMsg)
      this.$emit('error', new Error(errMsg))
      return false
    }
    // if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
    //   this.$message.error("上传格式不正确，请上传xls或者xlsx格式")
    //   return false
    // }
    if (this.$listeners.beforeUpload) {
      let flag = this.$listeners.beforeUpload(file)
      if (flag === false) return false
    }
    console.log("开始上传")
    this.startTime = Date.now()
    this.fileReader && this.fileReader.abort()
    this.calcSha1(file.raw)
    this.$emit('change', file, fileList)
  }
  public onUploadError(err, file, fileList) {
    console.error(err)
    // this.$message.error("上传失败 " + err)
    this.$emit('error', err, file, fileList)
  }

  private calcSha1(oFile) {
    this.loading.upload = true
    this.progress.type = 'sha1'
    this.progress.n = 0
    this.fid = Date.now()
    this.currFileId = fileIncId++;
    const timeBegin = Date.now();
    const handler = (e) => {
      const { type, data, id } = e.data;
      console.log('worker on message', e.data);
      if (this.currFileId !== id) {
        return;
      }
      if (type === 'progress') {
        this.$emit('progress', 'sha1', data)
        this.progress.type = 'sha1'
        this.progress.n = data || 0
      } else if (type === 'error') {
        sha1Worker.removeEventListener("message", handler);
        this.$emit('error', data)
        this.stopUpload();
      } else if (type === 'result') {
        sha1Worker.removeEventListener("message", handler);
        this.sha1 = data;
        console.log("校验完成，耗时：" + (Date.now() - this.startTime))
        this.checkSha1(oFile);
        console.log('calcSha1 done', (oFile.size/1024/1024/((Date.now() - timeBegin)/1000)).toFixed(2)+'MB/s');
      }
    }
    sha1Worker.addEventListener("message", handler);
    sha1Worker.postMessage({ type: 'start', params: { file: oFile, chunkSize }, id: this.currFileId });
  }

  private stopUpload() {
    sha1Worker.postMessage({ type: 'stop', id: this.currFileId });
    this.loading.upload = false
    if (this.fileRef) {
      this.fileRef.value = ''
    }
  }

  private checkSha1(file) {
    const ChunkCount = Math.ceil(file.size / chunkSize)
    const data = { size: file.size, sha1: this.sha1, chunk_size: chunkSize, name: file.name, chunk_count: ChunkCount }
    const fid = this.fid

    AUpdInit(data).then(response => {
      const { uploaded_size, file_exist } = response
      if (fid !== this.fid) {
        return
      }
      if (response.status == 0) {
        this.stopUpload()
        this.$emit('error', response)
        return
      }
      if (file_exist) {
        this.$emit('success', response, file)
        console.log('upload finish', response)
        this.stopUpload()
      } else {
        this.upload(file, { ...data, id: response.id }, +uploaded_size || 0)
      }
    }).catch(err => {
      if (fid === this.fid) {
        this.stopUpload()
      }
      this.$emit('error', err)
    })
  }

  private async upload(file, data, offset = 0) {
    const fid = this.fid
    const chunkCount = data.chunk_count;
    let formData = new FormData()
    this.progress.type = 'upload'
    this.progress.n = 0
    formData.append('file', '')
    formData.append('chunk', '0')
    formData.append('id', data.id)
    while(true) {
      if(fid !== this.fid) {
          return;
      }
      const chunk = Math.floor(offset / chunkSize);
      const nextOffset = offset + chunkSize;
      let response: { is_done: boolean, uploaded_size: number } | null = null;
      let lastErr: any = null;
      formData.set('chunk', `${chunk + 1}`);
      formData.set('file', file.slice(offset, nextOffset));
      for(let i=0;i<5;i++) {
          // 自动重试5次
          try {
            response = await AUpdUpload(formData)
            break;
          } catch(e) {
            console.error('upload failed, try next', i);
            lastErr = e;
            await new Promise((resolve)=>setTimeout(resolve, Math.random() * 5000 + 1000));
          }
      }
      if(!response) {
        this.stopUpload();
        this.$emit('error', lastErr)
        return;
      }
      offset = +response.uploaded_size;
      if(response.is_done) {
        console.log('upload finish', response)
        this.$emit('success', response, file)
        console.log("上传完成，耗时：" + (Date.now() - this.startTime))
        break;
      }  else {
        let progress = (chunk + 1) / chunkCount * 100
        this.progress.n = progress
        this.$emit('progress', 'upload', progress)
      }
    }
    if (fid === this.fid) {
      this.stopUpload()
    }
  }

  private cancelUpload() {
    const fn = () => {
      if (this.fileReader) {
        this.fileReader.abort()
      }
      this.fid = 0
      this.stopUpload()
    }
    fn()
  }

}
export default ComponentUpload
