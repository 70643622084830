export const GameList = [
  [
    {
      img: 'https://image.guangzi.qq.com/uploadfiles/oasis/app_default.png',
      name: 'Fgame',
      name_en: 'Fgame',
      downloaded: true,
      url: 'https://www.lightspeed-studios.com/',
    },
    {
      img: 'https://image.guangzi.qq.com/uploadfiles/oasis/app_default.png',
      name: 'Rgame',
      name_en: 'Rgame',
      downloaded: false,
      url: 'https://www.lightspeed-studios.com/',
    },
    {
      img: 'https://image.guangzi.qq.com/uploadfiles/oasis/app_default.png',
      name: 'Origin',
      name_en: 'Origin',
      downloaded: false,
      url: 'https://www.lightspeed-studios.com/',
    },
  ],
];
