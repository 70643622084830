// 定义内容
export default {
  route: {
    setting: {
      general: '基础设置',
      password: '密码设置',
      connect: '连接',
      developer: '成为开发者',
    },
    home: {
      browser: '主页',
      client: '主页',
    },
    account: {
      login: '登录',
      register: '注册',
      reset: '找回密码',
      bind: '绑定账号',
      redirect: '登录重定向',
    },
    oauth: {
      login: '授权登录',
      consent: '授权登录',
      redirect: '授权登录',
    },
    developer: {
      home: '首页',
      register: '开发者注册',
      application: '我的作品',
      sdk: 'SDK与AI工具',
      document: '文档',
    },
    article: {
      add: '文章'
    },
    error: {
      index: '发生错误',
      401: '无权限',
      404: '未找到资源',
    },
  },
  views: {
    account: {
      login: '登录',
      logout: '退出登录',
      register: '注册',
      email: '邮箱',
      forgotPassword: '忘记密码',
      rememberMe: '记住我',
      hasNoAccount: '还没有Oasis账户？',
      hasAnAccount: '已有Oasis账户？',
      termsOfService: '服务条款',
      privacyPolicy: '隐私协议',
      backToOtherLogin: '返回到其他登录方式',
      backToOtherRegister: '返回到其他注册方式',
      linkToExistAcc: '关联已有账户',
      linkToNewAcc: '关联新账户',
      donotWantLink: '不想关联此账户？',
      resetSuccess: '重置密码成功',
      hasResetPls: '你已重置你的密码，请',
      loginAgain: '重新登录',
      autoJumpIn10: '（10s后自动跳转）',
      sendSuccess: '我们已将一封验证邮件发送至您的邮箱，请点击邮箱中的链接完成注册',
      sendNotRec: '没有收到邮件？',
      sendAgain: '重新发送邮件',
      sendVerifyCode: '发送验证码',
      haveReadAndAgree: '我已阅读并同意',
      bindHasBind1: '你的邮箱 ',
      bindHasBind2: ' 已经与现有的Oasis账户关联，请登录并关联你的',
      bindHasBind3: ' 账户',
      or: '或',
    },
    home: {
      ourGame: '我们的游戏',
      myAccount: '我的账户',
      signin: '登录',
      signout: '登出',
      download: '下载Oasis',
      downloadGame: '下载游戏',
      launchGame: '启动游戏',

    },
    setting: {
      connect: '连接',
      disconnect: '解除连接',
      gameTips: '你可以选择将自己的Oasis账户与游戏进行连接。你可随时撤销游戏访问你数据的权限',
      channelTips: '你可以选择将自己的Oasis账户与第三方账户进行连接。你可随时撤销绑定关系',
      game: '游戏',
      none: '暂无',
      account: '账户',
      add: '添加',
      diaCon1: '关联你的',
      diaCon2: '账户',
      diaCon3: '关联账户',
      diaCon4: '您已使用',
      diaCon5: '登录AI Competition为关联您的账户，您将被跳转至',
      diaCon6: '网站登录',
      diaDiscon1: '从该账户取消与',
      diaDiscon2: '的关联',
      diaDiscon3: '取消关联',
      diaDiscon4: '你将无法再使用',
      diaDiscon5: '登录AI Competition账户',

      accountInfo: '账号信息',
      delTips: '选择“永久删除账号”后，你的Oasis账号将被永久删除，并且不可恢复。',
      delForever: '永久删除账号',
      sessionTips: '设置敏感信息需要较新登录态，当前登录时间已过期，请重新登录',
    },
    game: {
      info: '游戏信息',
    },
    guide: {
      more: 'More Details',
      success: '订阅成功!',
      emailCheckFail: '请输入正确的邮箱',
      banner: {
        tips1: 'AI赋能游戏：知识、向导、资源与更多...',
        slogan1: '从LitGate社区启程',
        scrollSlogan: ['游戏创作', 'AI应用', '资讯&洞见', '职业成长'],
        slogan3: 'WITH LITGATE COMMUNITY',
        emailTips: '功能即将开放，立即订阅上线通知。',
        tips2: 'LitGate社区致力于保护您的隐私和确保个人信息的安全。我们承诺您提供的电子邮件地址仅用于官方发送产品更新通知。未经授权与明确同意，我们绝不会披露或与任何第三方共享您的电子邮件信息。',
        subscribe: '订阅',
        placeholder: '请输入您的邮件地址',
        discord: 'Discord',
        wechat: '微信',
      },
      events: {
        title: '活动',
        eventTimeRange1: '2023年10月21~30日',
        eventTitle1: 'CiGA中国AI游戏巡回赛',
        eventAddress1: '上海/武汉/广州/深圳',
        eventIntro1: '○AIGT（中国AI游戏巡回赛）是由CiGA主办，LitGate赞助的一个AI主题极限游戏创作赛事。在赛事中，你将尝试将AI技术运用至游戏Demo中，冲刺奖项。优秀者将获得丰富的曝光机会与奖杯，同时你也将结交到广泛的行业人士、游戏同好与其他创作者。',
        eventMore: '更多',
        eventTimeRange2: '2024年1月22~28日',
        eventTitle2: '全球Gamejam极限创作',
        eventAddress2: '全球线上线下多站点',
        eventIntro2: 'Global Gamejam（GGJ）是全球最大的游戏创作活动，自2008年以来拥有数以万计的参与者（即Jammer），覆盖了100多个国家的线上和线下场地。通过每年组织的48小时的Gamejam极限游戏创作活动，GGJ旨在激励全球各地创作者学习、创意交流和共同创作。 2024年，LitGate将出席作为中国赞助方之一，提供AI相关游戏创作主题，并为创作者提供资源支持。'
      },
      aboutUs: {
        title: '关于LitGate',
        introTitle1: '开放活跃的AI游戏生态',
        introContent1: "无论你是经验丰富的创作者，还是拥抱AI浪潮的游戏用户，LitGate都欢迎你在这儿分享想法观点、工具资源与创作历程，又或是参与LiGate举行的创作赛事与活动，成为社区的一份子。",
        stations: [
          { title: '游戏作品库', label: 'AI游戏应用的发布和分享站'},
          { title: '极客社区', label: '新鲜信息资讯与知识人才交流'},
          { title: '前沿科技', label: 'AI赋能游戏开发的专业工具站'},
          { title: '职业发展', label: '助力技术、孵化、投资与成长'},
        ],
        introTitle2: '不仅是社区，还是更多可能',
        introContent2: "LitGate还为创作者提供各类开源工具与服务，以及自研或赞助的付费资源使用权限。在动态更新的信息流和文章专栏中，你也能随时了解到新鲜资讯与独家文章。",
        introTitle3: '创作活动与Gamejam',
        introContent3: "LitGate在全球范围内举办丰富的创作活动和Gamejam，帮助创作者脱颖而出。在这里，你不仅可以通过线上或线下的交流会，接触到行业先驱和知名嘉宾，还有机会获得潜在的融资与孵化机会。",
      },
      case: {
        title: '游戏作品分享',
        carousel1: {
          title: '2023 Top Ten Works Collection',
          intro: 'The second Photon Al Exploration and Innovation Competition is nearing its end, and in the past two months, participants have actively explored the boundaries of developing games, tools, and designs using Al, maximizing their creativity. A total of 28 entries were received in this competition, and after a joint decision by experts and the public, 10 were ultimately selected for the "Best AI Works Award". ',
        },
        banner2: {
          title: '囚灵',
          intro: "本游戏利用了由ChatGPT4提供支持的AI实时能力，玩家能够在每次游戏中体验随机生成全新叙事内容。每次游戏启动时，系统将载入约10万字的详细提示，确保AI能够清楚地理解上下文。",
          tags: ['Roguelike', '恐怖', '实时生成'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner3: {
          title: 'AI美少女梦工厂',
          intro: '本游戏是一款以二次元生活模拟RPG，玩家可以体验到"养女儿"的虚拟乐趣。游戏根据不同的选择和角色点数，经由Stable Diffusion实时计算得到视觉素材，使玩家能获得到独属于自己的女儿形象。',
          tags: ['人生模拟', '角色扮演', '实时生成'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner1: {
          title: '融合',
          intro: '这款卡牌游戏设计了丰富的卡牌装备和多样化的关卡，复玩性极高。游戏的所有视觉素材由Midjourney和Stable Diffusion生成，音乐生产则使用了AIVA。此外，文本、图像和卡牌效果均由ChatGPT和SD实时计算得到。',
          tags: ['卡牌', '策略'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner4: {
          title: '神明传音',
          intro: '本工具帮助用户一键便捷地部署SDwebUI，适配所有类型的设备环境，同时还提供一体化的管理面板，大大方便了用户管理SD相关的模型资源、脚本工具、预设等文件。',
          tags: ['沙盒', '冒险', '动作'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
      },
      partner: {
        title: '商业合作伙伴'
      },
      contact: {
        title: '联系我们',
        tips: '如果您对合作机会感兴趣，或是有任何相关问题，请随时联系我们的邮箱',
        email: 'business@gate.com',
        listTitle: 'OUR COSIALS:',
      },
    }
  },
  common: {
    ok: '确认',
    cancel: '取消',
    and: '和',
    continue: '继续',
    nickname: '昵称',
    email: '邮箱',
    country: '国家',
    password: '密码',
    curPassword: '原密码',
    newPassword: '新密码',
    sndPassword: '确认密码',
    save: '保存',
    giveup: '放弃',
    tips: '提示',
    logoutTips: '此操作将退出登录, 是否继续?',
    logouting: '退出中',

  },
  plchld: {
    email: '请输入邮箱',
    password: '请输入密码',
    passwordCur: '请输入原密码',
    passwordNew: '请输入新密码',
    passwordSnd: '请确认密码',
    country: '请选择国家',
    nickname: '请输入昵称',
    verifyCode: '请输入验证码',
    firstname: '请输入姓氏',
    lastname: '请输入名字',
  },
  tips: {
    charLen: '英文占用1个字符，中文2个字符',
    succ: {
      get: '拉取数据成功',
      connect: '连接成功',
      disconnect: '解绑成功',
      add: '添加成功',
      edit: '修改成功',
      del: '删除成功',
      login: '登录成功',
      submit: '提交成功',

    },
    fail: {
      get: '拉取数据失败',
      connect: '连接失败',
      disconnect: '解绑失败',
      add: '添加失败',
      edit: '修改失败',
      del: '删除失败',
      closed: '暂不可用',
      submit: '提交失败',

    },
  },
  error: {
    content: '请输入内容',
    email: '请输入邮箱',
    emailFormat: '请输入正确的邮箱地址',
    password: '请输入密码',
    passwordCur: '请输入原密码',
    passwordNew: '请输入新密码',
    passwordSnd: '请确认密码',
    pswNotSame: '两次密码不一致',
    pswOldSame: '新旧密码一样请重新输入',
    country: '请选择国家',
    nickname: '请输入昵称',
    verifyCode: '请输入验证码',
    verifyCodeFormat: '请输入正确格式的验证码',
    lengthAt: '长度在',
    notBlank: '之间，不能包含空格',
    withSpecialChar: '，可包含@#$等特殊字符',
    sameEmailPsw: '密码和邮箱过于相似',
    protocol: '请先同意协议',
    overMax: '长度超过上限',
    format: '格式错误',
    backspace: '不能包含空格',

  },
};
