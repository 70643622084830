
import { Component, Vue, Watch } from 'vue-property-decorator'
import Aside from '@/layout/component/aside.vue';
import Header from '@/layout/component/header.vue';
import Main from '@/layout/component/main.vue';

@Component({
  name: 'layoutDeveloper',
  components: { Aside, Header, Main },
})
class layoutDeveloper extends Vue {
  public isShowHeader = true;

  created() {
    this.isShowHeader = this.$route.name === 'developerSDK' ? false : true;
  }
  mounted() {
    console.log('mounted')
    // 上报开发者管理页
    this.$report('dev/admin', 'visit', { content: { path: this.$route.path } });
  }
  get noFrame() {
    return this.$route.meta?.noFrame;
  }

  @Watch('$route')
  onRouteChanged(val: any) {
    //sdk页面不使用公共头部
    this.isShowHeader = val.name === 'developerSDK' ? false : true;
  }
}
export default layoutDeveloper
