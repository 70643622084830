
import { Component, Vue } from 'vue-property-decorator'
import { AIToolMenuList, AIToolPlatform } from '@/config/developer'
import { getToolsadvertise, getToolsgather, getToolsbytype, getToolName, addToolsCollect, deleteToolsCollect, replaceToolsRecentuse, searchAllToll} from '@/api/developerApi/tools'

@Component({
  name: 'ToolMarket',
  filters: {
    lableFormat(value: number) {
      if (!value && value !== 0) return '';
      let labelName = '';
      AIToolPlatform.forEach(item => {
        if (item.key === value) {
          labelName = item.name;
        }
      })
      return labelName;
    }
  }
})

class ToolMarket extends Vue {
  public menuList:any = [];
  public menuName = '';
  public toolList:any = [];
  public bannerList = [];
  public currList = [];
  public isAllData = true;
  public searchText = '';
  public searchList = [];
  public search = false;
  public classify = false;
  public classifyList = [];
  public searchTotal = 0;
  public classifyTotal = 0;
  public currClassify = 0;

  created() {
    this.menuList = AIToolMenuList;
    this.getBannerList();
    this.getTools();
  }

  mounted() {
    this.obServerScroll();
  }

  public menuChange(key:string) {
    this.menuList.forEach(item => {
      item.active = false;
      if (item.key === key) {
        item.active = true;
        if (key === 'all') {
          this.isAllData = true;
          this.searchTotal = 0;
          this.classifyTotal = 0;
        } else if (key === 'recommend') {
          this.menuName = item.name;
          this.isAllData = false;
          this.currClassify = item.index;
          this.getRecommend('recommend');
        } else {
          this.menuName = item.name;
          this.isAllData = false;
          this.currClassify = item.index;
          this.getClassify(item.index);
        }
      }
    })
  }
  public getBannerList() {
    getToolsadvertise({advertise_type: 1}).then((res) => {
      console.log('getToolsadvertise res', res)
      this.bannerList = res.data?.advertise_data || [];
    })
  }
  public getTools() {
    // Promise.all([
    //   getToolsgather({gather_type: 1}),
    //   getToolsgather({gather_type: 2}),
    //   getToolsgather({gather_type: 3}),
    //   getToolsgather({gather_type: 4}),
    //   getToolsgather({gather_type: 5}),
    //   getToolsgather({gather_type: 6})
    // ]).then((res) => {
    Promise.all([
      getToolsbytype({tools_type: 1, tools_limit: 10, tools_offset: 0}),
      getToolsbytype({tools_type: 2, tools_limit: 10, tools_offset: 0}),
      getToolsbytype({tools_type: 3, tools_limit: 10, tools_offset: 0}),
      getToolsbytype({tools_type: 4, tools_limit: 10, tools_offset: 0}),
      getToolsbytype({tools_type: 5, tools_limit: 10, tools_offset: 0}),
      getToolsbytype({tools_type: 6, tools_limit: 10, tools_offset: 0}),
    ]).then((res) => {
      this.toolList = [];
      res.forEach((item, index) => {
        let gather = {
          gather_name: '',
          gather_data: [],
          key: '',
          all: false,
          index: 0,
        }
        gather.gather_name = this.menuList[index + 1].name;
        gather.key = this.menuList[index + 1].key;
        gather.index = this.menuList[index + 1].index;
        gather.gather_data = item.data;
        gather.all = parseInt(item.data_total) >= 8 ? true : false;
        // this.menuList.forEach(item => {
        //   if (gather.gather_name.indexOf(item.name) !== -1) {
        //     gather.index = item.index;
        //     gather.key = item.key;
        //   }
        // })
        if (gather.gather_data.length > 0) {
          this.toolList.push(gather);
        }
      })
      this.getRecommend();
      console.log('toolList', this.toolList)
    })
  }
  public getRecommend(type: string = '') {
    let gather = {
      gather_name: '',
      gather_data: [],
      key: 'recommend',
      all: false,
      index: 0,
    }
    getToolsgather({gather_type: 1}).then(res => {
      console.log('getToolsgather res', res)
      gather.gather_name = res.data?.gather_name || '';
      gather.gather_data = res.data?.gather_data || [];
      if (type === 'recommend') {
        this.classifyList = res.data.gather_data;
        this.classify = true;
        return
      }
      if (gather.gather_data.length > 0) {
        gather.all = gather.gather_data.length >= 8 ? true : false;
        if (this.menuList[1].key !== 'recommend') {
          let menu = {
            active: false,
            key: 'recommend',
            name: res.data.gather_name
          }
          this.menuList.splice(1, 0, menu);
        }
        this.toolList.unshift(gather);
      }
    })
  }
  public getClassify(type: number, page: number = 0) {
    this.classifyList = []
    getToolsbytype({tools_type: type, tools_limit: 20, tools_offset: page}).then((res) => {
      console.log('getToolsbytype res', res)
      this.classifyList = res.data;
      this.classify = true;
      this.classifyTotal = parseInt(res.data_total)
    })
  }
  public openLink(link: string, id: string) {
    this.addToolsRecentuse(id);
    window.open(`${link}`);
  }
  public callDetailes(item: any) {
    this.$emit('showDetails', item);
  }
  public searchTool(e:any, page: number = 0) {
    this.searchList = []
    if (!this.searchText) {
      this.getAllToll(page)
      return;
    }
    getToolName({search_name: this.searchText.trim(), tools_type: 0, tools_limit: 20, tools_offset: page}).then(res => {
      console.log('searchtools res', res)
      this.searchList = res.data;
      this.isAllData = false;
      this.search = true;
      this.searchTotal = parseInt(res.data_total)
    })
  }
  public emptySearch() {
    this.menuChange('all');
    this.searchText = '';
    this.isAllData = true;
    this.search = false;
    this.searchTotal = 0;
  }
  public handleCurrentChange(val: number) {
    this.searchTool("", val - 1);
  }
  public handleCurrentClassify(val: number) {
    this.getClassify(this.currClassify, val - 1);
  }
  //收藏
  public toolsCollect(id: string, index: number, childIndex: number, search: boolean = false) {
    const data = {
      replace_data: {
        tools_id: id
      }
    }
    addToolsCollect(data).then(res => {
      if (res.code === 0) {
        this.updateState(index, childIndex, 'add', search)
      }
    })
  }
  //取消收藏
  public deleteCollect(id: string, index: number, childIndex: number, search: boolean = false) {
    const data = {
      del_data: {
        tools_id: id
      }
    }
    deleteToolsCollect(data).then(res => {
      if (res.code === 0) {
        this.updateState(index, childIndex, 'delete', search)
      }
    })
  }
  public updateState(index: number, childIndex: number, type: string = '', search: boolean) {
    if (search) {
      if (childIndex === -1) {
        this.searchList[index].tools_basic_data.is_collect = type === 'add' ? 1 : 0;
      } else {
        this.classifyList[index].tools_basic_data.is_collect = type === 'add' ? 1 : 0;
      }
    } else {
      this.toolList[index].gather_data[childIndex].tools_basic_data.is_collect = type === 'add' ? 1 : 0;
    }
  }
  public getAllToll(page: number) {
    searchAllToll({tools_limit: 20, tools_offset: page}).then(res => {
      console.log('getAllToll res', res)
      this.searchList = res.data;
      this.isAllData = false;
      this.search = true;
      this.searchTotal = parseInt(res.data_total)
    })
  }
  public obServerScroll() {
    const header:any = document.querySelector('.layout-header');
    const splitLine:any = document.querySelector('.split-line');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          splitLine.style.marginTop = '-.9rem';
        } else {
          splitLine.style.marginTop = 0;
        }
      });
    })
    observer.observe(header);
  }
  public addToolsRecentuse(id: string) {
    const data = {
      data: {
        tools_id: id
      }
    }
    replaceToolsRecentuse(data).then(res => {
    })
  } 
}

export default ToolMarket

