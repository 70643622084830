import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const AccountBaseUrl = `${AccountDomain}/api`;

//获取广告位
export async function getToolsadvertise(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/gettoolsadvertise`,
      method: 'POST',
      data
    })
  }
  //获取工具位
  export async function getToolsgather(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/gettoolsgather`,
      method: 'POST',
      data
    })
  }
  //获取全局类型
  export async function getGlobalcommtype(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/getglobalcommtype`,
      method: 'POST',
      data
    })
  }
  //搜索所有工具
  export async function searchtools(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/getalltools`,
      method: 'POST',
      data
    })
  }
  //获取分类数据
  export async function getToolsbytype(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/selectalltoolsbytype`,
      method: 'POST',
      data
    })
  }
  //获取工具收藏数据
  export async function getToolsCollect(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/gettoolscollect`,
      method: 'POST',
      data
    })
  }
  //搜索工具名称
  export async function getToolName(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/selectalltoolsbysearchname`,
      method: 'POST',
      data
    })
  }
  //添加工具收藏
  export async function addToolsCollect(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/replacetoolscollect`,
      method: 'POST',
      data
    })
  }
  //删除工具收藏
  export async function deleteToolsCollect(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/deltoolscollect`,
      method: 'POST',
      data
    })
  }
  //最近使用
  export async function getToolsrecentuse(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/gettoolsrecentuse`,
      method: 'POST',
      data
    })
  }
  //添加最近使用
  export async function replaceToolsRecentuse(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/replacetoolsrecentuse`,
      method: 'POST',
      data
    })
  }
  //删除最近使用
  export async function delToolsRecentuse(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/deltoolsrecentuse`,
      method: 'POST',
      data
    })
  }
  //搜索全部
  export async function searchAllToll(data: object) {
    return request({
      url: `${AccountBaseUrl}/tools/getalltools`,
      method: 'POST',
      data
    })
  }
