var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "activity-card": true,
        "activity-card-hover": _vm.isHover,
        "activity-card-normal": !_vm.isHover,
      },
      style: _vm.computedStyle,
      on: { mouseenter: _vm.mouseOverFunc, mouseleave: _vm.mouseoutFunc },
    },
    [
      _c(
        "div",
        {
          class: {
            "float-layer": true,
            "hover-layer": _vm.isHover,
            "normal-layer": !_vm.isHover,
          },
        },
        [
          _c(
            "div",
            {
              class: {
                time: true,
                "time-hover": _vm.isHover,
                "time-normal": !_vm.isHover,
              },
            },
            [_vm._v(_vm._s(_vm.timeRange))]
          ),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c("div", { staticClass: "address" }, [
                _vm._v(_vm._s(_vm.address)),
              ]),
            ]),
            _c(
              "div",
              {
                class: {
                  more: true,
                  "more-hover": _vm.isHover,
                  "more-normal": !_vm.isHover,
                },
              },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("views.guide.events.eventMore"))),
                ]),
                _c("div", { class: { icon: true, "icon-hover": _vm.isHover } }),
              ]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isHover,
                  expression: "isHover",
                },
              ],
              staticClass: "introduction",
            },
            [_vm._v(_vm._s(_vm.introduction))]
          ),
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isHover,
            expression: "!isHover",
          },
        ],
        staticClass: "corner",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }