
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'DeveloperAppData',
})
class DeveloperAppData extends Vue {
  
}
export default DeveloperAppData
