var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "error" }, [
    _c("div", { staticClass: "error-flex" }, [
      _vm._m(0),
      _c("div", { staticClass: "item-animation item-num" }, [
        _vm._v("401 Unauthorized"),
      ]),
      _c("div", { staticClass: "item-animation item-title" }, [
        _vm._v(_vm._s("您未被授权，没有操作权限~")),
      ]),
      _c("div", { staticClass: "item-animation item-msg" }, [
        _vm._v(_vm._s("请联系管理员")),
      ]),
      _c(
        "div",
        { staticClass: "item-animation item-btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSetAuth } },
            [_vm._v(_vm._s("重新授权"))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "error-icon" }, [
      _c("img", { attrs: { src: require("@/assets/error/401.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }