var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container-layout", attrs: { id: "layout-game" } },
    [
      _vm.isShowMenu && _vm.isMobile
        ? _c("div", { staticClass: "show-mobile-menu" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.openPath("/application")
                  },
                },
              },
              [_vm._v("AI Developer")]
            ),
            _c(
              "a",
              {
                staticClass: "active",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.toPath("/aicompetions/index")
                  },
                },
              },
              [_vm._v("AI创作大赛")]
            ),
          ])
        : _vm._e(),
      _vm.isShowMenu && _vm.isMobile
        ? _c("div", { staticClass: "nav-mobile-mask" })
        : _vm._e(),
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "header-menu-warp" }, [
          _c(
            "div",
            {
              staticClass: "header-title",
              on: {
                click: function ($event) {
                  return _vm.toPath("/aicompetions/index")
                },
              },
            },
            [_vm._v("AI Competition")]
          ),
          _c("div", { staticClass: "header-right-box" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.openPath("/application")
                  },
                },
              },
              [_vm._v("AI Developer")]
            ),
            _c(
              "a",
              {
                staticClass: "active",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.toPath("/aicompetions/index")
                  },
                },
              },
              [_vm._v("AI创作大赛")]
            ),
            _c("div", { staticClass: "header-right" }, [
              _c(
                "div",
                { staticClass: "header-lang" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "header-pop dark",
                        placement: "bottom",
                        trigger: "hover",
                        width: 100,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "reference",
                          fn: function () {
                            return [
                              _c("div", {
                                staticClass: "header-lang-icon",
                                class: [_vm.active.lang],
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "pop-list" },
                        _vm._l(_vm.langList, function (item, i) {
                          return _c(
                            "li",
                            {
                              key: i,
                              class: { active: _vm.active.lang == item.key },
                              on: {
                                click: function ($event) {
                                  return _vm.onChangeLang(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item[_vm.$tk("name")]))]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.session.token
                ? _c(
                    "button",
                    {
                      staticClass: "header-btn login",
                      on: { click: _vm.goToLogin },
                    },
                    [_vm._v(_vm._s(_vm.$t("views.home.signin")))]
                  )
                : _c(
                    "div",
                    { staticClass: "header-user" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "header-pop dark",
                            placement: "bottom",
                            trigger: "hover",
                            width: 100,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "reference",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "header-user-name header-user-name-box",
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: _vm.avatarUrl },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.userInfo.nickname)),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("ul", { staticClass: "pop-list" }, [
                            _c("li", { on: { click: _vm.logout } }, [
                              _vm._v(_vm._s(_vm.$t("views.home.signout"))),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ]),
            _vm.isMobile
              ? _c("button", {
                  class: _vm.isShowMenu
                    ? "nav-mobile-menu nav-close"
                    : "nav-mobile-menu",
                  on: { click: _vm.isShowNav },
                })
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "header-search" }, [
          _c("i", {
            staticClass: "el-icon-search",
            on: {
              click: function ($event) {
                _vm.visible.search = true
              },
            },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [_c("router-view", { key: _vm.key })],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          {
            staticClass: "logo",
            on: {
              click: function ($event) {
                return _vm.toPath("/aicompetions/index")
              },
            },
          },
          [_vm._v("AI Competition")]
        ),
        _c("p", [
          _vm._v("Copyright © 2023 AI Competition.All rights reserved"),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "bottom" }),
      ]),
      _c("SearchMobile", {
        attrs: { visible: _vm.visible.search },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.visible, "search", $event)
          },
          search: _vm.onSearch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "btns" }, [
      _c("img", {
        attrs: { src: require("../assets/home/ai/logo-facebook.png") },
      }),
      _c("img", {
        attrs: { src: require("../assets/home/ai/logo-youtube.png") },
      }),
      _c("img", {
        attrs: { src: require("../assets/home/ai/logo-twitter.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }