
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinUpload from '@/mixins/upload'
import MixinAISeason from '@/mixins/aiSeason'
import { AStoreGet, AStoreGenUpt } from '@/api/developerApi/developer'
import { AStoreAIBuildAdd, AStoreAIBuildPptUpt } from '@/api/developerApi/gamePackage'
import { ProductStatusList, MediumTypeList, PlatformList } from '@/config/developer'
import { DeveloperModule } from '@/store/modules/developer'
import { ElForm } from 'element-ui/types/form'
import { validateUrl, validateBackspace } from '@/utils/validator'
import { RegUrlLax } from '@/utils/regs'
import { getFileName, splitFileName, DocFileTypeList, VidFileTypeList } from '@/utils/file'
import { transArrayToMapByKey } from '@/utils/array'

const MaxLenName = 24
let keyCnt = 1
@Component({
  name: 'CompStoreGeneral',
})
class CompStoreGeneral extends mixins(MixinUpload, MixinAISeason) {
  public validateUrl = validateUrl
  public ProductStatusList = ProductStatusList
  public MediumTypeList = MediumTypeList
  public PlatformList = PlatformList
  public DocAndVidTypeList = ([] as string[]).concat(DocFileTypeList, VidFileTypeList)
  // public AITypeList = AITypeList
  // private AITrackKeyMap = transArrayToMapByKey(AITrackList, 'key')
  // private AITrackNameMap = transArrayToMapByKey(AITrackList, 'name')
  public uploadUrl = ''
  public form = {
    productName: '',
    productBrief: '',
    productDetail: '',
    developer: '',
    publisher: '',
    productStatus: '',
    pubdate: '',
    webUrl: '',
    mediums: [
      {
        id: keyCnt++,
        mediumType: '',
        mediumUrl: '',
      }
    ],
    platforms: [] as number[],
    aiType: '',
    aiPpt: '',
    aiPptName: '',
    aiTrack: [] as string[],
  }
  public aiTrackList: any = []
  public fileList: any = []
  public rules = {
    productName: [
      { required: true, message: '请输入作品名称', trigger: 'change', },
      { max: MaxLenName, message: '超过最大长度：' + MaxLenName, trigger: 'change' },
      { validator: validateBackspace.bind(this), trigger: 'change' },
    ],
    platforms: [
      { required: true, message: '请选择平台类型', trigger: 'blur', },
    ],
    webUrl: [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (!this.isWebType) callback();
          if (value === '') callback('请输入网页地址');
          else if (!RegUrlLax.test(value)) callback('网页地址格式错误');
          else callback();
        }, trigger: 'blur',
      },
    ],
    aiType: [
      { required: true, message: '请选择作品类别', trigger: 'blur', },
    ],
    aiTrack: [
      { required: true, message: '请选择报名赛道', trigger: 'blur', },
    ],
    aiPpt: [
      { required: true, message: '请上传创作说明', trigger: 'blur', },
    ],
    productDetail: [
      { max: 2000, message: '超过最大长度：2000', trigger: 'change', },
    ]
  }
  public loading = {
    info: false,
    save: false,
    upload: false,
  }
  private appId = this.$route.query.appId as string

  // get devModule() {
  //   return this.$store.state.developer
  // }
  // get appData() {
  //   return this.devModule.appData
  // }
  get storeInfo() {
    return this.devModule.storeData.info
  }
  get aiBuildInfo() {
    return this.devModule.aiBuild
  }
  get isWebType() {
    return this.form.platforms.includes(2) || this.form.platforms.includes(5)
  }
  // get appStage() {
  //   let stage = '' + (this.appData.stage || 0)
  //   return Object.keys(AITypeListMap).includes(stage) ? stage : 0
  // }
  // get isCurStage() {
  //   return this.appStage == this.devModule.aiSeason.period
  // }
  // get AITypeList() {
  //   return AITypeListMap[this.appStage]
  // }
  // get AITrackKeyMap() {
  //   return transArrayToMapByKey(this.AITypeList, 'key')
  // }
  // get AITrackNameMap() {
  //   return transArrayToMapByKey(AITrackListMap[this.appStage], 'name')
  // }

  @Watch('$route.query.appId', { immediate: true })
  onAppIdChanged(val: any) {
    if (val) {
      this.appId = val
      this.getStoreData()
    }
  }
  @Watch('form.aiType', { immediate: true })
  onFormAiTypeChanged(val: any) {
    let list
    let curType = this.AITypeList.find(item => item.key === this.form.aiType)
    if (!curType) list = []
    else {
      list = curType.tracks.map(item => {
        let track = this.AITrackKeyMap[item]
        if (!track) return undefined
        return track
      })
      // 如果之前有选择，用之前的，否则设置为默认
      if (val == this.aiBuildInfo.type && this.aiBuildInfo.tracks.length) {
        this.form.aiTrack = this.aiBuildInfo.tracks.map(key => this.AITrackKeyMap[key] && this.AITrackKeyMap[key].name)
      }
      else {
        let defaultTrack = list.find(item => item.key === curType.key)
        this.form.aiTrack = defaultTrack ? [defaultTrack.name] : []
      }
    }
    this.aiTrackList = list
    this.aiBuildInfo.type
  }

  mounted() {

  }


  public onAddRow() {
    this.form.mediums.push({
      id: keyCnt++,
      mediumType: '',
      mediumUrl: '',
    })
  }
  public onCancel() {
    this.reset()
    this.$message.success("已重置")
  }
  public async submitForm() {
    this.$report('dev/store/general/save', 'click', { appid: this.appId })
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate(async (valid: boolean) => {
      if (!valid) return false
      this.loading.save = true
      try {
        await this.updateStore()
        await this.updateAi()
        this.loading.save = false
        this.$emit('changeTab', 'media')
      }
      catch (e) {
        console.error(e)
        this.loading.save = false
      }
    })
  }
  private updateStore() {
    return new Promise((resolve, reject) => {
      const param = {
        productName: this.form.productName,
        productDetail: this.form.productDetail,
        developer: this.form.developer,
        publisher: this.form.publisher,
        productStatus: +this.form.productStatus,
        pubdate: this.form.pubdate,
        mediums: this.form.mediums.filter(item => item.mediumType !== '' && item.mediumUrl !== '').map(item => {
          return {
            mediumType: item.mediumType,
            mediumUrl: item.mediumUrl,
          }
        }),
        summary: this.form.productBrief,
        platformer: this.form.platforms,
        weburl: this.form.webUrl,
      }
      AStoreGenUpt(this.appId, param).then(() => {
        this.$message.success(this.$t('tips.succ.edit') as string)
        DeveloperModule.MAGetDevStore(this.appId)
        resolve(null)
      }).catch(err => {
        console.error(err)
        this.$message.error(this.$t('tips.fail.edit') as string)
        reject()
      })
    })
  }
  // AI数据上传
  private updateAi() {
    return new Promise((resolve, reject) => {
      // 判断是否需要create
      if (!this.aiBuildInfo.created) {
        const aiParam = {
          type: this.form.aiType,
          ppt: `${this.form.aiPpt}|${this.form.aiPptName}`,
          package: '',
          packgename: '',
          tracks: this.form.aiTrack.map(name => this.AITrackNameMap[name] && this.AITrackNameMap[name].key)
        }
        AStoreAIBuildAdd(this.appId, aiParam).then(() => {
          // this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAIBuild(this.appId)
          resolve(null)
        }).catch(err => {
          console.error(err)
          this.$message.error(this.$t('tips.fail.edit') as string)
          reject()
        })
      }
      else {
        const aiParam = {
          type: this.form.aiType,
          ppt: `${this.form.aiPpt}|${this.form.aiPptName}`,
          tracks: this.form.aiTrack.map(name => this.AITrackNameMap[name] && this.AITrackNameMap[name].key)
        }
        AStoreAIBuildPptUpt(this.appId, aiParam).then(() => {
          // this.$message.success(this.$t('tips.succ.edit') as string)
          DeveloperModule.MAGetDevAIBuild(this.appId)
          resolve(null)
        }).catch(err => {
          console.error(err)
          this.$message.error(this.$t('tips.fail.edit') as string)
          reject()
        })
      }
    })
  }
  public onDelMedium(index) {
    this.form.mediums.splice(index, 1)
    if (this.form.mediums.length === 0) {
      keyCnt = 1
      this.form.mediums.push({
        id: keyCnt++,
        mediumType: '',
        mediumUrl: '',
      })
    }
  }

  private async getStoreData() {
    this.loading.info = true
    if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    if (!this.devModule.init.aiBuild) await DeveloperModule.MAGetDevAIBuild(this.appId)
    this.loading.info = false
    this.initInfo()
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  private initInfo() {
    this.form.productName = this.storeInfo.productName
    this.form.productDetail = this.storeInfo.productDetail
    this.form.developer = this.storeInfo.developer
    this.form.publisher = this.storeInfo.publisher
    this.form.productStatus = this.storeInfo.productStatus
    this.form.pubdate = this.storeInfo.pubdate
    let mediums: any = [].concat(this.storeInfo.mediums || [])
    if (mediums.length < MediumTypeList.length - 1) {
      mediums.push({
        mediumType: '',
        mediumUrl: '',
      })
    }
    this.form.mediums = mediums.map(item => {
      item.id = keyCnt++
      return item
    })
    this.form.productBrief = this.storeInfo.summary
    this.form.platforms = this.storeInfo.platformer || []
    this.form.webUrl = this.storeInfo.weburl

    this.form.aiType = this.aiBuildInfo.type
    let aiPptFile = (this.aiBuildInfo.ppt || '').split('|')
    this.form.aiPpt = aiPptFile[0] || ''
    this.form.aiPptName = aiPptFile[1] || ''
    this.form.aiTrack = this.aiBuildInfo.tracks.map(key => this.AITrackKeyMap[key] && this.AITrackKeyMap[key].name)
    // fileList特殊处理，判断是否有修改，不触发动画
    if (this.form.aiPpt) {
      if (this.fileList.length && this.fileList[0].name === (this.form.aiPptName || this.form.aiPpt) && this.fileList[0].url === this.form.aiPpt) return
      this.fileList = [
        {
          name: this.form.aiPptName || this.form.aiPpt,
          url: this.form.aiPpt
        }
      ]
    }
    else {
      if (this.fileList.length === 0) return
      this.fileList = []
    }
  }
  private reset() {
    (this.$refs.formRef as ElForm)?.clearValidate()
    keyCnt = 1
    this.initInfo()
  }


  public onUploadSuccess(res, file) {
    this.loading.upload = false
    let fileName = getFileName(res.url) as string
    this.form.aiPpt = fileName
    this.form.aiPptName = file.name
    this.fileList = [
      {
        name: this.form.aiPptName,
        url: this.form.aiPpt
      }
    ]
  }
  public onUploadError(error, file) {
    this.$message.error("上传失败 " + error)
    this.loading.upload = false
    if (!this.fileList.length) this.fileList = []
  }
  public beforeUpload(file) {
    const { name, ext = '' } = splitFileName(file.name)
    const extLimit = this.DocAndVidTypeList
    if (!extLimit.includes(ext)) {
      this.$message.error('格式错误，只支持' + extLimit.join() + '格式')
      this.fileList = []
      return false
    }
    this.loading.upload = true
    return true
  }
  public onRemove() {
    this.fileList = []
    this.form.aiPpt = ''
    this.form.aiPptName = ''
  }
}
export default CompStoreGeneral
