
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getParam } from '@/utils';
import { UserModule } from '@/store/modules/user'
import { isGameClient, isShippingClient } from '@/utils/env';

@Component({
  name: 'OauthRedirect',
  components: {},
})
class OauthRedirect extends Vue {
  public isValid = true;
  public error = ''
  public errorDesc = ''

  beforeMount() {
    // 存在错误，显示
    let error = getParam('error') || '', errorDesc = getParam('error_description') || ''
    if (error) {
      this.isValid = false
      this.error = decodeURIComponent(error)
      this.errorDesc = decodeURIComponent(errorDesc)
      return
    }

    let code = getParam('code'), state = getParam('state')
    if (!code || !state) {
      this.isValid = false
      this.$message.error("缺少code或state参数")
      return
    }

    console.log("code", code)
    console.log("state", state)
    // 存储code state，拉起时传入
    UserModule.MSaveOauth({ code, state })
    window.oasis?.loginSuccess(`code=${code}&state=${state}`)
    console.log("call loginSuccess")

    if (isGameClient && !isShippingClient) {
      this.$message.info(`已IOA登录完成, code: ${code} state: ${state}`)
    }
    else {
      setTimeout(() => {
        this.$router.replace('/')
      }, 1000)
    }
  }
}
export default OauthRedirect
