
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

const DefaultUrl = require('../../assets/developer/icon-app-default.png')
@Component({
  name: "Component-UploadPicture",
  components: {},
})
class ComponentUploadPicture extends Vue {

  @Prop({ type: String, required: true, default: DefaultUrl }) private src   // 图片url
  @Prop({ type: Number, required: false, default: 3000 }) private intervalTime   // 轮询时间/ms

  public picUrl = DefaultUrl
  private checkInt = 0

  @Watch('src', { immediate: true })
  onSrcChanged(val: string) {
    this.checkInt = setInterval(this.startCheckImg(), this.intervalTime)
  }

  mounted() {

  }

  private startCheckImg() {
    this.checkImgExists(this.src).then(() => {
      if (this.checkInt) clearInterval(this.checkInt)
      this.picUrl = this.src
      this.$emit('success')
    }).catch((err) => {
      console.error(err)
      this.picUrl = DefaultUrl
      this.$emit('error')
    })
    return this.startCheckImg
  }

  private checkImgExists(url) {
    return new Promise((resolve, reject) => {
      let imgEl = new Image()
      imgEl.onload = resolve
      imgEl.onerror = reject
      imgEl.src = url
    })
  }

}
export default ComponentUploadPicture
