
import { Component, Vue } from 'vue-property-decorator'
import { OpenDomain } from '@/utils/env'
import { getDocumentUrl } from '@/utils'
import { DeveloperModule } from '@/store/modules/developer';

@Component({
  name: 'AICompetion',
})
class AICompetion extends Vue{
  public headerCountdown = false;
  public days = 0;
  public isMobile = false
  public ruleMenuList = [{name: '游戏作品', active: true},
  {name: 'AI工具作品', active: false},{name: '视觉创意作品', active: false}]
  public ruleActive = 0
  public isShowCount = false

  get developerModule() {
    return this.$store.state.developer;
  }

  created() {
    this.initDevModule();
    window.scrollTo(0,0);
    // @ts-ignore
    this.isMobile =  this.$parent.getMode() === 'h5' ? true : false;
  }
  mounted() {
    const navDOM :any = document.getElementById('nav-warps');
    navDOM.scrollLeft = 0
  }

  public toPath(url: string) {
    window.open(`${OpenDomain}${url}`)
  }

  public goToDocument() {
    let docUrl = getDocumentUrl()
    window.open(`${OpenDomain}/idocs?uri=${encodeURIComponent(docUrl)}`)
  }

  public changeData(status: boolean) {
    //父路由传参改变倒计时状态
    this.headerCountdown = status;
  }

  public async initDevModule() {
    if (!DeveloperModule.init.aiSeason) await DeveloperModule.MAGetAISeason();
    this.days = this.$store.state.developer.aiSeason.leave;
    this.isShowCount = this.$store.state.developer.aiSeason.displayleave;
  }
  public changeRule(name: string, index: number) {
    this.ruleActive = index;
    this.ruleMenuList.forEach((item, index) =>{
      if (item.name === name) {
        this.ruleMenuList[index].active = true
      } else {
        this.ruleMenuList[index].active = false
      }
    })
  }
}

export default AICompetion;

