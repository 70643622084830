var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gate-editor", attrs: { id: "gateEditor" } },
    [
      _c("Editor", {
        ref: "tinyEditor",
        attrs: {
          "api-key": _vm.tinyApiKey,
          init: _vm.editorConfig,
          "tag-name": "div",
          height: _vm.height,
        },
        model: {
          value: _vm.editorData,
          callback: function ($$v) {
            _vm.editorData = $$v
          },
          expression: "editorData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }