
import { Component, Vue, Watch } from 'vue-property-decorator'
import StoreMedia from './component/StoreMedia.vue'
import productSet from './component/productSet.vue'
import productInfo from './component/productInfo.vue'

// Component.registerHooks(['beforeRouteLeave'])
@Component({
  name: 'DeveloperAppStore',
  components: {
    StoreMedia,
    productSet,
    productInfo,
  }
})
class DeveloperAppStore extends Vue {
  public tabs = {
    store: 'set'
  }

  @Watch('$route.query.appId', { immediate: true })
  onRouteQueryAppIdChanged(val: string) {
    if (val) this.tabs.store = 'set'
  }
  @Watch('tabs.store')
  onTabInfoChanged(val: string) {
    let path = this.$router.history.current.path, query = this.$router.history.current.query
    let newQuery = JSON.parse(JSON.stringify(query))
    newQuery.tabs = val
    this.$router.push({ path, query: newQuery })
  }

  // beforeRouteLeave(to, from, next) {
  //   if (/\/error\//.test(to.path)) return false
  //   next(false)
  //   setTimeout(() => {
  //     this.$confirm('系统可能不会保存您所做的更改，是否离开？', {
  //       confirmButtonText: '是',
  //       cancelButtonText: '否',
  //       type: 'warning'
  //     }).then(() => {
  //       next()
  //     })
  //   })
  // }
  
  mounted() {
    if (this.$route.query.tab) this.tab.store = this.$route.query.tab
  }

}
export default DeveloperAppStore
