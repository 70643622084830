var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "article-option" },
    [
      _c(
        "el-form",
        {
          ref: "optionForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Article Category", prop: "lore_essay_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "please select article category" },
                  model: {
                    value: _vm.form.lore_essay_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "lore_essay_type", $$v)
                    },
                    expression: "form.lore_essay_type",
                  },
                },
                _vm._l(_vm.ArticleCategoryOptions, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Article Tags", prop: "label" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    filterable: "",
                    "allow-create": "",
                    "multiple-limit": 5,
                    "default-first-option": "",
                    placeholder: "Separate tags with spaces, up to 5 tags",
                  },
                  model: {
                    value: _vm.form.label,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "label", $$v)
                    },
                    expression: "form.label",
                  },
                },
                _vm._l(_vm.ArticleTagOptions, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Add Cover", prop: "cover" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    "list-type": "picture-card",
                    action: "https://jsonplaceholder.typicode.com/posts/",
                    "on-remove": _vm.handleRemove,
                    multiple: false,
                    "on-change": _vm.uploadCover,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Recommended AI Tool", prop: "tools_id" } },
            [
              _c("el-button", { attrs: { icon: "el-icon-plus", plain: "" } }, [
                _vm._v("Add AI Tool"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Relavent Content", prop: "connects" } },
            [
              _c("el-button", { attrs: { icon: "el-icon-plus", plain: "" } }, [
                _vm._v("Add Content"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }