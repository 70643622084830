
import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'layoutBreadcrumbUser',
  components: {},
})
class layoutBreadcrumbUser extends Vue {

  private isScreenfull = false
  private disabledI18n = 'zh-cn'
  private disabledSize = 'large'
  private DefaultAvatar = 'https://image.guangzi.qq.com/uploadfiles/oasis/avatar_default.jpg'

  mounted() {
    console.log('userInfo:', this.userInfo);
  }
  // 获取用户信息 vuex
  get userInfo() {
    if (!this.$store.state.user.stateInit){
      UserModule.GTGetUserInfo();
    }
    return <any>this.$store.state.user.userInfo;
  }
  get avatarUrl() {
    if (!this.userInfo || !this.userInfo.nickname) return this.DefaultAvatar
    return `https://dayu.woa.com/avatars/${this.userInfo.nickname}/profile.jpg`
  }

  // 下拉菜单点击时
  public onHandleCommandClick = (path: string) => {
    if (path === 'logOut') {
      this.$msgbox({
        closeOnClickModal: false,
        closeOnPressEscape: false,
        title: this.$t('common.tips') as string,
        message: this.$t('common.logoutTips') as string,
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok') as string,
        cancelButtonText: this.$t('common.cancel') as string,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = this.$t('common.logouting') as string;
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 700);
          } else {
            done();
          }
        },
      }).then(async () => {
        // Local.clear(); // 清除缓存/token等
        UserModule.MALogout();
        // this.$message.success(this.$t('tips.succ.login') as string);
        setTimeout(() => {
          window.location.href = '/account/login'; // 去登录页
        }, 500);
      }).catch(() => { });
    } else {
      this.$router.push(path);
    }
  };
}
export default layoutBreadcrumbUser
