var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "app-item",
      class: { active: _vm.queryAppid == _vm.appInfo.appId },
      on: { click: _vm.changeApp },
    },
    [
      _c("span", { attrs: { title: _vm.mainTitle } }, [
        _vm._v(_vm._s(_vm.mainTitle)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }