var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-guide-about" }, [
    _c(
      "div",
      { staticClass: "gate-guide-contain" },
      [
        _c("guideTitle", {
          attrs: { title: _vm.$t("views.guide.aboutUs.title"), hasMore: false },
        }),
        _c(
          "div",
          { staticClass: "gate-guide-about-box" },
          [
            _c("div", { staticClass: "first-info" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("views.guide.aboutUs.introTitle1"))),
              ]),
              _c("div", { staticClass: "greenStroke" }),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.$t("views.guide.aboutUs.introContent1"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "introduction",
                  style: { backgroundImage: "url(" + _vm.introAIImg + ") " },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content" },
                    _vm._l(
                      _vm.$t("views.guide.aboutUs.stations"),
                      function (item, index) {
                        return _c("div", { key: index, staticClass: "intro" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("div", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.label)),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]
              ),
            ]),
            _c("guideIntroduction", {
              attrs: {
                title: _vm.$t("views.guide.aboutUs.introTitle2"),
                content: _vm.$t("views.guide.aboutUs.introContent2"),
                iconPath: _vm.introKnowledgeImg,
                isReserve: true,
              },
            }),
            _c("guideIntroduction", {
              attrs: {
                title: _vm.$t("views.guide.aboutUs.introTitle3"),
                content: _vm.$t("views.guide.aboutUs.introContent3"),
                iconPath: _vm.introGameImg,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }