
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AOauthLogin } from '@/api/oauth';
import { Local } from '@/utils/storage';
import { getParam } from '@/utils';
import { SessionKey } from '@/config';
import { isGameClient, isShippingClient } from '@/utils/env';

@Component({
  name: 'OauthLogin',
  components: {},
})
class OauthLogin extends Vue {
  public loading = {
    auth: false,
  }
  private loginChallenge = ''
  private process = {
    init: false,
  }

  beforeMount() {
    this.process.init = false;
    let loginChallenge = getParam('login_challenge')
    if (!loginChallenge) {
      this.$message.error("缺少授权参数")
      return
    }
    this.loginChallenge = loginChallenge
    let session = Local.get(SessionKey)
    if (!session) {
      let conf: any = { path: '/account/login', query: { redirect: '/oauth/login', type: 'oauth', params: JSON.stringify({ login_challenge: loginChallenge }) } }
      // 内测包，自动触发ioa登录
      // if (isGameClient && !isShippingClient) {
      //   conf.query.channel = 'ioa'
      // }
      this.$router.replace(conf)
    }
    else {
      this.process.init = true
      // 0701修改：自动授权
      this.onAuthorize(true)
    }
  }
  mounted() {

  }

  public onAuthorize(status: boolean) {
    if (!status) {
      this.$message.info("拒绝授权，回调客户端")
      window.oasis?.loginFail("Reject")
      return
    }
    const param = {
      "remember": true,
      "remember_for": 3600,
      "login": status
    }
    AOauthLogin(param, this.loginChallenge).then((res: any) => {
      let data = res.data || {}
      if (data.code) {
        this.$message.error("授权失败：" + data.message)
        return
      }
      let redirectTo = data.redirect_to || ''
      location.href = redirectTo
      // console.log("login data", data)
      // setTimeout(() => {
      //   location.href = redirectTo
      // }, 10000)
    }).catch(() => {
      this.$message.error("授权失败")
    })
  }
}
export default OauthLogin
