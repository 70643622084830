
import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ElForm } from 'element-ui/types/form'
import MixinUpload from '@/mixins/upload'
import MixinAISeason from '@/mixins/aiSeason'
import { AAppList, AAppAdd, AAppDel } from '@/api/developerApi/app'
import { AStoreAIBuildAuditGet } from '@/api/developerApi/audit'
import Dialog from '@/components/dialog/index.vue'
import { getDocumentUrl } from '@/utils'
import { DeveloperModule } from '@/store/modules/developer'
import { RoutesModule } from '@/store/modules/routes'
import { validateAppName } from '@/utils/validator'

@Component({
  name: 'DeveloperApps',
  components: {
    Dialog,
  },
})
class DeveloperApps extends mixins(MixinUpload, MixinAISeason) {
  // public appList = []
  public form = {
    appName: '',
  }
  public rules = {
    appName: [
      {
        required: true,
        validator: validateAppName.bind(this),
        trigger: 'change',
      },
    ],
  }
  public visible = {
    add: false,
  }
  public loading = {
    list: false,
    add: false,
  }
  public appAuditMap: any = {}

  get userInfo() {
    return this.$store.state.user.userInfo
  }
  get devModule() {
    return this.$store.state.developer
  }
  // get isDeveloper() {
  //   return this.devModule.info.developer_id && this.devModule.info.status == "Success"
  // }
  get session() {
    return this.$store.state.user.session
  }
  get appList() {
    return this.devModule.apps || []
  }

  @Watch('devModule.init.apps', { immediate: true })
  onInitAppsChanged(val: boolean) {
    this.loading.list = !val
  }
  @Watch('appList', { immediate: true })
  onAppsListChanged(val) {
    this.appAuditMap = {}
    val.forEach(item => this.getAuditInfo(item.appId))
  }

  created() {
    RoutesModule.MASetNavList([{ path: "/application", meta: { title: "作品列表" } }]);
    this.initData()

    console.log(this.devModule.aiSeason)
  }

  beforeMount() {
    const userInfo = this.userInfo;
    this.form = Object.assign(this.form, {
      country: userInfo.country,
      email: userInfo.email,
      nickname: userInfo.nickname,
    })
  }

  public onAddAppUnopen() {
    this.$message.info('暂未开放上传作品通道，敬请期待')
  }
  public onAddApp() {
    // if (this.appList.length >= 1) {
    //   this.$message.info('只能添加一个作品')
    //   return
    // }
    this.$report('dev/app/add', 'click')
    this.visible.add = true
  }
  public onDelApp(app) {
    this.$confirm('此操作将删除App, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      AAppDel(app.appId).then(async (data: any) => {
        this.$message.success(this.$t('tips.succ.del') as string)
        await DeveloperModule.MAGetDevApps();
        // this.appList = this.devModule.apps || []
      }).catch(err => {
        console.error(err)
        this.$message.error(this.$t('tips.fail.del') as string)
      })
    }).catch(() => { })
  }
  public goToApp(app) {
    this.$router.push({ name: 'developerAppInfo', query: { appId: app.appId } })
  }

  public onBeforeSubmit() {
    let formEl = this.$refs.formRef
    if (!formEl) return false
    return formEl.validate()
  }

  public onSubmitAdd() {
    this.$report('dev/app/addsubmit', 'click')
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate((valid: boolean) => {
      if (!valid) return false
      this.loading.add = true
      const param = {
        appName: this.form.appName,
        status: '',
        cover: '',
      }
      AAppAdd(param).then(async (data: any) => {
        this.visible.add = false
        this.$message.success(this.$t('tips.succ.add') as string)
        await DeveloperModule.MAGetDevApps()
        this.reset()
        this.loading.add = false
      }).catch(err => {
        console.error(err)
        this.loading.add = false
        this.$message.error(this.$t('tips.fail.add') as string)
      })
    })
  }

  public goToDocument() {
    let docUrl = getDocumentUrl()
    window.open(`/idocs?uri=${encodeURIComponent(docUrl)}`)
  }

  private async initData() {
    // if (!this.isDeveloper) return
    // this.loading.list = true
    // if (!this.devModule.init.apps) await DeveloperModule.MAGetDevApps();
    // this.appList = this.devModule.apps || []
    // this.loading.list = false
  }
  private getAuditInfo(appId, refresh = false) {
    if (this.appAuditMap[appId]) {
      if (this.appAuditMap[appId].pending || !refresh) return
    }
    this.$set(this.appAuditMap, appId, {
      pending: true,
      data: null
    })
    // console.log("getAuditInfo", appId)
    AStoreAIBuildAuditGet(appId).then(({ data }) => {
      this.appAuditMap[appId].pending = false
      this.appAuditMap[appId].data = data
    }).catch(e => {
      console.error(e)
      delete this.appAuditMap[appId]
    })
  }
  private reset() {
    if (!this.$refs.formRef) return
    this.form.appName = ''
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm).clearValidate()
    })
  }

  // private getAppList() {
  //   // if (!this.isDeveloper) return
  //   this.loading.list = true
  //   const param = {
  //     developerId: this.devModule.info.developer_id,
  //     offset: 0,
  //     limit: 20,
  //   }
  //   AAppList(param).then((res: any) => {
  //     this.appList = res.data || []
  //     this.loading.list = false
  //   }).catch(err => {
  //     console.error(err)
  //     this.$message.error(this.$t('tips.fail.get') as string)
  //     this.loading.list = false
  //   })
  // }

}
export default DeveloperApps
