var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-guide-nav" }, [
    _c("div", { staticClass: "gate-guide-contain" }, [
      _c("div", { staticClass: "gate-guide-logo" }),
      _c(
        "div",
        { staticClass: "gate-lang" },
        _vm._l(_vm.langTypes, function (item) {
          return _c(
            "div",
            {
              key: item.key,
              class: { "lang-option": true, active: _vm.lang === item.key },
              on: {
                click: function ($event) {
                  return _vm.setLang(item)
                },
              },
            },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }