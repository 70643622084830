import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api/basesys`;
console.log('guide base url:', BaseUrl)

// 邮箱收集
export async function sendEmailCollect(email) {
  return request({
    url: `${BaseUrl}/emailcollect`,
    method: 'POST',
    data: {
      email
    }
  });
}
// 上报
export async function RPCCommReportEvent() {
  return request({
    url: `${BaseUrl}/RPCCommReportEvent`,
    method: 'POST',
  });
}