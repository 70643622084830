
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'layoutBlank',
  components: {},
})
class layoutBlank extends Vue {
  get key() {
    return this.$route.path;
  }
 }
export default layoutBlank
