<template>
  <div class="gate-guide-banner">
    <guideNav @setLang="setLang"/>
    <div class="gate-guide-contain">
      <div class="gate-email-contain">
        <div class="gate-tips">
          {{ $t('views.guide.banner.tips1') }}
        </div>
        <div class="gate-slogan font-while">
          {{ $t('views.guide.banner.slogan1') }}
        </div>
        <div class="gate-slogan font-green">
          <div v-if="isChinese">开启</div>
          <div class="scrollAnimation" >
            <div :class="{'animation': true, 'zhText': isChinese}">
              <div class="sloganText" v-for="(item, index) in $t('views.guide.banner.scrollSlogan')" :key="index">{{ item }}</div>
              <div class="sloganText">{{ $t('views.guide.banner.scrollSlogan')[0] }}</div>
            </div>
          </div>
          <div v-if="isChinese">之门</div>
        </div>
        <div class="gate-slogan font-while" v-if="!isChinese">
          {{ $t('views.guide.banner.slogan3') }}
        </div>
        <div class="gate-email-box">
          <div class="email-tips">{{ $t('views.guide.banner.emailTips') }}</div>
          <el-input :placeholder="$t('views.guide.banner.placeholder')" style="width: 460px" v-model="email">
            <template slot="append">
              <el-button type="success" @click="submitEmail">{{ $t('views.guide.banner.subscribe') }}</el-button>
            </template>
          </el-input>
        </div>
        <div class="follow-row">
          <div class="follow-btn">
            <div class="icon icon-discord"></div>
            <div class="title">{{ $t('views.guide.banner.discord') }}</div>
          </div>
          <div class="follow-btn">
            <div class="icon icon-wechat"></div>
            <div class="title">{{ $t('views.guide.banner.wechat') }}</div>
          </div>
        </div>
        <div class="style-spacer"></div>
      </div>
    </div>
    <div class="gate-guide-readme">
      <div class="gate-readme">
        {{ $t('views.guide.banner.tips2') }}
      </div>
    </div>
  </div>
</template>

<script>
import guideNav from './guideNav.vue';
import { sendEmailCollect } from '@/api/guide';
export default {
  name: 'guide-banner',
  components: { guideNav },
  data() {
    return {
      email: '',
      lang: 'en'
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    submitEmail() {
      if(this.validateEmail(this.email)){
        this.reportEmail();
      } else {
        this.$message.warning(this.$t('views.guide.emailCheckFail'));
      }
    },
    async reportEmail() {
      const result = await sendEmailCollect(this.email);
      if (result.code === 0) {
        this.$message.success(this.$t('views.guide.success'));
      }
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    setLang(lang){
      this.lang = lang;
    }
  },
  computed: {
    isChinese() {
      return this.lang !== 'en';
    }
  }
}

</script>

<style lang="scss" scoped>
.gate-guide-banner {
  margin: 0;
  height: 85px;
  padding: 0;
  width: 100%;
  height: 990px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  background: url("../../assets/guide/guide_banner.png") no-repeat;
  background-size: cover;
  align-items: center;
  .gate-guide-contain{
    width: 1200px;
    height: calc(100% - 140px);
    justify-content: start;
    align-items: center;
    display: flex;
    .gate-email-contain{
      .gate-tips{
        font-size: 16px;
        line-height: 1.5;
        color: #f3faf7;
        opacity: 0.8;
      }
      .gate-slogan{
        font-size: 56px;
        font-weight: 700;
        line-height: 120%;
        display: flex;
        justify-content: start;
        align-items: center;
        div{
          font-weight: 700;
        }
      }
      .scrollAnimation{
        height: 60px;
        overflow: hidden;
        .zhText{
          text-align: center;
        }
        .animation{
          animation: scroll 14s linear infinite;
          .sloganText{
            line-height: 1.1;
          }
        }
        @keyframes scroll {
          0% {
            transform: translateY(0); /* 停留1秒钟 */
          }
          5%{
            transform: translateY(-20%);
          }
          20%{
            transform: translateY(-20%);
          }
          25%{
            transform: translateY(-40%);
          }
          40%{
            transform: translateY(-40%);
          }
          45%{
            transform: translateY(-60%);
          }
          60%{
            transform: translateY(-60%);
          }
          65%{
            transform: translateY(-80%);
          }
          80% {
            transform: translateY(-80%); /* 滚动到顶部 */
          }
          100% {
            transform: translateY(-80%); /* 滚动到顶部 */
          }
        }
      }
      .font-green{
        color: #31C48D;
      }
      .font-while{
        color: #F3FAF7;
      }
      
      .gate-email-box{
        width: 500px;
        padding: 16px 0;
        .email-tips{
          color:  #F3FAF7;
          // 
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          opacity: 0.65;
          padding-bottom: 5px;
        }
        .el-button{
          background-color: #31C48D;
          color: #fff;
        }
      }
      .follow-row{
        display: flex;
        .follow-btn{
          padding: 8px 16px;
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 2px;
          border: 1px solid #6B7280;
          margin-right: 8px;
          .icon{
            width: 24px;
            height: 24px;
          }
          .icon-wechat{
            background: url('../../assets/guide/icon_wechat.png') no-repeat;
            background-size: 100% 100%;
          }
          .icon-discord{
            background: url('../../assets/guide/icon_discord.png') no-repeat;
            background-size: 100% 100%;
          }
          .title{
            text-align: center;
            // 
            font-size: 14px;
            font-style: normal;
            color: #fff;
            font-weight: 400;
            line-height: 150%; 
          }
        }
      }
    }
    .style-spacer{
      height: 118px;
      width: 120px;
    }
  }
  .gate-guide-readme{
    width: 1200px;
    height: 84px;
    min-height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    .gate-readme{
      font-size: 12px;
      color: #fff;
      opacity: 0.65;
      text-align: center;
      // 
      font-style: normal;
      font-weight: 400;
      line-height: 150%; 
      width: 1170px;
    }
  }
}
</style>
