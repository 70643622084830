var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.gameList,
          expression: "loading.gameList",
        },
      ],
      staticClass: "container",
      attrs: {
        "element-loading-background": "rgba(0, 0, 0, 0.5)",
        id: "view-shop-info",
      },
    },
    [
      _c("div", { staticClass: "header-bg" }),
      _c("div", { staticClass: "contentbox" }, [
        _c("div", {
          class: _vm.headerScroll ? "header header-scroll" : "header",
        }),
        _c("div", { staticClass: "title-name-score" }, [
          _c("h1", { staticClass: "name" }, [
            _vm._v(
              _vm._s(
                (_vm.appData &&
                  _vm.appData.store &&
                  _vm.appData.store.info &&
                  _vm.appData.store.info.productName) ||
                  ""
              ) + " "
            ),
          ]),
          _c("div", { staticClass: "score-total" }, [
            _vm.commentScore
              ? _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.commentScore.toFixed(1))),
                ])
              : _c("span", { staticClass: "num-zero" }, [_vm._v("暂无评分")]),
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("el-rate", {
                  staticClass: "rate score-total-rete",
                  attrs: {
                    colors: _vm.scoreColors,
                    "void-color": _vm.scoreVoidColor,
                    "disabled-void-color": _vm.scoreVoidColor,
                    disabled: "",
                    "allow-half": true,
                  },
                  model: {
                    value: _vm.commentScore,
                    callback: function ($$v) {
                      _vm.commentScore = $$v
                    },
                    expression: "commentScore",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "section-box" }, [
          _c("section", { staticClass: "left" }, [
            _c(
              "div",
              { staticClass: "mediabox" },
              [
                _vm.curCover
                  ? [
                      _c("div", { staticClass: "cover" }, [
                        _vm.curCover.type == "video"
                          ? _c("div", { staticClass: "cover" }, [
                              _c("video", {
                                ref: "coverMedia",
                                attrs: {
                                  src: _vm._f("fCosBase")(_vm.curCover.url),
                                  poster: _vm._f("fCosPoster")(
                                    _vm.curCover.url
                                  ),
                                  autoplay: "",
                                  controls: "",
                                  controlslist: "nodownload",
                                  "webkit-playsinline": "",
                                  "x5-playsinline": "",
                                  playsinline: "",
                                },
                              }),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "cover preview",
                                attrs: { title: "双击查看大图" },
                                on: { dblclick: _vm.showPreview },
                              },
                              [
                                _c("img", {
                                  ref: "coverMedia",
                                  attrs: {
                                    src: _vm._f("fCosBase")(_vm.curCover.url),
                                  },
                                }),
                              ]
                            ),
                        _c(
                          "div",
                          {
                            staticClass: "in-btn-left in-btn",
                            on: { click: _vm.onPre },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/shop/in_left.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "in-btn-right in-btn",
                            on: { click: _vm.onNext },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/shop/in_right.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  : _c("div", { staticClass: "cover" }),
                _c(
                  "div",
                  { ref: "covercontrolbox", staticClass: "covercontrol" },
                  [
                    _c(
                      "div",
                      { staticClass: "btn-left", on: { click: _vm.onPre } },
                      [_c("i", { staticClass: "el-icon-arrow-left" })]
                    ),
                    _c(
                      "el-carousel",
                      {
                        ref: "covercontrol",
                        staticClass: "coverlist",
                        attrs: {
                          arrow: "always",
                          autoplay: false,
                          loop: true,
                          "indicator-position": "none",
                        },
                      },
                      _vm._l(_vm.splitMediaList, function (cover, i) {
                        return _c("el-carousel-item", { key: i }, [
                          _c(
                            "div",
                            { staticClass: "cover-box" },
                            _vm._l(cover, function (item, j) {
                              return _c(
                                "div",
                                {
                                  key: j,
                                  staticClass: "cover-thumb",
                                  class: {
                                    active:
                                      _vm.curCover &&
                                      _vm.curCover.id == item.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onChangeCover(item)
                                    },
                                  },
                                },
                                [
                                  item.type == "video"
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm._f("fCosGif")(item.url),
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: _vm._f("fCosBase")(item.url),
                                        },
                                      }),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      }),
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-right", on: { click: _vm.onNext } },
                      [_c("i", { staticClass: "el-icon-arrow-right" })]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.toTop,
                    expression: "toTop",
                  },
                ],
                staticClass: "second-top",
              },
              [
                _c("h1", { staticClass: "name" }, [
                  _vm._v(
                    _vm._s(
                      (_vm.appData &&
                        _vm.appData.store &&
                        _vm.appData.store.info &&
                        _vm.appData.store.info.productName) ||
                        ""
                    )
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "bottom-btn",
                    staticStyle: { "align-items": "center" },
                  },
                  [
                    _vm.isWeb
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.download,
                                expression: "loading.download",
                              },
                            ],
                            staticClass: "btn-free btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleToPlay },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("免费开玩"),
                            ]),
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "btn-download btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.downloadGame },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("下载资源"),
                            ]),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "info" },
              [
                _c(
                  "h2",
                  {
                    staticClass: "info-title",
                    staticStyle: { "margin-top": ".2rem" },
                  },
                  [_vm._v("详情介绍")]
                ),
                _c(
                  "div",
                  {
                    ref: "gamedetail",
                    staticClass: "gamedetail",
                    class: {
                      showMore: _vm.moreText.show,
                      activeMore: _vm.moreText.show && _vm.moreText.active,
                    },
                  },
                  [
                    _vm.appData &&
                    _vm.appData.store &&
                    _vm.appData.store.info &&
                    _vm.appData.store.info.productDetail
                      ? _c("p", {
                          staticClass: "gamedetail-text",
                          domProps: {
                            innerHTML: _vm._s(
                              (_vm.appData &&
                                _vm.appData.store &&
                                _vm.appData.store.info &&
                                _vm.appData.store.info.productDetail) ||
                                ""
                            ),
                          },
                        })
                      : _c("p", [_vm._v("暂无介绍")]),
                    _vm.moreText.show
                      ? [
                          !_vm.moreText.active
                            ? _c(
                                "div",
                                {
                                  staticClass: "gamedetail-more",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowMore(true)
                                    },
                                  },
                                },
                                [_vm._m(0)]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "gamedetail-more",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowMore(false)
                                    },
                                  },
                                },
                                [_vm._m(1)]
                              ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("h2", { staticClass: "info-title" }, [_vm._v("评分及评测")]),
                _c("Comment", {
                  attrs: { appId: _vm.appId },
                  on: {
                    avgScore: (score) => {
                      _vm.avgScore = score
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("section", { staticClass: "right" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "logo" }, [
                _c("img", {
                  attrs: {
                    src: _vm._f("fCosBase")(_vm.appData.store.medias.cover),
                    alt: "",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "title",
                    attrs: {
                      title:
                        (_vm.appData &&
                          _vm.appData.store &&
                          _vm.appData.store.info &&
                          _vm.appData.store.info.summary) ||
                        "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.appData &&
                            _vm.appData.store &&
                            _vm.appData.store.info &&
                            _vm.appData.store.info.summary) ||
                            ""
                        )
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  { staticClass: "list" },
                  [
                    _c("span", { staticClass: "pro" }, [_vm._v("用户测评")]),
                    _c("span", { staticClass: "re" }, [
                      _vm._v(
                        _vm._s(_vm.commentScore.toFixed(1)) +
                          " (" +
                          _vm._s(_vm.commentlen) +
                          "个评分)"
                      ),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "pro" }, [_vm._v("发行日期")]),
                    _c("span", { staticClass: "re" }, [
                      _vm._v(_vm._s(_vm.createTime)),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "pro" }, [_vm._v("开发者名称")]),
                    _c("span", { staticClass: "re" }, [
                      _vm._v(
                        _vm._s(
                          (_vm.appData &&
                            _vm.appData.store &&
                            _vm.appData.store.info &&
                            _vm.appData.store.info.developer) ||
                            ""
                        )
                      ),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "pro" }, [_vm._v("类型")]),
                    _c("span", { staticClass: "re" }, [
                      _vm._v(_vm._s(_vm.type)),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "pro" }, [_vm._v("平台")]),
                    _vm._l(_vm.platformList, function (val, i) {
                      return _c("span", { key: i, staticClass: "re" }, [
                        _c("img", {
                          staticStyle: {
                            width: ".16rem",
                            height: ".16rem",
                            "margin-right": ".032rem",
                          },
                          attrs: {
                            src: require(`../../assets/shop/${val}.svg`),
                            alt: "",
                          },
                        }),
                      ])
                    }),
                    _c("br"),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "bottom-btn" },
                  [
                    _vm.isWeb
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.download,
                                expression: "loading.download",
                              },
                            ],
                            staticClass: "btn-free btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleToPlay },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("免费开玩"),
                            ]),
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "btn-download btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.downloadGame },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("下载资源"),
                            ]),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "bottom-color" }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toTop,
                expression: "toTop",
              },
            ],
            staticClass: "btn-top",
            on: { click: _vm.scrollToTop },
          },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/shop/button_top.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
      _c(
        "Dialog",
        {
          attrs: {
            visible: _vm.visible.openClient,
            titleText: "已有Oasis客户端？",
            okText: "打开AI Competition",
            okClass: "dialog-btn-open",
            cancelText: "下载AI Competition",
            cancelClass: "dialog-btn-download",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "openClient", $event)
            },
            ok: _vm.onOpen,
            cancel: _vm.downloadClient,
          },
        },
        [
          _c("p", [
            _vm._v(
              "如果你已经拥有Oasis客户端，可使用“打开”按钮；否则需要下载Oasis客户端进行畅玩。"
            ),
          ]),
        ]
      ),
      _c("Preview", {
        attrs: {
          visible: _vm.visible.preview,
          list: _vm.previewList,
          curIndex: _vm.curCoverIndex,
        },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.visible, "preview", $event)
          },
          pre: _vm.onPre,
          next: _vm.onNext,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("展开全部"),
      _c("i", { staticClass: "el-icon-arrow-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("收起全部"),
      _c("i", { staticClass: "el-icon-arrow-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }