import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n, { getLanguage } from '@/lang';
import store from './store';
import { AReport } from '@/api/report';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/prism.css';
import '@/css/prism.js';
import '@/theme/index.scss';
import 'cherry-markdown/dist/cherry-markdown.css';

Vue.use(ElementUI, {
  // size: AppModule.size, // Set element-ui default size
  i18n: (key: string, value: string) => i18n.t(key, value),
});
Vue.prototype.$tk = function (key) {
  // 获取语言字段名，如zh-cn name;en name_eng
  const lang = getLanguage();
  const suffixMap = {
    'zh-cn': '',
    en: '_en',
  };
  return `${key}${suffixMap[lang] || ''}`;
};
// 上报
Vue.prototype.$report = AReport;

// 增加自定义ai-disabled状态
Vue.directive('aiDisabled', {
  componentUpdated(el, binding) {
    if (!binding.value) {
      el.setAttribute('title', '非当前赛季不可操作');
      // el.setAttribute('disabled', 'disabled');
    }
    else {
      el.setAttribute('title', '');
      // el.setAttribute('disabled', '');
    }
  },
});

document.querySelector('html')?.setAttribute("class", "env-main");
console.log(router);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    // Vue.prototype.$bus = this
  },
}).$mount('#app');
