
import User from '@/layout/navBars/breadcrumb/user.vue';
import Header from './component/header.vue'
import { OpenDomain } from '@/utils/env'
import { getDocumentUrl } from '@/utils'

export default {
  components:{ User, Header },
  data() {
    return {
      menuList: [
        {name: '首页', select: true, path: 'index', href: '#index'},
        // {name: '赛事资料', select: false, path: 'information', href: ''},
        {name: '赛事进程', select: false, path: 'index', href: '#process'},
        {name: '提交规范', select: false, path: 'index', href: '#rule'},
        {name: '全部作品', select: false, path: 'allworks', href: ''},
        {name: '评选结果', select: false, path: 'result', href: ''},
      ],
      menuStatus: false,
      isMobile: false,
      isShowMenu: false,
      isIos: false,
      isIndex:false,
    }
  },
  created() {
    this.initMenu()
    // this.preloadimages('upload-btn-hover.png')
    this.isMobile =  this.getMode() === 'h5' ? true : false;
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) this.isIos = true
  },
  watch:{
    '$route':{
      handler(val){
        //商城共用头部底部判断是否显示banner
        this.isIndex = val.path.indexOf('shop/') !== -1 ? false : true;
      },
      immediate:true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.menuEvent)
    if (this.isMobile) this.mobileBottom()
    //ios关闭自动播放
    // if (this.isMobile && this.isIos) {
    //   const videoDOM:any = document.getElementById('video1');
    //   videoDOM.autoplay = false;
    // }
  },
  methods: {
    menuPush(index: number, path: string, href: string) {
      this.menuList.forEach((item: any, i: number) =>{
        item.select = false;
        if (index === i) {
          item.select = true;
        }
      })
      if (path) {
        if (path === 'information') {
          //跳转文档地址
          this.goToDocument();
        } else {
          if (path === 'index') {
            if (this.$route.name !== 'aiCompetionsIndex') {
              this.$router.push(`/aicompetions/index`)
              setTimeout(() => {
                this.getDocumentScroll(href);
              }, 300)
            } else {
              if (href) this.getDocumentScroll(href);
            }
          } else {
            this.$router.push(`/aicompetions/${path}`)
          }
        }
      }
    },
    initMenu() {
      if (this.$route.name === 'allWorks') {
        this.menuPush(3)
      } else if (this.$route.name === 'result') {
        this.menuPush(4)
      } else {
        this.menuPush(0)
      }
    },
    openPath(url: string) {
      window.open(`${OpenDomain}${url}`)
    },
    goToDocument() {
      let docUrl = getDocumentUrl()
      window.open(`${OpenDomain}/idocs?uri=${encodeURIComponent(docUrl)}`)
    },
    menuEvent() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const process:any = document.getElementById('process');
      const rule: any = document.getElementById('rule');

      if (this.isMobile) {
        this.menuStatus = scrollTop >= 260 ? true : false;
      } else {
        this.menuStatus = scrollTop >= 700 ? true : false;
      }
      if (this.$route.name === 'aiCompetionsIndex') {
        if (this.menuStatus) {
          this.$refs.child.changeData(true);
        } else {
          this.$refs.child.changeData(false);
        }
        let processsTop = process.offsetTop - 100;
        if (this.isMobile) {
          processsTop = processsTop - 410
        }

        if (scrollTop > processsTop) {
          this.menuPush(1)
        }  
        if (scrollTop > (rule.offsetTop - 100)) {
          this.menuPush(2)
        }
        if (scrollTop < processsTop) {
          this.menuPush(0)
        }
      }
    },
    getDocumentScroll(id: string) {
      if (id) {
        id = id.substring(1);
        if (id === 'index') {
          window.scrollTo(0, 0)
        } else {
          const dom :any = document.getElementById(id);
          let scrollTop = dom.offsetTop;
          //移动端锚点处理
          if (id === 'process' && this.isMobile) {
            scrollTop = scrollTop - 440;
          } else if (id === 'rule' && this.isMobile) {
            scrollTop = scrollTop + 110;
          }
          window.scrollTo(0, scrollTop)
        }
      }
    },
    toPath(path: string) {
      this.$router.push(path);
      this.initMenu();
    },
    // preloadimages(src: string) {
    //   let img = new Image();
    //   img.src = `/src/assets/aiCompetion/${src}`
    // }
    getMode() {
      const html = document.documentElement;
      const cliWidth = html.clientWidth;
      return cliWidth <= 750 ? 'h5': 'pc';
    },
    isShowNav() {
      this.isShowMenu = !this.isShowMenu;
    },
    mobileBottom() {
      const fixBottom:any = document.getElementById('fix-bottom');
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        const bottomHeight = window.innerHeight - document.documentElement.clientHeight;
        console.log('bottomHeight', bottomHeight)
        console.log('fixBottom.style.marginBottom', fixBottom.style.marginBottom)
        fixBottom.style.marginBottom = -1 * bottomHeight + 'px';
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.menuEvent)
  }
}
