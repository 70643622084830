
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'layoutConsent',
  components: {},
})
class layoutConsent extends Vue { 
  get key() {
    return this.$route.path;
  }
}
export default layoutConsent
