var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "shopPage", staticClass: "shop-home" }, [
    _c("div", { staticClass: "header-bg" }),
    _c("div", { staticClass: "shop-home-nav" }, [
      _vm.curCover
        ? _c("div", { staticClass: "shop-home-nav-box" }, [
            _c("img", {
              staticStyle: { "border-radius": ".12rem", cursor: "pointer" },
              attrs: { src: _vm.curCover.images, alt: "" },
              on: {
                click: function ($event) {
                  return _vm.handleToInfo(_vm.curCover)
                },
              },
            }),
            _c("div", { staticClass: "shop-home-nav-box-title" }, [
              _c("p", [_vm._v(_vm._s(_vm.curCover.advertise_name))]),
              _c("span", [_vm._v(_vm._s(_vm.curCover.description))]),
            ]),
            _c(
              "div",
              { staticClass: "shop-home-nav-box-cover" },
              [
                _c(
                  "el-carousel",
                  {
                    staticClass: "shop-home-nav-box-cover-list",
                    attrs: {
                      direction: "vertical",
                      arrow: "always",
                      "indicator-position": "none",
                      autoplay: false,
                      loop: true,
                    },
                  },
                  [
                    _c("el-carousel-item", [
                      _c(
                        "div",
                        {
                          ref: "curImages",
                          staticClass: "shop-home-nav-box-cover-list-box",
                          on: {
                            mouseenter: _vm.handleEnter,
                            mouseleave: _vm.hanldeLeave,
                          },
                        },
                        _vm._l(_vm.advertiseInfo, function (info, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass:
                                "shop-home-nav-box-cover-list-box-thumb",
                              class: _vm.curCoverIndex == i ? "active" : "",
                              on: {
                                click: function ($event) {
                                  _vm.curCoverIndex = i
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: { src: info.images, alt: "" },
                              }),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.curCoverIndex == i,
                                    expression: "curCoverIndex == i",
                                  },
                                ],
                                staticClass: "mask",
                                attrs: {
                                  src: require("../../assets/shop/mask_bottom.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "bottom-mask" }),
            _c("div", { staticClass: "right-mask" }),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { ref: "shopIndexContent", staticClass: "shop-home-content" },
      [
        _vm._m(0),
        _c("singleModule", {
          attrs: { propsList: _vm.gatherInfo1[0], size: 3 },
        }),
        _c("div", { staticClass: "title-bar" }, [
          _c("div", { staticClass: "title" }, [_vm._v("热门资源")]),
          _c("div", { staticClass: "btns" }, [
            _c("span", {
              class:
                _vm.curIid == 0
                  ? "left-unable"
                  : _vm.hover.left
                  ? "left-btn-hover"
                  : "left-btn",
              on: {
                click: function ($event) {
                  return _vm.handleSwitch("pre")
                },
                mouseenter: function ($event) {
                  _vm.hover.left = true
                },
                mouseleave: function ($event) {
                  _vm.hover.left = false
                },
              },
            }),
            _c("span", {
              class:
                _vm.curIid == _vm.gatherInfo2.length - 1
                  ? "right-unable"
                  : _vm.hover.right
                  ? "right-btn-hover"
                  : "right-btn",
              on: {
                click: function ($event) {
                  return _vm.handleSwitch("next")
                },
                mouseenter: function ($event) {
                  _vm.hover.right = true
                },
                mouseleave: function ($event) {
                  _vm.hover.right = false
                },
              },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "hot-gather" },
          [
            _c(
              "el-carousel",
              {
                ref: "nop",
                staticClass: "hot-gather-list",
                staticStyle: { width: "100%" },
                attrs: { "indicator-position": "outside", autoplay: false },
                on: { change: _vm.handleChange },
              },
              _vm._l(_vm.gatherInfo2, function (list, i) {
                return _c("el-carousel-item", { key: i }, [
                  _c(
                    "div",
                    { class: _vm.curIid == i ? "show" : "hide" },
                    [
                      _c("singleModule", {
                        attrs: { propsList: list, size: list.length },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              1
            ),
          ],
          1
        ),
        _vm._m(1),
        _c("div", { staticClass: "game-filter" }, [
          _c(
            "div",
            { staticClass: "filter-box" },
            [
              _c("h3", [_vm._v("筛选条件")]),
              _c("p", [_vm._v("已应用筛选条件")]),
              _c(
                "div",
                { ref: "tagbox", staticClass: "filter-box-all" },
                _vm._l(_vm.tags, function (tag, index) {
                  return _c("div", { key: index, staticClass: "taglist" }, [
                    _c(
                      "span",
                      {
                        staticClass: "tag",
                        class: _vm.idFilters.includes(tag.id) ? "show" : "hide",
                      },
                      [
                        _c("span", [_vm._v(_vm._s(tag.label))]),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/shop/close.svg"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClose(tag)
                            },
                          },
                        }),
                      ]
                    ),
                  ])
                }),
                0
              ),
              _c(
                "el-button",
                { staticClass: "reset", on: { click: _vm.handleReset } },
                [_vm._v("重置")]
              ),
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-openeds": _vm.openeds,
                    collapse: false,
                    "collapse-transition": false,
                    "background-color": "transparent",
                    "text-color": "#fff",
                    "active-text-color": "#fff",
                  },
                },
                _vm._l(_vm.menuList, function (val, index) {
                  return _c(
                    "el-submenu",
                    { key: index, attrs: { index: index.toString() } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(val.title)),
                      ]),
                      _vm._l(val.menuList, function (option, i) {
                        return _c(
                          "el-menu-item",
                          { key: i, attrs: { index: option.id.toString() } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheck(option, val.filed)
                                  },
                                },
                                model: {
                                  value: option.check,
                                  callback: function ($$v) {
                                    _vm.$set(option, "check", $$v)
                                  },
                                  expression: "option.check",
                                },
                              },
                              [_vm._v(_vm._s(option.label))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "game-box" }, [
            _c("h3", [_vm._v("所有结果")]),
            _c("div", { staticClass: "game-box-tab" }, [
              _c(
                "ul",
                _vm._l(_vm.gameClassify, function (title, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: _vm.curTid == title.id ? "active" : "",
                      on: {
                        click: function ($event) {
                          _vm.curTid = title.id
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(title.label))]
                  )
                }),
                0
              ),
            ]),
            _c("hr"),
            _c("div", { staticClass: "game-box-list" }, [
              _c(
                "ul",
                _vm._l(_vm.gameList, function (game, index) {
                  return _c(
                    "li",
                    { key: index },
                    [_c("GameIntro", { attrs: { info: game } })],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "more",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                  },
                },
                [
                  _vm.more && _vm.gameList.length
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              _vm.more = false
                            },
                          },
                        },
                        [
                          _vm._v("查看更多"),
                          _c("i", { staticClass: "el-icon-arrow-down" }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "bottom-color" }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.toTop,
            expression: "toTop",
          },
        ],
        staticClass: "btn-top",
        on: { click: _vm.scrollToTop },
      },
      [
        _c("img", {
          attrs: { src: require("../../assets/shop/button_top.svg"), alt: "" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-bar" }, [
      _c("div", { staticClass: "title" }, [_vm._v("最近上新")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-bar" }, [
      _c("div", { staticClass: "title" }, [_vm._v("游戏筛选")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }