
import { Component, Vue, Watch } from 'vue-property-decorator'
import { isClient } from '@/utils/env'
import { setLanguage } from '@/lang'
import { LangList } from '@/config'
import { UserModule } from '@/store/modules/user'
import { APubBannerGet } from '@/api/game'
import { AITrackListMap } from '@/config/developer';

const DefaultAvatar = 'https://image.guangzi.qq.com/uploadfiles/oasis/avatar_default.jpg'
@Component({
  name: 'HomeBrowser',
  components: {},
})
class HomeBrowser extends Vue {
  public AITrackList = AITrackListMap[2]
  public active = {
    lang: 'zh-cn'
  }
  public langList = LangList
  public loading = {
    gameList: false,
    download: false
  }
  public visible = {
    toTop: false,
  }
  public bannerList = [
    {
      id: 1,
      img: require('../../assets/home/ai/banner.jpg'),
      url: 'https://km.woa.com/group/LightspeedExplorationLab/articles/show/536372'
    },
  ]
  public curTab = 'all'
  public condition = {
    name: ''
  }
  public rawGameList: any = []
  public fitGameList: any = []

  get session() {
    return this.$store.state.user.session
  }
  get userInfo() {
    return this.$store.state.user.userInfo
  }
  get avatarUrl() {
    if (!this.userInfo || !this.userInfo.nickname) return DefaultAvatar
    return `https://dayu.woa.com/avatars/${this.userInfo.nickname}/profile.jpg`
  }

  beforeCreate() {
    if (isClient) this.$router.replace('/home/client')
  }
  created() {
    this.getBanner()
  }
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }

  public onScroll(e) {
    this.visible.toTop = document.documentElement.scrollTop >= window.screen.height / 2
  }
  public scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  public changeTab(key: string) {
    this.curTab = key
  }
  public onSearch() {
    console.log("search", this.condition.name)
    this.filterGameList()
  }
  public filterGameList() {
    this.fitGameList = this.rawGameList.filter(item => {
      if (this.condition.name == '') return true
      return new RegExp(this.condition.name, 'i').test(item.appName)
    })
  }

  public goToBanner(banner) {
    if (!banner.url) return
    window.open(banner.url)
  }
  public onLogoClick() {
    this.$router.push('/home')
  }
  public onChangeLang(lang: any) {
    this.active.lang = lang.key
    this.$i18n.locale = lang.key
    setLanguage(lang.key)
  }
  public goToLogin() {
    this.$router.push({ name: 'accountLogin', params: { type: "" } })
  }
  public goToGame(game) {
    this.$router.push({ name: 'gameInfo', query: { appId: game.appId } })
  }

  private getBanner() {
    this.loading.gameList = true
    APubBannerGet().then((res: any) => {
      let list = res.data || []
      this.rawGameList = list
      this.condition.name = ''
      this.filterGameList()
      this.loading.gameList = false
    }).catch((err) => {
      console.error(err)
      this.$message.error("获取banner列表失败")
      this.loading.gameList = false
    })
  }


  public logout() {
    this.$msgbox({
      closeOnClickModal: false,
      closeOnPressEscape: false,
      title: this.$t('common.tips') as string,
      message: this.$t('common.logoutTips') as string,
      showCancelButton: true,
      confirmButtonText: this.$t('common.ok') as string,
      cancelButtonText: this.$t('common.cancel') as string,
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = this.$t('common.logouting') as string
          setTimeout(() => {
            done()
            setTimeout(() => {
              instance.confirmButtonLoading = false;
            }, 300)
          }, 700)
        } else {
          done()
        }
      },
    }).then(async () => {
      UserModule.MALogout()
      setTimeout(() => {
        window.location.href = '/'
      }, 500)
    }).catch(() => { })
  }

}
export default HomeBrowser
