var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-info-client" } },
    [
      _c("h2", [_vm._v("客户端")]),
      _c("p", { staticClass: "title-desc" }, [
        _vm._v(
          "接入OasisSDK的作品，需添加客户端配置才可正常使用Oasis账户服务 "
        ),
        _c(
          "a",
          { attrs: { href: "javascript:;" }, on: { click: _vm.goToDocument } },
          [_vm._v("查看文档")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-head" },
        [
          _c("span", { staticClass: "sum" }, [
            _vm._v(_vm._s(_vm.list.length) + "个客户端"),
          ]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "aiDisabled",
                  rawName: "v-aiDisabled",
                  value: _vm.isCurStage,
                  expression: "isCurStage",
                },
              ],
              staticClass: "btn-add",
              attrs: { type: "primary", disabled: !_vm.isCurStage },
              on: {
                click: function ($event) {
                  return _vm.showEditDrawer(null)
                },
              },
            },
            [_vm._v("添加客户端")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.list,
              expression: "loading.list",
            },
          ],
          staticClass: "table-list",
          attrs: { data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "客户端名称与ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [_vm._v(_vm._s(scope.row.name))]),
                    _c("p", [_vm._v(_vm._s(scope.row.id))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "secret", label: "客户端秘钥", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(new Array(10).fill("*").join(""))),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-document-copy btn-copy",
                      on: {
                        click: function ($event) {
                          return _vm.onCopySecret(scope.row.secret, $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "urls", label: "重定向URL" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.urls.filter(
                      (url) => url != _vm.officialRedirectUrl
                    ),
                    function (url, i) {
                      return _c("p", { key: i }, [_vm._v(_vm._s(url))])
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "ipWhitelist", label: "IP白名单" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    (scope.row.ipWhitelist || "").split(";"),
                    function (ip, i) {
                      return _c("p", { key: i }, [_vm._v(_vm._s(ip))])
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operation",
              "class-name": "col-operation",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.isCurStage
                      ? [
                          _c(
                            "i",
                            {
                              staticClass: "btn-opt btn-edit",
                              on: {
                                click: function ($event) {
                                  return _vm.showEditDrawer(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-popconfirm",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.delete,
                                  expression: "loading.delete",
                                },
                              ],
                              attrs: { title: "是否确认删除此条记录？" },
                              on: {
                                confirm: function ($event) {
                                  return _vm.onDelClient(scope.row)
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "btn-opt btn-delete",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          ),
                        ]
                      : _c("span", [_vm._v("非当前赛季不可操作")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            visible: _vm.visible.editDrawer,
            titleText: _vm.isEdit ? "编辑客户端" : "添加客户端",
            okText: "确认",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "editDrawer", $event)
            },
            open: _vm.onDrawerOpen,
            close: _vm.onDrawerClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  staticClass: "comp-form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    size: "large",
                    "label-width": "100px",
                  },
                },
                [
                  _vm.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "id", label: "客户端ID" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "id", $$v)
                              },
                              expression: "form.id",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "客户端名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "secret", label: "客户端密钥" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.secret,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "secret", $$v)
                                },
                                expression: "form.secret",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-document-copy btn-copy",
                                attrs: { slot: "append" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCopySecret(
                                      _vm.curClient.secret,
                                      $event
                                    )
                                  },
                                },
                                slot: "append",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "form-item-urls" },
                    [
                      _vm._l(_vm.form.urls, function (item, i) {
                        return _c(
                          "el-form-item",
                          {
                            key: i,
                            staticClass: "form-item-url",
                            attrs: {
                              prop: `urls[${i}]`,
                              rules: [
                                { validator: _vm.validateUrl, trigger: "blur" },
                              ],
                              label: i == 0 ? "重定向URL" : "",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入内容",
                                autocomplete: "off",
                                disabled:
                                  _vm.form.urls[i] == _vm.officialRedirectUrl,
                              },
                              model: {
                                value: _vm.form.urls[i],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.urls, i, $$v)
                                },
                                expression: "form.urls[i]",
                              },
                            }),
                            (_vm.form.urls.length <= 1 && i == 0) ||
                            _vm.form.urls[i] == _vm.officialRedirectUrl
                              ? _c("i", { staticClass: "btn-del-url disabled" })
                              : _c("i", {
                                  staticClass: "btn-del-url",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDelUrl(i)
                                    },
                                  },
                                }),
                          ],
                          1
                        )
                      }),
                      _c("div", { staticClass: "drawer-row" }, [
                        _c(
                          "span",
                          {
                            staticClass: "drawer-btn-add",
                            on: { click: _vm.onAddRow },
                          },
                          [_vm._v("+ 添加URL")]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "ipWhitelist", label: "IP白名单" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入IP，使用;分隔",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.ipWhitelist,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ipWhitelist", $$v)
                          },
                          expression: "form.ipWhitelist",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-cancel",
              attrs: { slot: "btn-cancel", type: "info" },
              on: { click: _vm.onCancel },
              slot: "btn-cancel",
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-ok",
              attrs: { slot: "btn-ok", type: "primary" },
              on: { click: _vm.submitForm },
              slot: "btn-ok",
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }