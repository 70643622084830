
import { Component, Vue, Watch } from 'vue-property-decorator'
import Aside from '@/layout/component/aside.vue';
import Header from '@/layout/component/header.vue';
import Main from '@/layout/component/main.vue';
@Component({
  name: 'layoutIndex',
  components: { Aside, Header, Main },
})
class layoutIndex extends Vue { }
export default layoutIndex
