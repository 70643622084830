var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "page-developer-app" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.list,
              expression: "loading.list",
            },
          ],
        },
        [
          _vm.appList.length
            ? [
                _c(
                  "div",
                  { staticClass: "app-section" },
                  [
                    _vm.isInUploadTime
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.add,
                                expression: "loading.add",
                              },
                            ],
                            staticClass: "btn-add top",
                            attrs: { type: "primary" },
                            on: { click: _vm.onAddApp },
                          },
                          [_vm._v("创建作品")]
                        )
                      : _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.add,
                                expression: "loading.add",
                              },
                            ],
                            staticClass: "btn-add top is-disabled",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$message.info("提交已截止")
                              },
                            },
                          },
                          [_vm._v("创建作品")]
                        ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.add,
                            expression: "loading.add",
                          },
                        ],
                        staticClass: "app-list",
                      },
                      _vm._l(_vm.appList, function (item) {
                        return _c(
                          "div",
                          { key: item.appId, staticClass: "app-list-item" },
                          [
                            item.cover
                              ? _c("img", {
                                  attrs: {
                                    src: _vm._f("fCosBase")(item.cover),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToApp(item)
                                    },
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("../../assets/developer/icon-app-default.png"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToApp(item)
                                    },
                                  },
                                }),
                            _c(
                              "p",
                              {
                                staticClass: "name",
                                attrs: { title: item.appName },
                              },
                              [_vm._v(_vm._s(item.appName))]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "appid",
                                attrs: { title: `APPID：${item.appId}` },
                              },
                              [_vm._v("APPID：" + _vm._s(item.appId))]
                            ),
                            _vm.appAuditMap[item.appId] &&
                            _vm.appAuditMap[item.appId].data
                              ? [
                                  _vm.appAuditMap[item.appId].data.status ==
                                    "" ||
                                  _vm.appAuditMap[item.appId].data.status ==
                                    "pendding"
                                    ? _c(
                                        "p",
                                        { staticClass: "status pendding" },
                                        [_vm._v("审核中")]
                                      )
                                    : _vm.appAuditMap[item.appId].data.status ==
                                      "pass"
                                    ? _c("p", { staticClass: "status pass" }, [
                                        _vm._v("已发布"),
                                      ])
                                    : _vm.appAuditMap[item.appId].data.status ==
                                      "reject"
                                    ? _c(
                                        "p",
                                        { staticClass: "status reject" },
                                        [
                                          _vm._v("审核不通过 "),
                                          _vm.appAuditMap[item.appId].data
                                            .comments &&
                                          _vm.appAuditMap[item.appId].data
                                            .comments.length
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: `审核意见：${_vm.appAuditMap[
                                                      item.appId
                                                    ].data.comments
                                                      .filter(
                                                        (item) => item !== ""
                                                      )
                                                      .join(";")}`,
                                                    placement: "bottom",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-info",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            : !_vm.loading.list
            ? [
                _c(
                  "div",
                  { staticClass: "app-section blank" },
                  [
                    _c("i", { staticClass: "logo-blank" }),
                    _c("p", [_vm._v("暂无作品")]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.add,
                            expression: "loading.add",
                          },
                        ],
                        staticClass: "btn-add",
                        attrs: { type: "primary" },
                        on: { click: _vm.onAddApp },
                      },
                      [_vm._v("创建作品")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "Dialog",
        {
          staticClass: "dialog-add",
          attrs: {
            visible: _vm.visible.add,
            beforeOk: _vm.onBeforeSubmit,
            titleText: `创建作品`,
            okText: "确认",
            okClass: "dialog-btn-connect",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "add", $event)
            },
            ok: _vm.onSubmitAdd,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              staticClass: "general-form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                size: "large",
                "label-width": "80px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "appName", label: "作品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "作品名称", autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onSubmitAdd.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.form.appName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appName", $$v)
                      },
                      expression: "form.appName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }