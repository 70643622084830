import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

export async function ADeployList() {
  return request({
    url: `${BaseUrl}/app/getdeploys`,
    method: 'GET',
  });
}
export async function ADeployAdd(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/deploy/create?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function ADeployUpt(appid: string, deployid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/deploy/update?appid=${appid}&deployid=${deployid}`,
    method: 'POST',
    data,
  });
}
export async function ADeployDel(appid: string, deployid: string) {
  return request({
    url: `${BaseUrl}/app/deploy/delete?appid=${appid}&deployid=${deployid}`,
    method: 'POST',
  });
}