import request from '@/utils/request';
import { fetchFlow } from '@/api/flow';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/proxy`;
// USED
// 无需flowId
export function AWhoami(token: string) {
  return request({
    url: `${BaseUrl}/sessions/whoami`,
    headers: {
      'x-head': JSON.stringify({ 'X-Session-Token': token }),
    },
    // noSessionToken: true,
  });
}
export function AThirdCallBack(channel: string, query: string) {
  return request({
    url: `${BaseUrl}/self-service/methods/oidc/callback/${channel}?${query}`,
    method: 'GET',
    // noSessionToken: true,
  });
}
export function ASettingsLink(channel: string) {
  return request({
    url: `${BaseUrl}/self-service/settings?link=${channel}`,
    method: 'GET',
    noSessionToken: true,
  });
}
export function ASettingsApi() {
  return request({
    url: `${BaseUrl}/self-service/settings/api`,
    method: 'GET',
  });
}
export function ASessionDel() {
  return request({
    url: `${BaseUrl}/sessions`,
    method: 'DELETE',
  });
}

export function ALogout(token) {
  return request({
    url: `${BaseUrl}/self-service/logout/api`,
    method: 'DELETE',
    data: {
      session_token: token,
    },
  });
}


export async function ALogin(data: object, flowId?: string) {
  if (!flowId) flowId = await fetchFlow('FLogin');
  if (!flowId) return Promise.reject(new Error('获取flowId失败'));
  return request({
    url: `${BaseUrl}/self-service/login?flow=${flowId}`,
    method: 'POST',
    data,
    noSessionToken: true,
  });
}
export async function ARegister(data: object, flowId = '') {
  if (!flowId) flowId = await fetchFlow('FRegister');
  if (!flowId) return Promise.reject(new Error('获取flowId失败'));
  return request({
    url: `${BaseUrl}/self-service/registration?flow=${flowId}`,
    method: 'POST',
    data,
    noSessionToken: true,
  });
}
export async function ASendVerifyCode(data: object, flowId = '') {
  if (!flowId) flowId = await fetchFlow('FSendVerifyCode');
  if (!flowId) return Promise.reject(new Error('获取flowId失败'));
  return request({
    url: `${BaseUrl}/self-service/registration/send-verify-code?flow=${flowId}`,
    method: 'POST',
    data,
    noSessionToken: true,
  });
}
export async function ARecoveryPost(data: object, flowId = '') {
  if (!flowId) flowId = await fetchFlow('FRecovery');
  if (!flowId) return Promise.reject(new Error('获取flowId失败'));
  return request({
    url: `${BaseUrl}/self-service/recovery?flow=${flowId}`,
    method: 'POST',
    data,
    noSessionToken: true,
  });
}
export function ARecoveryGet(token: string, flowId: string) {
  return request({
    url: `${BaseUrl}/self-service/recovery?flow=${flowId}&token=${token}`,
    method: 'GET',
    noSessionToken: true,
  });
}
export async function ASettingsSave(data: object, flowId = '') {
  if (!flowId) flowId = await fetchFlow('FSettingsSave');
  if (!flowId) return Promise.reject(new Error('获取flowId失败'));
  return request({
    url: `${BaseUrl}/self-service/settings?flow=${flowId}`,
    method: 'POST',
    data,
  });
}
export async function ASettingsUnLink(channel: string, flowId = '') {
  if (!flowId) flowId = await fetchFlow('FSettingsUnLink');
  if (!flowId) return Promise.reject(new Error('获取flowId失败'));
  return request({
    url: `${BaseUrl}/self-service/settings?flow=${flowId}`,
    method: 'POST',
    data: {
      unlink: channel,
    },
    noCookie: true,
  });
}
export function ASettingsFlow(flowId: string) {
  return request({
    url: `${BaseUrl}/self-service/settings/flows?id=${flowId}`,
    method: 'GET',
    noSessionToken: true,
  });
}
