var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "layout-navbars-breadcrumb-index" },
    [
      _vm.navList.length
        ? _c(
            "div",
            { staticClass: "navigator" },
            [
              _vm.navList.length == 1
                ? _c("h1", [_vm._v(_vm._s(_vm.navList[0].meta?.title || ""))])
                : _c(
                    "el-breadcrumb",
                    {
                      staticClass: "navigator",
                      attrs: { "separator-class": "el-icon-arrow-right" },
                    },
                    [
                      _vm._l(_vm.navList.slice(0, -1), function (nav) {
                        return _c(
                          "el-breadcrumb-item",
                          { key: nav.path, attrs: { to: { path: nav.path } } },
                          [_vm._v(_vm._s(nav.meta?.title || "上一级"))]
                        )
                      }),
                      _c("el-breadcrumb-item", [
                        _vm._v(
                          _vm._s(
                            _vm.navList[_vm.navList.length - 1].meta?.title ||
                              "当前层级"
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
            ],
            1
          )
        : _vm._e(),
      _c("User"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }