var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-store-buildai" } },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "com-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "large",
            "label-width": "140px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "package", label: "游戏包/作品集" } },
            [
              _c(
                "Upload",
                {
                  staticClass: "upload-box",
                  attrs: {
                    drag: "",
                    "with-credentials": true,
                    "file-list": _vm.fileList,
                    maxSize: 1024 * 100,
                    "show-file-list": true,
                    accept: _vm.ZipFileTypeList.map((type) => "." + type).join(
                      ","
                    ),
                    "on-remove": _vm.onRemove,
                    disabled: !_vm.isCurStage,
                  },
                  on: {
                    success: _vm.onUploadSuccess,
                    error: _vm.onUploadError,
                    beforeUpload: _vm.beforeUpload,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _c("em", [_vm._v("点击上传")]),
                    _vm._v(" / 拖拽到此区域"),
                  ]),
                ]
              ),
              _c("div", { staticClass: "upload-tips" }, [
                _vm._v("上传游戏/AI工具/艺术作品，请上传压缩文件"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item-btns" },
        [
          _vm.cHasChanged
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "form-btn cancel",
                  attrs: {
                    loading: _vm.loading.save || _vm.loading.upload,
                    disabled: !_vm.isCurStage,
                  },
                  on: { click: _vm.onCancel },
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "aiDisabled",
                  rawName: "v-aiDisabled",
                  value: _vm.isCurStage,
                  expression: "isCurStage",
                },
              ],
              staticClass: "form-btn save",
              attrs: {
                type: "info",
                loading: _vm.loading.save || _vm.loading.upload,
                disabled: !_vm.isCurStage,
              },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存草稿")]
          ),
          false
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn save closed",
                  on: { click: _vm.onPublishClosed },
                },
                [_vm._v("保存并发布")]
              )
            : _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "form-btn save",
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.save || _vm.loading.upload,
                    disabled: !_vm.isCurStage,
                  },
                  on: { click: _vm.onPublish },
                },
                [_vm._v("保存并发布")]
              ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.visible.dupConfirm,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "dupConfirm", $event)
            },
          },
        },
        [
          _c("p", { staticClass: "dialog-content" }, [
            _vm._v("已存在审核版本，是否覆盖？"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      _vm.visible.dupConfirm = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.confirmAudit()
                      _vm.visible.dupConfirm = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }