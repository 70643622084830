import request from '@/utils/request';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/api`;

export async function oasisToken(data: any) {
  return request({
    url: `${BaseUrl}/auth/tokenexchange`,
    method: 'post',
    data
  });
}

export async function verifySession(data: any) {
  return request({
    url: `${BaseUrl}/auth/verifysession`,
    method: 'post',
    data
  });
}

export async function getUserInfo(data: any) {
  return request({
    url: `${BaseUrl}/auth/getuser`,
    method: 'post',
    data
  });
}
