var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-store-general" } },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "comp-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "large",
            "label-width": "100px",
          },
        },
        [
          _c("h2", [_vm._v("作品基本信息")]),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "作品名称", prop: "productName" },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "aiDisabled",
                        rawName: "v-aiDisabled",
                        value: _vm.isCurStage,
                        expression: "isCurStage",
                      },
                    ],
                    attrs: {
                      placeholder: "请输入内容",
                      autocomplete: "off",
                      disabled: !_vm.isCurStage,
                    },
                    model: {
                      value: _vm.form.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productName", $$v)
                      },
                      expression: "form.productName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "游戏简介", prop: "productBrief" },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "aiDisabled",
                        rawName: "v-aiDisabled",
                        value: _vm.isCurStage,
                        expression: "isCurStage",
                      },
                    ],
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 8 },
                      placeholder: "请输入游戏简介",
                      autocomplete: "off",
                      disabled: !_vm.isCurStage,
                    },
                    model: {
                      value: _vm.form.productBrief,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productBrief", $$v)
                      },
                      expression: "form.productBrief",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "详细介绍", prop: "productDetail" },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "aiDisabled",
                        rawName: "v-aiDisabled",
                        value: _vm.isCurStage,
                        expression: "isCurStage",
                      },
                    ],
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 3, maxRows: 10 },
                      placeholder: "请输入详细介绍",
                      autocomplete: "off",
                      disabled: !_vm.isCurStage,
                    },
                    model: {
                      value: _vm.form.productDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productDetail", $$v)
                      },
                      expression: "form.productDetail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "平台类型", prop: "platform" },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      directives: [
                        {
                          name: "aiDisabled",
                          rawName: "v-aiDisabled",
                          value: _vm.isCurStage,
                          expression: "isCurStage",
                        },
                      ],
                      attrs: { disabled: !_vm.isCurStage },
                      model: {
                        value: _vm.form.platforms,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "platforms", $$v)
                        },
                        expression: "form.platforms",
                      },
                    },
                    _vm._l(_vm.PlatformList, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.key, attrs: { label: item.key } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isWebType
            ? _c(
                "el-row",
                { staticClass: "form-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item-block",
                      attrs: { label: "网页地址", prop: "webUrl" },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "aiDisabled",
                            rawName: "v-aiDisabled",
                            value: _vm.isCurStage,
                            expression: "isCurStage",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入游戏网页地址",
                          autocomplete: "off",
                          disabled: !_vm.isCurStage,
                        },
                        model: {
                          value: _vm.form.webUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "webUrl", $$v)
                          },
                          expression: "form.webUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "作品类别", prop: "aiType" },
                },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "aiDisabled",
                          rawName: "v-aiDisabled",
                          value: _vm.isCurStage,
                          expression: "isCurStage",
                        },
                      ],
                      attrs: {
                        placeholder: "请选择",
                        disabled: !_vm.isCurStage,
                      },
                      model: {
                        value: _vm.form.aiType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "aiType", $$v)
                        },
                        expression: "form.aiType",
                      },
                    },
                    _vm._l(_vm.AITypeList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.name, value: item.key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.aiType,
                  expression: "form.aiType",
                },
              ],
              staticClass: "form-row",
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "报名赛道", prop: "aiTrack" },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      directives: [
                        {
                          name: "aiDisabled",
                          rawName: "v-aiDisabled",
                          value: _vm.isCurStage,
                          expression: "isCurStage",
                        },
                      ],
                      attrs: { disabled: !_vm.isCurStage },
                      model: {
                        value: _vm.form.aiTrack,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "aiTrack", $$v)
                        },
                        expression: "form.aiTrack",
                      },
                    },
                    _vm._l(_vm.aiTrackList, function (item) {
                      return _c("el-checkbox", {
                        key: item.key,
                        attrs: {
                          label: item.name,
                          disabled: item.key === _vm.form.aiType,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-block",
                  attrs: { label: "创作说明", prop: "aiPpt" },
                },
                [
                  _c(
                    "Upload",
                    {
                      directives: [
                        {
                          name: "aiDisabled",
                          rawName: "v-aiDisabled",
                          value: _vm.isCurStage,
                          expression: "isCurStage",
                        },
                      ],
                      staticClass: "upload-box",
                      attrs: {
                        drag: "",
                        "with-credentials": true,
                        "file-list": _vm.fileList,
                        maxSize: 1024,
                        "show-file-list": true,
                        accept: _vm.DocAndVidTypeList.map(
                          (type) => "." + type
                        ).join(","),
                        "on-remove": _vm.onRemove,
                        disabled: !_vm.isCurStage,
                      },
                      on: {
                        success: _vm.onUploadSuccess,
                        error: _vm.onUploadError,
                        beforeUpload: _vm.beforeUpload,
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _c("em", [_vm._v("点击上传")]),
                        _vm._v(" / 拖拽到此区域"),
                      ]),
                    ]
                  ),
                  _c("p", { staticClass: "title-desc" }, [
                    _vm._v(
                      "添加一个文档或视频，来具体说明你的创作过程，使用的AI工具，以及其他你想展现的东西"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("h2", { staticClass: "form-title" }, [
            _c("span", [_vm._v("媒体信息")]),
          ]),
          _c("p", { staticClass: "title-desc" }, [
            _vm._v("添加团队或个人的媒体信息作为宣传或联系方式"),
          ]),
          _vm._l(_vm.form.mediums, function (item, i) {
            return _c(
              "el-row",
              { key: item.id, staticClass: "form-row form-medium" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item-block",
                    attrs: {
                      label: `媒体${["一", "二", "三", "四", "五", "六"][i]}`,
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "aiDisabled",
                            rawName: "v-aiDisabled",
                            value: _vm.isCurStage,
                            expression: "isCurStage",
                          },
                        ],
                        attrs: {
                          placeholder: "请选择媒体类别",
                          disabled: !_vm.isCurStage,
                        },
                        model: {
                          value: _vm.form.mediums[i].mediumType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.mediums[i], "mediumType", $$v)
                          },
                          expression: "form.mediums[i].mediumType",
                        },
                      },
                      _vm._l(_vm.MediumTypeList, function (item) {
                        return _c("el-option", {
                          key: item.key,
                          attrs: {
                            label: item[_vm.$tk("name")],
                            value: item.key,
                            disabled: !!_vm.form.mediums.find(
                              (medium) => medium.mediumType === item.key
                            ),
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item-block form-item-link",
                    attrs: {
                      prop: `mediums[${i}].mediumUrl`,
                      rules: [{ validator: _vm.validateUrl, trigger: "blur" }],
                    },
                  },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "aiDisabled",
                          rawName: "v-aiDisabled",
                          value: _vm.isCurStage,
                          expression: "isCurStage",
                        },
                      ],
                      attrs: {
                        placeholder: "请输入链接",
                        autocomplete: "off",
                        disabled: !_vm.isCurStage,
                      },
                      model: {
                        value: _vm.form.mediums[i].mediumUrl,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mediums[i], "mediumUrl", $$v)
                        },
                        expression: "form.mediums[i].mediumUrl",
                      },
                    }),
                  ],
                  1
                ),
                _vm.isCurStage
                  ? _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.delete,
                            expression: "loading.delete",
                          },
                        ],
                        attrs: { title: "是否确认删除此条记录？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.onDelMedium(i)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "btn-del-medium",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm.isCurStage
            ? _c("el-row", { staticClass: "btn-add-box" }, [
                _vm.form.mediums.length < _vm.MediumTypeList.length
                  ? _c(
                      "span",
                      { staticClass: "btn-add", on: { click: _vm.onAddRow } },
                      [_vm._v("+ 新增媒体")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-item-btns" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "aiDisabled",
                  rawName: "v-aiDisabled",
                  value: _vm.isCurStage,
                  expression: "isCurStage",
                },
              ],
              staticClass: "form-btn cancel",
              attrs: {
                loading: _vm.loading.save || _vm.loading.upload,
                disabled: !_vm.isCurStage,
              },
              on: { click: _vm.onCancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "aiDisabled",
                  rawName: "v-aiDisabled",
                  value: _vm.isCurStage,
                  expression: "isCurStage",
                },
              ],
              staticClass: "form-btn save",
              attrs: {
                type: "primary",
                loading: _vm.loading.save || _vm.loading.upload,
                disabled: !_vm.isCurStage,
              },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存并下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }