
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getDocumentUrl } from '@/utils'
import { OpenDomain } from '@/utils/env';
import { RoutesModule } from '@/store/modules/routes'

@Component({
  name: 'DeveloperDoc',
})
class DeveloperDoc extends Vue {
  created() {
    RoutesModule.MASetNavList([{ path: "/document", meta: { title: "文档" } }]);
    // // 新窗口打开
    // let domain = process.env.VUE_APP_OPEN_DOMAIN
    // window.open(`${domain}/book/`)
    // // 跳回上一层
    // this.$nextTick(() => {
    //   this.$router.go(-1)
    // })
  }

  public goToDocument() {
    this.$report('dev/document/oasis', 'click')
    let docUrl = getDocumentUrl()
    window.open(`https://oasisdev-gate.woa.com/idocs?uri=${encodeURIComponent(docUrl)}`)
  }
  public goToAIDoc() {
    this.$report('dev/document/ai', 'click')
    window.open(`https://oasisdev-gate.woa.com/idocs?uri=${encodeURIComponent(`${OpenDomain}/docs/ai/`)}`)
  }
}
export default DeveloperDoc
