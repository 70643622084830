
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'Error404',
  components: {},
})
class Error404 extends Vue {
  public onGoHome() {
    this.$router.push('/')
  }
}
export default Error404
