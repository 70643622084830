
import { Component, Vue, Watch } from 'vue-property-decorator'
import { LangList } from '@/config'
import { isClient } from '@/utils/env';

@Component({
  name: 'layoutAccount',
  components: {},
})
class layoutAccount extends Vue {
  private active = {
    lang: 'zh-cn'
  }
  private langList = LangList

  get key() {
    return this.$route.path;
  }

  public onLogoClick() {
    // this.$router.push(isClient ? '/home/client' : '/home/browser');
  }
  private onChangeLang(lang: any) {
    this.active.lang = lang.key;
    this.$i18n.locale = lang.key;
  }
}
export default layoutAccount
