import { Component, Vue } from 'vue-property-decorator';
import { AITypeListMap, AITrackListMap } from '@/config/developer'
import { transArrayToMapByKey } from '@/utils/array'

@Component({
  name: 'MixinAISeason',
  components: {

  },
})
export default class extends Vue {

  get devModule() {
    return this.$store.state.developer
  }
  get appData() {
    return this.devModule.appData
  }

  get appStage() {
    let stage = '' + (this.appData.stage || 0)
    return Object.keys(AITypeListMap).includes(stage) ? stage : 0
  }
  get isInUploadTime() {
    // 当前赛季且在上传时间段内
    let seasonInfo = this.devModule.aiSeason, curTime = Math.round(+new Date() / 1000)
    return (seasonInfo.startupload <= curTime) && (seasonInfo.endupload > curTime)
  }
  get isCurStage() {
    // 当前赛季且在上传时间段内
    let seasonInfo = this.devModule.aiSeason
    return this.appStage == seasonInfo.period && this.isInUploadTime
  }
  get AITypeList() {
    return AITypeListMap[this.appStage]
  }
  get AITrackKeyMap() {
    return transArrayToMapByKey(this.AITypeList, 'key')
  }
  get AITrackNameMap() {
    return transArrayToMapByKey(AITrackListMap[this.appStage], 'name')
  }


}
