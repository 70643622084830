var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-info-testacc" } },
    [
      _c(
        "section",
        [
          _c("div", { staticClass: "table-head" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "btn-add",
                on: {
                  click: function ($event) {
                    return _vm.showEditDrawer(null)
                  },
                },
              },
              [_vm._v("添加测试账号")]
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.list,
                  expression: "loading.list",
                },
              ],
              staticClass: "table-list",
              attrs: { data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "用户名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "uuid", label: "用户ID" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatTime(scope.row.createAt))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operation",
                  "class-name": "col-operation",
                  label: "操作",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit btn-edit",
                          on: {
                            click: function ($event) {
                              return _vm.showEditDrawer(scope.row)
                            },
                          },
                        }),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.delete,
                                expression: "loading.delete",
                              },
                            ],
                            attrs: { title: "是否确认删除此条记录？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.onDelTestAcc(scope.row)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete btn-delete",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.current.account ? "编辑测试号" : "创建测试号",
            visible: _vm.visible.editDrawer,
            direction: "rtl",
            "show-close": false,
            "custom-class": "drawer-edit",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "editDrawer", $event)
            },
            open: _vm.onDrawerOpen,
            close: _vm.onDrawerClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  staticClass: "comp-form",
                  attrs: { model: _vm.form, rules: _vm.rules, size: "large" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("用户名"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "请输入用户名，长度8~30个字符，只允许字母、数字和下划线",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("新密码"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          autocomplete: "off",
                          type: "password",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isEdit
                    ? _c(
                        "el-button",
                        {
                          staticClass: "drawer-btn-add",
                          attrs: { type: "primary" },
                          on: { click: _vm.onAddAccount },
                        },
                        [_vm._v("添加账户")]
                      )
                    : _vm._e(),
                  _vm.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "passwordSnd" } },
                        [
                          _c("p", { staticClass: "drawer-label" }, [
                            _vm._v("确认新密码"),
                          ]),
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              autocomplete: "off",
                              type: "password",
                            },
                            model: {
                              value: _vm.form.passwordSnd,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "passwordSnd", $$v)
                              },
                              expression: "form.passwordSnd",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "cover" } },
                        [
                          _c("p", { staticClass: "drawer-label" }, [
                            _vm._v("导入列表"),
                          ]),
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.upload,
                                  expression: "loading.upload",
                                },
                              ],
                              staticClass: "upload-cover",
                              attrs: {
                                drag: "",
                                action: "",
                                name: "image",
                                "with-credentials": true,
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "on-change": _vm.onFileChange,
                                "on-error": _vm.onUploadError,
                                accept: ".xls,.xlsx,.csv",
                                "auto-upload": false,
                              },
                            },
                            [
                              _c("div", { staticClass: "el-upload__text" }, [
                                _c("em", [_vm._v("点击上传")]),
                                _vm._v(" / 拖拽到此区域"),
                              ]),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "drawer-template",
                              attrs: {
                                href: "/files/Oasis测试账号模板.xlsx",
                                download: "Oasis测试账号模板.xlsx",
                              },
                            },
                            [_vm._v("下载模板")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isEdit
                    ? _c("div", { staticClass: "account-box" }, [
                        _c("p", { staticClass: "drawer-label" }, [
                          _vm._v(_vm._s(_vm.accList.length) + "个账户"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "account-list" },
                          _vm._l(_vm.accList, function (item, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "account-list-item" },
                              [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _c("i", {
                                  staticClass: "el-icon-close",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDelAddAcc(i)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item-btns" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn save",
                          attrs: { type: "primary", loading: _vm.loading.save },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn cancel",
                          attrs: { type: "default", loading: _vm.loading.save },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.giveup")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h2", [_vm._v("测试账号")]),
      _c("p", { staticClass: "title-desc" }, [
        _vm._v("与真实的测试帐号不同，这些帐号都是虚拟的，仅适用于测试"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }