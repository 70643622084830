var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "shop-comment", attrs: { id: "comp-shop-comment" } },
    [
      _c("div", { staticClass: "shop-comment-total" }, [
        _c("div", { staticClass: "score-info" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm.isPc
                ? [
                    _vm.commentScore.avg
                      ? _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.commentScore.avg.toFixed(1))),
                        ])
                      : _c("span", { staticClass: "num" }, [
                          _vm._v("暂无评分"),
                        ]),
                    _c(
                      "div",
                      { staticClass: "box" },
                      [
                        _c("span", { staticClass: "sum" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.commentList.length) + "条评测"),
                          ]),
                        ]),
                        _c("el-rate", {
                          staticClass: "rate score-total-rete",
                          attrs: {
                            colors: _vm.scoreColors,
                            "void-color": _vm.scoreVoidColor,
                            "disabled-void-color": _vm.scoreVoidColor,
                            disabled: "",
                            "allow-half": true,
                          },
                          model: {
                            value: _vm.commentScore.avg,
                            callback: function ($$v) {
                              _vm.$set(_vm.commentScore, "avg", $$v)
                            },
                            expression: "commentScore.avg",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _c(
                    "div",
                    { staticClass: "score-total" },
                    [
                      _vm.commentScore.avg
                        ? _c("p", { staticClass: "num-score" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.commentScore.avg.toFixed(1))),
                            ]),
                            _c("i", [_vm._v("评分")]),
                          ])
                        : _c("p", { staticClass: "num-score blank" }, [
                            _vm._v("暂无评分"),
                          ]),
                      _c("p", { staticClass: "num-comment" }, [
                        _c("span", [_vm._v(_vm._s(_vm.commentList.length))]),
                        _c("i", [_vm._v("条评测")]),
                      ]),
                      _c("el-rate", {
                        staticClass: "rate",
                        attrs: {
                          colors: _vm.scoreLightColors,
                          "void-color": _vm.scoreHalfTransColor,
                          "disabled-void-color": _vm.scoreHalfTransColor,
                          disabled: "",
                        },
                        model: {
                          value: _vm.commentScore.avg,
                          callback: function ($$v) {
                            _vm.$set(_vm.commentScore, "avg", $$v)
                          },
                          expression: "commentScore.avg",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "score-bar" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-rate", {
                staticClass: "rate",
                attrs: {
                  colors: _vm.scoreColors2,
                  "void-color": _vm.scoreTransColor,
                  "disabled-void-color": _vm.scoreTransColor,
                  disabled: "",
                },
                model: {
                  value: _vm.scoreLevel.five,
                  callback: function ($$v) {
                    _vm.$set(_vm.scoreLevel, "five", $$v)
                  },
                  expression: "scoreLevel.five",
                },
              }),
              _c("el-progress", {
                staticClass: "progress",
                attrs: {
                  percentage: _vm.commentScore.sum
                    ? (_vm.commentScore.scores.five / _vm.commentScore.sum) *
                      100
                    : 0,
                  color: _vm.progressColor,
                  "define-back-color": _vm.progressBgColor,
                  "show-text": false,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-rate", {
                staticClass: "rate",
                attrs: {
                  colors: _vm.scoreColors2,
                  "void-color": _vm.scoreTransColor,
                  "disabled-void-color": _vm.scoreTransColor,
                  disabled: "",
                },
                model: {
                  value: _vm.scoreLevel.four,
                  callback: function ($$v) {
                    _vm.$set(_vm.scoreLevel, "four", $$v)
                  },
                  expression: "scoreLevel.four",
                },
              }),
              _c("el-progress", {
                staticClass: "progress",
                attrs: {
                  percentage: _vm.commentScore.sum
                    ? (_vm.commentScore.scores.four / _vm.commentScore.sum) *
                      100
                    : 0,
                  color: _vm.progressColor,
                  "define-back-color": _vm.progressBgColor,
                  "show-text": false,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-rate", {
                staticClass: "rate",
                attrs: {
                  colors: _vm.scoreColors2,
                  "void-color": _vm.scoreTransColor,
                  "disabled-void-color": _vm.scoreTransColor,
                  disabled: "",
                },
                model: {
                  value: _vm.scoreLevel.three,
                  callback: function ($$v) {
                    _vm.$set(_vm.scoreLevel, "three", $$v)
                  },
                  expression: "scoreLevel.three",
                },
              }),
              _c("el-progress", {
                staticClass: "progress",
                attrs: {
                  percentage: _vm.commentScore.sum
                    ? (_vm.commentScore.scores.three / _vm.commentScore.sum) *
                      100
                    : 0,
                  color: _vm.progressColor,
                  "define-back-color": _vm.progressBgColor,
                  "show-text": false,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-rate", {
                staticClass: "rate",
                attrs: {
                  colors: _vm.scoreColors2,
                  "void-color": _vm.scoreTransColor,
                  "disabled-void-color": _vm.scoreTransColor,
                  disabled: "",
                },
                model: {
                  value: _vm.scoreLevel.two,
                  callback: function ($$v) {
                    _vm.$set(_vm.scoreLevel, "two", $$v)
                  },
                  expression: "scoreLevel.two",
                },
              }),
              _c("el-progress", {
                staticClass: "progress",
                attrs: {
                  percentage: _vm.commentScore.sum
                    ? (_vm.commentScore.scores.two / _vm.commentScore.sum) * 100
                    : 0,
                  color: _vm.progressColor,
                  "define-back-color": _vm.progressBgColor,
                  "show-text": false,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-rate", {
                staticClass: "rate",
                attrs: {
                  colors: _vm.scoreColors2,
                  "void-color": _vm.scoreTransColor,
                  "disabled-void-color": _vm.scoreTransColor,
                  disabled: "",
                },
                model: {
                  value: _vm.scoreLevel.one,
                  callback: function ($$v) {
                    _vm.$set(_vm.scoreLevel, "one", $$v)
                  },
                  expression: "scoreLevel.one",
                },
              }),
              _c("el-progress", {
                staticClass: "progress",
                attrs: {
                  percentage: _vm.commentScore.sum
                    ? (_vm.commentScore.scores.one / _vm.commentScore.sum) * 100
                    : 0,
                  color: _vm.progressColor,
                  "define-back-color": _vm.progressBgColor,
                  "show-text": false,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "shop-comment-self" },
        [
          _c(
            "div",
            { staticClass: "row score" },
            [
              _c("img", { attrs: { src: _vm.avatarUrl } }),
              _c("span", [_vm._v("轻点打分")]),
              _c("el-rate", {
                staticClass: "rate score-rate",
                attrs: {
                  colors: _vm.scoreLightColors,
                  "void-color": _vm.scoreVoidColor,
                  "disabled-void-color": _vm.scoreVoidColor,
                },
                model: {
                  value: _vm.comment.scoreSelf,
                  callback: function ($$v) {
                    _vm.$set(_vm.comment, "scoreSelf", $$v)
                  },
                  expression: "comment.scoreSelf",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row input" },
            [
              !_vm.defaultState
                ? _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 8 },
                      placeholder: "您的声音对我们很重要",
                      maxlength: "500",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.comment.textSelf,
                      callback: function ($$v) {
                        _vm.$set(_vm.comment, "textSelf", $$v)
                      },
                      expression: "comment.textSelf",
                    },
                  })
                : _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("span", [_vm._v("给这个游戏打个分吧 ~")]),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading.save,
                              expression: "loading.save",
                            },
                          ],
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              _vm.defaultState = false
                            },
                          },
                        },
                        [_c("span", { staticClass: "text" }), _vm._v("写评测")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          !_vm.defaultState
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading.save,
                      expression: "loading.save",
                    },
                  ],
                  staticClass: "btn",
                  on: { click: _vm.onAddComment },
                },
                [_c("span", { staticClass: "text" }), _vm._v("提交评测")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "shop-comment-list" },
        [
          _vm._l(_vm.splitCommentList, function (item) {
            return _c(
              "div",
              {
                key: item.commentUserId,
                staticClass: "shop-comment-list-item",
              },
              [
                _vm.isPc
                  ? [
                      _c("div", { staticClass: "row user" }, [
                        _c("img", {
                          attrs: { src: _vm.parseAvatarUrl(item.nickname) },
                        }),
                        _c("span", [_vm._v(_vm._s(item.nickname))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row score" },
                        [
                          _c("el-rate", {
                            staticClass: "score-total-rete",
                            attrs: {
                              "void-color": _vm.scoreVoidColor,
                              "disabled-void-color": _vm.scoreVoidColor,
                              colors: _vm.scoreColor,
                              disabled: "",
                            },
                            model: {
                              value: item.playability,
                              callback: function ($$v) {
                                _vm.$set(item, "playability", $$v)
                              },
                              expression: "item.playability",
                            },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatTime(item.updateAt, "YYYY年mm月dd日")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c("div", { staticClass: "row user" }, [
                        _c("img", {
                          attrs: { src: _vm.parseAvatarUrl(item.nickname) },
                        }),
                        _c("div", { staticClass: "info" }, [
                          _c("span", [_vm._v(_vm._s(item.nickname))]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatTime(item.updateAt, "YYYY年mm月dd日")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row score" },
                        [
                          _c("el-rate", {
                            attrs: {
                              "void-color": _vm.scoreHalfTransColor,
                              "disabled-void-color": _vm.scoreHalfTransColor,
                              disabled: "",
                            },
                            model: {
                              value: item.playability,
                              callback: function ($$v) {
                                _vm.$set(item, "playability", $$v)
                              },
                              expression: "item.playability",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                _c("div", { staticClass: "row text" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(item.comment.replace(/\n/g, "<br>")),
                    },
                  }),
                ]),
              ],
              2
            )
          }),
          !_vm.isPc && !_vm.moreComment.show && _vm.splitCommentList.length
            ? _c("p", { staticClass: "nomore" }, [
                _vm._v("暂时没有更多的内容了..."),
              ])
            : _vm._e(),
          _vm.moreComment.show
            ? [
                !_vm.moreComment.active
                  ? _c(
                      "div",
                      {
                        staticClass: "comment-more",
                        on: {
                          click: function ($event) {
                            return _vm.onShowMore(true)
                          },
                        },
                      },
                      [_vm._m(0)]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "comment-more",
                        on: {
                          click: function ($event) {
                            return _vm.onShowMore(false)
                          },
                        },
                      },
                      [_vm._m(1)]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("查看更多评测"),
      _c("i", { staticClass: "el-icon-arrow-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _vm._v("收起更多评测"),
      _c("i", { staticClass: "el-icon-arrow-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }