
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinUpload from '@/mixins/upload'
import Dialog from '@/components/dialog/black.vue'
import Preview from '@/components/preview/index.vue'
import Comment from './component/Comment.vue'
import { checkSessionExpire } from '@/utils'
import { isClient } from '@/utils/env'
import { EInstallStatus, EProductStatus, EClientStatus } from '@/config/store'
import { DefaultGameCover } from '@/config'
import { parseCosUrl, getCosUrl } from '@/utils/file';
import { normalizeDate, formatDate } from '@/utils/format'
// import { AITrackList } from '@/config/developer';
import { GameModule } from '@/store/modules/game'
import eventbus from '@/utils/eventbus'

type Cover = {
  img: string
  thumb: string
}

// 文本隐藏行数
// const TextMaxRow = 10
const TextMaxRow = 4
// 轮播切换时间
const CarouselIntTime = 5000
@Component({
  name: 'GameInfo',
  components: {
    Dialog,
    Preview,
    Comment,
  },
})
class HomeClient extends mixins(MixinUpload) {
  public DefaultGameCover = DefaultGameCover
  // public AITrackList = AITrackList
  public getCosUrl = getCosUrl
  public condition = {
    name: ''
  }

  public recommendList: any = []
  public scoreTotal = 3
  public scoreColors = ['#F3A918', '#F3A918', '#F3A918']
  public scoreTransColor = 'rgba(243, 169, 24, 0.1)'
  public avgScore = 0
  public headerScroll = false;
  public tabsScroll = false;


  // public appData: any = null
  public active = {
    lang: 'zh-cn'
  }
  public visible = {
    openClient: false,
    preview: false,
  }
  public moreText = {
    show: false,
    active: false,
  }
  public loading = {
    // appData: false,
    download: false,
    gameList: false,
  }
  public curTab = 'detail'   // detail comment
  // public curCover: Cover | null = null
  public curCoverIndex: number = -1
  public downloadPercent = 0
  // productStatus 0-开发中 1-即将开放 2-抢先体验 3-已发布
  // clientStatus 0-即将推出 1-未下载 2-下载中 3-已下载
  public clientStatus = EClientStatus.NotInstall
  private splitNum = this.isPc ? 7 : 6
  private carouselInt = 0
  private touchStartPoint = { x: 0, y: 0 }
  public appId = this.$route.query.appId as string

  get isPc() {
    return document.documentElement.clientWidth > 750
  }
  get gameModule() {
    return this.$store.state.game
  }
  get rawGameList() {
    return this.gameModule.list
  }
  get appData() {
    return this.rawGameList.find(item => item.appId === this.appId)
  }
  get previewList() {
    return this.mediaList.map(item => {
      return {
        type: item.type,
        name: item.url,
        url: getCosUrl(item.url, 'base'),
        poster: item.type === 'video' ? getCosUrl(item.url, 'poster') : '',
      }
    })
  }

  // 组合媒体信息，封面视频+宣传图
  get mediaList() {
    if (!this.appData) return []
    let mediaInfo = this.appData.store.medias || {}
    let list: any = [], id = 1
    if (mediaInfo.movie) list.push({
      id: id++,
      type: 'video',
      url: mediaInfo.movie
    });
    (mediaInfo.pictures || []).forEach(item => {
      list.push({
        id: id++,
        type: 'image',
        url: item
      })
    })
    return list
  }
  get splitMediaList() {
    let sNum = this.splitNum, total = this.mediaList.length, sList: any = []
    for (let i = 0; i < Math.ceil(total / sNum); i++) {
      sList.push(this.mediaList.slice(i * sNum, (i + 1) * sNum))
    }
    return sList
  }
  get curCover() {
    return this.curCoverIndex >= 0 ? this.mediaList[this.curCoverIndex] : null
  }
  get session() {
    return this.$store.state.user.session
  }

  @Watch('appData.store.info.productDetail', { immediate: true })
  onProductDetailChanged(val: string) {
    if (val) {
      this.$nextTick(() => {
        let textEl = (this.$refs.gamedetail as HTMLDivElement).querySelector('p') as HTMLParagraphElement
        let cpTextEl = textEl?.cloneNode() as HTMLParagraphElement
        cpTextEl.innerHTML = '高'
        cpTextEl.style.position = 'absolute'
        cpTextEl.style.top = '-9999px'
        textEl.parentNode!.appendChild(cpTextEl)
        let onLineHeight = cpTextEl.clientHeight, maxRow = TextMaxRow, maxHeight = onLineHeight * maxRow
        textEl.parentNode!.removeChild(cpTextEl)
        this.moreText.show = textEl.clientHeight > maxHeight
        textEl.style.maxHeight = maxHeight + 'px'
      })
    }
  }
  @Watch('curCover', { immediate: true })
  onCurCoverChanged(val: string) {
    window.clearTimeout(this.carouselInt)
    if (val) {
      this.$nextTick(() => {
        let elCoverMedia = this.$refs.coverMedia as HTMLImageElement | HTMLVideoElement, isMouseHover = false, intTime = CarouselIntTime, startTime = Date.now()

        const checkAutoNext = () => {
          // 视频播放完成后轮播，图片5s轮播 鼠标悬停停止
          if (elCoverMedia.tagName === 'VIDEO') {
            if (elCoverMedia.ended) {
              if (!isMouseHover && !this.visible.preview) this.onNext()
            } else {
              elCoverMedia.addEventListener('ended', () => {
                if (!isMouseHover && !this.visible.preview) this.onNext()
              }, false)
            }
          } else {
            this.carouselInt = setTimeout(() => {
              if (!isMouseHover && !this.visible.preview) this.onNext()
            }, intTime)
          }
        }

        //防抖函数防止鼠标移除事件多次触发
        const debounce = (fn: Function) =>{
          let timer:any = null;
          return function() {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
              fn.apply(this, arguments);
            }, 1000);
          }
        }

        // 鼠标悬停判断
        elCoverMedia.addEventListener('mouseenter', () => {
          isMouseHover = true
          let curTime = Date.now(), deltaTime = curTime - startTime
          intTime -= deltaTime
        }, false)
        elCoverMedia.addEventListener('mouseleave', () => {
          isMouseHover = false
          // checkAutoNext()
          debounce(checkAutoNext)
        }, false)
        checkAutoNext()
      })
    }
  }

  created() {
    this.initData()
    // this.getAppInfo()
  }
  mounted() {
    this.scrollToTop()
    this.registerClientEvent()
    this.registerDomEvent()
    eventbus.$on('game-search', this.onGameSearch)
    window.addEventListener('scroll', this.changeHeader)
    this.videoScreenchange()
  }
  beforeDestroy() {
    this.unregisterDomEvent()
  }

  private registerDomEvent() {
    this.$nextTick(() => {
      let elCoverCtlBox = this.$refs.covercontrolbox as HTMLDivElement
      if (elCoverCtlBox) {
        elCoverCtlBox.addEventListener('touchstart', this.onTouchStart)
        elCoverCtlBox.addEventListener('touchend', this.onTouchEnd)
      }
    })
  }
  private unregisterDomEvent() {
    let elCoverCtlBox = this.$refs.covercontrolbox as HTMLDivElement
    if (elCoverCtlBox) {
      elCoverCtlBox.removeEventListener('touchstart', this.onTouchStart)
      elCoverCtlBox.removeEventListener('touchend', this.onTouchEnd)
    }
  }
  private onTouchStart(e) {
    let point = e.changedTouches[0]
    this.touchStartPoint = {
      x: point.pageX,
      y: point.pageY
    }
  }
  private onTouchEnd(e) {
    let point = e.changedTouches[0], threshold = 50
    let touchEndPoint = {
      x: point.pageX,
      y: point.pageY
    }
    let curPage = Math.floor(this.curCoverIndex / this.splitNum), totalPage = Math.ceil(this.mediaList.length / this.splitNum) - 1
    // 右滑
    if (touchEndPoint.x - this.touchStartPoint.x >= threshold) {
      let nextPage = curPage - 1
      if (nextPage < 0) return   // nextPage = totalPage
      this.curCoverIndex = nextPage * this.splitNum;
      (this.$refs.covercontrol as any).next()
    }
    // 左滑
    else if (touchEndPoint.x - this.touchStartPoint.x <= -threshold) {
      let nextPage = curPage + 1
      if (nextPage > totalPage) return   // nextPage = 0
      this.curCoverIndex = nextPage * this.splitNum;
      (this.$refs.covercontrol as any).prev(nextPage)
    }
  }

  private async initData() {
    this.loading.gameList = true
    if (!this.gameModule.init.list) await GameModule.MAGetGameList()
    this.curCoverIndex = 0
    this.checkClientStatus()
    this.registerDomEvent()
    this.loading.gameList = false
  }
  public onPre() {
    let lastIndex = this.curCoverIndex
    let preIndex = this.curCoverIndex - 1
    if (preIndex < 0) preIndex = this.mediaList.length - 1
    this.curCoverIndex = preIndex
    let curPage = Math.floor(this.curCoverIndex / this.splitNum), prePage = Math.floor(lastIndex / this.splitNum)
    if (curPage != prePage) (this.$refs.covercontrol as any).prev(curPage)
  }
  public onNext() {
    let lastIndex = this.curCoverIndex
    let nextIndex = this.curCoverIndex + 1
    if (nextIndex >= this.mediaList.length) nextIndex = 0
    this.curCoverIndex = nextIndex
    let curPage = Math.floor(this.curCoverIndex / this.splitNum), prePage = Math.floor(lastIndex / this.splitNum)
    if (curPage != prePage) (this.$refs.covercontrol as any).next(curPage)
  }
  public onChangeCover(cover: Cover) {
    this.curCoverIndex = this.mediaList.findIndex(item => item == cover)
  }
  public onLogoClick() {
    // this.$router.push('/home/client')
  }
  public goToMedius(medium) {
    if (isClient) {
      window.oasis.openUrl(medium.mediumUrl)
    }
    else {
      window.open(medium.mediumUrl)
    }
  }

  public showPreview() {
    this.visible.preview = true
  }
  public onShowMore(status: boolean) {
    this.moreText.active = status
  }
  public onOpen() {
    const clientUrl = `oasisclient:\/\/?`
    window.location.href = clientUrl
  }
  public onLaunch() {
    let hasExpired = checkSessionExpire()
    if (hasExpired) {
      this.$message.info("请先进行登录")
      return
    }
    window.oasis?.launchGame(`code=${this.session.code}&state=${this.session.state}`)
  }
  public downloadClient() {
    this.$message.info("下载链接待上传")
  }
  public downloadGame() {
    if (isClient) {
      // 校验登录态
      let hasExpired = checkSessionExpire()
      if (hasExpired) {
        this.$message.info("请先进行登录")
      }
      else {
        // 拉取store中gcloud信息
        // ADevStoreGet
        const gcloudInfo = {
          "uPufferProductId": 2115,
          "strPufferServerUrl": "pre-puffer.340538624-11-2.gcloudsvcs.com",
          "nPufferGameId": "340538624",
          "uPufferDolphinProductId": 4268,
          "channelId": 4268,
          "updateUrl": "pre-download.340538624-1-2.gcloudsvcs.com",
          "GameId": "340538624",
          "GameKey": "36cb38f2a61fdd2ba414378d7e553aa9"
        }
        // TODO 传入appId deployId
        window.oasis.downloadGame(JSON.stringify(gcloudInfo))
        this.clientStatus = EClientStatus.Installing
      }
    }
    else {
      // AI大赛-下载安装包
      this.downloadFile(this.appData.pkg.package)
      // this.loading.download = true
      // ADevAIBuildGET(this.appId).then((res: any) => {
      //   let data = res.data || null
      //   if (!data || !data.package) {
      //     this.$message.info("未配置安装包")
      //     this.loading.download = false
      //     return
      //   }
      //   let cosUrl = parseCosUrl(data.package)
      //   window.open(cosUrl.base)
      //   this.loading.download = false
      //   // fetch(cosUrl.base).then(res =>
      //   //   res.blob().then(blob => {
      //   //     download(blob, data.package)
      //   //     this.loading.download = false
      //   //   })
      //   // )
      // }).catch((err) => {
      //   console.error(err)
      //   this.$message.error("获取下载信息失败")
      //   this.loading.download = false
      // })
      // // this.visible.openClient = true
    }
  }
  public downloadFile(file: string) {
    let fileName = file.split('|')
    if (!fileName.length) return
    // this.loading.download = true
    let cosUrl = getCosUrl(fileName[0] || '', 'base')
    window.open(cosUrl)
  }
  public changeTab(key: string) {
    this.$router.push({ name: isClient ? 'homeClient' : 'homeBrowser', params: { tab: key } })
  }
  public onGameSearch(name) {
    this.condition.name = name
    this.onSearch()
  }
  public onSearch() {
    this.$router.push({ name: isClient ? 'homeClient' : 'homeBrowser', params: { search: this.condition.name } })
  }


  private registerClientEvent() {
    if (isClient) {
      // 注册进度回调事件
      window.oasis.onDownloadProgress((progress: number) => {
        console.log("onDownloadProgress", progress)
        // -2 取消安装 -1 失败 0~100 进度
        if (progress == -2) {
          this.downloadPercent = 0
          this.clientStatus = EClientStatus.NotInstall
          this.$message.info("已取消安装")
        }
        else if (progress == -1) {
          this.downloadPercent = 0
          this.clientStatus = EClientStatus.NotInstall
          this.$message.info("安装失败")
        }
        else {
          this.downloadPercent = progress
        }
      })
      // 注册安装状态事件
      window.oasis.onInstalltionState((status: number) => {
        console.log("onInstalltionState", status)
        if (status == EInstallStatus.Installed) this.clientStatus = EClientStatus.Installed
        else if (status == EInstallStatus.Installing) {
          this.clientStatus = EClientStatus.Installing
        }
        else this.clientStatus = EClientStatus.NotInstall
      })
    }
  }

  // private getAppInfo() {
  //   if (!this.appId) {
  //     this.$message.error("缺少appId参数")
  //     return
  //   }
  //   this.loading.appData = true
  //   APubStoreGet(this.appId).then((res: any) => {
  //     let data = res.data || null
  //     this.appData = data
  //     this.curCover = this.mediaList[0] && this.mediaList[0][0]
  //     this.checkClientStatus()
  //     this.loading.appData = false
  //   }).catch((err) => {
  //     console.error(err)
  //     this.$message.error("获取banner列表失败")
  //     this.loading.appData = false
  //   })
  // }
  private checkClientStatus() {
    // console.log("checkClientStatus", isClient)
    let productStatus = this.appData.store.info.productStatus
    if (productStatus == EProductStatus.Developing || productStatus == EProductStatus.ComingSoon) this.clientStatus = EClientStatus.ComingSoon
    else if (productStatus == EProductStatus.Experience || productStatus == EProductStatus.Released) this.clientStatus = EClientStatus.NotInstall
    // 查询包体信息
    if (isClient) {
      // 触发安装状态查询
      window.oasis.checkGameInstallState('test', (installStatus) => {
        console.log("installStatus", installStatus)
        if (installStatus == EInstallStatus.Installed) this.clientStatus = EClientStatus.Installed
        else if (installStatus == EInstallStatus.Installing) {
          this.clientStatus = EClientStatus.Installing
        }
        console.log("clientStatus", this.clientStatus)
      })
    }
    this.clientStatus = EClientStatus.NotInstall
  }
  private scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    })
  }

  public goToGame(game) {
    this.$router.push({ path: '/game/info', query: { appId: game.appId } })
  }
  public formatTime(time: string, format) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime, format)
  }
  public changeHeader() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    this.headerScroll = scrollTop >= 100 ? true : false;
    this.tabsScroll = scrollTop >= 400 ? true : false;
  }
  public videoScreenchange() {
    const video = document.querySelector('.mediaVideo')
    if (video) {
      video.addEventListener('fullscreenchange', function() {
        if (document.fullscreenElement) {
            // 进入全屏模式
            console.log('进入全屏模式')
          } else {
            // 退出全屏模式
            console.log('退出全屏模式')
          }
      })
    }
  }
}
export default HomeClient
