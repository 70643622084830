
import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutParentView from '@/layout/routerView/parent.vue';

@Component({
  name: 'layoutMain',
  components: { LayoutParentView },
})
class layoutMain extends Vue { }
export default layoutMain
