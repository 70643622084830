
  import { Component, Vue } from 'vue-property-decorator'
  @Component({
    name: 'layoutHeader',
    components: {},
  })
  class layoutHeader extends Vue {
    public menuList = [
      { label: 'menu 1', key: 101 },
      { label: 'menu 2', key: 102 },
      { label: 'menu 3', key: 103 },
    ];
  }
  export default layoutHeader
  