var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "blank-container", attrs: { id: "layout-blank" } },
    [_c("router-view", { key: _vm.key })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }