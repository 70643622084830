
import { Component, Vue, Prop } from "vue-property-decorator";

type ImgItem = {
  type: 'video' | 'image'
  name: string | undefined
  url: string
  poster: string | undefined
}

@Component({
  name: "Component-Modal-Center",
  components: {},
})
class ComponentPreviewIndex extends Vue {

  @Prop({ required: false, default: false }) public visible;
  @Prop({ required: false, default: () => 0 }) public curIndex;
  @Prop({ required: true, default: () => [] as ImgItem[] }) public list;

  // public curCover: ImgItem

  get curCover() {
    return this.list[this.curIndex] || this.list[0]
  }

  created() {

  }
  mounted() {

  }

  public onPre() {
    let preIndex = this.curIndex - 1
    if (preIndex < 0) preIndex = this.list.length - 1
    this.curIndex = preIndex
    this.$emit('pre')
  }
  public onNext() {
    let nextIndex = this.curIndex + 1
    if (nextIndex >= this.list.length) nextIndex = 0
    this.curIndex = nextIndex
    this.$emit('next')
  }
  public show() {
    this.$emit("update:visible", true)
  }
  public hide() {
    this.$emit("update:visible", false)
  }
  public async onOk() {
    this.$emit('ok')
    this.hide()
  }
  public onCancel() {
    this.$emit('cancel')
    this.hide()
  }

}
export default ComponentPreviewIndex
