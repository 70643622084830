
import { Component, Vue, Watch } from 'vue-property-decorator'
import logo from '@/assets/logo-dev-n.png';

@Component({
  name: 'layoutLogo',
  components: {},
})
class layoutLogo extends Vue {
  public logo = logo

  get title() {
    return this.$store.state.config.title
  }

  public goToHome() {
    return
    this.$router.push({ path: '/' })
  }
}
export default layoutLogo
