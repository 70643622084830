<template>
    <div class="game-intro" :style="{ height: boxSize.height + 'rem' }" @mouseenter="handleEnter" @mouseleave="handleLeave"
        @click="handleToGame">
        <div v-show="!isHover">
            <img :src="info.store && info.store.medias && info.store.medias.cover && getCosUrls(info.store.medias.cover, 'base') || DefaultGameCover"
                alt="" class="game-intro-img" :style="{ width: boxSize.imgWidth + 'rem' }">
        </div>
        <video v-show="curVideo && isHover" :src="curVideo | fCosBase" :poster="curVideo | fCosPoster" autoplay class="imgs"
            ref="coverMedia" muted :style="{ width: boxSize.imgWidth + 'rem' }"></video>

        <div v-show="mediaList.length && isHover" class="cover" :style="{ width: boxSize.imgWidth + 'rem' }">
            <img v-for="(cover, i) in mediaList" :key="i" class="imgs" :src="cover.url | fCosBase"
                :style="{ opacity: i == curCoverIndex ? '1' : '0' }" :class="i == curCoverIndex ? 'show' : ''" />
        </div>
        <div v-show="!mediaList.length && !curVideo && isHover" class="cover" :style="{ width: boxSize.imgWidth + 'rem' }">
            <img :src="info.store && info.store.medias && info.store.medias.cover && getCosUrls(info.store.medias.cover, 'base') || DefaultGameCover"
                alt="" class="imgs">
        </div>

        <div class="game-intro-info">
            <div class="game-intro-info-text">
                <span>{{ info.store && info.store.info && info.store.info.productName || '' }}</span>
                <!-- <div class="game-intro-info-text-tags">
                    <span v-for="(tag, index) in tagList" :key="index">{{ tag }}</span>
                </div> -->
            </div>
            <div class="game-intro-info-bottom">
                <div class="game-intro-info-bottom-rate" v-if="!isHover">
                    <el-rate v-model="info.score" :colors="scoreColors" :void-color="scoreVoidColor"
                        :disabled-void-color="scoreVoidColor" disabled :allow-half="true">
                    </el-rate> <span>{{ commentList }}人评测</span>
                </div>
                <div class="game-intro-info-bottom-text" :title="info.store && info.store.info &&
                    info.store.info.summary || ''" v-else>{{ info.store && info.store.info &&
        info.store.info.summary || '' }}</div>
            </div>
        </div>
        <div class="platform">
            <span v-for="(val, i) in platformList" :key="i">
                <img :src="require(`../../../assets/shop/${val}.svg`)" alt="">
            </span>
        </div>
        <span class="tips">免费</span>
    </div>
</template>

<script>
import { platformType } from '@/config/index'
import { DefaultGameCover } from '@/config'
import { ADevAICommentAllGET } from '@/api/game'
import MixinUpload from '@/mixins/upload'

export default {
    name: 'game-intro',
    components: {},
    props: {
        info: {
            type: Object
        }
    },
    mixins: [MixinUpload],
    data() {
        return {
            tagList: ['冒险', '视觉小说', '2D', '单人'],
            boxSize: {
                boxHeight: 0,
                imgWidth: 0
            },
            isHover: false,
            commentList: null,
            intro: '',
            scoreVoidColor: 'rgba(255,255,255,0.1)',
            scoreColors: ['#FF7A01', '#FF7A01', '#FF7A01'],
            platformList: [],
            DefaultGameCover: DefaultGameCover,
            carouselInt: null,
            intTime: 2000,
            curCoverIndex: 0,
            curVideo: '',
            mediaList: []
        }
    },
    mounted() {
        this.handleSize()
    },
    watch: {
        info: {
            handler(val) {
                if (!val.store.medias) return
                let mediaInfo = val.store.medias || {}
                let list = val.store && val.store.info && val.store.info.platformer.map(item => {
                    return platformType.filter(x => x.id === item).length ? platformType.filter(x => x.id === item)[0].label : 'windows'
                })
                this.platformList = list.filter(x => x)
                this.curVideo = mediaInfo.movie
                if (!this.curVideo) { // 有视频播放视频 ，没有视频再展示图片
                    let lists = []
                    mediaInfo.pictures.forEach((item, index) => {
                        lists.push({
                            type: 'image',
                            url: item,
                            id: index
                        })
                    })
                    this.mediaList = lists
                }
                this.getScore()
            },
            immediate: true
        },
    },
    computed: {
        curCover() {
            return this.curCoverIndex >= 0 ? this.mediaList[this.curCoverIndex] : null
        },
    },
    methods: {
        async getScore() {
            let id = this.info.appId
            // let res = await ADevAICommentScoreGET(id)
            let comment = await ADevAICommentAllGET(id)
            this.commentList = comment.data && comment.data.length || 0
        },
        handleEnter() {
            this.isHover = true
            this.handleHoverSize()
            this.curCoverIndex = 0
            if (!this.curVideo) this.checkAutoNext()
        },
        handleLeave() {
            this.isHover = false
            this.handleSize()
            clearTimeout(this.carouselInt);
        },
        handleSize() {
            this.boxSize = {
                boxHeight: 1.12,
                imgWidth: 2
            }
        },
        handleHoverSize() {
            this.boxSize = {
                boxHeight: 1.64,
                imgWidth: 2 * 1.5
            }
        },
        handleToGame() {
            location.href = `${location.origin}/game/shop?appId=${this.info.appId}`
        },
        getCosUrls(cover) {
            const prefix = 'https://oasis-1300799399.cos.ap-nanjing.myqcloud.com/image/';
            return `${prefix}${cover}`;
        },
        checkAutoNext() {
            this.carouselInt = setInterval(() => {
                this.onNext()
            }, this.intTime)
        },
        onNext() {
            let lastIndex = this.curCoverIndex
            let nextIndex = this.curCoverIndex + 1
            if (nextIndex >= this.mediaList.length) nextIndex = 0
            this.curCoverIndex = nextIndex
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/style.css';
@import '@/theme/mixins/index.scss';

.game-intro {
    width: 100%;
    border-radius: 0.12rem;
    display: flex;
    // align-items: center;
    height: 1.12rem;
    position: relative;
    padding: 0 .24rem;

    &-img {
        width: 2rem;
        height: 1.12rem;
        height: 100%;
        border-radius: 0.12rem;
        object-fit: cover;
        background: transparent
    }

    .cover {
        height: 100%;
        border-radius: 0.12rem;
        // background: transparent;
        position: relative;

        .imgs {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;


        }

        .show {
            animation: coverShow 1s linear !important;
            opacity: 1;
        }

        @keyframes coverShow {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }


    }

    &-info {
        flex: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-left: .26rem;

        &-text {
            span {
                font-family: 'cssClassCNB';
                font-style: normal;
                font-weight: 800;
                font-size: .18rem;
                line-height: .28rem;
                color: rgba(255, 255, 255, 0.9);
            }

            span:hover {
                border-bottom: 1px solid #fff;
                padding: 0, 24rem;
            }

            &-tags {
                margin-top: 0.09rem;

                &>span {
                    display: inline-block;
                    padding: 0.01rem 0.08rem;
                    border-radius: 0.04rem;
                    background: rgba(255, 255, 255, 0.08);
                    margin-right: 0.1rem;
                    font-family: 'cssClassCNR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .12rem;
                    color: rgba(255, 255, 255, 0.55);
                    margin-top: 0.08rem;
                }

            }
        }

        &-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-rate {
                display: flex;
                align-items: center;

                &>span {
                    font-family: 'cssClassENR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: .12rem;
                    line-height: .18rem;
                    color: rgba(255, 255, 255, 0.55);
                    margin-left: 0.08rem;
                }
            }

            &>span {
                font-family: 'cssClassCNR';
                font-style: normal;
                font-weight: 400;
                font-size: .12rem;
                background: rgba(255, 255, 255, 0.08);
                border-radius: 0.04rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.55);
                padding: 0.01rem 0.08rem;
            }

            &-text {
                font-family: 'cssClassENR';
                font-style: normal;
                font-weight: 400;
                font-size: .16rem;
                line-height: .24rem;
                display: flex;
                align-items: flex-end;
                color: rgba(255, 255, 255, 0.45);
                @include text-ellipsis;
            }
        }

    }

    .platform {
        &>span {
            margin-left: 0.04rem;


            &>img {
                width: .2rem;
                height: .2rem;
            }

        }
    }

    .tips {
        display: inline-block;
        padding: 0.01rem 0.08rem;
        border-radius: 0.04rem;
        background: rgba(255, 255, 255, 0.08);
        margin-right: 0.1rem;
        font-family: 'cssClassCNR';
        font-style: normal;
        font-weight: 400;
        font-size: .12rem;
        color: rgba(255, 255, 255, 0.55);
        position: absolute;
        right: .16rem;
        bottom: 0;
    }

}

.game-intro:hover {
    background: #263256;
    padding: 0;
    box-shadow: 0px .12rem .32rem 0px rgba(255, 94, 44, 0.65);
    border: .03rem solid transparent;
    height: 1.68rem;
    border-radius: .12rem;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #263256, #263256), linear-gradient(90deg, #FF7A01, #FF33E7);
    overflow: hidden;
    cursor: pointer;
    animation: show .2s linear;

    .game-intro-info {
        padding-top: .16rem;
        padding-right: .16rem;
        justify-content: flex-start;
        background: #263256;


        .game-intro-info-bottom-text {
            margin-top: .17rem;
        }
    }

    .game-intro-img {
        border-radius: 0
    }

    .tips {
        right: .16rem;
        bottom: .16rem;
    }

    .platform {
        position: absolute;
        top: .16rem;
        right: .16rem;

        &>span {
            margin-left: 0.04rem;

            &>img {
                width: .2rem;
                height: .2rem;
            }
        }
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
<style lang="scss">
.game-intro {
    .el-rate {
        height: auto;

        .el-rate__item {
            width: 0.1rem;
            margin-right: 0.02rem;

            .el-rate__icon {
                font-size: .12rem;
                // color: #FF7A01 !important
            }
        }
    }

    // .el-icon-star-on:before {
    //     color: #FF7A01 !important
    // }

    // .el-icon-star-off:before {
    //     content: '\e900';
    //     color: #707070;
    // }
}
</style>