var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "layout-header" }, [
    _c(
      "div",
      { staticClass: "menu-container" },
      _vm._l(_vm.menuList, function (item, index) {
        return _c("div", { key: index }, [
          _vm._v(" " + _vm._s(item.label) + " "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }