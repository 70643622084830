var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isShowDialog
    ? _c("div", { staticClass: "detail-container" }, [
        _c("div", { staticClass: "detail-mask" }, [
          _c("div", { staticClass: "detail-warp" }, [
            _c("div", { staticClass: "detail-box" }, [
              _c("div", { staticClass: "detail-top" }, [
                _c("div", { staticClass: "tool-img" }, [
                  _c("img", {
                    attrs: { src: _vm.details.tools_basic_data.icon, alt: "" },
                  }),
                ]),
                _c("div", { staticClass: "tool-name-warp" }, [
                  _c(
                    "div",
                    { staticClass: "tool-name" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.details.tools_basic_data.tools_name)),
                      ]),
                      _vm._l(
                        _vm.details.tools_basic_data.label,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "tool-label-list" },
                            [
                              _c("div", { staticClass: "tool-label-item" }, [
                                _vm._v(_vm._s(_vm._f("lableFormat")(item))),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "tool-describe" }, [
                    _vm._v(_vm._s(_vm.details.tools_basic_data.profiles)),
                  ]),
                ]),
                _c("div", { staticClass: "tool-btn-warp" }, [
                  _c("div", { staticClass: "access-btn" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.details.tools_basic_data.web_url,
                          target: "_blank",
                        },
                      },
                      [_vm._v("直接访问")]
                    ),
                  ]),
                  _vm.details.tools_basic_data.is_collect === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "collect-btn collect-collect-btn-btn",
                          on: { click: _vm.deleteCollect },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/developer/star-btn-activ.png"),
                              alt: "",
                            },
                          }),
                          _c("a", { attrs: { href: "javascript:;" } }, [
                            _vm._v("取消收藏"),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "collect-btn",
                          on: { click: _vm.toolsCollect },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/developer/star-btn.png"),
                              alt: "",
                            },
                          }),
                          _c("a", { attrs: { href: "javascript:;" } }, [
                            _vm._v("加入收藏"),
                          ]),
                        ]
                      ),
                  _c(
                    "div",
                    {
                      staticClass: "tool-close",
                      on: {
                        click: function ($event) {
                          _vm.isShowDialog = false
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/developer/tool-close.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "tool-content-warp" }, [
                _c("div", { staticClass: "tool-content-title" }, [
                  _vm._v("详情介绍"),
                ]),
                _c("div", { staticClass: "tool-content" }, [
                  _vm._v(_vm._s(_vm.details.tools_basic_data.description)),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }