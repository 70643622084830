var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-info-general" } },
    [
      _c("h2", [_vm._v("通用")]),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.appData,
              expression: "loading.appData",
            },
          ],
          ref: "formRef",
          staticClass: "comp-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "large",
            "label-width": "0.8rem",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form-item-name",
              attrs: { label: "作品名称", prop: "appName" },
            },
            [
              _c(
                "el-input",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  attrs: {
                    placeholder: "请输入作品名称",
                    autocomplete: "off",
                    disabled: !_vm.isCurStage,
                  },
                  model: {
                    value: _vm.form.appName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "appName", $$v)
                    },
                    expression: "form.appName",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(
                      _vm._s(_vm.form.appName.length) +
                        "/" +
                        _vm._s(_vm.maxNameLen)
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item-btns" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "form-btn cancel",
                  attrs: {
                    loading: _vm.loading.save || _vm.loading.upload,
                    disabled: !_vm.isCurStage,
                  },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "form-btn save",
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.save || _vm.loading.upload,
                    disabled: !_vm.isCurStage,
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("保存并下一步")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }