<template>
  <div class="gate-guide-contact">
    <div class="gate-guide-contain">
      <guideTitle :title="$t('views.guide.contact.title')" :hasMore="false"/>
      <div class="tips">
        {{ $t('views.guide.contact.tips') }}
        <span class="email">{{ $t('views.guide.contact.email') }}</span>
      </div>
      <!-- <div class="listTitle">
        {{ $t('views.guide.contact.listTitle') }}
      </div> -->
      <div class="guide-contact-container">
        <contactCard v-for="(item, index) in contactList" :title="item.title" :iconPath="item.icon" :key="index" />
      </div>
    </div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import contactCard from './components/contactCard.vue';
import discordIcon from '../../assets/guide/contact/discord.png';
import twitterIcon from '../../assets/guide/contact/twitter.png';
import youtubeIcon from '../../assets/guide/contact/youtube.png';
import linkedIn from '../../assets/guide/contact/linkedIn.png';
import wechatIcon from '../../assets/guide/contact/wechat.png';
export default {
  name: 'guide-contact',
  components: { guideTitle, contactCard },
  data() {
    return {
      contactList: [
        { title: 'Discord', icon: discordIcon },
        { title: 'Twitter', icon: twitterIcon },
        { title: 'Youtube', icon: youtubeIcon },
        { title: 'LinkedIn', icon: linkedIn },
        { title: 'Wechat', icon: wechatIcon },
      ]
    }
  },
  mounted() {
    console.log('mounted');
  },
}

</script>

<style lang="scss" scoped>
.gate-guide-contact {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000;
  align-items: end;
  .gate-guide-contain{
    width: 1200px;
    .tips{
      margin-top: 20px;
      color:  #FFF;
      text-align: left;
      
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding: 0 360px 0 0;
      span{
        color: #84E1BC;
      }
    }
    .listTitle{
      margin-top: 20px;
      color:  #FFF;
      text-align: center;
      
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      opacity: 0.6;
    }
    .guide-contact-container{
      margin-top: 20px;
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .guide-contact-container>div:nth-child(4n+1){
      margin-left: 0px;
    }
    .guide-contact-container>div{
      margin-left: 4px;
    }
  }
}
</style>
