var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "case-swiper-card": true,
        "case-normal": !_vm.isHover,
        "case-hover": _vm.isHover,
      },
      style: { width: _vm.width + "px", height: _vm.height + "px" },
      on: { mouseenter: _vm.mouseOverFunc, mouseleave: _vm.mouseoutFunc },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isHover,
              expression: "isHover",
            },
          ],
          staticClass: "video-container",
        },
        [
          _c("video", {
            staticClass: "imgs",
            attrs: {
              autoplay: "",
              loop: "",
              muted: "",
              width: "100%",
              src: _vm.videoUrl,
              crossorigin: "anonymous",
            },
            domProps: { muted: true },
          }),
        ]
      ),
      _c("div", {
        class: { "game-img": true, "game-img-hover": _vm.isHover },
        style: {
          background: "url(" + _vm.bgPath + ") no-repeat",
          backgroundSize: "100% 100%",
        },
      }),
      _c("div", { staticClass: "info" }, [
        _c("div", { class: { title: true, "title-hover": _vm.isHover } }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        !_vm.isHover
          ? _c("div", { staticClass: "introduction" }, [
              _vm._v(" " + _vm._s(_vm.introduction) + " "),
            ])
          : _vm._e(),
        _vm.isHover
          ? _c(
              "div",
              { staticClass: "tagsBox" },
              _vm._l(_vm.tags, function (item, index) {
                return _c("div", { key: index, staticClass: "tag" }, [
                  _vm._v(" " + _vm._s(item)),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }