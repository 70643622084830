// 字符串相似度计算
// GENERIC FUNCTIONS ----------------------------

// apList (<*>) :: [(a -> b)] -> [a] -> [b]
const apList = (fs, xs) => fs.reduce((a, f) => a.concat(xs.reduce((a, x) => a.concat([f(x)]), [])), []);

// comparing :: (a -> b) -> (a -> a -> Ordering)
const comparing = f => (x, y) => {
  const a = f(x);
  const b = f(y);
  return a < b ? -1 : (a > b ? 1 : 0);
};

// compose (<<<) :: (b -> c) -> (a -> b) -> a -> c
const compose = (f, g) => x => f(g(x));

// concat :: [[a]] -> [a]
// concat :: [String] -> String
const concat = xs => (0 < xs.length ? (() => {
  const unit = 'string' !== typeof xs[0] ? ([]) : '';
  return unit.concat.apply(unit, xs);
})() : []);

// concatMap :: (a -> [b]) -> [a] -> [b]
const concatMap = (f, xs) => xs.reduce((a, x) => a.concat(f(x)), []);

// inits([1, 2, 3]) -> [[], [1], [1, 2], [1, 2, 3]
// inits('abc') -> ["", "a", "ab", "abc"]

// inits :: [a] -> [[a]]
// inits :: String -> [String]
const inits = xs => [[],].concat(('string' === typeof xs ? xs.split('') : xs).map((_, i, lst) => lst.slice(0, i + 1)));

// intersect :: (Eq a) => [a] -> [a] -> [a]
const intersect = (xs, ys) => xs.filter(x => -1 !== ys.indexOf(x));

// length :: [a] -> Int
const arrLength = xs => ((Array.isArray(xs) || 'string' === typeof xs) ? (xs.length) : Infinity);

// map :: (a -> b) -> [a] -> [b]
const map = (f, xs) => xs.map(f);

// maximumBy :: (a -> a -> Ordering) -> [a] -> a
const maximumBy = (f, xs) => (0 < xs.length ? (xs.slice(1).reduce((a, x) => (0 < f(x, a) ? x : a), xs[0])) : undefined);

// tail :: [a] -> [a]
const tail = xs => (0 < xs.length ? xs.slice(1) : []);

// tails :: [a] -> [[a]]
const tails = (xs) => {
  const es = ('string' === typeof xs) ? (xs.split('')) : xs;
  return es.map((_, i) => es.slice(i)).concat([[]]);
};

/**
 * 最长公共子串实现
 * https://rosettacode.org/wiki/Longest_Common_Substring#Go
 */
const longestCommon = (s1: string, s2: string) => maximumBy(
  comparing(arrLength),
  intersect(...apList(
    [s => map(
      concat,
      concatMap(tails, compose(tail, inits)(s)),
    )],
    [s1, s2],
  )),
);

/**
 * 最长公共子串长度
 */
export const lcsLength = (s1: string, s2: string) => (longestCommon(s1, s2) || '').length;


/**
 * levenshtein算法，计算编辑距离，即两个字符串之间，由一个转换成另一个所需的最少编辑操作次数
 * https://github.com/arbovm/levenshtein/blob/master/levenshtein.go
 */
export const levenshteinDistance = (str1: string, str2: string): number => {
  let cost; let lastdiag; let olddiag;
  const s1 = str1.split('');
  const s2 = str2.split('');

  const len_s1 = s1.length;
  const len_s2 = s2.length;

  const column = new Array(len_s1 + 1);

  for (let y = 1; y <= len_s1; y++) {
    column[y] = y;
  }

  for (let x = 1; x <= len_s2; x++) {
    column[0] = x;
    lastdiag = x - 1;
    for (let y = 1; y <= len_s1; y++) {
      olddiag = column[y];
      cost = 0;
      if (s1[y - 1] != s2[x - 1]) {
        cost = 1;
      }
      column[y] = Math.min(
        column[y] + 1,
        column[y - 1] + 1,
        lastdiag + cost,
      );
      lastdiag = olddiag;
    }
  }
  return column[len_s1];
};
