
import { Component, Vue, Watch } from 'vue-property-decorator'
import { setLanguage } from '@/lang'
import { LangList } from '@/config'
import { GameList } from '@/config/api'
import { UserModule } from '@/store/modules/user'
import { AccountDomain } from '@/utils/env'
import { DeveloperModule } from '@/store/modules/developer';

const DefaultAvatar = 'https://image.guangzi.qq.com/uploadfiles/oasis/avatar_default.jpg'
@Component({
  name: 'HomeBrowser',
  components: {},
})
class HomeBrowser extends Vue {
  public active = {
    lang: 'zh-cn'
  }
  public langList = LangList
  public loading = {
    devInfo: false,
    download: false
  }
  public gameList = GameList
  public bannerList = [
    {
      id: 1,
      img: require('../../assets/developer/ai/banner.jpg'),
      url: 'https://km.woa.com/group/LightspeedExplorationLab/articles/show/536372'
    },
  ]

  get session() {
    return this.$store.state.user.session
  }
  get userInfo() {
    return this.$store.state.user.userInfo
  }
  get avatarUrl() {
    if (!this.userInfo || !this.userInfo.nickname) return DefaultAvatar
    return `https://dayu.woa.com/avatars/${this.userInfo.nickname}/profile.jpg`
  }
  // get devInfo() {
  //   console.log("developer info", this.$store.state.developer.info)
  //   return this.$store.state.developer.info
  // }
  // get isDeveloper() {
  //   return this.devInfo.developer_id && this.devInfo.status == "Success"
  // }

  mounted() {
    this.$report('dev/home', 'visit')
  }

  public onLogoClick() {
    this.$router.push('/home')
  }
  public onChangeLang(lang: any) {
    this.active.lang = lang.key
    this.$i18n.locale = lang.key
    setLanguage(lang.key)
  }
  public goToLogin() {
    this.$router.push({ path: '/account/login' })
  }
  public goToOasisAccount() {
    // this.$message.info('敬请期待')
    // return
    this.$report('dev/home/account', 'click')
    location.href = AccountDomain
  }
  public goToDeveloper() {
    this.$report('dev/home/developer', 'click')
    this.onJump()
  }
  public goToDiscord() {
    this.$report('dev/home/discord', 'click')
    window.open('https://discord.gg/EkHDqutUJc')
  }
  public goToBanner(banner) {
    if (!banner.url) return
    this.$report('dev/home/banner', 'click')
    window.open(banner.url)
  }
  public async onJump() {
    if (this.loading.devInfo) return
    // 未登录，跳转登录
    if (!this.session.token) {
      this.$router.push({ path: '/account/login', params: { redirect: '/application' } })
    }
    else {
      this.loading.devInfo = true
      try {
        await DeveloperModule.MAGetDevInfo()
        this.loading.devInfo = false
        this.$router.push({ path: '/application' })
      }
      catch (e) {
        console.error(e)
        this.loading.devInfo = false
        this.$message.error("注册开发者失败")
      }
    }
    // // 已登录，未成为开发者，跳转注册
    // else if (!this.isDeveloper) {
    //   console.log("developer isDeveloper", this.$store.state.developer.info)
    //   this.$router.push({ path: '/register' })
    // }
    // // 已登录，已是开发者，跳转配置
    // else {
    //   this.$router.push({ path: '/application' })
    // }
  }
  public goToSetting() {
    // 跳回账号系统管理
    location.href = `${AccountDomain}/general`
    // this.$router.push({ path: '/application' })
  }
  public logout() {
    this.$msgbox({
      closeOnClickModal: false,
      closeOnPressEscape: false,
      title: this.$t('common.tips') as string,
      message: this.$t('common.logoutTips') as string,
      showCancelButton: true,
      confirmButtonText: this.$t('common.ok') as string,
      cancelButtonText: this.$t('common.cancel') as string,
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = this.$t('common.logouting') as string
          setTimeout(() => {
            done()
            setTimeout(() => {
              instance.confirmButtonLoading = false;
            }, 300)
          }, 700)
        } else {
          done()
        }
      },
    }).then(async () => {
      UserModule.MALogout()
      setTimeout(() => {
        window.location.href = '/'
      }, 500)
    }).catch(() => { })
  }

}
export default HomeBrowser
