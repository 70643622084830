var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-card" }, [
    _c(
      "div",
      {
        staticClass: "img-box",
        style: {
          opacity: _vm.isHover ? "0" : "1",
          width: _vm.boxSize.boxWidth + "rem",
          height: _vm.boxSize.boxHeight + "rem",
        },
        on: { mouseenter: _vm.handleEnterBox, mouseleave: _vm.handleLeave },
      },
      [
        _c("img", {
          staticClass: "imgs",
          style: { height: _vm.boxSize.imgHeight + "rem" },
          attrs: {
            src:
              (_vm.propObj.basic_info &&
                _vm.propObj.basic_info.medias &&
                _vm.propObj.basic_info.medias.cover &&
                _vm.getCosUrls(_vm.propObj.basic_info.medias.cover)) ||
              _vm.DefaultGameCover,
          },
        }),
        _c("div", { staticClass: "box" }, [
          _c("span", { attrs: { title: _vm.propObj.basic_info.app_name } }, [
            _vm._v(_vm._s(_vm.propObj.basic_info.app_name)),
          ]),
          _c("span", { staticClass: "tips" }, [_vm._v("免费")]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHover,
            expression: "isHover",
          },
        ],
        staticClass: "img-hover-box",
        class: _vm.isHover ? "show" : "",
        style: {
          width: _vm.hoverSize.boxWidth + "rem",
          left: "-" + _vm.boxSize.boxWidth / 20 + "rem",
          opacity: _vm.isHover ? "1" : "0",
        },
        on: {
          mouseleave: _vm.handleLeave,
          mouseenter: _vm.handleEnterHover,
          click: _vm.handleToGame,
        },
      },
      [
        _vm.curVideo
          ? _c("video", {
              staticClass: "imgs",
              style: { height: _vm.hoverSize.imgHeight + "rem" },
              attrs: {
                src: _vm._f("fCosBase")(_vm.curVideo),
                poster: _vm._f("fCosPoster")(_vm.curVideo),
                autoplay: "",
                loop: "",
                muted: "",
              },
              domProps: { muted: true },
            })
          : _c(
              "div",
              {
                staticClass: "cover",
                style: { height: _vm.hoverSize.imgHeight + "rem" },
              },
              _vm._l(_vm.mediaList, function (cover, i) {
                return _c("img", {
                  key: i,
                  staticClass: "imgs",
                  class: i == _vm.curCoverIndex ? "show" : "",
                  style: { opacity: i == _vm.curCoverIndex ? "1" : "0" },
                  attrs: { src: _vm._f("fCosBase")(cover.url) },
                })
              }),
              0
            ),
        !_vm.curVideo && !_vm.mediaList.length && _vm.isHover
          ? _c("div", { staticClass: "cover" }, [
              _c("img", {
                staticClass: "imgs",
                style: { height: _vm.boxSize.imgHeight + "rem" },
                attrs: {
                  src:
                    (_vm.propObj.basic_info &&
                      _vm.propObj.basic_info.medias &&
                      _vm.propObj.basic_info.medias.cover &&
                      _vm.getCosUrls(_vm.propObj.basic_info.medias.cover)) ||
                    _vm.DefaultGameCover,
                },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "hover-box" }, [
          _c(
            "div",
            {
              staticClass: "hover-box-cover",
              style: { height: _vm.hoverSize.coverHeight / 2 + "rem" },
            },
            [
              _c("img", {
                style: {
                  width: _vm.hoverSize.coverWidth + "rem",
                  height: _vm.hoverSize.coverHeight + "rem",
                },
                attrs: {
                  src:
                    (_vm.propObj.basic_info &&
                      _vm.propObj.basic_info.medias &&
                      _vm.propObj.basic_info.medias.cover &&
                      _vm.getCosUrls(_vm.propObj.basic_info.medias.cover)) ||
                    _vm.DefaultGameCover,
                  alt: "",
                },
              }),
              _c(
                "div",
                { staticClass: "platform" },
                _vm._l(_vm.platformList, function (val, i) {
                  return _c("span", { key: i }, [
                    _c("img", {
                      attrs: {
                        src: require(`../../../assets/shop/${val}.svg`),
                        alt: "",
                      },
                    }),
                  ])
                }),
                0
              ),
            ]
          ),
          _c("div", { staticClass: "hover-box-info" }, [
            _c("p", [_vm._v(_vm._s(_vm.propObj.basic_info.app_name))]),
            _c("div", { staticClass: "hover-box-info-bottom" }, [
              _c(
                "div",
                { staticClass: "hover-box-info-bottom-rate" },
                [
                  _c("el-rate", {
                    staticClass: "rate",
                    attrs: {
                      colors: _vm.scoreColors,
                      "void-color": _vm.scoreVoidColor,
                      "disabled-void-color": _vm.scoreVoidColor,
                      disabled: "",
                      "allow-half": true,
                    },
                    model: {
                      value: _vm.score,
                      callback: function ($$v) {
                        _vm.score = $$v
                      },
                      expression: "score",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.commentList) + "人评测")]),
                ],
                1
              ),
              _c("span", { staticClass: "tips" }, [_vm._v("免费")]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }