import { Policy } from '@/config';

// url
export const RegUrl = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

export const RegUrlLax = new RegExp('^((https|http)://)?'//(https或http):// 可有可无
  // + '(([\\w_!~*\'()\\.&=+$%-]+: )?[\\w_!~*\'()\\.&=+$%-]+@)?' //ftp的user@  可有可无（禁用）
  + '(([0-9]{1,3}\\.){3}[0-9]{1,3}' // IP形式的URL- 3位数字.3位数字.3位数字.3位数字
  + '|' // 允许IP和DOMAIN（域名） 
  + '(localhost)|'	//匹配localhost
  + '([\\w_!~*\'()-]+\\.)*' // 域名- 至少一个[英文或数字_!~*\'()-]加上.
  + '\\w+\\.' // 一级域名 -英文或数字  加上.
  + '[a-zA-Z]{1,6})' // 顶级域名- 1-6位英文
  + '(:[0-9]{1,5})?' // 端口- :80 ,1-5位数字
  + '((/?)|' // url无参数结尾 - 斜杆或这没有
  + '(/[\\w_!~*\'()\\.;?:@&=+$,%#-]+)+/?)$', 'i');//请求参数结尾- 英文或数字和[]内的各种字符
  
// ip地址
export const RegIp = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;

// nickname
export const RegNickname = new RegExp(`^[^\\s]{${Policy.MinNicknameLength},${Policy.MaxNicknameLength}}$`);

// email
export const RegEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// password
export const RegPassword = new RegExp(`^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]{${Policy.MinPasswordLength},${Policy.MaxPasswordLength}}$`);

// verify code
export const RegVerifyCode = new RegExp(`^[a-zA-Z0-9]{${Policy.VerifyCodeLength}}$`);

// 测试账号-用户名
export const RegTestAccUserName = new RegExp(`^[a-zA-Z0-9_]{${Policy.MinTestAccUserNameLength},${Policy.MaxTestAccUserNameLength}}$`);
