import { render, staticRenderFns } from "./info.vue?vue&type=template&id=14456ffd&scoped=true&"
import script from "./info.vue?vue&type=script&lang=ts&"
export * from "./info.vue?vue&type=script&lang=ts&"
import style0 from "./info.vue?vue&type=style&index=0&id=14456ffd&lang=scss&scoped=true&"
import style1 from "./info.vue?vue&type=style&index=1&id=14456ffd&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14456ffd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/landun/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14456ffd')) {
      api.createRecord('14456ffd', component.options)
    } else {
      api.reload('14456ffd', component.options)
    }
    module.hot.accept("./info.vue?vue&type=template&id=14456ffd&scoped=true&", function () {
      api.rerender('14456ffd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shop/info.vue"
export default component.exports