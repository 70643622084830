import { RouteConfig } from 'vue-router';

/**
 * 定义默认路由
 * @description 当单独打包时，会设置默认路由
 */
export const defaultRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: '/',
    component: () => import('@/layout/developer.vue'),
    redirect: '/application',
    meta: {
      isKeepAlive: true,
    },
    children: [],
  },
];


/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，
 * 会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'developer',
    component: () => import('@/layout/developer.vue'),
    redirect: '/application',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: '/application',
        name: 'developerApps',
        component: () => import(/* webpackChunkName: "dev-setting" */ '@/views/developer/application.vue'),
        meta: {
          module: 'base',
          title: 'route.developer.application',
          isHide: false,
          isKeepAlive: true,
          icon: 'el-icon-user',
          isNavigation: true
        },
      },
      {
        path: '/sdk',
        name: 'developerSDK',
        component: () => import(/* webpackChunkName: "dev-setting" */ '@/views/developer/sdk.vue'),
        meta: {
          module: 'base',
          title: 'route.developer.sdk',
          isHide: false,
          isKeepAlive: true,
          icon: 'el-icon-takeaway-box',
          isNavigation: true
        },
      },
      {
        path: '/document',
        name: 'developerDoc',
        component: () => import(/* webpackChunkName: "dev-setting" */ '@/views/developer/document.vue'),
        meta: {
          module: 'base',
          title: 'route.developer.document',
          isHide: false,
          icon: 'el-icon-tickets',
          // isLink: getDocumentUrl(),
          isNavigation: true
        },
      },
      {
        path: 'process',
        name: 'developerProcess',
        component: () => import(/* webpackChunkName: "dev-setting" */ '@/views/developer/process.vue'),
        meta: {
          module: 'base',
          isHide: true,
        },
      },
    ],
  },
  {
    path: '/app',
    name: 'developerApp',
    component: () => import('@/layout/blank.vue'),
    redirect: '/app/info',
    meta: {
      isHide: true,
      isKeepAlive: true,
    },
    children: [
      {
        path: 'info',
        name: 'developerAppInfo',
        component: () => import(/* webpackChunkName: "dev-app" */ '@/views/developer/app/info.vue'),
        meta: {
          module: 'app',
          isHide: true,
        },
      },
      {
        path: 'store',
        name: 'developerAppStore',
        component: () => import(/* webpackChunkName: "dev-app" */ '@/views/developer/app/store.vue'),
        meta: {
          module: 'app',
          isHide: true,
        },
      },
      {
        path: 'service',
        name: 'developerAppService',
        component: () => import(/* webpackChunkName: "dev-app" */ '@/views/developer/app/service.vue'),
        meta: {
          module: 'app',
          isHide: true,
        },
      },
      {
        path: 'data',
        name: 'developerAppData',
        component: () => import(/* webpackChunkName: "dev-app" */ '@/views/developer/app/data.vue'),
        meta: {
          module: 'app',
          isHide: true,
        },
      },
    ],
  },
  {
    path: '/idocs',
    name: 'developerDocs',
    component: () => import('@/layout/iframe.vue'),
    meta: {
      module: 'idocs',
      isHide: true,
      noFrame: true,
    },
  },
  {
    path: '/session',
    name: 'developerSession',
    component: () => import(/* webpackChunkName: "dev-session" */ '@/views/developer/session.vue'),
    meta: {
      title: 'route.developer.home',
      noSession: true,
    },
  },
  {
    path: '/home',
    name: 'developerHome',
    component: () => import(/* webpackChunkName: "dev-developer" */ '@/views/developer/homeAI.vue'),
    meta: {
      title: 'route.developer.home',
      noSession: false,
    },
  },
];
