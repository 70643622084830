import axios from 'axios';
import { AxiosRequestExtConfig, AxiosResponse } from 'axios';
import { Message } from 'element-ui';
import { OasisSessionKey, ResponseCode, SessionKey } from '@/config';
import { Local, clearUserSession } from '@/utils/storage';
import { getCache } from '@/utils/cache';

import { AccountName } from '@/utils/env';
import router from '@/router/account';

declare module 'axios' {
  interface AxiosRequestExtConfig extends AxiosRequestConfig {
    noSessionToken: boolean   // 是否带X-Session-Token的header，并前置校验session
    noCookie: boolean   // 是否带x-cookie
  }
  // type AxiosInstance = (config: AxiosRequestExtConfig) => Promise<any>;
}

const initOptsRemote = {
  withCredentials: true,
  timeout: 0,
  headers: { 'Content-Type': 'application/json' },
  baseUrl: './'
};

const isAccountUrl = url => (url || '').indexOf(AccountName) >= 0;
const isAccountEnv = isAccountUrl(location.origin);
const clearAndLogout = () => {
  Message.error('登录已过期，请重新登录');
  setTimeout(() => {
    clearUserSession();
    window.location.href = `${location.origin}/account/login`;
  }, 100);
};
const reqOpts = {
  success: (config: AxiosRequestExtConfig) => {
    if (!config.noSessionToken) {
      const oasisSession = Local.get(OasisSessionKey);
      if (oasisSession) {
        (config.headers as any)['user_id'] = oasisSession['user_id'] || '';
        (config.headers as any)['verify_session'] = oasisSession['verify_session'] || '';
      }
    }
    if (!config.noCookie) {
      const cookie = getCache('cookie');
      if (cookie) {
        (config.headers as any)['x-cookie'] = JSON.stringify(cookie);
      }
    }
    if (config.url?.indexOf('/openpf/') === 0) {
      config.baseURL = '';
    }
    return config;
  },
  error: (err: any) => Promise.reject(err),
};
const resOpts = {
  success: (response: AxiosResponse<any, any>) => {
    // trpc错误处理
    if (response.headers['trpc-func-ret']) {
      return Promise.reject(response.headers['trpc-error-msg']);
    }
    const res = response.data;
    if ((res.code && res.code !== 0) || (res.ret && res.ret !== 0)) {
      if (res.code === ResponseCode.SESSION_INVALID) {
        clearAndLogout();
      }
      return Promise.reject(service.interceptors.response);
    }
    return response.data;
  },
  error: (error: any) => {
    console.dir(error);
    // 对响应错误做点什么
    if (error.message.indexOf('timeout') !== -1) {
      Message.error('网络超时');
    } else if (error.message === 'Network Error') {
      // 非账号系统跨域调用，登录态失效会直接跨域错误
      // if (!isAccountEnv && isAccountUrl(error.config?.url)) clearAndLogout();
      // else 
      Message.error('网络连接错误');
    } else {
      if (!error.response) {
        Message.error('网络连接错误');
        return Promise.reject(error);
      }
      const httpStatus = error.response.status;
      // 400为业务错误，reject
      if (httpStatus === 400) return Promise.reject(error.response.data);
      const errPageMap = {
        // 401: '/error/401',
        // 404: '/error/404',
      };
      if (httpStatus === 401 || httpStatus === 403) {
        clearAndLogout();
      } else if (errPageMap[httpStatus]) {
        router.push({ path: errPageMap[httpStatus] });
      } else if (error.response.data) Message.error(error.response.data);
      else Message.error('接口请求失败');
    }
    return Promise.reject();
  },
};

const service: any = axios.create(initOptsRemote);
service.interceptors.request.use(reqOpts.success, reqOpts.error);
service.interceptors.response.use(resOpts.success, resOpts.error);
export default service;
