var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "dialog",
      on: {
        touchmove: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hide.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "box",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "header" }, [
            _c("i", { staticClass: "btn-close", on: { click: _vm.hide } }),
          ]),
          _c("div", { staticClass: "body" }, [
            _c(
              "div",
              { staticClass: "cover" },
              [
                _vm.curCover
                  ? [
                      _vm.curCover.type == "video"
                        ? _c("video", {
                            staticClass: "cover-pic",
                            attrs: {
                              src: _vm.curCover.url,
                              poster: _vm.curCover.url,
                              muted: "",
                              autoplay: "",
                              controls: "",
                              controlslist: "nodownload",
                            },
                            domProps: { muted: true },
                          })
                        : _c("img", {
                            staticClass: "cover-pic",
                            attrs: { src: _vm.curCover.url },
                          }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "btn-left", on: { click: _vm.onPre } }, [
              _c("i", { staticClass: "el-icon-arrow-left" }),
            ]),
            _c("div", { staticClass: "number" }, [
              _c("span", [_vm._v(_vm._s(_vm.curIndex + 1))]),
              _vm._v(" / "),
              _c("span", [_vm._v(_vm._s(_vm.list.length))]),
            ]),
            _c("div", { staticClass: "btn-right", on: { click: _vm.onNext } }, [
              _c("i", { staticClass: "el-icon-arrow-right" }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }