var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gate-guide", attrs: { lang: _vm.lang } },
    [
      _c("guideBanner"),
      _c("guideEvents"),
      _c("guideAbout"),
      _c("guideCase"),
      _c("guidePartners"),
      _c("guideContact"),
      _c("guideFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }