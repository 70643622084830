<template>
    <div class="gate-article-add">
      <PublishHeader />
      <div class="article-container">
        <div class="editor-card">
          <div class="editor-title">
            <el-input v-model="articleTitle" placeholder="Title (Maximum 100 Words)"></el-input>
          </div>
          <div class="editor-title">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="Short Introduction (Optional)"
              v-model="introduction" />
          </div>
          <TinyEditor ref="articleEditor" />
        </div>
        <div class="divisionLine" ></div>
        <div class="option-card">
          <div class="option-title">Setting</div>
          <ArticleOptionFormVue ref="articleOption"/>
        </div>
      </div>
      <div class="style-spacer" />
      <ArticleFooter @onSave="onSave"/>
    </div>
  </template>
  
  <script>
  import TinyEditor from '@/components/gateEditor/tinyEditor.vue';
  import ArticleOptionFormVue from './components/articleOptionForm.vue';
  import ArticleFooter from './components/articleFooter.vue';
  import PublishHeader  from './components/publishHeader.vue';
  import { queryArticleDraft } from '@/api/article';
  export default {
    name: 'gate-article-add',
    components: { TinyEditor, ArticleOptionFormVue, PublishHeader, ArticleFooter },
    data() {
      return {
        articleTitle: '',
        introduction: '',
        articleId: '', // 知识文章
        userId: '', // 用户Id
      }
    },
    mounted() {
      // this.getArticleDraft();
    },
    methods: {
      async getArticleDraft() {
        const result = await queryArticleDraft({});
        console.log('result', result);
      },
      async onSave() {
        const articleOption = await this.$refs.articleOption.submitForm();
        console.log(articleOption);
      },
      checkArticle(option) {
        const params = {
          lore_essay_id: this.articleId,
          lore_essay_type: option.lore_essay_type,
          title: this.articleTitle,
          profiles: this.introduction,
          ...option,
          status: 0
        };
      },
      getArticleContent() {
        return this.$refs.articleEditor.getContent();
      },
      recoveryArticleContent(content) {
        this.$refs.articleEditor.setContent(content);
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  body{
    background: #000;
  }
  .gate-article-add{
    display: flex;
    justify-content: center;
    background: #000;
    flex-direction: column;
    align-items: center;
    .article-container{
      width: 1200px;
      .editor-card{
        display: flex;
        flex-direction: column;
        .editor-title{
          font-size: 32px;
          color: #333;
          width: 700px;
          padding: 10px 0px 15px 4px;
          :deep(.el-input__inner){
            font-size: 32px;
            color: #333;
            width: 700px;
            padding: 30px 0px 20px 4px;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #eee;
          }
          :deep(.el-textarea__inner){
            font-size: 16px;
            color: #333;
            width: 700px;
            border: none;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 5px;
            height: 28px;
            overflow: hidden;
            border: none;
            resize: none;
          }
        }
      }
      .divisionLine{
        margin: 40px 0;
        width: 100%;
        height: 1px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0.10);
      }
      .option-card{
        margin-top: 40px;
      }
    }
    .style-spacer{
      width: 100%;
      height: 96px;
    }
  }
  
  </style>
  