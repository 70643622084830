var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "case-carousel-card",
      style: { width: _vm.width + "px", height: _vm.height + "px" },
    },
    [
      _c("div", { staticClass: "videoContainer", on: { click: _vm.onPlay } }, [
        _c("video", {
          ref: "videoEle",
          staticClass: "imgs",
          attrs: {
            preload: "auto",
            loop: "",
            controls: _vm.isPlaying,
            muted: "",
            width: "100%",
            src: _vm.videoUrl,
          },
          domProps: { muted: true },
        }),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPlaying,
              expression: "!isPlaying",
            },
          ],
          staticClass: "info",
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c("div", { staticClass: "introduction" }, [
            _vm._v(" " + _vm._s(_vm.introduction) + " "),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }