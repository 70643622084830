
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "Component-Modal-Center",
  components: {},
})
class ComponentModalCenter extends Vue {

  @Prop({ required: false, default: false }) public visible;
  @Prop({ required: false }) private beforeOk;

  @Watch('visible')
  onVisibleChanged(val: string) {
    if(val) this.$emit("open")
    else this.$emit("close")
  }

  created() {

  }
  mounted() {

  }

  public show() {
    this.$emit("update:visible", true)
  }
  public hide() {
    this.$emit("update:visible", false)
  }
  public async onOk() {
    if (typeof this.beforeOk == 'function') {
      let result = this.beforeOk()
      if (result instanceof Promise) {
        try {
          result = await result
          if (!result) return
        }
        catch (err) {
          return
        }
      }
    }
    this.$emit('ok')
    this.hide()
  }
  public onCancel() {
    this.$emit('cancel')
    this.hide()
  }

}
export default ComponentModalCenter
