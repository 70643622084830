var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "page-developer-app-service" } },
    [
      _c("h1", { staticClass: "service-title" }, [_vm._v("账户服务")]),
      _c(
        "el-tabs",
        {
          staticClass: "service-tabs",
          model: {
            value: _vm.tab.service,
            callback: function ($$v) {
              _vm.$set(_vm.tab, "service", $$v)
            },
            expression: "tab.service",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "测试账号", name: "testAcc" } },
            [_vm.tab.service == "testAcc" ? _c("InfoTestAcc") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }