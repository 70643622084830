
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinUpload from '@/mixins/upload'
import MixinAISeason from '@/mixins/aiSeason'
import Picture from '@/components/upload/picture.vue'
import { getFileName, splitFileName, ZipFileTypeList } from '@/utils/file'
import { AStoreAIBuildAuditGet, AStoreAIBuildAuditSub } from '@/api/developerApi/audit'
import { AStoreAIBuildAdd, AStoreAIBuildPkgUpt } from '@/api/developerApi/gamePackage'
import { DeveloperModule } from '@/store/modules/developer'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'CompStoreAIBuild',
  components: {
    Picture,
  }
})
class CompStoreAIBuild extends mixins(MixinUpload, MixinAISeason) {
  public ZipFileTypeList = ZipFileTypeList
  public form = {
    package: '',
    packageName: '',
  }
  public fileList: any = []
  public rules = {
    package: [
      { required: true, message: '请上传构件', trigger: 'change', },
    ],
  }
  public loading = {
    aiBuild: false,
    save: false,
    upload: false,
  }
  public visible = {
    dupConfirm: false,
  }
  public alreadyHasAuditPkg = false
  private appId = this.$route.query.appId as string

  get cHasChanged() {
    return true
  }
  get storeInfo() {
    return this.devModule.storeData.info
  }
  get storeMeidas() {
    return this.devModule.storeData.medias
  }
  // get devModule() {
  //   return this.$store.state.developer
  // }
  get aiBuildInfo() {
    return this.devModule.aiBuild
  }

  @Watch('aiBuildInfo', { deep: true })
  onAiBuildInfoChanged(val: any) {
    if (val) this.initPackage()
  }

  mounted() {
    this.initData()
  }

  public onCancel() {
    this.reset()
    this.$message.success("已重置")
  }

  private checkForm(preCheck = true) {
    return new Promise((resolve) => {
      // 前置校验，必须先提交类别等基本信息
      if (preCheck) {
        if (!this.aiBuildInfo.created || this.storeInfo.productName === '') {
          this.$message.info("请先填写作品类别等基本信息再上传")
          resolve(false)
          return
        }
        if (this.storeMeidas.cover == '' || (this.storeMeidas.pictures || []).length === 0) {
          this.$message.info("请先上传封面图、宣传图片等素材再上传")
          resolve(false)
          return
        }
      }
      let formEl = this.$refs.formRef as ElForm
      if (!formEl) {
        resolve(false)
        return
      }
      formEl.validate((valid: boolean) => {
        if (!valid) {
          resolve(false)
          return
        }
        resolve(true)
      })
    })
  }

  private execSubmit() {
    return new Promise((resolve, reject) => {
      if (!this.aiBuildInfo.created) {
        const aiParam = {
          type: 'game',
          tracks: ['game'],
          ppt: '',
          package: `${this.form.package}|${this.form.packageName}`,
          packgename: '',
        }
        AStoreAIBuildAdd(this.appId, aiParam).then((res) => {
          DeveloperModule.MAGetDevAIBuild(this.appId)
          resolve(res)
        }).catch(reject)
      }
      else {
        const aiParam = {
          package: `${this.form.package}|${this.form.packageName}`,
          packgename: '',
        }
        AStoreAIBuildPkgUpt(this.appId, aiParam).then((res) => {
          DeveloperModule.MAGetDevAIBuild(this.appId)
          resolve(res)
        }).catch(reject)
      }
    })
  }
  public async submitForm() {
    this.$report('dev/store/build/save', 'click', { appid: this.appId })
    this.loading.save = true;
    this.checkForm(false).then((result) => {
      if (!result) {
        this.loading.save = false;
        return
      }
      this.execSubmit().then(() => {
        this.loading.save = false;
        this.$message.success(this.$t('tips.succ.edit') as string)
      }).catch(() => {
        this.loading.save = false;
        this.$message.error(this.$t('tips.fail.edit') as string)
      })
    })
  }
  public onPublishClosed() {
    this.$message.info("上传失败，提交作品时间已经截止")
  }
  public onPublish() {
    this.$report('dev/store/build/audit', 'click', { appid: this.appId })
    this.loading.save = true
    this.checkForm(true).then((result) => {
      if (!result) {
        this.loading.save = false;
        return
      }
      this.getAuditInfo().then(() => {
        if (this.alreadyHasAuditPkg) {
          this.loading.save = false
          this.visible.dupConfirm = true
          return
        }
        this.saveAndSubmit().then(() => {
          this.loading.save = false;
          this.$message.success(this.$t('tips.succ.submit') as string)
          this.$router.push('/process')
        }).catch((err) => {
          console.error(err)
          this.loading.save = false;
          this.$message.error(this.$t('tips.fail.submit') as string)
        })
      })
    })
  }
  public confirmAudit() {
    this.loading.save = true
    this.checkForm().then((result) => {
      if (!result) {
        this.loading.save = false;
        return
      }
      // 先提交保存再入库
      this.saveAndSubmit().then(() => {
        this.loading.save = false;
        this.$message.success(this.$t('tips.succ.submit') as string)
        this.$router.push('/process')
      }).catch((err) => {
        console.error(err)
        this.loading.save = false;
        this.$message.error(this.$t('tips.fail.submit') as string)
      })
    })
  }
  private saveAndSubmit() {
    return new Promise((resolve, reject) => {
      this.execSubmit().then(() => {
        AStoreAIBuildAuditSub(this.appId).then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  public onUploadSuccess(res, file) {
    console.log("onUploadSuccess", res, file)
    this.loading.upload = false
    let fileName = getFileName(res.url) as string
    this.form.package = fileName
    this.form.packageName = file.name
    this.fileList = [
      {
        name: this.form.packageName,
        url: this.form.package
      }
    ]
  }
  public onUploadError(error, file) {
    console.log("onUploadError", error, file)
    this.$message.error("上传失败 " + error)
    this.loading.upload = false
    if (!this.fileList.length) this.fileList = []
  }
  public beforeUpload(file) {
    (this.$refs.formRef as ElForm)?.clearValidate()
    const { name, ext = '' } = splitFileName(file.name)
    const extLimit = this.ZipFileTypeList
    if (!extLimit.includes(ext)) {
      this.$message.error('格式错误，只支持' + extLimit.join() + '格式')
      this.fileList = []
      return false
    }
    this.loading.upload = true
    return true
  }
  public onRemove() {
    this.fileList = []
    this.form.package = ''
    this.form.packageName = ''
  }

  private async initData() {
    this.loading.aiBuild = true
    if (!this.devModule.init.aiBuild) await DeveloperModule.MAGetDevAIBuild(this.appId)
    this.loading.aiBuild = false
    this.initPackage()
  }
  private initPackage() {
    let packageFile = (this.aiBuildInfo.package || '').split('|')
    this.form.package = packageFile[0] || ''
    this.form.packageName = packageFile[1] || ''
    // fileList特殊处理，判断是否有修改，不触发动画
    if (this.form.package) {
      if (this.fileList.length && this.fileList[0].name === (this.form.packageName || this.form.package) && this.fileList[0].url === this.form.package) return
      this.fileList = [
        {
          name: this.form.packageName || this.form.package,
          url: this.form.package
        }
      ]
    }
    else {
      if (this.fileList.length === 0) return
      this.fileList = []
    }
  }
  private getAuditInfo() {
    return new Promise((resolve, reject) => {
      AStoreAIBuildAuditGet(this.appId).then(({ data }) => {
        this.alreadyHasAuditPkg = !!data
        resolve(null)
      }).catch(reject)
    })
  }
  private reset() {
    (this.$refs.formRef as ElForm)?.clearValidate()
    this.initPackage()
  }
}
export default CompStoreAIBuild
