import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { ConfigState } from '@/store/interface/index';
import store from '@/store';

export type IConfigState = ConfigState;

@Module({ dynamic: true, store, name: 'config' })
class Config extends VuexModule {
  public title = 'Oasis';

  @Action
  public async MASetTitle(title: string) {
    this.MMSetTitle(title);
  }

  @Mutation
  private MMSetTitle(title: string) {
    this.title = title;
  }
}

export const ConfigModule = getModule(Config);
