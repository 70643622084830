import { render, staticRenderFns } from "./game.vue?vue&type=template&id=7fe4d0ee&scoped=true&"
import script from "./game.vue?vue&type=script&lang=ts&"
export * from "./game.vue?vue&type=script&lang=ts&"
import style0 from "./game.vue?vue&type=style&index=0&id=7fe4d0ee&lang=scss&scoped=true&"
import style1 from "./game.vue?vue&type=style&index=1&id=7fe4d0ee&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe4d0ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/landun/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7fe4d0ee')) {
      api.createRecord('7fe4d0ee', component.options)
    } else {
      api.reload('7fe4d0ee', component.options)
    }
    module.hot.accept("./game.vue?vue&type=template&id=7fe4d0ee&scoped=true&", function () {
      api.rerender('7fe4d0ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/game.vue"
export default component.exports