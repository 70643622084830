
import { Component, Vue } from 'vue-property-decorator';
import User from '@/layout/navBars/breadcrumb/user.vue';
import { OpenDomain } from '@/utils/env'
import { getDocumentUrl } from '@/utils'

@Component({
  name: 'AIheader',
  components: { User },
})

class AIheader extends Vue {
  public isMobile = false;
  public isShowMenu = false;

  created() {
    this.isMobile =  this.getMode() === 'h5' ? true : false;
  }
  public openPath(url: string) {
    window.open(`${OpenDomain}${url}`)
  }
  public goToDocument() {
    let docUrl = getDocumentUrl()
    window.open(`${OpenDomain}/idocs?uri=${encodeURIComponent(docUrl)}`)
  }
  public toPath(path: string) {
    this.$router.push(path);
    // @ts-ignore
    this.$parent.initMenu();
  }
  public isShowNav() {
    this.isShowMenu = !this.isShowMenu;
  }
  public getMode() {
    const html = document.documentElement;
    const cliWidth = html.clientWidth;
    return cliWidth <= 750 ? 'h5': 'pc';
  }
}

export default AIheader
