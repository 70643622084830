import { SessionKey } from '@/config';
import { Local } from '@/utils/storage';
import { OpenDomain, isLocal } from '@/utils/env';

const BaseUrl = `${OpenDomain}/api`;
const ReportUrlMap = {
  "widthSession": `${BaseUrl}/log/report`,
  "noSession": `${BaseUrl}/public/log/report`,
}
function sendReport(url, path, event, param, token) {
  if (isLocal) {
    console.info("本地上报", { url, path, event, param, token });
    return;
  }
  try {
    let fullUrl = `${url}?path=${path}&event=${event}`;
    for (let [k, v] of Object.entries(param)) {
      // 对象序列化
      if (typeof v === 'object') {
        try {
          v = JSON.stringify(v);
        }
        catch (e) {
          v = '';
          console.error(`上报序列化失败:${k}`, e);
        }
      }
      if (v) fullUrl += `&${k}=${v}`;
    }
    let xhr = new XMLHttpRequest();
    xhr.open("GET", fullUrl, true);
    xhr.setRequestHeader("X-Session-Token", token);
    xhr.onload = console.log;
    xhr.send(null);
  }
  catch (e) {
    console.error("上报失败", e);
  }
}

/**
 * 发送上报请求
 * @param path 路径
 * @param event 事件类型
 * @param content 内容 json
 * @param withSession 是否带登录态
 */
export function AReport(path, event, param = {}, withSession = true) {
  const sSession = Local.get(SessionKey);
  // 无session回退到不带登录态
  if (!sSession && withSession) withSession = false;
  sendReport(ReportUrlMap[withSession ? "widthSession" : "noSession"], path, event, param, withSession ? sSession.token : '');
}