var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-upload",
    _vm._b(
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.upload,
            expression: "loading.upload",
          },
        ],
        staticClass: "comp-upload",
        attrs: {
          "element-loading-text": _vm.progressTips,
          action: "",
          "before-upload": _vm.$listeners.beforeUpload,
          "on-change": _vm.onFileChange,
          "on-error": _vm.onUploadError,
          "auto-upload": false,
        },
        nativeOn: {
          drop: function ($event) {
            return _vm.onDrop.apply(null, arguments)
          },
        },
      },
      "el-upload",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }