import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

// 用户应用列表
export async function AAppList() {
  return request({
    url: `${BaseUrl}/app/getusersapps`,
    method: 'GET',
  });
}
// 获取应用信息
export async function AAppGet(appid: string) {
  return request({
    url: `${BaseUrl}/app/query?appid=${appid}`,
    method: 'GET',
  });
}
// 新增应用
export async function AAppAdd(data: object) {
  return request({
    url: `${BaseUrl}/app/create`,
    method: 'POST',
    data,
  });
}
// 更新应用信息
export async function AAppUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/update?appid=${appid}`,
    method: 'POST',
    data,
  });
}
// 删除应用
export async function AAppDel(appid: string) {
  return request({
    url: `${BaseUrl}/app/delete?appid=${appid}`,
    method: 'POST',
  });
}
