
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AOauthConsent } from '@/api/oauth';
import { Local } from '@/utils/storage';
import { getParam } from '@/utils';
import { SessionKey } from '@/config';
import { isGameClient, isShippingClient } from '@/utils/env';

@Component({
  name: 'OauthConsent',
  components: {},
})
class OauthConsent extends Vue {
  public loading = {
    auth: false,
  }
  private authList = ["email", "openid", "offline"]   // "email", "openid", "offline"
  private consentChallenge = ''

  beforeMount() {
    let consentChallenge = getParam('consent_challenge')
    if (!consentChallenge) {
      this.$message.error("缺少授权参数")
      return
    }
    this.consentChallenge = consentChallenge
    let session = Local.get(SessionKey)
    if (!session) {
      let conf: any = { path: '/account/login', query: { redirect: '/oauth/consent', type: 'oauth', params: JSON.stringify({ consent_challenge: consentChallenge }) } }
      // 内测包，自动触发ioa登录
      // if (isGameClient && !isShippingClient) {
      //   conf.query.channel = 'ioa'
      // }
      this.$router.replace(conf)
    }
    else {
      // 0701修改：自动授权
      this.onAuthorize(true)
    }
  }
  mounted() {

  }

  public onAuthorize(status: boolean) {
    if (!status) {
      this.$message.info("拒绝授权，回调客户端");
      window.oasis?.loginFail("Reject");
      return;
    }
    const param = {
      "grant_scope": this.authList,
      "remember": true,
      "remember_for": 3600,
      "consent": true,
    }
    AOauthConsent(param, this.consentChallenge).then((res: any) => {
      let data = res.data || {}
      if (data.code) {
        this.$message.error("授权失败：" + data.message)
        return
      }
      // 压测——每次清除登录态
      // Local.remove(SessionKey)
      let redirectTo = data.redirect_to || ''
      location.href = redirectTo
      // console.log("consent data", data)
      // setTimeout(() => {
      //   location.href = redirectTo
      // }, 10000)
    }).catch(() => {
      this.$message.error("授权失败")
    })
  }

}
export default OauthConsent
