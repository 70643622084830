export const tinyApiKey = 'ub8oh8oe6s7gfeda06lspuse75v5qdhk1i39zmxziaw30x3y';
export const cherryId = 'gateCherryIdsdg';

export const gateEditorConfig = {
    plugins: 'image link lists advlist autosave codesample table textcolor',
    toolbar: 'undo redo  | fontsizeselect | formatselect | forecolor backcolor | bold italic underline strikethrough | link image media table mergetags | codesample | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    menubar: false,
    // font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif',
    fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
    // branding: false,
    resize: false,
    content_css: [ '//www.tiny.cloud/css/codepen.min.css' ],
    statusbar: false,
    images_upload_url: '/demo/upimg',
    images_upload_base_path: '/',
    // images_upload_handler: function(blobInfo, success, fail) {}, 自定义上传逻辑
    // autosave_ask_before_unload: true
  };

  export const markdownEditorConfig = {
    externals: {
    },
    engine: {
      global: {
        urlProcessor(url, srcType) {
          console.log(`url-processor`, url, srcType);
          return url;
        },
      },
      syntax: {
        table: {
          enableChart: false,
          // chartEngine: Engine Class
        },
        fontEmphasis: {
          allowWhitespace: true, // 是否允许首尾空格
        },
        mathBlock: {
          engine: 'MathJax', // katex或MathJax
          src: 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js', // 如果使用MathJax将js在此处引入，katex则需要将js提前引入
        },
        inlineMath: {
          engine: 'MathJax', // katex或MathJax
        },
        emoji: {
          useUnicode: false,
          customResourceURL: 'https://github.githubassets.com/images/icons/emoji/unicode/${code}.png?v8',
          upperCase: true,
        },
        // toc: {
        //     tocStyle: 'nested'
        // }
        // 'header': {
        //   strict: false
        // }
      },
      customSyntax: {
        // SyntaxHookClass
        // CustomHook: {
        //   syntaxClass: CustomHookA,
        //   force: false,
        //   after: 'br',
        // },
      },
    },
    toolbars: {
      toolbar: [
        'switchModel',
        '|',
        'bold',
        'italic',
        'strikethrough',
        '|',
        'color',
        'header',
        '|',
        'list',
        {
          insert: ['image', 'audio', 'video', 'link', 'hr', 'br', 'code', 'formula', 'toc', 'table', 'pdf', 'word'],
        },
        'graph',
        'settings',
      ],
      bubble: false
    },
    editor: {
      defaultModel: 'editOnly',
    },
    previewer: {
      // 自定义markdown预览区域class
      // className: 'markdown'
    },
    keydown: [],
    //extensions: [],
  };
  