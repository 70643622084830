import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { ADevGetInfo, AStoreGet, AAISeasonGet } from '@/api/developerApi/developer';
import { AAppList, AAppGet} from '@/api/developerApi/app';
import { AStoreAIBuildGET } from '@/api/developerApi/gamePackage';
import { DeveloperState } from '@/store/interface/index';
import { deepClone } from '@/utils';
import store from '@/store';

export type IDeveloperState = DeveloperState;
const defaultData = {
  info: {
    developer_id: '',
    surname: '',
    name: '',
    email: '',
    extra: '',
    create_on: 0,
    update_at: 0,
  },
  aiSeason: {
    leave: 0,
    period: 0,   // 第n期，0指第一期
    startupload: 0,
    endupload: 0,
    starttime: 0,
    endtime: 0,
    displayleave: false,
  },
  apps: [],
  appData: {
    appId: '',
    userId: '',
    appName: '',
    status: '',
    cover: '',
    stage: 0,
    createdOn: 0,
    updateAt: 0,
    clients: [],
    env: [],
    groups: [],
  },
  storeData: {
    info: {
      developer: '',
      mediums: [],
      productDetail: '',
      productName: '',
      productStatus: '',
      pubdate: '',
      publisher: '',
    },
    builds: [],
    medias: {
      cover: '',
      movie: '',
      pictures: [],
    },
    exp: {
      enableExperience: false,
      developerComment: '',
    },
    gcloud: {
      uPufferProductId: 0,
      strPufferServerUrl: '',
      nPufferGameId: '',
      uPufferDolphinProductId: 0,
      channelId: 0,
      updateUrl: '',
      GameId: '',
      GameKey: '',
    },
  },
  aiBuild: {
    created: false,
    type: '',
    ppt: '',
    package: '',
    tracks: []
  }
};

@Module({ dynamic: true, store, name: 'developer' })
class Developer extends VuexModule {
  public init = {
    info: false,
    apps: false,
    store: false,
    aiBuild: false,
    aiSeason: false,
  };
  public info = deepClone(defaultData.info);
  public apps = deepClone(defaultData.apps);
  public appData = deepClone(defaultData.appData);
  public storeData = deepClone(defaultData.storeData);
  public aiBuild = deepClone(defaultData.aiBuild);
  public aiSeason = deepClone(defaultData.aiSeason);

  @Action
  public async MAReset(keys: string[]) {
    this.MMReset(keys);
  }
  @Action
  public async MAGetDevInfo() {
    // 获取开发者数据
    return new Promise((resolve) => {
      ADevGetInfo().then((res: any) => {
        this.MMSetDevInfo(res.data);
        this.init.info = true;
        resolve(null);
      })
        .catch(() => {
          this.MMSetDevInfo({});
          resolve(null);
        });
    });
  }
  @Action
  public async MAGetAISeason() {
    // this.MMSetAISeason({
    //   leave: 0,
    //   period: 0,
    //   startupload: 1686483357,
    //   endupload: 1686593357,
    //   starttime: 0,
    //   endtime: 0,
    // });
    // this.init.aiSeason = true;
    // return
    return new Promise((resolve) => {
      AAISeasonGet().then((res: any) => {
        this.MMSetAISeason(res);
        // this.MMSetAISeason({
        //   leave: res.leave,
        //   period: res.period,
        //   startupload: res.startupload,
        //   endupload: res.endupload,
        //   starttime: res.starttime,
        //   endtime: res.endtime,
        // });
        this.init.aiSeason = true;
        resolve(null);
      })
        .catch(() => {
          this.MMSetAISeason({});
          resolve(null);
        });
    });
  }
  @Action
  public async MASetDevInfo(data) {
    this.MMSetDevInfo(data);
  }
  @Action
  public async MAGetDevApps() {
    return new Promise((resolve) => {
      AAppList().then((res: any) => {
        this.MMSetDevApps(res.data);
        this.init.apps = true;
        resolve(null);
      })
        .catch(() => {
          this.MMSetDevApps([]);
          resolve(null);
        });
    });
  }
  @Action
  public async MAGetDevAppData(appId) {
    return new Promise((resolve) => {
      AAppGet(appId).then((res: any) => {
        this.MMSetDevAppData(res.data);
        resolve(null);
      })
        .catch(() => {
          this.MMSetDevAppData({});
          resolve(null);
        });
    });
  }
  @Action
  public async MAGetDevStore(appId) {
    return new Promise((resolve) => {
      AStoreGet(appId).then((res: any) => {
        this.MMSetDevStore(res.data);
        this.init.store = true;
        resolve(null);
      })
        .catch(() => {
          this.MMSetDevStore({});
          resolve(null);
        });
    });
  }
  @Action
  public async MAGetDevAIBuild(appId) {
    return new Promise((resolve) => {
      AStoreAIBuildGET(appId).then((res: any) => {
        this.MMSetAIBuild(res.data);
        this.init.aiBuild = true;
        resolve(null);
      })
        .catch(() => {
          this.MMSetAIBuild(null);
          resolve(null);
        });
    });
  }

  @Mutation
  private MMReset(keys: string[] = Object.keys(this.init)) {
    // console.log("MMReset", keys)
    for (const k of keys) {
      this.init[k] = false;
      if (defaultData[k]) this[k] = deepClone(defaultData[k]);
    }
  }
  @Mutation
  private MMSetDevInfo(data: any) {
    this.info = Object.assign(this.info, data);
  }
  @Mutation
  private MMSetAISeason(data: any) {
    this.aiSeason = Object.assign(this.aiSeason, data);
  }
  @Mutation
  private MMSetDevApps(data: any) {
    this.apps = data || [];
  }
  @Mutation
  private MMSetDevAppData(data: any) {
    if (!data) data = {};
    this.appData = Object.assign(this.appData, data);
  }
  // @Mutation
  // private MMSetDevDeploys(data: any) {
  // 	this.deploys = data || [];
  // }
  @Mutation
  private MMSetDevStore(data: any) {
    if (!data) {
      this.storeData = deepClone(defaultData.storeData);
      return;
    }
    this.storeData.info = Object.assign(this.storeData.info, data.info || {});
    this.storeData.builds = data.builds || [];
    this.storeData.medias = Object.assign(this.storeData.medias, data.medias || {});
    this.storeData.exp = Object.assign(this.storeData.exp, data.exp || {});
  }
  @Mutation
  private MMSetAIBuild(data: any) {
    // console.log("MMSetAIBuild", data)
    if (!data) {
      this.aiBuild = deepClone(defaultData.aiBuild);
      this.aiBuild.created = false;
      return;
    }
    this.aiBuild.created = true;
    this.aiBuild.type = data.type || '';
    this.aiBuild.ppt = data.ppt || '';
    this.aiBuild.package = data.package || '';
    this.aiBuild.tracks = data.tracks || [];
    this.aiBuild = Object.assign({}, this.aiBuild)
  }
}

export const DeveloperModule = getModule(Developer);
