var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-info-group" } },
    [
      _c(
        "section",
        [
          _c("h2", [_vm._v("玩家组")]),
          _c("p", { staticClass: "title-desc" }, [
            _vm._v("对不同的身份群体进行分组，用于控制访问权限"),
          ]),
          _c("div", { staticClass: "table-head" }, [
            _c("span", { staticClass: "sum" }, [
              _vm._v(_vm._s(_vm.list.length) + "个玩家组"),
            ]),
            _c(
              "div",
              {
                staticClass: "btn-add",
                on: {
                  click: function ($event) {
                    return _vm.showEditDrawer(null)
                  },
                },
              },
              [_vm._v("添加玩家组")]
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.list,
                  expression: "loading.list",
                },
              ],
              staticClass: "table-list",
              attrs: { data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: { label: "群组名称与ID" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [_vm._v(_vm._s(scope.row.name))]),
                        _c("p", [_vm._v(_vm._s(scope.row.id))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "users", label: "账户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [_vm._v(_vm._s(scope.row.users.join(";")))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "deploy", label: "部署" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.findBuild(
                                scope.row.sandbox[0] &&
                                  scope.row.sandbox[0].deploy
                              ).name || ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "build", label: "构件" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.findBuild(
                                  scope.row.sandbox[0] &&
                                    scope.row.sandbox[0].deploy
                                ).build || ""
                              ).split(";").length
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operation",
                  "class-name": "col-operation",
                  label: "操作",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit btn-edit",
                          on: {
                            click: function ($event) {
                              return _vm.showEditDrawer(scope.row)
                            },
                          },
                        }),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.delete,
                                expression: "loading.delete",
                              },
                            ],
                            attrs: { title: "是否确认删除此条记录？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.onDelGroup(scope.row)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete btn-delete",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.curGroup ? "编辑玩家组" : "创建玩家组",
            visible: _vm.visible.editDrawer,
            direction: "rtl",
            "show-close": false,
            "custom-class": "drawer-edit",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "editDrawer", $event)
            },
            open: _vm.onDrawerOpen,
            close: _vm.onDrawerClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c(
                "el-form",
                {
                  ref: "formRef",
                  staticClass: "comp-form",
                  attrs: { model: _vm.form, rules: _vm.rules, size: "large" },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "name", attrs: { prop: "name" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("群组名称"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "user" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("添加账户 "),
                        _c("span", [
                          _vm._v(
                            "/已添加" + _vm._s(_vm.userList.length) + "个"
                          ),
                        ]),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.user,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "user", $$v)
                          },
                          expression: "form.user",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "drawer-btn-add",
                          attrs: { type: "primary" },
                          on: { click: _vm.onAddUser },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "sandbox", attrs: { prop: "sandbox" } },
                    [
                      _c("p", { staticClass: "drawer-label" }, [
                        _vm._v("选择沙盒与部署"),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.sandbox,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sandbox", $$v)
                            },
                            expression: "form.sandbox",
                          },
                        },
                        _vm._l(_vm.sandboxList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "deploy", attrs: { prop: "deploy" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.deploy,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deploy", $$v)
                            },
                            expression: "form.deploy",
                          },
                        },
                        _vm._l(_vm.deployList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "account-box" }, [
                    _c("p", { staticClass: "drawer-label" }, [
                      _vm._v(_vm._s(_vm.userList.length) + "个账户"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "account-list" },
                      _vm._l(_vm.userList, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "account-list-item" },
                          [
                            _c("span", [_vm._v(_vm._s(item))]),
                            _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.onDelUser(i)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item-btns" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn save",
                          attrs: { type: "primary", loading: _vm.loading.save },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn cancel",
                          attrs: { type: "default", loading: _vm.loading.save },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.giveup")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }