import request from '@/utils/request';
import { AccountDomain } from '@/utils/env';

// 商城首页
// 广告数据
export async function getShopAdvertise(data: object) {
    return request({
      url: `${AccountDomain}/api/shop/getshopadvertise`,
      method: 'POST',
      data
    })
  }
  
  // 获取集合位数据
  export async function getShopGather(data: object) {
    return request({
      url: `${AccountDomain}/api/shop/getshopgather`,
      method: 'POST',
      data
    })
  }