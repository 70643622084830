// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/logo-v.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n/* 栅格布局（媒体查询变量）\n* https://developer.mozilla.org/zh-CN/docs/Learn/CSS/CSS_layout/Media_queries\n* $us ≥376px  响应式栅格\n* $xs ≥576px  响应式栅格\n* $sm ≥768px  响应式栅格\n* $md ≥992px  响应式栅格\n* $lg ≥1200px 响应式栅格\n* $xl ≥1920px 响应式栅格\n------------------------------- */\n@media screen and (max-width: 768px) {\n#layout-account.login-container .login-header .login-header-title {\n    top: 1.53rem;\n    left: calc(50% - 0.85rem);\n    width: 1.7rem;\n    height: 2.02rem;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-size: 100% 100%;\n}\n#layout-account.login-container .login-content {\n    padding: 0.673rem 0.48rem;\n    width: 5.96rem;\n}\n#layout-account.login-container .login-content .login-content-main .login-box .btn-main {\n    margin-top: 0.69rem;\n    width: 5rem;\n    height: 0.81rem;\n}\n#layout-account.login-container .login-content .login-content-main .login-box .btn-other {\n    width: 1.15rem;\n    height: 0.81rem;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
