import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

// 查询审核
export async function AStoreAIBuildAuditGet(appid: string) {
  return request({
    url: `${BaseUrl}/store/audit/query?appid=${appid}`,
    method: 'GET',
  });
}
// 提交审核
export async function AStoreAIBuildAuditSub(appid: string) {
  return request({
    url: `${BaseUrl}/store/audit/submit?appid=${appid}`,
    method: 'POST',
  });
}