var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-store-trial" } },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "com-form",
          attrs: { model: _vm.form, rules: _vm.rules, size: "large" },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form-item-block",
              attrs: { prop: "enableExperience" },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.form.enableExperience,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "enableExperience", $$v)
                    },
                    expression: "form.enableExperience",
                  },
                },
                [_vm._v("启用抢先体验功能")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.enableExperience,
                  expression: "form.enableExperience",
                },
              ],
              staticClass: "form-item-block",
              attrs: { label: "开发者留言", prop: "developerComment" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 8,
                  placeholder: "请输入内容",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.form.developerComment,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "developerComment", $$v)
                  },
                  expression: "form.developerComment",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "form-item-btns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "form-btn save",
                  attrs: { type: "primary", loading: _vm.loading.save },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(_vm._s(_vm.$t("common.save")))]
              ),
              _vm.cHasChanged
                ? _c(
                    "el-button",
                    {
                      staticClass: "form-btn cancel",
                      attrs: { type: "default", loading: _vm.loading.save },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.giveup")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }