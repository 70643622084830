var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-guide-contact" }, [
    _c(
      "div",
      { staticClass: "gate-guide-contain" },
      [
        _c("guideTitle", {
          attrs: { title: _vm.$t("views.guide.contact.title"), hasMore: false },
        }),
        _c("div", { staticClass: "tips" }, [
          _vm._v(" " + _vm._s(_vm.$t("views.guide.contact.tips")) + " "),
          _c("span", { staticClass: "email" }, [
            _vm._v(_vm._s(_vm.$t("views.guide.contact.email"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "guide-contact-container" },
          _vm._l(_vm.contactList, function (item, index) {
            return _c("contactCard", {
              key: index,
              attrs: { title: item.title, iconPath: item.icon },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }