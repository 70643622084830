var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "content-warp" }, [
      _vm.isShowCount
        ? _c(
            "div",
            {
              class: _vm.headerCountdown
                ? "content-warp__countdown-box"
                : "content-warp__countdown-box countdown-regular",
            },
            [
              _c("div", { staticClass: "content-warp__countdown-info" }, [
                _c("div", [_vm._v(_vm._s(_vm.days))]),
                _c("p", [_vm._v("days")]),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.toPath("/application")
                      },
                    },
                  },
                  [_vm._v("上传作品")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content-warp__introduce", attrs: { id: "introduce" } },
        [
          _c("div", { staticClass: "content-warp__introduce-title title" }, [
            _vm._v("赛事介绍"),
          ]),
          _c("div", { staticClass: "content-warp__introduce-box" }, [
            _vm.isMobile
              ? _c("div", { staticClass: "content-warp__introduce-text" }, [
                  _c("p", [
                    _vm._v(
                      "第二届AI探索创新大赛由光子探索实验室发起，立足于“科技向善”价值基点，探索AI技术在游戏创新发展上的可能性。"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "在这个数字化的时代，AI或许已成为你有利的创作拍档和助手。无论你是技术大拿、游戏狂热者，还是充满奇思妙想的创意达人，都能在这个舞台上一展才华，让AI的智慧与你的创造力碰撞，带来一场全新的内容生产革命！"
                    ),
                  ]),
                  _c(
                    "p",
                    { staticClass: "content-warp__introduce-text-last" },
                    [_vm._v("快来加入我们，一同探索创意的无限边界吧！")]
                  ),
                ])
              : _c("div", { staticClass: "content-warp__introduce-text" }, [
                  _vm._m(0),
                  _vm._m(1),
                  _c(
                    "p",
                    { staticClass: "content-warp__introduce-text-last" },
                    [_vm._v("快来加入我们，一同探索创意的无限边界吧！")]
                  ),
                ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "content-warp__illustrate" }, [
        _c(
          "div",
          {
            staticClass: "content-warp__illustrate-title title",
            attrs: { id: "illustrate" },
          },
          [_vm._v("作品类型")]
        ),
        _c("p", [
          _vm._v(
            "设定最佳AI作品奖，可参赛产品类型包括：游戏，工具及视觉艺术三类"
          ),
        ]),
        _vm._m(2),
        _c("div", { staticClass: "content-warp__illustrate-tips" }, [
          _c("span", [
            _vm._v("详细赛事规则，参见本章赛事章程，"),
            _c(
              "a",
              {
                attrs: {
                  href: "https://km.woa.com/group/52491/articles/show/540394",
                  target: "_blank",
                },
              },
              [_vm._v("点击查看")]
            ),
            _vm._v(" "),
            _vm.isMobile
              ? _c("span", { staticClass: "symbol" }, [_vm._v(">>")])
              : _vm._e(),
          ]),
        ]),
      ]),
      !_vm.isMobile
        ? _c("div", { staticClass: "content-warp__process" }, [
            _c("div", { staticClass: "content-warp__process-title title" }, [
              _vm._v("赛事进程"),
            ]),
            _vm._m(3),
            _vm._m(4),
          ])
        : _vm._e(),
      _vm.isMobile
        ? _c("div", { staticClass: "content-warp__process" }, [
            _c("div", { staticClass: "content-warp__process-title title" }, [
              _vm._v("赛事进程"),
            ]),
            _vm._m(5),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content-warp__reward", attrs: { id: "process" } },
        [
          _c("div", { staticClass: "content-warp__reward-title title" }, [
            _vm._v("赛事奖励"),
          ]),
          _vm.isMobile
            ? _c("p", { staticClass: "content-warp__reward-money" }, [
                _vm._v("大赛专项研发基金-总奖金池 "),
                _c("span", [_vm._v("100W")]),
              ])
            : _vm._e(),
          !_vm.isMobile
            ? _c("img", {
                staticClass: "content-warp__bonus-pool",
                attrs: {
                  src: require("../../assets/aiCompetion/bonus-pool.png"),
                  alt: "",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "content-warp__reward-img" }, [
            _vm.isMobile
              ? _c("img", {
                  attrs: {
                    src: require("../../assets/aiCompetion/mobile/reward-bg.png"),
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: require("../../assets/aiCompetion/reward-bg.png"),
                    alt: "",
                  },
                }),
          ]),
        ]
      ),
      _c("div", { staticClass: "content-warp__rule" }, [
        _c("div", { staticClass: "content-warp__rule-title title" }, [
          _vm._v("作品提交规范"),
        ]),
        _vm.isMobile
          ? _c("div", { staticClass: "content-warp__rule-table" }, [
              _c(
                "div",
                {
                  staticClass: "content-warp__rule-menu-box",
                  attrs: { id: "rule" },
                },
                _vm._l(_vm.ruleMenuList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: item.active ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.changeRule(item.name, index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm.ruleActive === 0
                ? _c("div", { staticClass: "content-warp__rule-box" }, [
                    _c("div", [_vm._v("作品细分格式要求")]),
                    _c("p", [_vm._v("本次比赛仅支持PC游戏")]),
                    _c("div", [_vm._v("附加文件要求")]),
                    _c("p", [_vm._v("作品描述文档")]),
                    _c("p", [_vm._v("1、团队成员（格式为企业微信英文+中文）")]),
                    _c("p", [_vm._v("2、作品玩法描述")]),
                    _c("p", [
                      _vm._v("3、所使用的AI工具及该工具参与的游戏生产环节描述"),
                    ]),
                    _c("div", [_vm._v("其他要求")]),
                    _c("p", [
                      _vm._v("必须接入oasis sdk，"),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.goToDocument },
                        },
                        [_vm._v("接入指引详见")]
                      ),
                      _vm._v(" >>"),
                    ]),
                  ])
                : _vm._e(),
              _vm.ruleActive === 1
                ? _c("div", { staticClass: "content-warp__rule-box" }, [
                    _c("div", [_vm._v("作品细分格式要求")]),
                    _c("p", [
                      _vm._v("不作具体格式要求，结合视频打包压缩上传即可"),
                    ]),
                    _c("div", [_vm._v("附加文件要求")]),
                    _c("p", [_vm._v("作品描述文档")]),
                    _c("p", [_vm._v("1、团队成员（格式为企业微信英文+中文）")]),
                    _c("p", [
                      _vm._v("2、AI工具对应的模型介绍，模型价值及代码描述"),
                    ]),
                    _c("p", [
                      _vm._v("3、所使用的AI工具及该工具参与的游戏生产环节描述"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4、必须提供工具使用过程中的vlog，具体展示工具从输入到最终输出全过程"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.ruleActive === 2
                ? _c("div", { staticClass: "content-warp__rule-box" }, [
                    _c("div", [_vm._v("作品细分格式要求")]),
                    _c("p", [
                      _vm._v(
                        "图片作品：JPG或PNG格式文件均可，文件最大不超过200M"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "视频作品：主流的MP4、MOV格式均可，文件最大不超过1G"
                      ),
                    ]),
                    _c("div", [_vm._v("附加文件要求")]),
                    _c("p", [_vm._v("作品描述文档")]),
                    _c("p", [_vm._v("1、团队成员（格式为企业微信英文+中文）")]),
                    _c("p", [_vm._v("2、作品创意与构思")]),
                    _c("p", [
                      _vm._v(
                        "3、所使用的AI工具及对应使用模型、prompt、参数、seed等描述，以实际是否能在工具内复现这一作品为准"
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        !_vm.isMobile
          ? _c("div", { staticClass: "content-warp__rule-table" }, [
              _vm._m(6),
              _c(
                "div",
                {
                  staticClass:
                    "content-warp__rule-table-row content-warp__rule-table-row2",
                },
                [
                  _vm._m(7),
                  _c("span", { staticClass: "content-warp__rule-table-col2" }, [
                    _vm._v("本次比赛仅支持PC游戏"),
                  ]),
                  _vm._m(8),
                  _c("span", { staticClass: "content-warp__rule-table-col4" }, [
                    _c("p", [_vm._v("必须接入oasis sdk")]),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: { click: _vm.goToDocument },
                      },
                      [_vm._v("接入指引详见")]
                    ),
                  ]),
                ]
              ),
              _vm._m(9),
              _vm._m(10),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "content-warp__notice" }, [
        _c(
          "div",
          {
            staticClass: "content-warp__notice-title title",
            attrs: { id: "notice" },
          },
          [_vm._v("参赛须知")]
        ),
        !_vm.isMobile
          ? _c("div", { staticClass: "content-warp__notice-box" }, [
              _vm._m(11),
              _vm._m(12),
            ])
          : _vm._e(),
        _vm.isMobile
          ? _c("div", { staticClass: "content-warp__notice-box" }, [_vm._m(13)])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        "第二届AI探索创新大赛由光子探索实验室发起，立足于“科技向善”价值基点，探索AI技"
      ),
      _c("br"),
      _vm._v("术在游戏创新发展上的可能性。"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v("在这个数字化的时代，AI或许已成为你有利的创作拍档和助手。"),
      _c("br"),
      _vm._v(
        "无论你是技术大拿、游戏狂热者，还是充满奇思妙想的创意达人，都能在这个舞台上一展才华，让AI的智慧"
      ),
      _c("br"),
      _vm._v("与你的创造力碰撞，带来一场全新的内容生产革命！"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "content-warp__illustrate-box content-warp__illustrate-game",
        },
        [
          _c("div", { staticClass: "content-warp__illustrate-box-btm" }, [
            _c("p", [_vm._v("游戏作品")]),
            _c("span", [
              _vm._v(
                "从可玩性，观赏性，AI工具利用程度三方面综合评估。期待能利用AI新能力有更亮点的创意玩法涌现"
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "content-warp__illustrate-box content-warp__illustrate-tool",
        },
        [
          _c("div", { staticClass: "content-warp__illustrate-box-btm" }, [
            _c("p", [_vm._v("工具作品")]),
            _c("span", [
              _vm._v(
                "从是否可被产品复用或迁移以解决实际需求；技术能力是否有深度；是否具备可量化的效率提升效果，三个维度综合评估"
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "content-warp__illustrate-box content-warp__illustrate-vision",
        },
        [
          _c("div", { staticClass: "content-warp__illustrate-box-btm" }, [
            _c("p", [_vm._v("视觉艺术作品")]),
            _c("span", [
              _vm._v(
                "从作品的概念构思、叙事细节、视觉体验、差异化审美等方向的艺术表现力，和更先进制作流程方法两个维度进行综合评估"
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "content-warp__process-img" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/aiCompetion/process-bg.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "content-warp__process-box" }, [
      _c("div", { staticClass: "content-warp__process-item" }, [
        _c("div", [_vm._v("作品报名")]),
        _c("p", [_vm._v("2023.4.27-2023.5.19")]),
        _c("div", [_vm._v("作品提交")]),
        _c("p", [_vm._v("2023.6.15-2023.6.25")]),
        _c(
          "a",
          {
            attrs: {
              href: "https://doc.weixin.qq.com/forms/AJEAIQdfAAoAKgAZwbdAFwhOFeCv3iRSf",
              target: "_blank",
            },
          },
          [_vm._v("报名登记")]
        ),
      ]),
      _c("div", { staticClass: "content-warp__process-item" }, [
        _c("div", [_vm._v("第一轮初评")]),
        _c("p", [_vm._v("2023.6.26-2023.6.30")]),
        _c("div", [_vm._v("第二轮答辩")]),
        _c("p", [_vm._v("2023.7.5-2023.7.6")]),
      ]),
      _c(
        "div",
        { staticClass: "content-warp__process-item process-item-last" },
        [
          _c("div", [_vm._v("所有参赛作品公示")]),
          _c("p", [_vm._v("2023.6.26")]),
          _c("div", {}, [_vm._v("决赛名单公示")]),
          _c("p", [_vm._v("2023.7.3")]),
          _c("div", [_vm._v("最终获奖公布")]),
          _c("p", [_vm._v("2023.7.10")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "content-warp__process-img" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/aiCompetion/mobile/process-bg.png"),
          alt: "",
        },
      }),
      _c("a", {
        staticClass: "content-warp__process-registration",
        attrs: {
          href: "https://doc.weixin.qq.com/forms/AJEAIQdfAAoAKgAZwbdAFwhOFeCv3iRSf",
          target: "_blank",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "content-warp__rule-table-row content-warp__rule-table-row1",
      },
      [
        _c("span", { staticClass: "content-warp__rule-table-col1" }, [
          _vm._v("作品类型"),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col2" }, [
          _vm._v("作品细分格式要求"),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col3" }, [
          _vm._v("附加文件要求"),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col4" }, [
          _vm._v("其他要求"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "content-warp__rule-table-col1" }, [
      _vm._v("游戏作品 "),
      _c("p", [_vm._v("GAME WORKS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "content-warp__rule-table-col3" }, [
      _c("p", [_vm._v("作品描述文档")]),
      _c("p", [_vm._v("1、团队成员（格式为企业微信英文+中文）")]),
      _c("p", [_vm._v("2、作品玩法描述")]),
      _c("p", [_vm._v("3、所使用的AI工具及该工具参与的游戏生产环节描述")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "content-warp__rule-table-row content-warp__rule-table-row3",
      },
      [
        _c("span", { staticClass: "content-warp__rule-table-col1" }, [
          _vm._v("AI工具作品 "),
          _c("p", [_vm._v("AI TOOL")]),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col2" }, [
          _vm._v("不作具体格式要求，结合视频打包"),
          _c("br"),
          _vm._v(" 压缩上传即可"),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col3" }, [
          _c("p", [_vm._v("作品描述文档")]),
          _c("p", [_vm._v("1、团队成员（格式为企业微信英文+中文）")]),
          _c("p", [_vm._v("2、AI工具对应的模型介绍，模型价值及代码描述")]),
          _c("p", { attrs: { id: "rule" } }, [
            _vm._v("3、AI工具部署教程描述，以让大众能实现工具使用为准"),
          ]),
          _c("p", [
            _vm._v("4、必须提供工具使用过程中的vlog，具体展示工具从输"),
            _c("br"),
            _vm._v(" 入到最终输出全过程"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "content-warp__rule-table-row content-warp__rule-table-row4",
      },
      [
        _c("span", { staticClass: "content-warp__rule-table-col1" }, [
          _vm._v("视觉创意作品 "),
          _c("p", [_vm._v("ART WORKS")]),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col2" }, [
          _c("p", [
            _vm._v("图片作品：JPG或PNG格式文件均"),
            _c("br"),
            _vm._v(" 可，文件最大不超过200M"),
          ]),
          _c("p", [
            _vm._v("视频作品：主流的MP4、MOV格"),
            _c("br"),
            _vm._v(" 式均可，文件最大不超过1G"),
          ]),
        ]),
        _c("span", { staticClass: "content-warp__rule-table-col3" }, [
          _c("p", [_vm._v("作品描述文档")]),
          _c("p", [_vm._v("1、团队成员（格式为企业微信英文+中文）")]),
          _c("p", [_vm._v("2、作品创意与构思")]),
          _c("p", [
            _vm._v("3、所使用的AI工具及对应使用模型、prompt、参数、 "),
            _c("br"),
            _vm._v(" seed等描述，以实际是否能在工具内复现这一作品为准"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", { staticClass: "content-warp__notice-box-col1" }, [
      _c("li", [
        _vm._v("本次光子内部AI探索创新大赛为光子工作室群内部举办赛事。"),
      ]),
      _c("li", [
        _vm._v(
          "参赛作品严禁包含政治、低俗、色情、宗教等内容，不得违反相关法律法规。"
        ),
      ]),
      _c("li", [
        _vm._v("请妥善保存作品源文件，所有获奖作品届时必须提交源文件。"),
      ]),
      _c("li", [
        _vm._v(
          "所有参赛作品版权归工作室所有，未经主办方允许，不得以任何形式对外商用，"
        ),
      ]),
      _c("li", { staticClass: "notice-box-row1 notice-box-col1-last" }, [
        _vm._v("如有违规，需承担对应的责任"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", { staticClass: "content-warp__notice-box-col2" }, [
      _c("li", { staticClass: "content-warp__notice-box-row1" }, [
        _vm._v(
          "未经主办方允许，所有赛事信息，包括但不限于赛事海报、赛事规则、赛"
        ),
      ]),
      _c("li", { staticClass: "notice-box-row1" }, [
        _vm._v(
          "事网站、赛事社群聊天等，不得以任何形式对外公开，包括但不限于口头"
        ),
      ]),
      _c("li", { staticClass: "notice-box-row1" }, [
        _vm._v("文字，截图等形式，如有违规，需承担对应的责任。"),
      ]),
      _c("li", { staticClass: "content-warp__notice-box-row2" }, [
        _vm._v("赛事规则以KM帖《第二届光子内部AI探索创新大赛 赛程公告》为准。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", {}, [
      _c("li", [
        _vm._v("本次光子内部AI探索创新大赛为光子工作室群内部举办赛事"),
      ]),
      _c("li", [
        _vm._v("请妥善保存作品源文件，所有获奖作品届时必须提交源文件"),
      ]),
      _c("li", [
        _vm._v("本次赛事目的为鼓励探索创新，所有参赛作品均不会作为商用"),
      ]),
      _c("li", [
        _vm._v(
          "参赛作品严禁包含政治、低俗、色情、宗教等内容，不得违反相关法律法规"
        ),
      ]),
      _c("li", [
        _vm._v(
          "所有赛事信息，包括但不限于赛事海报、赛事规则、赛事网站、赛事社群聊天等，不得以任何形式对外公开，包括但不限于口头，文字，截图等形式，如有违规，需承担对应的责任"
        ),
      ]),
      _c("li", [
        _vm._v("赛事规则以KM帖《第二届光子内部AI探索创新大赛 赛程公告》为准"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }