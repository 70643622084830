// 客户端UA示例：INFO[0008] Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
// OasisClient/++UE4+Release-4.23-CL-0 UnrealEngine/4.23.1-0+++UE4+Release-4.23 Chrome/59.0.3071.15 Safari/537.36
// Mozilla/5.0 (Windows NT 6.2; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
// QtWebEngine/5.12.10 Chrome/69.0.3497.128 Safari/537.36 OasisClient
const ua = navigator.userAgent;

// 当前判定：qt-QtWebEngine ue-UnrealEngine   oasis-OasisClient
const _env = {
  qt: /QtWebEngine/.test(ua),
  ue: /UnrealEngine/.test(ua),
  unity: /Unity/.test(ua),
  xe: /XEngine/.test(ua),
  oasis: /OasisClient/.test(ua),
};

// Client环境
export const isClient = _env.qt || _env.ue || _env.unity || _env.xe || _env.oasis;
// Oasis Client环境
export const isOasis = _env.oasis;
// 游戏Client环境
export const isGameClient = isClient && !isOasis;
// Shipping包
export const isShippingClient = false;
export const env = _env;
// 账号系统、开平都走一个口情况，统一替换 https://${env}-${name}.woa.com
export const AccountName = 'oasisgames';
export const OpenName = 'openoasis';
// 本地环境替换为默认地址
export const isLocal = location.hostname == '127.0.0.1' || location.hostname == 'localhost';
const isGate = location.hostname.indexOf('gate') >= 0

const ENV = process.env; const OG = location.origin;
//todo 测试配置使用
// ENV.VUE_APP_ACCOUNT_DOMAIN = '/api';
// ENV.VUE_APP_ACCOUNT_DOMAIN = '/dev';
// ENV.VUE_APP_OPEN_DOMAIN = '/dev';
export const AccountDomain = (() => (isLocal ? ENV.VUE_APP_ACCOUNT_DOMAIN : (isGate ? ENV.VUE_APP_ACCOUNT_DOMAIN_GATE : OG.replace(OpenName, AccountName))))();
export const OpenDomain = (() => (isLocal ? ENV.VUE_APP_OPEN_DOMAIN : (isGate ? ENV.VUE_APP_OPEN_DOMAIN_GATE : OG.replace(AccountName, OpenName))))();

export const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);