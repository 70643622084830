var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "dialog",
      on: {
        touchmove: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "box",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "header" }, [
            _vm.$attrs.titleText
              ? _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$attrs.titleText)),
                ])
              : _vm._e(),
            _c("i", { staticClass: "btn-close", on: { click: _vm.hide } }),
          ]),
          _c("div", { staticClass: "body" }, [_vm._t("default")], 2),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _vm._t("btn-cancel", function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-cancel",
                      class: [_vm.$attrs.cancelClass],
                      attrs: { type: "info" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(_vm._s(_vm.$attrs.cancelText || "取消"))]
                  ),
                ]
              }),
              _vm._t("btn-ok", function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-ok",
                      class: [_vm.$attrs.okClass],
                      attrs: { type: "primary" },
                      on: { click: _vm.onOk },
                    },
                    [_vm._v(_vm._s(_vm.$attrs.okText || "确定"))]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }