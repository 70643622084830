
import { Component, Vue, Prop } from 'vue-property-decorator'

// 定义接口来定义对象的类型
interface ParentViewState {
  refreshRouterViewKey: null | string;
  keepAliveNameList: string[];
}
@Component({
  name: 'layoutParentView',
  components: {},
})
class layoutParentView extends Vue {
  @Prop({ default: '' }) public minHeight!: string

  public refreshRouterViewKey = null
  private keepAliveNameList = []

  // 获取组件缓存列表(name值)
  // get getKeepAliveNames() {
  //   console.log("getKeepAliveNames", this.$store.state.keepAlive)
  //   return this.$store.state.keepAlive.keepAliveNames;
  // };
  // 页面加载前，处理缓存，页面刷新时路由缓存处理
  beforeMount() {
    // this.keepAliveNameList = this.getKeepAliveNames.value;

    // this.$bus.on('onTagsViewRefreshRouterView', (fullPath: string) => {
    //   this.keepAliveNameList = this.getKeepAliveNames.value.filter((name: string) => this.$route.name !== name);
    //   this.refreshRouterViewKey = null;
    //   this.$nextTick(() => {
    //     this.refreshRouterViewKey = fullPath;
    //     this.keepAliveNameList = this.getKeepAliveNames.value;
    //   });
    // })
  }
  // 页面卸载时
  unmouted() {
    // this.$bus.off('onTagsViewRefreshRouterView');
  }
  // 监听路由变化，防止 tagsView 多标签时，切换动画消失
  // watch(
  // 	() => route.fullPath,
  // 	() => {
  // 		state.refreshRouterViewKey = route.fullPath;
  // 	}
  // );
}
export default layoutParentView

