import request from '@/utils/request';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/trpc.oasis.cos.Cos`;
export async function AUpdInit(data: object) {
  return request({
    url: `${BaseUrl}/RPCInitUpload`,
    method: 'POST',
    data,
  });
}
export async function AUpdUpload(data: object) {
  return request({
    url: `${BaseUrl}/RPCUpload`,
    method: 'POST',
    data,
  });
}
