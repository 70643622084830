
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AGroupGET, AGroupAdd, AGroupUpt, AGroupDel } from '@/api/developerApi/group'
import { DeveloperModule } from '@/store/modules/developer'
import { normalizeDate, formatDate } from '@/utils/format'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'CompInfoGroup',
  components: {}
})
class CompInfoGroup extends Vue {
  public form = {
    name: '',
    user: '',
    sandbox: '1',
    deploy: '',
  }
  public list: any = []
  public userList: any = []
  public rules = {
    name: [
      {
        required: true,
        message: '请输入玩家组名称',
        trigger: 'change',
      },
    ],
    deploy: [
      {
        required: true,
        message: '请选择部署',
        trigger: 'blur',
      },
    ],
  }
  public sandboxList = [
    {
      id: '1',
      name: '沙盒',
    }
  ]
  // public deployList = []
  public visible = {
    editDrawer: false,
  }
  public loading = {
    save: false,
    list: false,
    delete: false,
  }
  private appId = this.$route.query.appId as string
  private curGroup: any = null

  get devModule() {
    return this.$store.state.developer
  }
  // get storeBuilds() {
  //   return this.devModule.storeData.builds
  // }
  get deployList() {
    return this.appData && this.appData.env || []
  }
  get appData() {
    return this.devModule.appData
  }
  // get list() {
  //   return this.appData && this.appData.groups || []
  // }
  get isEdit() {
    return !!this.curGroup
  }

  mounted() {
    this.initData()
  }

  public findBuild(id) {
    if (!id) return {}
    let build = this.deployList.find(deploy => deploy.id == id)
    return build || {}
  }
  public onAddUser() {
    if (!this.form.user) {
      this.$message.info("请输入需要添加的账号")
      return
    }
    this.userList.push(this.form.user)
  }

  public showEditDrawer(deploy) {
    this.curGroup = deploy
    this.visible.editDrawer = true
  }

  public onDelGroup(group) {
    this.loading.delete = true
    AGroupDel(this.appId, group.groupId).then(() => {
      this.loading.delete = false
      this.$message.success(this.$t('tips.succ.del') as string)
      this.getGroups()
      this.visible.editDrawer = false
    }).catch(err => {
      console.error(err)
      this.loading.delete = false
      this.$message.error(this.$t('tips.fail.del') as string)
    })
  }
  public onDelUser(index) {
    this.userList.splice(index, 1)
  }

  public onDrawerOpen() {
    // 编辑，初始化数据
    if (this.curGroup) {
      this.setcur()
    }
  }
  public onDrawerClose() {
    this.reset()
  }

  public onCancel() {
    this.visible.editDrawer = false
  }

  public async submitForm() {
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate((valid: boolean) => {
      if (!valid) return false
      this.loading.save = true
      const param = {
        name: this.form.name,
        sandbox: [
          {
            id: this.form.sandbox,
            deploy: this.form.deploy,
          }
        ],
        users: this.userList,
      }
      if (!this.isEdit) {
        AGroupAdd(this.appId, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.edit') as string)
          this.getGroups()
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.edit') as string)
        })
      }
      else {
        AGroupUpt(this.appId, this.curGroup.groupId, param).then(() => {
          this.loading.save = false
          this.$message.success(this.$t('tips.succ.edit') as string)
          this.getGroups()
          this.visible.editDrawer = false
        }).catch(err => {
          console.error(err)
          this.loading.save = false
          this.$message.error(this.$t('tips.fail.edit') as string)
        })
      }
    })
  }

  public formatTime(time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime)
  }

  private async initData() {
    // this.loading.list = true
    // if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    // this.loading.list = false
    this.getGroups()
  }
  private getGroups() {
    this.loading.list = true
    AGroupGET(this.appId).then((res: any) => {
      this.loading.list = false
      let list = res.data || []
      this.list = list
    }).catch(err => {
      console.error(err)
      this.loading.list = false
      this.$message.error(this.$t('tips.fail.get') as string)
    })
  }
  private reset() {
    this.form.name = ''
    this.form.user = ''
    this.form.deploy = ''
    this.userList = []
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
  private setcur() {
    this.form.name = this.curGroup.name
    this.userList = [].concat(this.curGroup.users)
    this.form.deploy = this.curGroup.sandbox && this.curGroup.sandbox[0] && this.curGroup.sandbox[0].deploy
    this.$nextTick(() => {
      (this.$refs.formRef as ElForm)?.clearValidate()
    })
  }
}
export default CompInfoGroup
