
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinUpload from '@/mixins/upload'
import MixinAISeason from '@/mixins/aiSeason'
import { DeveloperModule } from '@/store/modules/developer'
import { AAppUpt } from '@/api/developerApi/app'
import { ElForm } from 'element-ui/types/form'
import { strlen } from '@/utils'
import { getFileName, splitFileName, ImgFileTypeList } from '@/utils/file'
import { validateAppName, validateBackspace } from '@/utils/validator'

@Component({
  name: 'CompInfoGeneral',
})
class CompInfoGeneral extends mixins(MixinUpload, MixinAISeason) {
  public ImgFileTypeList = ImgFileTypeList
  public strlen = strlen
  public maxNameLen = 24
  public form = {
    appName: '',
    cover: '',
  }
  public rules = {
    appName: [
      { required: true, validator: validateAppName, trigger: 'change' },
      { validator: validateBackspace.bind(this), trigger: 'change' },
    ],
  }
  public loading = {
    appData: false,
    save: false,
    upload: false,
  }
  private appId = ''

  get cHasChanged() {
    return true
    // return (this.form.appName !== this.appData.appName) || this.form.cover !== this.appData.cover
  }
  // get devModule() {
  //   return this.$store.state.developer
  // }
  // get appData() {
  //   return this.devModule.appData
  // }
  // get appData() {
  //   let app = this.devModule.apps.find(item => item.appId == this.$route.query.appId)
  //   return app || null
  // }

  @Watch('$route.query.appId', { immediate: true })
  onAppIdChanged(val: string) {
    if (val) {
      this.appId = val
      this.getAppData()
    }
  }

  mounted() {

  }

  async getAppData() {
    this.loading.appData = true
    await DeveloperModule.MAGetDevAppData(this.appId)
    this.loading.appData = false
    this.initAppInfo()
  }

  public onCancel() {
    this.form.appName = this.appData.appName
    this.form.cover = this.appData.cover
    this.$message.success("已重置")
  }

  public async submitForm() {
    let formEl = this.$refs.formRef as ElForm
    if (!formEl) return
    formEl.validate((valid: boolean) => {
      if (!valid) return false
      this.loading.save = true
      const param = {
        appName: this.form.appName,
        cover: this.form.cover,
      }
      AAppUpt(this.appId, param).then(() => {
        this.loading.save = false
        this.$message.success(this.$t('tips.succ.edit') as string)
        DeveloperModule.MAGetDevApps()
        DeveloperModule.MAGetDevAppData(this.appId)
        // 跳转作品信息->基本信息
        this.$emit('changeTab', 'info')
      }).catch(err => {
        console.error(err)
        this.loading.save = false
        this.$message.error(this.$t('tips.fail.edit') as string)
      })
    })
  }

  public onUploadSuccess(res, file) {
    this.loading.upload = false
    this.form.cover = getFileName(res.url)
  }
  public onUploadError(error, file) {
    this.$message.error("上传失败 " + error)
    this.loading.upload = false
  }
  public beforeUpload(file) {
    const { name, ext = '' } = splitFileName(file.name)
    const extLimit = this.ImgFileTypeList
    if (!extLimit.includes(ext)) {
      this.$message.error('格式错误，只支持' + extLimit.join() + '格式')
      return false
    }
    this.loading.upload = true
    return true
  }

  private initAppInfo() {
    this.form.appName = this.appData.appName
    this.form.cover = this.appData.cover
  }
  private reset() {
    if (!this.$refs.formRef) return
    (this.$refs.formRef as ElForm)?.clearValidate()
    this.form.appName = ''
    this.form.cover = ''
  }
}
export default CompInfoGeneral
