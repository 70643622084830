import { RouteConfig } from 'vue-router';

/**
 * 存放公共路由
 * 公用路由包括： 登录、报错、异常等
 */
export const commonRoutes: Array<RouteConfig> = [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/layout/account.vue'),
    redirect: '/account/login',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'login',
        name: 'accountLogin',
        component: () => import(/* webpackChunkName: "acc-account" */ '@/views/account/login.vue'),
        meta: {
          title: 'route.account.login',
          noSession: true,
          isHide: false,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    redirect: '/error/index',
    component: () => import('@/layout/blank.vue'),
    children: [
      {
        path: 'index',
        name: 'errorIndex',
        component: () => import(/* webpackChunkName: "acc-error" */ '@/views/error/index.vue'),
        meta: {
          title: 'route.error.index',
          noSession: true,
        },
      },
      {
        path: '404',
        name: 'notFound',
        component: () => import(/* webpackChunkName: "acc-error" */ '@/views/error/404.vue'),
        meta: {
          title: 'route.error.404',
          noSession: true,
        },
      },
      {
        path: '401',
        name: 'noPower',
        component: () => import(/* webpackChunkName: "acc-error" */ '@/views/error/401.vue'),
        meta: {
          title: 'route.error.401',
          noSession: true,
        },
      },
      {
        path: 'developer',
        name: 'errorDev',
        component: () => import(/* webpackChunkName: "acc-error" */ '@/views/error/developer.vue'),
        meta: {
          title: 'route.error.index',
          noSession: true,
        },
      },
    ],
  },
];
