import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';
import { appsData } from '../mock/appsData';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;
const AccountBaseUrl = `${AccountDomain}/api`;
// 获取开发者数据
export async function ADevGetInfo() {
  return request({
    url: `${BaseUrl}/developer`,
    method: 'GET',
  });
}
// 注册开发者
export async function ADevRegister(data: object) {
  return request({
    url: `${BaseUrl}/developer/register`,
    method: 'POST',
    data,
  });
}
// 开发者注册验证码
export async function ADevSendVerifyCode(data: object) {
  return request({
    url: `${BaseUrl}/developer/sendcode`,
    method: 'POST',
    data,
  });
}

export async function AStoreGet(appid: string) {
  return request({
    url: `${BaseUrl}/store/query?appid=${appid}`,
    method: 'GET',
  });
}
export async function AStoreGenUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/store/update/basicinfo?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function AStoreProUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/store/update/media?appid=${appid}`,
    method: 'POST',
    data,
  });
}

export async function AStoreTriUpt(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/store/update/experience?appid=${appid}`,
    method: 'POST',
    data,
  });
}

export async function ASDKInfoGet() {
  // return new Promise((resolve, reject) => {
  //   resolve(appsData);
  // });
  return request({
    url: `${BaseUrl}/sdk/query`,
    method: 'GET',
  });
}

// 查询当前赛季
export async function AAISeasonGet() {
  return request({
    url: `${AccountBaseUrl}/inner/getleaveday`,
    method: 'POST',
  });
}
