
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MixinUpload from '@/mixins/upload'
import MixinAISeason from '@/mixins/aiSeason'
import Picture from '@/components/upload/picture.vue'
import { getFileName, ImgFileTypeList, VidFileTypeList } from '@/utils/file'
import { AStoreProUpt } from '@/api/developerApi/developer'
import { DeveloperModule } from '@/store/modules/developer'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'CompStoreMedia',
  components: {
    Picture,
  }
})
class CompStoreMedia extends mixins(MixinUpload, MixinAISeason) {
  public ImgFileTypeList = ImgFileTypeList
  public VidFileTypeList = VidFileTypeList
  public form = {
    cover: '',
    movie: '',
    pictures: [],
  }
  public rules = {
    cover: [
      { required: true },
    ],
    pictures: [
      { required: true },
    ],
  }
  public loading = {
    medias: false,
    save: false,
    upload: {
      cover: false,
      movie: false,
      pictures: false,
    },
  }
  public visible = {
    video: false,
  }
  public loaded = {
    video: false,
  }
  public videoUrl = {
    base: '',
    poster: '',
  }
  private appId = this.$route.query.appId as string

  get cHasChanged() {
    return true
  }
  // get devModule() {
  //   return this.$store.state.developer
  // }
  get storeMeidas() {
    return this.devModule.storeData.medias
  }

  @Watch('storeMeidas', { deep: true })
  onStoreMeidasChanged(val: any) {
    if (val) this.initMedias()
  }

  mounted() {
    this.initData()
  }

  public onCancel() {
    this.reset()
    this.$message.success("已重置")
  }

  public async submitForm() {
    // 数据校验
    if (!this.form.cover) {
      this.$message.error("请上传封面图")
      return
    }
    if (!this.form.pictures.length) {
      this.$message.error("请上传宣传图片")
      return
    }
    this.$report('dev/store/media/save', 'click', { appid: this.appId })
    this.loading.save = true;
    const param = {
      cover: '' + this.form.cover,
      movie: '' + this.form.movie,
      pictures: this.form.pictures.map(item => '' + item),
    }
    AStoreProUpt(this.appId, param).then(() => {
      this.loading.save = false
      this.$message.success(this.$t('tips.succ.edit') as string)
      DeveloperModule.MAGetDevStore(this.appId)
      this.$emit('changeTab', 'build')
    }).catch(err => {
      console.error(err)
      this.loading.save = false
      this.$message.error(this.$t('tips.fail.edit') as string)
    })
  }

  public onUploadSuccess(type, res, file) {
    this.loading.upload[type] = false
    let fileName = getFileName(res.url)
    if (type == 'pictures') this.form[type].push(fileName)
    else this.form[type] = fileName
  }
  public onUploadError(type, error, file) {
    this.$message.error("上传失败 " + error)
    this.loading.upload[type] = false
  }
  public beforeUpload(type, file) {
    const limitMap = {
      cover: {
        type: ['image/png', 'image/jpeg', 'image/gif'],
      },
      movie: {
        type: ['video/mp4'],
      },
      pictures: {
        type: ['image/png', 'image/jpeg', 'image/gif'],
        total: 10,
      },
    }
    const typeLimit = limitMap[type].type
    if (!typeLimit.includes(file.raw.type)) {
      this.$message.error('格式错误，只支持：' + typeLimit.join())
      return false
    }
    const totalLimit = limitMap[type].total
    if (totalLimit && this.form[type].length >= totalLimit) {
      this.$message.error('超过数量上限' + totalLimit)
      return false
    }
    this.loading.upload[type] = true
    return true
  }

  public onDelPicture(i) {
    this.form.pictures.splice(i, 1)
  }
  public playVideo(url) {
    if (!this.loaded.video) {
      this.$message.info('视频处理中，请稍后再试')
      return
    }
    let videoUrl = this.getCosUrl(url)
    this.videoUrl = videoUrl
    this.visible.video = true
  }
  public onDialogOpen() {
    this.$nextTick(() => {
      (this.$refs.videoPlayer as HTMLVideoElement).play()
    })
  }
  public onDialogClose() {
    (this.$refs.videoPlayer as HTMLVideoElement).pause()
  }

  private async initData() {
    this.loading.medias = true
    if (!this.devModule.init.store) await DeveloperModule.MAGetDevStore(this.appId)
    this.loading.medias = false
    this.initMedias()
  }
  private initMedias() {
    this.form.cover = this.storeMeidas.cover
    this.form.movie = this.storeMeidas.movie
    this.form.pictures = [].concat(this.storeMeidas.pictures || [])
  }
  private reset() {
    (this.$refs.formRef as ElForm)?.clearValidate()
    this.initMedias()
  }
}
export default CompStoreMedia
