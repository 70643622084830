
//知识文章类型
export enum ArticleTypes {
  Type_0 = 0,       //其他
  Type_2D = 1,       //2D美术领域
  Type_3D = 2,         //3D建模领域
  Type_Video = 3,   //视频动画领域
  Type_Mp3 = 4,   //音频生成领域
  Type_Test = 5,   //文本生成领域
  Type_Code = 6,   //代码辅助领域
}

export enum ArticleAuditStatus {  //知识文章审核状态
  Status_Draft = 0,              //草稿
  Status_Release_Ing = 1,      //发布审核中
  Status_Audit_Fail = 2,      //发布审核失败
  Status_Audit_OK = 3,       //审核通过
}

export enum EditorTypes {  //知识文章 编辑器类型
  Editor_Type_0 = 0,    //其他
  Editor_Type_1 = 1,      //富文本
}

export const ArticleCategoryOptions = [
  { type: ArticleTypes.Type_0, label: 'other' },
  { type: ArticleTypes.Type_2D, label: '2D Art' },
  { type: ArticleTypes.Type_3D, label: '3D Modeling ' },
  { type: ArticleTypes.Type_Video, label: 'Video Animation' },
  { type: ArticleTypes.Type_Mp3, label: 'Audio Generation' },
  { type: ArticleTypes.Type_Test, label: 'Text Generation' },
  { type: ArticleTypes.Type_Code, label: 'Coding Assistance' },
]

export const ArticleTagOptions = ['3D', '2D']
