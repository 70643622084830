
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DefaultAvatar, LangList } from '@/config'
import { setLanguage } from '@/lang'
import { UserModule } from '@/store/modules/user'
import SearchMobile from '@/components/search/mobile.vue'
import eventbus from '@/utils/eventbus'
import { OpenDomain } from '@/utils/env'

@Component({
  name: 'layoutGame',
  components: {
    SearchMobile,
  },
})
class layoutGame extends Vue {
  public active = {
    lang: 'zh-cn'
  }
  public langList = LangList
  public visible = {
    search: false,
  }
  public isMobile = false
  public isShowMenu = false

  get session() {
    return this.$store.state.user.session
  }
  get userInfo() {
    return this.$store.state.user.userInfo
  }
  get avatarUrl() {
    if (!this.userInfo || !this.userInfo.nickname) return DefaultAvatar
    return `https://dayu.woa.com/avatars/${this.userInfo.nickname}/profile.jpg`
  }
  get key() {
    return this.$route.path;
  }

  created() {
    this.isMobile = this.getMode() === 'h5' ? true : false;
  }

  public onSearch(name) {
    eventbus.$emit('game-search', name)
  }
  public onLogoClick() {
    this.$router.push('/home')
  }
  public onChangeLang(lang: any) {
    this.active.lang = lang.key
    this.$i18n.locale = lang.key
    setLanguage(lang.key)
  }
  public goToLogin() {
    this.$router.push({ name: 'accountLogin', params: { type: "" } })
  }
  public toPath(path: string) {
    this.$router.push(path)
  }

  public logout() {
    this.$msgbox({
      closeOnClickModal: false,
      closeOnPressEscape: false,
      title: this.$t('common.tips') as string,
      message: this.$t('common.logoutTips') as string,
      showCancelButton: true,
      confirmButtonText: this.$t('common.ok') as string,
      cancelButtonText: this.$t('common.cancel') as string,
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = this.$t('common.logouting') as string
          setTimeout(() => {
            done()
            setTimeout(() => {
              instance.confirmButtonLoading = false;
            }, 300)
          }, 700)
        } else {
          done()
        }
      },
    }).then(async () => {
      UserModule.MALogout()
      setTimeout(() => {
        window.location.href = '/'
      }, 500)
    }).catch(() => { })
  }
  public getMode() {
    const html = document.documentElement;
    const cliWidth = html.clientWidth;
    return cliWidth <= 750 ? 'h5' : 'pc';
  }
  public isShowNav() {
    this.isShowMenu = !this.isShowMenu;
  }
  public openPath(url: string) {
    window.open(`${OpenDomain}${url}`)
  }
}
export default layoutGame
