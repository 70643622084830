
import { Component, Vue } from 'vue-property-decorator'
import { addToolsCollect, deleteToolsCollect, replaceToolsRecentuse } from '@/api/developerApi/tools';
import { AIToolPlatform } from '@/config/developer'
@Component({
  name: 'ToolDetails',
  filters: {
    lableFormat(value: number) {
      if (!value && value !== 0) return '';
      let labelName = '';
      AIToolPlatform.forEach(item => {
        if (item.key === value) {
          labelName = item.name;
        }
      })
      return labelName;
    }
  }
})
class ToolDetails extends Vue {
  public isShowDialog = false;
  public details:any = '';

  public ShowToolDetail(item: any) {
    console.log('ShowToolDetail', item)
    this.details = item;
    this.isShowDialog = true;
    this.addToolsRecentuse();
  }
  public toolsCollect() {
    const data = {
      replace_data: {
        tools_id: this.details.tools_id
      }
    }
    addToolsCollect(data).then(res => {
      console.log('addToolsCollect res', res)
      if (res.code === 0) {
        //收藏成功
         if (res.code === 0) {
          this.details.tools_basic_data.is_collect = 1
        }
      }
    })
  }
  public deleteCollect() {
    const data = {
      del_data: {
        tools_id: this.details.tools_id
      }
    }
    deleteToolsCollect(data).then(res => {
      console.log('deleteToolsCollect res', res)
      if (res.code === 0) {
        this.details.tools_basic_data.is_collect = 0
      }
    })
  }
  public addToolsRecentuse() {
    const data = {
      data: {
        tools_id: this.details.tools_id || this.details.collect_data.tools_id
      }
    }
    replaceToolsRecentuse(data).then(res => {
      console.log('replaceToolsRecentuse res', res)
    })
  }
}

export default ToolDetails
