import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { RoutesState } from '@/store/interface/index';
import { Route } from 'vue-router'
import store from '@/store';

export type IRoutesState = RoutesState;

@Module({ dynamic: true, store, name: 'routes' })
class Routes extends VuexModule implements RoutesState {
  public routesList: Array<Route> = [];
  public navList: Array<Route> = [];
  public isColumnsMenuHover= false;
  public isColumnsNavHover= false;

  @Action
  public async MASetRoutesList(data: any) {
    this.MMGetRoutesList(data);
  }
  @Action
  public async MASetNavList(data: any) {
    this.MMGetNavList(data);
  }
  @Action
  public async MASetColumnsMenuHover(bool: boolean) {
    this.MMGetColumnsMenuHover(bool);
  }
  @Action
  public async MASetColumnsNavHover(bool: boolean) {
    this.MMGetColumnsNavHover(bool);
  }

  // 设置路由，菜单中使用到
  @Mutation
  private MMGetRoutesList(data: Array<Route>) {
    this.routesList = data;
  }
  // 设置面包屑导航
  @Mutation
  private MMGetNavList(data: Array<Route>) {
    this.navList = data;
  }
  // 设置分栏布局，鼠标是否移入移出（菜单）
  @Mutation
  private MMGetColumnsMenuHover(bool: boolean) {
    this.isColumnsMenuHover = bool;
  }
  // 设置分栏布局，鼠标是否移入移出（导航）
  @Mutation
  private MMGetColumnsNavHover(bool: boolean) {
    this.isColumnsNavHover = bool;
  }
}

export const RoutesModule = getModule(Routes);
