var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.isShowMenu && _vm.isMobile
      ? _c("div", { staticClass: "nav-mobile-mask" })
      : _vm._e(),
    _c(
      "div",
      {
        class:
          _vm.isShowMenu && _vm.isMobile
            ? "head-nav head-mobile-show"
            : "head-nav",
      },
      [
        _c("div", { class: _vm.isMobile ? "mobile-head-mask" : "head-mask" }),
        _c("div", { staticClass: "head-nav-box" }, [
          _c(
            "span",
            {
              staticClass: "logo",
              on: {
                click: function ($event) {
                  return _vm.toPath("/aicompetions/index")
                },
              },
            },
            [_vm._v("AI Competition")]
          ),
          _c("div", [
            !_vm.isMobile
              ? _c("div", { staticClass: "nav-box" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.toPath("/application")
                        },
                      },
                    },
                    [_vm._v("AI Developer")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "active",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.toPath("/aicompetions/index")
                        },
                      },
                    },
                    [_vm._v("AI创作大赛")]
                  ),
                  _c("div", { staticClass: "user-info-warp" }, [_c("User")], 1),
                ])
              : _vm._e(),
            _vm.isMobile
              ? _c("div", { staticClass: "nav-box" }, [
                  _c("div", { staticClass: "user-info-warp" }, [_c("User")], 1),
                  _c("button", {
                    class: _vm.isShowMenu
                      ? "nav-mobile-menu nav-close"
                      : "nav-mobile-menu",
                    on: { click: _vm.isShowNav },
                  }),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
    _vm.isShowMenu && _vm.isMobile
      ? _c("div", { staticClass: "show-mobile-menu" }, [
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: {
                click: function ($event) {
                  return _vm.openPath("/application")
                },
              },
            },
            [_vm._v("AI Developer")]
          ),
          _c(
            "a",
            {
              staticClass: "active",
              attrs: { href: "javascript:;" },
              on: {
                click: function ($event) {
                  return _vm.toPath("/aicompetions/index")
                },
              },
            },
            [_vm._v("AI创作大赛")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }