
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'Process',
  components: {},
})
class Process extends Vue {
  public onGoApps() {
    this.$router.push('/application')
  }
}
export default Process
