var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gate-guide-partner" }, [
    _c(
      "div",
      { staticClass: "gate-guide-contain" },
      [
        _c("guideTitle", {
          attrs: { title: _vm.$t("views.guide.partner.title"), hasMore: false },
        }),
        _c(
          "div",
          { staticClass: "guide-partner-container" },
          _vm._l(_vm.partnerList, function (item, index) {
            return _c("partnerCard", {
              key: index,
              attrs: { iconPath: item.icon, title: item.name },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }