<template>
  <div class="gate-guide-case">
    <div class="gate-guide-contain">
      <div class="title">
        <guideTitle :title="$t('views.guide.case.title')" :hasMore="false"/>
      </div>
      
      <div class="carousel">
        <caseCarouselCard :title="$t('views.guide.case.carousel1.title')" :introduction="$t('views.guide.case.carousel1.intro')" :bgPath="carouselBg1" />    
      </div>
      <div class="banner">
        <div class="cardBox">
          <div class="container" :style="animationStyle">
            <caseSwiperCard :title="$t('views.guide.case.banner1.title')" :introduction="$t('views.guide.case.banner1.intro')" :tags="$t('views.guide.case.banner1.tags')" :videoUrl="wraithVideo" :bgPath="bannerBg1" />
            <caseSwiperCard :title="$t('views.guide.case.banner2.title')" :introduction="$t('views.guide.case.banner2.intro')" :tags="$t('views.guide.case.banner2.tags')" :videoUrl="aiMakerVideo" :bgPath="bannerBg2" />
            <caseSwiperCard :title="$t('views.guide.case.banner3.title')" :introduction="$t('views.guide.case.banner3.intro')" :tags="$t('views.guide.case.banner3.tags')" :videoUrl="fusionVideo" :bgPath="bannerBg3" />
            <caseSwiperCard :title="$t('views.guide.case.banner4.title')" :introduction="$t('views.guide.case.banner4.intro')" :tags="$t('views.guide.case.banner4.tags')" :videoUrl="godCanTalkVideo" :bgPath="bannerBg4" />
          </div>
        </div>
        <div class="maskContainer">
          <div class="leftMask mask">
            <div class="leftBtnBg btnBg" @click="toLast" v-show="this.translateIndex > 0">
              <div class="leftBtn Btn"></div>
            </div>
          </div>
          <div class="rightMask mask">
            <div class="rightBtnBg btnBg" @click="toNext" v-show="this.translateIndex < this.translateXList.length - 1">
              <div class="rightBtn Btn"></div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="style-spacer"></div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import caseCarouselCard from './components/caseCarouselCard.vue';
import carouselBg1 from '../../assets/guide/case/carousel_bg_1.png';
import bannerBg1 from '../../assets/guide/case/banner_bg_1.png';
import bannerBg2 from '../../assets/guide/case/banner_bg_2.png';
import bannerBg3 from '../../assets/guide/case/banner_bg_3.png';
import bannerBg4 from '../../assets/guide/case/banner_bg_4.png';
import wraithVideo from '../../assets/guide/case/video/wraith.mp4';
import aiMakerVideo from '../../assets/guide/case/video/aiMaker.mp4';
import fusionVideo from '../../assets/guide/case/video/fusion.mp4';
import godCanTalkVideo from '../../assets/guide/case/video/godCanTalk.mp4';
import caseSwiperCard from './components/caseSwiperCard.vue';
export default {
  name: 'guide-case',
  components: { guideTitle, caseCarouselCard, caseSwiperCard },
  data() {
    return {
      carouselBg1,
      bannerBg1,
      bannerBg2,
      bannerBg3,
      bannerBg4,
      wraithVideo,
      aiMakerVideo,
      fusionVideo,
      godCanTalkVideo,
      translateIndex: 0,
      translateXList: [0, -610, -1220]
    }
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    toLast() {
      if (this.translateIndex > 0) {
        this.translateIndex --;
      }
    },
    toNext() {
      if (this.translateIndex < this.translateXList.length - 1) {
        this.translateIndex ++;
      }
    }
  },
  computed: {
    animationStyle() {
      return { transform: 'translateX(' + this.translateXList[this.translateIndex] + 'px)' };
    }
  }
}

</script>

<style lang="scss" scoped>

.gate-guide-case {
  margin-top: 150px;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
  background-image: url('../../assets/guide/guide_case_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .gate-guide-contain{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title{
      width: 1200px;
      display: flex;
      justify-content: center;
    }
    .banner{
      width: 100%;
      min-width: 1440px;
      height: 525px;
      overflow: hidden;
      position: relative;
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cardBox{
        width: 1200px;
        height: 100%;
        .container{
          width: 2420px;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: start;
          flex-direction: row;
          transition: all ease-in-out 1s;
        }
      }
        .mask{
          position: absolute;
          top: 0;
          width: calc(50% - 600px);
          height: 100%;
          background: rgba($color: #000000, $alpha: 0.8);
          .btnBg{
            position: absolute;
            top: 150px;
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            opacity: 1;
            background: url('../../assets/guide/case/arrow_bg.png') no-repeat;
            background-size: 100% 100%;
            .Btn{
              width: 64px;
              height: 45px;
            }
          }
        }
        .leftMask{
          left: 0;
          .leftBtnBg{
            right: 20px;
            .leftBtn{
              background: url('../../assets/guide/case/left_arrow.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .rightMask{
          right: 0;
          .rightBtnBg{
            left: 20px;
            .rightBtn{
              background: url('../../assets/guide/case/right_arrow.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      
    }
    .carousel{
      width: 1200px;
      height: 700px;
      margin-top: 20px;
      
      .el-carousel--horizontal{
        height: 680px;
        :deep(.el-carousel__container){
          height: 680px !important;
        }
      }
    }
  }
  .style-spacer{
    width: 100%;
    height: 150px;
  }
}
</style>
