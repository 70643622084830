var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "page-developer-app-info" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "info-tabs",
          model: {
            value: _vm.tab.info,
            callback: function ($$v) {
              _vm.$set(_vm.tab, "info", $$v)
            },
            expression: "tab.info",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "产品设置", name: "general" } },
            [
              _c("keep-alive", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab.info == "general",
                        expression: "tab.info == 'general'",
                      },
                    ],
                  },
                  [
                    _c("InfoGeneral", {
                      on: { changeTab: (tabName) => (_vm.tab.info = tabName) },
                    }),
                    _c("InfoClient"),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "作品信息", name: "info" } },
            [
              _c(
                "keep-alive",
                [
                  _c("ProductInfo", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab.info == "info",
                        expression: "tab.info == 'info'",
                      },
                    ],
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }