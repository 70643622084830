var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.chils, function (val) {
      return _c(
        "div",
        { key: val.path },
        [
          val.children && val.children.length > 0
            ? _c(
                "el-sub-menu",
                {
                  key: val.path,
                  attrs: { index: val.path },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { class: val.meta.icon }),
                            _c("span", [_vm._v(_vm._s(val.meta.title))]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("sub-item", { attrs: { chil: val.children } })],
                1
              )
            : [
                _c(
                  "el-menu-item",
                  { key: val.path, attrs: { index: val.path } },
                  [
                    !val.meta.isLink || (val.meta.isLink && val.meta.isIframe)
                      ? [
                          _c("i", { class: val.meta.icon }),
                          _c("span", [_vm._v(_vm._s(val.meta.title))]),
                        ]
                      : [
                          _c(
                            "a",
                            {
                              staticClass: "w100",
                              attrs: {
                                href: val.meta.isLink,
                                target: "_blank",
                                rel: "opener",
                              },
                            },
                            [
                              _c("i", { class: val.meta.icon }),
                              _vm._v(" " + _vm._s(val.meta.title) + " "),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }