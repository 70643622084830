
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import SubItem from '@/layout/navMenu/subItem.vue';

@Component({
  name: 'navMenuVertical',
  components: { SubItem },
})
class navMenuVertical extends Vue {
  @Prop({ default: () => [] }) private menuList!: any[]

  public defaultActive = this.$route.meta.isDynamic ? this.$route.meta.isDynamicPath : this.$route.path
  public isCollapse = false

  // 获取父级菜单数据
  get menuLists() {
    return <any>this.menuList;
  }

  @Watch('$route', { immediate: true })
  onRouteChanged(val: any) {
    if(val.meta && val.meta.module == 'base') {
      this.defaultActive = val.path
    }
    else {
      this.defaultActive = ''
    }
  }
  
  // 页面加载时
  mounted() {
    this.defaultActive = this.setParentHighlight(this.$route);
  }
  // 路由更新时
  // onBeforeRouteUpdate((to) => {
  //   state.defaultActive = setParentHighlight(to);
  // });

  // 菜单高亮（详情时，父级高亮）
  private setParentHighlight(currentRoute: any) {
    const { path, meta } = currentRoute;
    const pathSplit = meta.isDynamic ? meta.isDynamicPath.split('/') : path.split('/');
    if (pathSplit.length >= 4 && meta.isHide) return pathSplit.splice(0, 3).join('/');
    else return path;
  }
}
export default navMenuVertical
