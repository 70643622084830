var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "view-account-client" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", {
          staticClass: "header-title",
          on: { click: _vm.onLogoClick },
        }),
        _c("div", { staticClass: "header-right" }, [
          _c(
            "div",
            { staticClass: "header-lang" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "header-pop dark",
                    placement: "bottom",
                    trigger: "hover",
                    width: 100,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function () {
                        return [
                          _c("div", {
                            staticClass: "header-lang-icon",
                            class: [_vm.active.lang],
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "pop-list" },
                    _vm._l(_vm.langList, function (item, i) {
                      return _c(
                        "li",
                        {
                          key: i,
                          class: { active: _vm.active.lang == item.key },
                          on: {
                            click: function ($event) {
                              return _vm.onChangeLang(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item[_vm.$tk("name")]))]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          !_vm.session.token
            ? _c(
                "button",
                {
                  staticClass: "header-btn login",
                  on: { click: _vm.goToLogin },
                },
                [_vm._v(_vm._s(_vm.$t("views.home.signin")))]
              )
            : _c(
                "div",
                { staticClass: "header-user" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "header-pop dark",
                        placement: "bottom",
                        trigger: "hover",
                        width: 100,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "reference",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "header-user-name" }, [
                                _c("img", { attrs: { src: _vm.avatarUrl } }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.userInfo.nickname)),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("ul", { staticClass: "pop-list" }, [
                        _c("li", { on: { click: _vm.logout } }, [
                          _vm._v(_vm._s(_vm.$t("views.home.signout"))),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "banner-wrap" },
        [
          _c(
            "el-carousel",
            {
              staticClass: "banner",
              attrs: {
                trigger: "click",
                arrow: "never",
                height: "6rem",
                "indicator-position": "none",
              },
            },
            _vm._l(_vm.bannerList, function (item) {
              return _c("el-carousel-item", { key: item.id }, [
                _c("div", { staticClass: "banner-pic" }, [
                  _c("img", { attrs: { src: item.img } }),
                  _c("div", { staticClass: "banner-pic-info" }, [
                    _c("h1", [_vm._v("AI GAMES")]),
                    _c("p", [
                      _vm._v(
                        "Don’t just get the game. Get more from your game. Unlock exclusive rewards, members-only content, and a library of top titles."
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToBanner(item)
                          },
                        },
                      },
                      [_vm._v("Join Now →")]
                    ),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "selection" }, [
          _c("div", { staticClass: "selection-box" }, [
            _c("span", { staticClass: "title" }, [_vm._v("AI作品")]),
            _c(
              "div",
              { staticClass: "tabs" },
              [
                _c(
                  "span",
                  {
                    staticClass: "tabs-item",
                    class: { active: _vm.curTab === "all" },
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("all")
                      },
                    },
                  },
                  [_vm._v("全部")]
                ),
                _vm._l(_vm.AITrackList, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.key,
                      staticClass: "tabs-item",
                      class: { active: _vm.curTab === item.key },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(item.key)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-input",
                {
                  staticClass: "search-input",
                  attrs: { placeholder: "搜索作品" },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.onSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.condition.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.condition, "name", $$v)
                    },
                    expression: "condition.name",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "gamelist" },
          _vm._l(_vm.fitGameList, function (item) {
            return _c(
              "div",
              {
                key: item.appId,
                staticClass: "gamelist-item",
                on: {
                  click: function ($event) {
                    return _vm.goToGame(item)
                  },
                },
              },
              [
                _c("img", { staticClass: "poster", attrs: { src: item.icon } }),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.appName)),
                ]),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible.toTop,
                expression: "visible.toTop",
              },
            ],
            staticClass: "btn-top",
            on: { click: _vm.scrollToTop },
          },
          [_c("i", { staticClass: "el-icon-arrow-up" })]
        ),
      ]),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "footer" }, [
      _c("img", { attrs: { src: require("../../assets/home/ai/logo-w.png") } }),
      _c("p", [_vm._v("Copyright © 2023 AI Competition.All rights reserved")]),
      _c("div", { staticClass: "btns" }, [
        _c("img", {
          attrs: { src: require("../../assets/home/ai/logo-facebook.png") },
        }),
        _c("img", {
          attrs: { src: require("../../assets/home/ai/logo-youtube.png") },
        }),
        _c("img", {
          attrs: { src: require("../../assets/home/ai/logo-twitter.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }