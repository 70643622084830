var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "page-developer-sdk" } },
    [
      _c("div", { staticClass: "layout-header" }, [
        _c(
          "div",
          { staticClass: "layout-navbars-breadcrumb-index" },
          [
            _c("div", { staticClass: "sdk-menu" }, [
              _c(
                "a",
                {
                  class: { active: _vm.curtab === "mytool" },
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange("mytool", "update")
                    },
                  },
                },
                [_vm._v("我的工具")]
              ),
              _c(
                "a",
                {
                  class: { active: _vm.curtab === "toolmarket" },
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange("toolmarket")
                    },
                  },
                },
                [_vm._v("AI工具市场")]
              ),
            ]),
            _c("User"),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curtab === "mytool",
              expression: "curtab === 'mytool'",
            },
          ],
        },
        [
          _vm._l(_vm.sdkList, function (item) {
            return _c(
              "div",
              { key: item.Name, staticClass: "app-section" },
              [
                _c("h1", { staticClass: "app-title" }, [
                  _vm._v(_vm._s(item.Title)),
                ]),
                _vm._l(item.Items || [], function (subItem) {
                  return _c(
                    "div",
                    {
                      key: subItem.Name,
                      staticClass: "app-doc",
                      on: {
                        click: function ($event) {
                          return _vm.goToDocment(subItem)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "content" }, [
                        _c("img", {
                          attrs: {
                            src: subItem.Icon || "/files/logo/default.png",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            attrs: { title: subItem.Name },
                          },
                          [_vm._v(_vm._s(subItem.Name))]
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          _c("div", { staticClass: "collect-list-warp" }, [
            _c("div", { staticClass: "collect-title" }, [
              _vm._v("我收藏的AI工具"),
            ]),
            _vm.collectList.length > 0
              ? _c(
                  "div",
                  { staticClass: "collect-data" },
                  _vm._l(_vm.collectList, function (tools, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "tool-list-warp" }, [
                        _c("div", { staticClass: "tool-title-warp" }, [
                          _c("div", { staticClass: "tool-title" }, [
                            _vm._v(_vm._s(tools.typeName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "tool-list" }, [
                          _c(
                            "div",
                            { staticClass: "tool-item-warp" },
                            _vm._l(tools.typeList, function (item, childIndex) {
                              return _c(
                                "div",
                                {
                                  key: childIndex,
                                  class:
                                    tools.type === "recentuse"
                                      ? "tool-item tool-item-recentuse"
                                      : "tool-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetails(item)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "toll-top" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _c("img", {
                                        attrs: {
                                          src: item.tools_basic_data.icon,
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.tools_basic_data.tools_name
                                          )
                                        ),
                                      ]),
                                    ]),
                                    tools.type === "recentuse"
                                      ? _c(
                                          "div",
                                          { staticClass: "link-start" },
                                          [
                                            _c("div", {
                                              staticClass: "link",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openLink(
                                                    item.tools_basic_data
                                                      .web_url
                                                  )
                                                },
                                              },
                                            }),
                                            _c("div", {
                                              staticClass: "delete-btn",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.delRecentuse(
                                                    item.tools_basic_data
                                                      .tools_id,
                                                    childIndex
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "link-start" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "more-btn-warp",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.isShowMore(
                                                      index,
                                                      childIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "more-btn-box",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: item.more,
                                                            expression:
                                                              "item.more",
                                                          },
                                                        ],
                                                        staticClass: "more-box",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "info",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.openLink(
                                                                  item
                                                                    .tools_basic_data
                                                                    .web_url,
                                                                  item
                                                                    .collect_data
                                                                    .tools_id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查看详情")]
                                                        ),
                                                        item.tools_basic_data
                                                          .is_collect === 1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "start-text",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteCollect(
                                                                        item
                                                                          .collect_data
                                                                          .tools_id,
                                                                        index,
                                                                        childIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "取消收藏"
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "start-text",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.toolsCollect(
                                                                        item
                                                                          .collect_data
                                                                          .tools_id,
                                                                        index,
                                                                        childIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "加入收藏"
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]),
                                  tools.type !== "recentuse"
                                    ? _c(
                                        "div",
                                        { staticClass: "tool-describe" },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                item.tools_basic_data
                                                  .description
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "describe-warp" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "describe-box" },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "describe-conten",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.tools_basic_data
                                                            .description
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  tools.type !== "recentuse"
                                    ? _c(
                                        "div",
                                        { staticClass: "tool-label-list" },
                                        _vm._l(
                                          item.tools_basic_data.label.slice(
                                            0,
                                            4
                                          ),
                                          function (label, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "tool-label-item",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("lableFormat")(label)
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _c("div", { staticClass: "no-collect-data" }, [
                  _c("div", { staticClass: "more-btn-warp" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.tabChange("toolmarket")
                          },
                        },
                      },
                      [_vm._v("获取更多")]
                    ),
                    _c("img", {
                      attrs: {
                        src: require("../../assets/developer/chevron-right.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "no-collect-content" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/developer/no-collect.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无收藏")]),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.tabChange("toolmarket")
                          },
                        },
                      },
                      [_vm._v("获取更多")]
                    ),
                  ]),
                ]),
          ]),
          _vm.collectList.length === 0
            ? _c("div", { staticClass: "tool-recommend-warp" }, [
                _c("div", { staticClass: "tool-title" }, [_vm._v("精选推荐")]),
                _c("div", { staticClass: "tool-list" }, [
                  _c(
                    "div",
                    { staticClass: "tool-item-warp" },
                    _vm._l(_vm.classifyList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tool-item",
                          on: {
                            click: function ($event) {
                              return _vm.showDetails(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "toll-top" }, [
                            _c("div", { staticClass: "name" }, [
                              _c("img", {
                                attrs: {
                                  src: item.tools_basic_data.icon,
                                  alt: "",
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.tools_basic_data.tools_name)
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "link-start" }, [
                              _c("div", {
                                staticClass: "link",
                                on: {
                                  click: function ($event) {
                                    return _vm.openLink(
                                      item.tools_basic_data.web_url
                                    )
                                  },
                                },
                              }),
                              item.tools_basic_data.is_collect === 1
                                ? _c("div", {
                                    staticClass: "star_active",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteCollect(
                                          item.tools_basic_data.tools_id,
                                          index,
                                          -1
                                        )
                                      },
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "start",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toolsCollect(
                                          item.tools_basic_data.tools_id,
                                          index,
                                          -1
                                        )
                                      },
                                    },
                                  }),
                            ]),
                          ]),
                          _c("div", { staticClass: "tool-describe" }, [
                            _c("p", [
                              _vm._v(_vm._s(item.tools_basic_data.description)),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "tool-label-list" },
                            _vm._l(
                              item.tools_basic_data.label.slice(0, 4),
                              function (label, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "tool-label-item",
                                  },
                                  [_vm._v(_vm._s(_vm._f("lableFormat")(label)))]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm.curtab === "toolmarket"
        ? _c(
            "div",
            [_c("ToolMarket", { on: { showDetails: _vm.showDetails } })],
            1
          )
        : _vm._e(),
      _c("div", [_c("ToolDetailsVue", { ref: "details" })], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }