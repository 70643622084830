
  import { Component, Vue } from 'vue-property-decorator'
  import { LangList } from '@/config'
  import Header from '@/layout/component/community/header.vue'
  import Footer from '@/layout/component/community/footer.vue'
  
  @Component({
    name: 'layoutAccount',
    components: { Header, Footer },
  })
  class layoutAccount extends Vue {
    private active = {
      lang: 'zh-cn'
    }
    private langList = LangList
  
    get key() {
      return this.$route.path;
    }
  
    public onLogoClick() {
      // this.$router.push(isClient ? '/home/client' : '/home/browser');
    }
    private onChangeLang(lang: any) {
      this.active.lang = lang.key;
      this.$i18n.locale = lang.key;
    }
  }
  export default layoutAccount
  