export const ProductStatusList = [
  {
    key: 0,
    name: '开发中',
    name_en: 'Developing',
  },
  {
    key: 1,
    name: '即将开放',
    name_en: 'Coming soon',
  },
  {
    key: 2,
    name: '抢先体验',
    name_en: 'Experience',
  },
  {
    key: 3,
    name: '已发布',
    name_en: 'Released',
  },
];
export const MediumTypeList = [
  {
    key: 0,
    name: '官方网站',
    name_en: 'Official site',
  },
  {
    key: 1,
    name: 'Twitter',
    name_en: 'Twitter',
  },
  {
    key: 2,
    name: 'Facebook',
    name_en: 'Facebook',
  },
  {
    key: 3,
    name: 'Instagram',
    name_en: 'Instagram',
  },
  {
    key: 4,
    name: 'Youtube',
    name_en: 'Youtube',
  },
  {
    key: 5,
    name: 'Discord',
    name_en: 'Discord',
  },
];
export const PlatformList = [
  {
    key: 0,
    name: 'windows',
    name_en: 'windows',
  },
  {
    key: 1,
    name: 'macos',
    name_en: 'macos',
  },
  {
    key: 2,
    name: 'pcweb',
    name_en: 'pcweb',
  },
  {
    key: 3,
    name: 'android',
    name_en: 'android',
  },
  {
    key: 4,
    name: 'ios',
    name_en: 'ios',
  },
  {
    key: 5,
    name: 'h5',
    name_en: 'h5',
  },
  {
    key: 6,
    name: 'xbox',
    name_en: 'xbox',
  },
  {
    key: 7,
    name: 'ps',
    name_en: 'ps',
  },
  {
    key: 8,
    name: 'wii',
    name_en: 'wii',
  },
];


export let AITypeListMap = {
  1: [
    {
      key: 'game',
      name: '游戏',
      tracks: ['game', 'aitoolkit', 'visualart', 'auditoryart', 'narrative']
    },
    {
      key: 'aitoolkit',
      name: 'AI工具包',
      tracks: ['aitoolkit']
    },
    {
      key: 'visualart',
      name: '视觉艺术',
      tracks: ['visualart']
    },
    {
      key: 'auditoryart',
      name: '听觉艺术',
      tracks: ['auditoryart']
    },
    {
      key: 'narrative',
      name: '叙事文本',
      tracks: ['narrative']
    }
  ],
  2: [
    {
      key: 'game',
      name: '游戏',
      tracks: ['game']
    },
    {
      key: 'aitoolkit',
      name: 'AI工具',
      tracks: ['aitoolkit']
    },
    {
      key: 'visualart',
      name: '视觉创意',
      tracks: ['visualart']
    },
  ]
}
AITypeListMap[0] = AITypeListMap[1]
export let AITrackListMap = {
  1: [
    {
      key: 'game',
      name: '游戏',
    },
    {
      key: 'aitoolkit',
      name: 'AI工具包',
    },
    {
      key: 'visualart',
      name: '视觉艺术',
    },
    {
      key: 'auditoryart',
      name: '听觉艺术',
    },
    {
      key: 'narrative',
      name: '叙事文本',
    }
  ],
  2: [
    {
      key: 'game',
      name: '游戏',
    },
    {
      key: 'aitoolkit',
      name: 'AI工具',
    },
    {
      key: 'visualart',
      name: '视觉创意',
    },
  ]
}
AITrackListMap[0] = AITrackListMap[1]

export const AIToolMenuList = [
  {
    name: '全部',
    key: 'all', 
    active: true
  },
  {
    name: '程序编码',
    key: 'choice', 
    active: false,
    index: 1
  },
  {
    name: '游戏策划案',
    key: 'gamePlan', 
    active: false,
    index: 2
  },
  {
    name: '美术创意设计',
    key: 'painting', 
    active: false,
    index: 3
  },
  {
    name: '语音生成',
    key: 'voice', 
    active: false,
    index: 4
  },
  {
    name: '视频生成',
    key: 'video', 
    active: false,
    index: 5
  },
  {
    name: '文本生成',
    key: 'text', 
    active: false,
    index: 6
  },
]

export const AIToolPlatform = [
  {
    key: 0,
    name: '其他'
  },
  {
    key: 1,
    name: 'chatgpt'
  },
  {
    key: 2,
    name: '办公助手'
  },
  {
    key: 3,
    name: '机器人对话'
  },
  {
    key: 4,
    name: 'ai引擎'
  }
]