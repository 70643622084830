<template>
  <div class="publish-header">
    <div class="logo">logo</div>
    <div class="menu-container">
      <div class="icon-arrow-left"></div>
      <div :class="{'menu': true, 'active': currentMenu == item.key}" v-for="(item, index) in menuList" :key="index">{{ item.label }}</div>
    </div>
    <div class="user">user</div>
  </div>
</template>
  
  <script>
  export default {
    name: 'publish-header',
    props: {
      currentMenu: {
        type: String,
        default: 'article'
      }
    },
    data() {
      return {
        menuList: [
          { key: 'article', label: 'Publish article' },
          { key: 'video', label: 'Publish videos' },
        ]
      }
    },
    mounted() {
    },
    methods: {
      onSubmit() {
        
      }
    }
  }
  </script>
  <style lang="scss">
  .publish-header {
    padding: 32px 0;
    margin: 0;
    width: 100%;
    height: 113px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .menu-container{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon-arrow-left{
        width: 24px;
        height: 24px;
        background: url('../../../../assets/article/icon/arrow_left.png') no-repeat;
        background-size: cover;
      }
      .menu{
        color: rgba($color: #fff, $alpha: 0.6);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: 42px;
      }
      .active{
        color: #fff;
      }
    }
  }
  </style>