import request from '@/utils/request';
import { AccountDomain } from '@/utils/env';

const BaseUrl = `${AccountDomain}/openpf/api`;
export function AOauthLogin(data: object, loginChallenge: string) {
  return request({
    url: `${BaseUrl}/login?login_challenge=${loginChallenge}`,
    method: 'POST',
    data,
  });
}

export function AOauthConsent(data: object, consentChallenge: string) {
  return request({
    url: `${BaseUrl}/consent?consent_challenge=${consentChallenge}`,
    method: 'POST',
    data,
  });
}
