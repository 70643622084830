var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "layout-navbars-breadcrumb-user", style: { flex: 1 } },
    [
      _c(
        "el-dropdown",
        {
          attrs: { "show-timeout": 70, "hide-timeout": 50 },
          on: { command: _vm.onHandleCommandClick },
          scopedSlots: _vm._u([
            {
              key: "dropdown",
              fn: function () {
                return [
                  _c(
                    "el-dropdown-menu",
                    [
                      _c("el-dropdown-item", { attrs: { command: "logOut" } }, [
                        _vm._v(_vm._s(_vm.$t("views.account.logout"))),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", { staticClass: "layout-navbars-breadcrumb-user-link" }, [
            _c("img", {
              staticClass: "layout-navbars-breadcrumb-user-link-photo",
              attrs: {
                src: _vm.avatarUrl,
                onerrοr: (this.src =
                  "https://image.guangzi.qq.com/uploadfiles/oasis/avatar_default.jpg"),
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.userInfo.nickname))]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }