import request from '@/utils/request';
import { AccountDomain, OpenDomain } from '@/utils/env';

// 开放平台
const BaseUrl = `${OpenDomain}/api`;

// 接口-客户端

export async function AClientAdd(appid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/client/create?appid=${appid}`,
    method: 'POST',
    data,
  });
}
export async function AClientUpt(appid: string, clientid: string, data: object) {
  return request({
    url: `${BaseUrl}/app/client/update?appid=${appid}&clientid=${clientid}`,
    method: 'POST',
    data,
  });
}
export async function AClientDel(appid: string, clientid: string) {
  return request({
    url: `${BaseUrl}/app/client/delete?appid=${appid}&clientid=${clientid}`,
    method: 'POST',
  });
}