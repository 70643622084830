
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { escape, unescape } from 'lodash-es'
import { ADevAICommentAllGET, ADevAICommentScoreGET, ADevAICommentSelfGET, ADevAICommentSelfAdd, ADevAICommentSelfUpt } from '@/api/game'
import { CommentItem, CommentScore } from '@/api/types/game'
import { parseAvatarUrl } from '@/utils'
import { normalizeDate, formatDate } from '@/utils/format'
import { DefaultAvatar } from '@/config'

const DefaultScore: CommentScore = {
  appId: '',
  avg: 0,
  scores: {
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
  },
  sum: 0
}
@Component({
  name: 'Comment',
  components: {},
})
class DgOpenClient extends Vue {
  @Prop({ required: true, default: '' }) public appId

  public parseAvatarUrl = parseAvatarUrl
  public scoreLevel = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
  }
  public comment = {
    // scoreTotal: 3,
    // scoreList: {
    //   1: 3,
    //   2: 2,
    //   3: 1,
    //   4: 0,
    //   5: 0,
    // },
    scoreSelf: 0,
    textSelf: '',
  }
  public scoreColors = ['#FF7A01', '#FF7A01', '#FF7A01']
  public scoreColors2 = ['#ffffffE6', '#ffffffE6', '#ffffffE6']
  public scoreLightColors = ['#FF7A01', '#FF7A01', '#FF7A01']
  public scoreHalfTransColor = 'rgba(243, 169, 24, 0.1)'
  public scoreVoidColor = 'rgba(255,255,255,0.1)'
  // public scoreVoidColor = 'rgba(255,255,255,0.9)'
  public scoreTransColor = 'transparent'
  public scoreColor = ['#FF7A01', '#FF7A01', '#FF7A01']
  public progressColor = 'rgba(255,255,255,0.9)'
  public progressBgColor = 'rgba(255,255,255,0.1)'
  public commentList = [] as CommentItem[]
  public selfComment: any = null
  public commentScore: CommentScore = Object.assign({}, DefaultScore)
  public loading = {
    init: false,
    save: false,
  }
  public moreComment = {
    show: false,
    active: false,
  }
  public splitCommentNum = 10
  public defaultState = true

  get isPc() {
    return document.documentElement.clientWidth > 750
  }
  get userInfo() {
    return this.$store.state.user.userInfo
  }
  get avatarUrl() {
    if (!this.userInfo || !this.userInfo.nickname) return DefaultAvatar
    return `https://dayu.woa.com/avatars/${this.userInfo.nickname}/profile.jpg`
  }
  get splitCommentList() {
    if (this.moreComment.active || this.commentList.length <= this.splitCommentNum) return this.commentList
    return this.commentList.slice(0, this.splitCommentNum)
  }

  @Watch('appId', { immediate: true })
  onAppIdChanged(val: string) {
    if (val) {
      this.initData()
    }
  }

  mounted() {

  }

  private async initData() {
    this.loading.init = true
    try {
      await this.getAllComment()
      await this.getAllScore()
      await this.getSelfComment()
    }
    catch (err) {
      console.error(err)
      this.$message.error("获取数据失败")
    }
    this.loading.init = false
  }
  private getAllComment() {
    return new Promise((resolve, reject) => {
      ADevAICommentAllGET(this.appId).then((res: any) => {
        let data = res.data || []
        this.commentList = data.map(item => {
          item.playability = +item.playability
          return item
        }).sort((pre, cur) => cur.updateAt - pre.updateAt)
        if (!this.moreComment.active) {
          if (this.commentList.length > this.splitCommentNum) this.moreComment.show = true
        }
        // localStorage.setItem('commentlen', this.commentList.length + '')
        resolve(data)
      }).catch(reject)
    })
  }
  private getAllScore() {
    return new Promise((resolve, reject) => {
      ADevAICommentScoreGET(this.appId).then((res: any) => {
        let data = res.data || {}
        let scores = data.scores || {}
        data = Object.assign(this.commentScore, data)
        data.scores = {
          one: scores.one || 0,
          two: scores.two || 0,
          three: scores.three || 0,
          four: scores.four || 0,
          five: scores.five || 0,
        }
        this.commentScore = data
        // localStorage.setItem('score', JSON.stringify(data))
        this.$emit('avgScore', data.avg)
        resolve(data)
      }).catch(reject)
    })
  }
  private getSelfComment() {
    return new Promise((resolve, reject) => {
      ADevAICommentSelfGET(this.appId).then((res: any) => {
        let data = res.data || null
        this.selfComment = data
        this.comment.scoreSelf = data && +data.playability || 0
        this.comment.textSelf = unescape(data && data.comment || '')

        resolve(data)
      }).catch(reject)
    })
  }

  public onAddComment() {
    // if (!this.comment.scoreSelf) {
    //   this.$message.info("请选择评分")
    //   return
    // }
    let textSelf = escape(this.comment.textSelf.trim())
    // if (!textSelf) {
    //   this.$message.info("请输入评论内容")
    //   return
    // }
    if (!this.comment.scoreSelf && !textSelf) {
      this.$message.info("请选择评分或者输入评论内容")
      return
    }
    this.loading.save = true
    const params = {
      comment: textSelf || '',
      playability: this.comment.scoreSelf || 0
    }
    // 修改
    if (this.selfComment) {
      ADevAICommentSelfUpt(this.appId, params).then((res: any) => {
        this.loading.save = false
        this.selfComment = {
          comment: textSelf,
          playability: this.comment.scoreSelf,
        }
        this.$message.success("修改成功")
        this.getAllComment()
        this.getAllScore()
      }).catch((err) => {
        this.loading.save = false
        console.error(err)
        this.$message.error("修改失败")
      })
    }
    else {
      ADevAICommentSelfAdd(this.appId, params).then((res: any) => {
        this.loading.save = false
        this.selfComment = {
          comment: textSelf,
          playability: this.comment.scoreSelf,
        }
        this.$message.success("提交成功")
        this.getAllComment()
        this.getAllScore()
      }).catch((err) => {
        this.loading.save = false
        console.error(err)
        this.$message.error("提交失败")
      })
    }
  }
  public onShowMore(status: boolean) {
    this.moreComment.show = false
    this.moreComment.active = status
  }
  public formatTime(time: string | number, format) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime, format)
  }
}
export default DgOpenClient

