
import { Component, Vue, Watch } from 'vue-property-decorator'
import { RoutesModule } from '@/store/modules/routes'
import InfoGeneral from './component/InfoGeneral.vue'
import InfoEnv from './component/InfoEnv.vue'
import InfoClient from './component/InfoClient.vue'
import InfoGroup from './component/InfoGroup.vue'
import ProductInfo from './component/productInfo.vue'

@Component({
  name: 'DeveloperAppInfo',
  components: {
    InfoGeneral,
    InfoEnv,
    InfoClient,
    InfoGroup,
    ProductInfo,
  }
})
class DeveloperAppInfo extends Vue {
  public tab = {
    info: 'general'
  }

  @Watch('$route.query.appId', { immediate: true })
  onRouteQueryAppIdChanged(val: string) {
    if (val) this.tab.info = 'general'
  }
  @Watch('tab.info')
  onTabInfoChanged(val: string) {
    let path = this.$router.history.current.path, query = this.$router.history.current.query
    let newQuery = JSON.parse(JSON.stringify(query))
    newQuery.tab = val
    newQuery.subtab = undefined
    this.$router.push({ path, query: newQuery })
  }

  created() {
    RoutesModule.MASetNavList([{ path: "/application", meta: { title: "作品列表" } }, { path: "/app/info", meta: { title: "作品信息" } }]);
    if (this.$route.query.tab) this.tab.info = this.$route.query.tab as string
  }

}
export default DeveloperAppInfo
