var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "comp-container", attrs: { id: "comp-app-store-promot" } },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "com-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "large",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "cover", label: "封面图" } },
            [
              _c(
                "Upload",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "upload-box",
                  attrs: {
                    drag: "",
                    accept: _vm.ImgFileTypeList.map((type) => "." + type).join(
                      ","
                    ),
                    maxSize: 1024,
                    "show-file-list": false,
                    disabled: !_vm.isCurStage,
                  },
                  on: {
                    success: (res, file) =>
                      _vm.onUploadSuccess("cover", res, file),
                    beforeUpload: (file) => _vm.beforeUpload("cover", file),
                    error: (error, file) =>
                      _vm.onUploadError("cover", error, file),
                  },
                },
                [
                  _vm.form.cover
                    ? _c("div", { staticClass: "previewimg" }, [
                        _c("img", {
                          attrs: { src: _vm._f("fCosBase")(_vm.form.cover) },
                        }),
                      ])
                    : [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _c("em", [_vm._v("点击上传")]),
                          _vm._v(" / 拖拽到此区域"),
                        ]),
                      ],
                ],
                2
              ),
              _c("div", { staticClass: "upload-tips" }, [
                _vm._v("支持图片文件格式，建议尺寸：1920x1080"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "movie", label: "宣传影片" } },
            [
              _c(
                "Upload",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "upload-box",
                  attrs: {
                    drag: "",
                    accept: _vm.VidFileTypeList.map((type) => "." + type).join(
                      ","
                    ),
                    maxSize: 10240,
                    "show-file-list": false,
                    disabled: !_vm.isCurStage,
                  },
                  on: {
                    success: (res, file) =>
                      _vm.onUploadSuccess("movie", res, file),
                    beforeUpload: (file) => _vm.beforeUpload("movie", file),
                    error: (error, file) =>
                      _vm.onUploadError("movie", error, file),
                  },
                },
                [
                  _vm.form.movie
                    ? _c(
                        "div",
                        { staticClass: "previewimg video" },
                        [
                          _c("Picture", {
                            attrs: {
                              src: _vm._f("fCosPoster")(_vm.form.movie),
                            },
                            on: {
                              success: function ($event) {
                                _vm.loaded.video = true
                              },
                              error: function ($event) {
                                _vm.loaded.video = false
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "icon-play",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.playVideo(_vm.form.movie)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _c("em", [_vm._v("点击上传")]),
                          _vm._v(" / 拖拽到此区域"),
                        ]),
                      ],
                ],
                2
              ),
              _c("div", { staticClass: "upload-tips" }, [
                _vm._v("支持视频文件格式，只能上传 1个视频"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "pictures", label: "宣传图片" } },
            [
              _c(
                "Upload",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "upload-box",
                  attrs: {
                    drag: "",
                    accept: _vm.ImgFileTypeList.map((type) => "." + type).join(
                      ","
                    ),
                    maxSize: 1024,
                    "show-file-list": false,
                    disabled: !_vm.isCurStage,
                  },
                  on: {
                    success: (res, file) =>
                      _vm.onUploadSuccess("pictures", res, file),
                    beforeUpload: (file) => _vm.beforeUpload("pictures", file),
                    error: (error, file) =>
                      _vm.onUploadError("pictures", error, file),
                  },
                },
                [
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _c("em", [_vm._v("点击上传")]),
                      _vm._v(" / 拖拽到此区域"),
                    ]),
                  ],
                ],
                2
              ),
              _c("div", { staticClass: "upload-tips" }, [
                _vm._v(
                  "支持图片文件格式，最多只能上传 10 张图片，建议尺寸：1920x1080"
                ),
              ]),
              _vm._l(_vm.form.pictures, function (item, i) {
                return _c("div", { key: i, staticClass: "previewimg list" }, [
                  _c("img", { attrs: { src: _vm._f("fCosBase")(item) } }),
                  _vm.isCurStage
                    ? _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.onDelPicture(i)
                          },
                        },
                      })
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item-btns" },
        [
          _vm.cHasChanged
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "aiDisabled",
                      rawName: "v-aiDisabled",
                      value: _vm.isCurStage,
                      expression: "isCurStage",
                    },
                  ],
                  staticClass: "form-btn cancel",
                  attrs: {
                    loading:
                      _vm.loading.save ||
                      Object.values(_vm.loading.upload).some((flag) => flag),
                    disabled: !_vm.isCurStage,
                  },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "aiDisabled",
                  rawName: "v-aiDisabled",
                  value: _vm.isCurStage,
                  expression: "isCurStage",
                },
              ],
              staticClass: "form-btn save",
              attrs: {
                type: "primary",
                loading:
                  _vm.loading.save ||
                  Object.values(_vm.loading.upload).some((flag) => flag),
                disabled: !_vm.isCurStage,
              },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存并下一步")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.visible.video,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.visible, "video", $event)
            },
            open: _vm.onDialogOpen,
            close: _vm.onDialogClose,
          },
        },
        [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-player",
            attrs: {
              src: _vm.videoUrl.base,
              poster: _vm.videoUrl.poster,
              loop: "",
              controls: "",
              controlslist: "nodownload",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }