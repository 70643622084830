import { RouteConfig } from 'vue-router';
import { isClient } from '@/utils/env';

/**
 * 定义默认路由
 * @description 当单独打包时，会设置默认路由
 */
export const defaultRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: '/',
    component: () => import('@/layout/index.vue'),
    redirect: '/aicompetions/index',
    meta: {
      isKeepAlive: true,
    },
    children: [],
  },
];

/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，
 * 会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteConfig> = [
  {
    path: '/community',
    name: 'community',
    component: () => import('@/layout/community.vue'),
    redirect: '/community/home',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'home',
        name: 'community-home',
        component: () => import(/* webpackChunkName: "acc-home-browser" */ '@/views/community/home/index.vue'),
        meta: {
          title: 'route.home.browser',
          noSession: false,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/layout/article.vue'),
    redirect: '/article/add',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'add',
        name: 'article-add',
        component: () => import(/* webpackChunkName: "acc-home-browser" */ '@/views/community/article/add.vue'),
        meta: {
          title: 'article.add.browser',
          noSession: false,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/guide/index.vue'),
    meta: {
      noSession: true
    },
  }
];
