import { render, staticRenderFns } from "./black.vue?vue&type=template&id=1d249088&scoped=true&"
import script from "./black.vue?vue&type=script&lang=ts&scoped=true&"
export * from "./black.vue?vue&type=script&lang=ts&scoped=true&"
import style0 from "./black.vue?vue&type=style&index=0&id=1d249088&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d249088",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/landun/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d249088')) {
      api.createRecord('1d249088', component.options)
    } else {
      api.reload('1d249088', component.options)
    }
    module.hot.accept("./black.vue?vue&type=template&id=1d249088&scoped=true&", function () {
      api.rerender('1d249088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialog/black.vue"
export default component.exports