
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'LayoutIFrame',
  components: {},
})
class LayoutIFrame extends Vue {
  public height = 0;
  get uri() {
    return decodeURIComponent(`${this.$route.query.uri}`);
  }
  setHeight(e) {
    document.body.style.overflow = 'hidden';
  }
 }
export default LayoutIFrame
