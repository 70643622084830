import { lcsLength, levenshteinDistance } from '@/utils/algorithm';
import { strlen } from '@/utils';
import { RegUrl, RegNickname, RegEmail, RegPassword, RegVerifyCode, RegTestAccUserName } from '@/utils/regs';
import { Policy } from '@/config';
import i18n from '@/lang';

export function validateEmail(rule: any, value: any, callback: any) {
  if (value === '') {
    callback(new Error(i18n.t('error.email') as string));
  } else if (!RegEmail.test(value)) {
    callback(new Error(i18n.t('error.email') as string));
  } else {
    callback();
  }
}
export function validateNickname(rule: any, value: any, callback: any) {
  if (value === '') {
    callback(new Error(i18n.t('error.nickname') as string));
  } else if (!RegNickname.test(value)) {
    callback(new Error(`${i18n.t('error.lengthAt')}${Policy.MinNicknameLength}~${Policy.MaxNicknameLength}${i18n.t('error.notBlank')}`));
  } else {
    callback();
  }
}

export function validatePassword(rule: any, value: any, callback: any) {
  if (value === '') {
    callback(new Error(i18n.t('error.password') as string));
  } else if (!RegPassword.test(value)) {
    callback(new Error(`${i18n.t('error.lengthAt')}${Policy.MinPasswordLength}~${Policy.MaxPasswordLength}${i18n.t('error.notBlank')}${i18n.t('error.withSpecialChar')}`));
  } else {
    // @ts-ignore
    const email = this?.form?.email || '';
    const password = value;
    const emailLow = email.toLowerCase(); const passwordLow = password.toLowerCase();
    const dist = levenshteinDistance(emailLow, passwordLow);
    const lcs = lcsLength(emailLow, passwordLow) / passwordLow.length;
    if (dist < Policy.MinIdentifierPasswordDist || lcs > Policy.MaxIdentifierPasswordSubstrThreshold) {
      callback(new Error(i18n.t('error.sameEmailPsw') as string));
    } else {
      callback();
    }
  }
}
export function validatePasswordSnd(rule: any, value: any, callback: any) {
  // @ts-ignore
  const password = this?.form?.password;
  if (value === '') {
    callback(new Error(i18n.t('error.passwordSnd') as string));
  } else if (value !== password) {
    callback(new Error(i18n.t('error.pswNotSame') as string));
  } else {
    callback();
  }
}
export function validateCode(rule: any, value: any, callback: any) {
  // let reg = new RegExp(`^[\\d]{${Policy.VerifyCodeLength}}$`)
  if (value === '') {
    callback(new Error(i18n.t('error.verifyCode') as string));
  } else if (!RegVerifyCode.test(value)) {
    callback(new Error(i18n.t('error.verifyCodeFormat') as string));
  } else {
    callback();
  }
}
export function validateProtocol(rule: any, value: any, callback: any) {
  if (!value) {
    callback(new Error(i18n.t('error.protocol') as string));
  } else {
    callback();
  }
}
// app名称 中英文均为1个字符，共限制24
export function validateAppName(rule: any, value: any, callback: any) {
  const maxLen = 24;
  if (!value) {
    callback(new Error(i18n.t('error.content') as string));
  } else if (value.length > maxLen) {
    callback(new Error(`${i18n.t('error.overMax')}: ${maxLen}`));
  } else {
    callback();
  }
}

export function validateUrl(rule: any, value: any, callback: any) {
  if (value == '') callback();
  else if ((typeof value !== 'string') || !RegUrl.test(value)) {
    callback(new Error(`URL ${i18n.t('error.format')}, 请输入完整URL如 https://×××`));
  } else {
    callback();
  }
}

// 测试号-用户名
export function validateTestAccUserName(rule: any, value: any, callback: any) {
  if (value === '') {
    callback(new Error(i18n.t('error.content') as string));
  } else if (!RegTestAccUserName.test(value)) {
    callback(new Error(i18n.t('error.format') as string));
  } else {
    callback();
  }
}

// 校验空格
export function validateBackspace(rule: any, value: any, callback: any) {
  if (/\s/.test(value)) {
    callback(new Error(`${i18n.t('error.backspace')}`));
  } else {
    callback();
  }
}