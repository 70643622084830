var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "login-container page-account",
      attrs: { id: "layout-account" },
    },
    [
      _c("div", { staticClass: "login-header" }, [
        !_vm.$router.currentRoute.meta || _vm.$router.currentRoute.meta.hideLogo
          ? _c("div", {
              staticClass: "login-header-title",
              on: { click: _vm.onLogoClick },
            })
          : _vm._e(),
      ]),
      _c("router-view", { key: _vm.key }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }