var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-scrollbar",
    {
      ref: "layoutScrollbarRef",
      staticClass: "layout-scrollbar",
      style: { transition: "padding 0.3s ease-in-out" },
    },
    [_c("LayoutParentView")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }