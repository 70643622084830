<template>
    <div>
        <div class="single-module" v-for="(row, index) in rowList" :key="index">
            <div class="single-module-box">
                <!-- 第一行 -->
                <div class="single-module-box-row">
                    <div v-for="(val, i) in showList[index]" :key="i">
                        <imgBox :size="index == 0 ? 3 : 4" :propObj="val" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div>展示更多</div> -->
    </div>
</template>

<script>
import imgBox from './imgBox.vue';
import { deepClone } from '@/utils/index'
export default {
    name: 'single-module',
    components: { imgBox },
    props: {
        propsList: {
            type: Array,
            default: () => {
                return []
            }
        },
        size: {
            type: Number
        }
    },
    data() {
        return {
            // num: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            showList: []
        }
    },
    mounted() {
    },
    watch: {
        propsList: {
            handler(val) {
                if (!val.length) return
            },
            immediate: true
        }
    },
    computed: {
        rowList() {
            // let list = this.getNewArray(this.propsList, 9), res = []
            // list.forEach((arr, i) => {
            //     let rows = this.getSingleRow(arr)
            //     res.push(rows)
            // })
            // return res.flat()

            let rowList = [0, 0], resList = deepClone(this.propsList), list = []
            const { size } = this
            if (size < 4) {
                rowList[0] = size
                list[0] = resList
            } else if (size <= 7) {
                rowList[0] = 3
                rowList[1] = size - 2
                list[0] = resList.slice(0, 3)
                list[1] = resList.slice(3, 7)
            }
            // else if (size === 7) {
            //     rowList[0] = 3
            //     rowList[1] = 4
            //     list[0] = resList.slice(0, 3)
            //     list[1] = resList.slice(3, 7)
            // }
            this.showList = list
            return rowList.filter(x => x !== 0)
        }
    },
    methods: {
        // getNewArray(array, subGroupLength) {
        //     let index = 0;
        //     let newArray = [];
        //     while (index < array.length) {
        //         newArray.push(array.slice(index, index += subGroupLength));
        //     }
        //     return newArray;
        // },
        getSingleRow(arr) {  // 当至少2条数据时
            let list = [0, 0, 0]  // 最多三行(9个)
            const len = arr.length
            if (len < 5) { // 9个
                list[0] = len
            }
            else if (len < 7) {
                list[0] = 2
                list[1] = len - 2
            } else if (len < 9) {
                list[0] = 2
                list[1] = len - 4
                list[2] = 2
            } else if (len === 9 || len > 9) {
                list[0] = 2
                list[1] = 3
                list[2] = 4
            }
            return list
        }
    }
}
</script>

<style lang="scss" scoped>
.single-module {
    width: 100%;
    height: 100%;

    // overflow: hidden;
    // animation: fadeoutR 1s linear;

    &-box {

        // word-wrap: break-word;
        // word-break: normal;
        &-row {
            display: flex;
            // justify-content: space-between;
            flex: 1 1 100%;
            gap: .24rem;
        }
    }
}

@keyframes fadeoutR {
    0% {
        opacity: 1;
        transform: translateX(100%);
    }

    100% {
        opacity: 0;
        transform: translateX(0);
    }
}
</style>